var component = require('../../../lib/js/component.js');

/**
 * Init for the Promotion Module Even
 * @param  {Object} element HTML element
 */

function PromotionModuleEven() {

    this.initialize = function (element) {
        var _this = this,
            $modalPlayer = $('.PromotionPiece-video'),
            $overlayTarget = $('.PromotionModule-videoPlayer');

        _this.$playButton = $overlayTarget.find('.start-video');
        _this.$videoWrapper = $overlayTarget.find('.PromotionModule-videoPlayer-wrapper');
        _this.$videoPlayerImage =_this.$videoWrapper.find('img');
        _this.$caption = $overlayTarget.find('.PromotionModule-video-caption');
        _this.$headline = $overlayTarget.find('.PromotionModule-video-headline');
        _this.$bulletedList = $overlayTarget.find('.PromotionModule-video-bulleted-list');
        _this.player = {};
        _this.videoIsReady = false;

        $modalPlayer.attr('data-toggle', 'modal');
        $modalPlayer.attr('data-target', '#PromotionModule-videoPlayer');

        startYoutubeAPI();
       
        onPlayerReady = function (event) {
            _this.videoIsReady = true;
        }

        onPlayerStateChange = function (event) {
            if (event.data == YT.PlayerState.ENDED) {
                _this.$playButton.fadeIn('normal');
            }
        }

        $modalPlayer.on('click', function(e) {
            e.preventDefault();
            $('body').addClass('video-player-from-modal');
            $($overlayTarget).modal('hide');

            $element = $(e.currentTarget);
            _this.idVideo = $element.find('.btn-play-video').attr('data-idVideo');
            if (!window.videoPlayer) initVideoPlayer();
            _this.videoData = window.videoPlayer[_this.idVideo];
            _this.renderModal(_this.idVideo);
        });

        _this.$videoWrapper.on('click', function (e) {
            _this.playVideo();
        }.bind(_this));

        $overlayTarget.on('hide.bs.modal', function(e) {
            if (_this.videoIsReady) {
                _this.player.pauseVideo();
            }
            
            $('body').removeClass('video-player-from-modal');
        });

        _this.playVideo = function () {
            if (_this.videoIsReady) {
                var heightVideo = _this.$videoPlayerImage.height();
                _this.$videoPlayer = $('.PromotionModule-videoPlayer-wrapper iframe');

                _this.$videoPlayerImage.hide();

                _this.$videoPlayer.height(heightVideo);
                _this.player.loadVideoById(_this.idVideo);
                _this.$videoPlayer.show();

                _this.$playButton.fadeOut('normal');
                _this.player.playVideo();
            } else {
                setTimeout(function() {
                    _this.playVideo()
                }, 1000);
            }
        };

        youtubeVideosStack.push({
            context: _this,
            player: 'player',
            id: 'playerPromotionModule',
            options: {
                height: '100%',
                width: '100%',
                videoId: '',
                playerVars: {
                    'autoplay': 0,
                    'rel': 0,
                    'showinfo': 0,
                    'enablejsapi': 1
                },
                events: {
                    'onReady': onPlayerReady,
                    'onStateChange': onPlayerStateChange
                }
            }
        });
    };

    /**
     * Method to render modal information
     * @param  id of YouTube video
     */
    this.renderModal = function () {
        var _this = this,
            sourceImage = '//i.ytimg.com/vi/' + _this.idVideo + '/hqdefault.jpg';

        _this.$bulletedList.empty();

        _this.$caption.text(_this.videoData.caption);
        _this.$videoPlayerImage.attr("src", sourceImage);
        _this.$headline.text(_this.videoData.headline);

        _this.videoData.bulletedList.forEach(function (value) {
            _this.$bulletedList.append($('<li>').text(value));
        });

        _this.$videoPlayer = $('.PromotionModule-videoPlayer-wrapper iframe');
        _this.$videoPlayer.hide();
        _this.$videoPlayerImage.show();
        _this.$playButton.show();
    };
}

module.exports = component(PromotionModuleEven);
