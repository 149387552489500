var utils = require('../../lib/js/utils.js')

/**
 * Facebook Pixel Client Side Service
 */
var FacebookPixel = {
  listings : [],

  pushFormSubmissionEvent: function(formType, formName, brand) {
    if (!brand) {
      brand = window.Pulte.Brand.replace("-", " ");
    }

    var event = {
      brand: brand,
      event: 'formsubmission',
      formType: formType,
      formName: formName,
      currentPageUrl: location.href
    };

    utils.pushToDataLayer(event);
  },

  pushRequestInfoEvent: function($element) {
    // ensure facebook pixel global variable exists
    if (window.PulteFacebookPixel && $element) {
      // get page type from the global facebook pixel variable
      var pageType = window.PulteFacebookPixel.pageType;

      var dataLayerObject = $element.closest('[data-fb-pixel-model]').data('fb-pixel-model');

      if (dataLayerObject && pageType) {
        var event = {
          pageType: pageType,
          event: 'requestinfobutton',
          requestinfo: dataLayerObject
        };

        utils.pushToDataLayer(event);
      }
    }
  },

  getFormNameFromId: function (dataFormId) {
    switch (dataFormId) {
      case 'Contactnav-financing':
        return this.FORM_FINANCING_NAME;
      case 'Contactnav-community':
      case 'Contactnav-community-specific':
        return this.FORM_REQUEST_INFO_NAME;
      case 'Contactnav-homeowner-question':
        return this.FORM_HOMEOWNER_QUESTION_NAME;
      default:
        return this.FORM_SERVICE_REQUEST_NAME;
    }
  },

  // (WARNING: Changing these WILL affect Facebook Pixel targeted ad functionality)
  // Form Event Data Constants
  FORM_REQUEST_INFO_NAME: 'Request Information',
  FORM_FINANCING_NAME: 'Financing',
  FORM_HOMEOWNER_QUESTION_NAME: 'Homeowner Question',
  FORM_SERVICE_REQUEST_NAME: 'Service Request',
  FORM_HOME_REGISTRATION_NAME: 'Home Registration',
  FORM_PLAN_A_VISIT_NAME: 'Plan A Visit',
  FORM_MORTGAGE_ADVISOR_NAME: 'Mortgage Advisor',
  FORM_INLINE_TYPE: 'Inline',
  FORM_MODAL_TYPE: 'Modal'
};

// listen for clicks on request info buttons with the fb pixel request info data attribute
$(document).on('click', "[data-fb-pixel-request-info-trigger]", function () {
  FacebookPixel.pushRequestInfoEvent($(this));
});

module.exports = FacebookPixel;
