var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');

function Tabs() {

    this.initialize = function(element, options) {
        // instance variables
        
        this.main = this.$element;
        this.foundVisible = false;

        // elements and their event bindings
        var $accordionTab = this.main.find('.accordion-tab');
        var $tab = this.main.find('.tab');
        $accordionTab.on('click touch', this.filterPrimaryContent.bind(this));
        $tab.on('click touch', this.filterPrimaryContent.bind(this));

        // mobile elements and their event bindings
        var $accordionSelection = this.main.find('.accordion-selection');
        var $accordionOption = this.main.find('.accordion-option');
        $accordionSelection.on('click touch', this.toggleAccordion.bind(this));
        $accordionOption.on('click touch', this.toggleAccordion.bind(this));

        // first primary option is default, store its data-option value
        this.selectedPrimaryOption = $accordionTab.first().data().option;

        // set visible content accordingly
        this.setActiveContent();        
    }

    this.filterPrimaryContent = function (e) {
        var $el = $(e.currentTarget);
        this.selectedPrimaryOption = $el.data().option;

        this.setActiveContent();
    }

    this.setActiveContent = function () {
        //toggle active tab
        this.main.find('.tab.active').removeClass('active');
        this.main.find('.tab[data-option="' + this.selectedPrimaryOption + '"]').addClass('active');

        // mobile view
        this.main.find('.primary-accordion .accordion-selection-text').text(this.selectedPrimaryOption.trim());
        this.main.find('.tab-pane.active').removeClass('active');
        this.main.find('.tab-pane[data-option="' + this.selectedPrimaryOption + '"]').addClass('active');

        // bugfix for avoiding blurry imagery in the embedded Inspiration component
        $(window).trigger('resize');
    }

    this.toggleAccordion = function(e) {
        var $el = $(e.currentTarget);
        var $accordion = $el.closest('.accordion');
        // toggle expanded
        $accordion.toggleClass('expanded');
    }

    this.handleResize = function() {
        var windowWidth = $(window).outerWidth();
        if (windowWidth < 768) {
            $('.scrollable .scrollable-inner').each(function() {
                var spaceBetween = $(this).attr('data-space-between');
                var responsiveWidth = 0;
                var childCount = $(this).children('.scrollable-item').length;
                $(this).children('.scrollable-item').each(function(i) {
                    responsiveWidth += $(this).outerWidth() + parseInt(spaceBetween);
                    if (i === childCount - 1) {
                        responsiveWidth -= parseInt(spaceBetween);
                    }
                });
                responsiveWidth += 30; // account for the margin-left and margin-right
                $(this).css('width', responsiveWidth);

                if (responsiveWidth > windowWidth) {
                    $(this).closest('.scrollable').addClass('with-hint');
                } else {
                    $(this).closest('.scrollable').removeClass('with-hint');
                }
            });
        } else {
            $('.scrollable .scrollable-inner').each(function() {
                $(this).css('width', 'auto');
            });
        }
    }

}

module.exports = component(Tabs);