var popover = require('bootstrap-sass/assets/javascripts/bootstrap/popover');
var component = require('../../../lib/js/component.js');

function HomeDesignCompact() {

    this.initialize = function (element, options) {

        $(element).find('.btn-primary.pop').on('click',function(){
        	$(this).popover('toggle');
        	return false;
        });

    };

}

module.exports = component(HomeDesignCompact);
