
/*
    user data is in the following object window.Pulte.user, undefined if no user is logged in.
    data-user-props =comma separated list of html attributes that need to be personalized
    data-user-[propname] is personalized version of the attribute containing {propname} tokens to be merged with user data
    .user-personalized class needs to be assigned to each element that needs to be personalized
    eg <a href="url" data-user-props="href" data-user-href="url{email}">
    if user is NOT authenticated then existing prop value will be merged with current session id using token {SessionId}
*/
function isUserAuthenticated(){
    return !!(window.Pulte && window.Pulte.user);
}

function selectPersonalizedElements(){
    return $('.user-personalized');
  }
  



    function personalizeDocument(){
        selectPersonalizedElements().personalize();
    }

    function personalizeElement(el){
        $(el).find('.user-personalized').personalize();
    }

    function personalize(el){
        var attrs = $(el).data('user-props').split(',');
        if(isUserAuthenticated()){ //authenticated user
            var user = window.Pulte.user;
            attrs.forEach(function(attrName){
                var userAttr =$(el).data('user-'+attrName);
                if(userAttr){
                    for(var userProp in user){
                        userAttr=userAttr.replace('{'+userProp+'}',window.Pulte.user[userProp]);
                    }
                    $(el).prop(attrName,userAttr);
                }
            });
        } else{ //non-authenticated user, only replace sessionId
            var sessionId = window.Pulte.SessionId;
            attrs.forEach(function(attrName){
                var attrValue =$(el).prop(attrName);
                attrValue = attrValue.replace('{SessionId}',sessionId);
                $(el).prop(attrName,attrValue);
            });
        }
        
    }

      $.fn.personalize=function(){
          
          this.each(
            function(idx,el){
                personalize(el);
               }
          );
      }

      $(function(){
        personalizeDocument();
      });
  
  module.exports={
    personalizeDocument:personalizeDocument,
    personalizeElement:personalizeElement
  };