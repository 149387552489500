var component = require('../../../lib/js/component.js');
var CreateForm = require('./account-create-form.js');

var AccountCreatePage = function () {

    this.initialize = function (el, options) {
        this.options = options || {};
        this.find('.AccountCreateForm').attach(CreateForm);
    }
}

module.exports = component(AccountCreatePage);
