var templater = require("handlebars/runtime")["default"].template;module.exports = templater({"1":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=this.lambda, alias2=this.escapeExpression, alias3=helpers.helperMissing, alias4="function";

  return "\r\n    <a href=\"https://maps.google.com/maps/place/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.position : depth0)) != null ? stack1.lat : stack1), depth0))
    + ","
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.position : depth0)) != null ? stack1.lng : stack1), depth0))
    + "\" target=\"_blank\" rel=\"noopener\" data-analytics=\"directions-map|"
    + alias2(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(depth0,{"name":"name","hash":{},"data":data}) : helper)))
    + "|address-link\"\r\n      data-analytics-cta=\""
    + alias2(((helper = (helper = helpers.googleMapsDirectionsAnalyticsCTAName || (depth0 != null ? depth0.googleMapsDirectionsAnalyticsCTAName : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(depth0,{"name":"googleMapsDirectionsAnalyticsCTAName","hash":{},"data":data}) : helper)))
    + "\">\r\n      <div class=\"Map-markerContentDirections\">\r\n          <p style=\"color:"
    + alias2(((helper = (helper = helpers.addressTextColor || (depth0 != null ? depth0.addressTextColor : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(depth0,{"name":"addressTextColor","hash":{},"data":data}) : helper)))
    + ";\">\r\n            "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.address : depth0)) != null ? stack1.Street1 : stack1), depth0))
    + "<br />\r\n            "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.address : depth0)) != null ? stack1.Street2 : stack1),{"name":"if","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " \r\n            "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.address : depth0)) != null ? stack1.City : stack1), depth0))
    + ", "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.address : depth0)) != null ? stack1.StateAbbreviation : stack1),{"name":"if","hash":{},"fn":this.program(4, data, 0),"inverse":this.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.address : depth0)) != null ? stack1.ZipCode : stack1), depth0))
    + "\r\n        </p>\r\n      </div>\r\n   </a>\r\n\r\n    <p class=\"Map-markerContentPhone mb-6\" style=\"color:"
    + alias2(((helper = (helper = helpers.phoneTextColor || (depth0 != null ? depth0.phoneTextColor : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(depth0,{"name":"phoneTextColor","hash":{},"data":data}) : helper)))
    + ";\">Phone: <a href=\""
    + alias2(((helper = (helper = helpers.phoneADA || (depth0 != null ? depth0.phoneADA : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(depth0,{"name":"phoneADA","hash":{},"data":data}) : helper)))
    + "\" data-analytics-cta=\""
    + alias2(((helper = (helper = helpers.phoneAnalyticsCTAName || (depth0 != null ? depth0.phoneAnalyticsCTAName : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(depth0,{"name":"phoneAnalyticsCTAName","hash":{},"data":data}) : helper)))
    + "\" style=\"color:"
    + alias2(((helper = (helper = helpers.phoneTextColor || (depth0 != null ? depth0.phoneTextColor : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(depth0,{"name":"phoneTextColor","hash":{},"data":data}) : helper)))
    + ";\"> "
    + alias2(((helper = (helper = helpers.phone || (depth0 != null ? depth0.phone : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(depth0,{"name":"phone","hash":{},"data":data}) : helper)))
    + " </a></p>\r\n\r\n    <a href=\"https://maps.google.com/maps/place/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.position : depth0)) != null ? stack1.lat : stack1), depth0))
    + ","
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.position : depth0)) != null ? stack1.lng : stack1), depth0))
    + "\" target=\"_blank\" rel=\"noopener\" class=\"Map-markerContentGoToGoogleMap\"\r\n    data-analytics=\"directions-map|"
    + alias2(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(depth0,{"name":"name","hash":{},"data":data}) : helper)))
    + "|get-directions\" data-analytics-cta=\""
    + alias2(((helper = (helper = helpers.googleMapsDirectionsAnalyticsCTAName || (depth0 != null ? depth0.googleMapsDirectionsAnalyticsCTAName : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(depth0,{"name":"googleMapsDirectionsAnalyticsCTAName","hash":{},"data":data}) : helper)))
    + "\" style=\"color:"
    + alias2(((helper = (helper = helpers.goToGoogleMapsTextColor || (depth0 != null ? depth0.goToGoogleMapsTextColor : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(depth0,{"name":"goToGoogleMapsTextColor","hash":{},"data":data}) : helper)))
    + ";\">"
    + alias2(((helper = (helper = helpers.goToGoogleMapsText || (depth0 != null ? depth0.goToGoogleMapsText : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(depth0,{"name":"goToGoogleMapsText","hash":{},"data":data}) : helper)))
    + "</a>\r\n\r\n";
},"2":function(depth0,helpers,partials,data) {
    var stack1;

  return this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.address : depth0)) != null ? stack1.Street2 : stack1), depth0))
    + "<br />";
},"4":function(depth0,helpers,partials,data) {
    var stack1;

  return this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.address : depth0)) != null ? stack1.StateAbbreviation : stack1), depth0));
},"6":function(depth0,helpers,partials,data) {
    var stack1;

  return this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.address : depth0)) != null ? stack1.State : stack1), depth0));
},"8":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression, alias4=this.lambda;

  return "\r\n    <div class=\"Map-markerContentDirections\">\r\n      <p style=\"color:"
    + alias3(((helper = (helper = helpers.addressTextColor || (depth0 != null ? depth0.addressTextColor : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"addressTextColor","hash":{},"data":data}) : helper)))
    + ";\">\r\n        "
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.address : depth0)) != null ? stack1.Street1 : stack1), depth0))
    + "<br />\r\n        "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.address : depth0)) != null ? stack1.Street2 : stack1),{"name":"if","hash":{},"fn":this.program(9, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\r\n        "
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.address : depth0)) != null ? stack1.City : stack1), depth0))
    + ", "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.address : depth0)) != null ? stack1.StateAbbreviation : stack1),{"name":"if","hash":{},"fn":this.program(4, data, 0),"inverse":this.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + " "
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.address : depth0)) != null ? stack1.ZipCode : stack1), depth0))
    + "\r\n      </p>\r\n    </div>\r\n\r\n    <p class=\"Map-markerContentPhone\" style=\"color:"
    + alias3(((helper = (helper = helpers.phoneTextColor || (depth0 != null ? depth0.phoneTextColor : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"phoneTextColor","hash":{},"data":data}) : helper)))
    + ";\">Phone: <a href=\""
    + alias3(((helper = (helper = helpers.phoneADA || (depth0 != null ? depth0.phoneADA : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"phoneADA","hash":{},"data":data}) : helper)))
    + "\" data-analytics-cta=\""
    + alias3(((helper = (helper = helpers.phoneAnalyticsCTAName || (depth0 != null ? depth0.phoneAnalyticsCTAName : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"phoneAnalyticsCTAName","hash":{},"data":data}) : helper)))
    + "\" style=\"color:"
    + alias3(((helper = (helper = helpers.phoneTextColor || (depth0 != null ? depth0.phoneTextColor : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"phoneTextColor","hash":{},"data":data}) : helper)))
    + ";\"> "
    + alias3(((helper = (helper = helpers.phone || (depth0 != null ? depth0.phone : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"phone","hash":{},"data":data}) : helper)))
    + "</a></p>\r\n\r\n";
},"9":function(depth0,helpers,partials,data) {
    var stack1;

  return this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.address : depth0)) != null ? stack1.Street2 : stack1), depth0))
    + " <br />";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "<div id=\"info-window-directionsV2-template\" class=\"Map-markerContent clearfix mx-0\">\r\n\r\n  <h4 style=\"color:"
    + alias3(((helper = (helper = helpers.communityNameTextColor || (depth0 != null ? depth0.communityNameTextColor : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"communityNameTextColor","hash":{},"data":data}) : helper)))
    + ";\"><span>"
    + alias3(((helper = (helper = helpers.communityName || (depth0 != null ? depth0.communityName : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"communityName","hash":{},"data":data}) : helper)))
    + "</span></h4>\r\n\r\n  <p class=\"my-4\" style=\"color:"
    + alias3(((helper = (helper = helpers.communityStatusTextColor || (depth0 != null ? depth0.communityStatusTextColor : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"communityStatusTextColor","hash":{},"data":data}) : helper)))
    + ";\">"
    + alias3(((helper = (helper = helpers.communityStatus || (depth0 != null ? depth0.communityStatus : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"communityStatus","hash":{},"data":data}) : helper)))
    + "</p>\r\n\r\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.displayGoogleDirections : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(8, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>";
},"useData":true});