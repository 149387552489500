var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');
var constants = require('../forms/constants.js');
var Form = require('../forms/form.js');
var analyticsForm = require('../forms/analyticsForm');

function AskHomeOwner(){
	var self = this,
		modalErrorClass = 'Ask-Homeowner-modal-error-shown',
		modalThanksClass = 'Ask-Homeowner-modal-thanks-shown';

	this.initialize = function(element, options){
		var topicsController = $( this.element ).find( '.Ask-Homeowner-topics .Filter-option' ),
			mobileDropdown = $(this.element).find('.Ask-Homeowner-topics-dropdown'),
		    mobileDropdownItems = $(this.element).find('.Ask-Homeowner-topics .Filter-item'),
	        modal = $( this.element ).find( '.Ask-Homeowner-modal' ),
            currentElements = $( this.element) .find( '.Ask-Homeowner-current' ),
	        questionsWrapper = $( this.element ).find( '.QuestionAnswer.container' );

	    	self.$element = $(element);
	    	self.$modal = $(element).find( '.Ask-Homeowner-modal' );
	    	self.$modalError = $(element).find( '.MsgModal--error' );

		$( this.element ).find('.Form-Ask-Homeowner')
			.attach(Form)
			.on(constants.FORM_AJAX_SUBMIT, this.handleFormSubmission.bind(this));

		topicsController.on( 'click', function(){
			var topics = [],
				currentTopics = [],
                allChildren = questionsWrapper.find( '.QuestionAnswer-mainContent' ),
			    childrenTopics = "";

			$( this ).toggleClass( 'is-selected' );
			topics = $( '.Filter-option.is-selected' );
			currentTopics = self.getSelectedTopics(topics);
			allChildren.removeClass(' active ');

			if (currentTopics.length > 0) {
			    if (!questionsWrapper.hasClass(' filtered ')) {
			        questionsWrapper.addClass(' filtered ')
			    }
			    childrenTopics = questionsWrapper.find(currentTopics);
			    childrenTopics.addClass(' active ');
			    currentElements.text(childrenTopics.length);
			} else {
			    questionsWrapper.removeClass(' filtered ');
			    currentElements.text(allChildren.length);
			}
		});

		this.mobileFeatures( mobileDropdown, mobileDropdownItems);
		this.modalFeatures( modal );

	}

	onError = function( data, textStatus, event ) {
		self.$modalError.html( data && data.responseJSON ? data.responseJSON.error : textStatus );
		self.$modal.addClass( modalErrorClass );
      	self.$modal.animate({ scrollTop: 0 }, 'slow');

		//rewrite error function
      	analyticsForm.RewriteObjectForm(data, event);
    }

	this.handleFormSubmission = function (event, form) {
        $.ajax({
			url: form.options.action,
			method: form.options.method,
            data: form.data,
            dataType: "json",
            context: this,
            success: function (data) {
                var errorMessage = [];
                if (!data.success) {
                    for (var field in data.fieldErrors) {
                        errorMessage.push(data.fieldErrors[field]);
                    }
                    onError(data, errorMessage.join('. '), event)
                } else {
                	self.$modal.addClass( modalThanksClass );

                    //rewrite error function
                    analyticsForm.RewriteObjectForm(data, event);
                    self.$modal.animate({ scrollTop: 0 }, 'slow');
                }
            }
        })
        .fail( onError );

        return false;
    };

	this.mobileFeatures = function ($dropDown, $dropDownItems) {
		var defaultCopy = $dropDown.data( 'default-value' ),
			copyWrapper = $dropDown.find( 'span' );

		$dropDown.on( 'click', function(){
			$dropDown.toggleClass( 'is-open' );
		});

		$dropDownItems.on('click', function () {
		    $dropDown.removeClass( 'is-open' );
		});
	}

	this.modalFeatures = function( modal ){
		var buttonEl = modal.find( '.btn-submit' ),
			questionEl = modal.find( 'textarea' ),
			anotherQuestionEl = modal.find( '.Ask-Homeowner-modal-another' );

		modal.on( 'hide.bs.modal', function(){
			modal.removeClass( 'Ask-Homeowner-modal-error-shown' ).removeClass( 'Ask-Homeowner-modal-thanks-shown' );
			buttonEl.attr( 'disabled', true );
			questionEl.val('');
		});

		anotherQuestionEl.on( 'click', function(){
			modal.removeClass( 'Ask-Homeowner-modal-thanks-shown' );
			questionEl.val('');
			buttonEl.attr( 'disabled', true );
		});

	}

	this.getSelectedTopics = function( listArray ){
		var selectedTopics = '';

		$.each( listArray, function( index, topic ){
		    var startLine = ', .QuestionAnswer-mainContent.';
			if ( index === 0 ){
			    startLine = '.QuestionAnswer-mainContent.';
			}

			selectedTopics += '' + startLine + $( topic ).attr('id') ;	
		});

		return selectedTopics;
	}
}


module.exports = component(AskHomeOwner);
