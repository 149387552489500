var component = require('../../../lib/js/component.js');

function ReviewFeedback() {
    var highlightedClass = 'highlighted',
        disabledClass = 'disabled',
        hiddenClass = 'hidden',
        visibleClass = 'visible',
        apiUrl = '/API/Reviews/RateReview';

    /**
     * Init for the load more functionality of the tile module
     * @param  {Object} element HTML element
     */
    this.initialize = function (element) {
        this.reviewContainer = $(element).find('.Ratings-list-rate-review')[0];
        this.id = this.element.dataset.id;
        this.$helpfulReviewsLabel = $(this.reviewContainer).find('.helpfulReviews');
        this.helpfulReviewsCount = parseInt(this.$helpfulReviewsLabel.html());
        this.$totalReviewsLabel = $(this.reviewContainer).find('.totalReviews');
        this.totalReviewsCount = parseInt(this.$totalReviewsLabel.html());
        this.$thankYouMsg = $(this.reviewContainer).find('.thankYouMsg')[0];
        this.$questionTxt = $(this.reviewContainer).find('.questionText')[0];
        this.feedbackSent = false;
        this.reviewData = {};

        $(this.reviewContainer).find('button').on('click', this.sendReviewFeedback.bind(this));
    };

    this.sendReviewFeedback = function(evt) {
        var $triggerBtn = $(evt.target);

        this.reviewData.ReviewId = this.id;
        this.reviewData.Helpful = $triggerBtn.hasClass('helpful') ? true : false;

        if(!this.feedbackSent) {
            this.feedbackSent = true;
            this.request = $.ajax({
                type: 'POST',
                url: apiUrl,
                data: this.reviewData,
                dataType: 'json',
                success: this.handleResultsSuccess.bind(this),
                error: this.resetReview.bind(this)
            });
        }

        $triggerBtn.addClass(highlightedClass);
        $(this.reviewContainer).find('button').addClass(disabledClass);
    };

    this.handleResultsSuccess = function(data) {
        if (data.success == true) {
            if (this.reviewData.Helpful) {
                this.helpfulReviewsCount += 1;
                this.$helpfulReviewsLabel.html(this.helpfulReviewsCount);
            }

            this.totalReviewsCount += 1;
            this.$totalReviewsLabel.html(this.totalReviewsCount);

            $(this.reviewContainer).find('button').addClass(hiddenClass);
            $(this.$thankYouMsg).addClass(visibleClass);
            $(this.$questionTxt).addClass(hiddenClass);
        } else {
            this.resetReview();
        }
    };

    this.resetReview = function() {
        this.reviewData = {};
        this.feedbackSent = false;
        $(this.reviewContainer).find('button').removeClass(highlightedClass);
        $(this.reviewContainer).find('button').removeClass(disabledClass);
    }
}

module.exports = component(ReviewFeedback);
