var component = require('../../../lib/js/component.js');
var Form = require('../forms/form.js');
var constants = require('../forms/constants.js');
var analyticsForm = require('../forms/analyticsForm');
var FacebookPixel = require('../../../lib/js/facebookpixel.js');

function PlanAVisit() {
    var hiddenClass = 'hidden',
        visibleClass = 'visible',
        lastFormStepClass = 'last';

    /**
     * initialize - parse data attributes, cache component selections,
     * attach various click handlers, initialize form components and
     * initialize date pickers.
     */
    this.initialize = function (element) {
        // earliest and latest dates that can be selected in the date picker
        var minDate = element.dataset.minDate,
            maxDate = element.dataset.maxDate;

        // cache selections so they can be hidden/shown dynamically
        this.$formWrapper = $(this.find('.PlanAVisit-form-wrapper')[0]);
        this.$confirmationScreen = $(this.find('.PlanAVisit-confirmation')[0]);
        this.$errorMessage = $(this.find('.MsgModal--error')[0]);

        // cache modal and form selections
        this.$modalDialog = this.find('.modal-dialog');
        this.$form = $(this.find('form')[0]);
        this.$formIntro = $(this.find('.PlanAVisit-form-intro')[0]);
        this.$formSteps = $(this.find('.PlanAVisit-form-step'));
        this.$formControls = $(this.find('.PlanAVisit-form-controls')[0]);

        // retrieve arrays of disabled days (for day passes) and disabled nights (for night passes)
        this.disabledDays = element.dataset.disabledDays ? element.dataset.disabledDays.split(',') : [];
        this.disabledNights = element.dataset.disabledNights ? element.dataset.disabledNights.split(',') : [];
        this.disabledDates = [];

        // attach handler to Schedule a Visit button that will hide the initial
        // content and show the form for selecting a visit pass
        $(this.find('.schedule-btn')[0]).on('click', this.onScheduleClick.bind(this));

        // wire up the Remove Choice button
        this.$formSteps.find('button').on('click', this.onRemoveChoice.bind(this));

        // wire up the Add Choice button
        this.$formControls.find('button').on('click', this.onAddChoice.bind(this));

        // listen for changes on the trip length radio buttons
        this.$radios = this.$formSteps.find('.PlanAVisit-stayLength');
        this.$radios.on('change', this.onRadioChange.bind(this));

        // set up date-pickers with jquery-ui date-picker widget
        // with beforeShow and beforeShowDay functions to handle
        // disabled dates supplied by component data attributes
        this.$formSteps.find('input[type=text]').attr("readonly",true).datepicker({
            minDate: minDate,
            maxDate: maxDate,
            beforeShow: this.beforeShowCalendar.bind(this),
            beforeShowDay: this.disableDates.bind(this),
            showAnim: ''
        });

        // core form initialization and submission handler
        this.$form
            .attach(Form)
            .on(constants.FORM_AJAX_SUBMIT, this.handleFormSubmission.bind(this));

        // reset this component any time this modal is opened
        $(element).on('show.bs.modal', this.resetModalElements.bind(this));
    };

    /**
     * onRadioChange - when a visit's radio buttons change, empty the selected
     * Arrival Day value so we don't end up with invalid date selections.
     */
    this.onRadioChange = function (event) {
        var dateField = $(event.currentTarget).parent().parent().parent().find('.hasDatepicker');
        dateField.val('');
    };

    /**
     * onScheduleClick - when the Schedule a Visit button is clicked,
     * hide the intro content and show the day/night pass form elements.
     */
    this.onScheduleClick = function () {
        this.$formIntro.addClass(hiddenClass);
        this.$formControls.addClass(visibleClass);
        this.onAddChoice(null);
    };

    /**
     * onAddChoice - update choice fieldsets so the appropriate ones are
     * shown and have enabled inputs. hide the Add Choice button if it is
     * no longer needed.
     */
    this.onAddChoice = function (event) {
        var visibleElements, visibleElementsCount,
            $nextStep = $(this.$formSteps.not('.' + visibleClass)[0]);

        // remove class for last form fieldset from all fieldsets (each fieldset
        // contains the fields for one choice for a visit pass)
        this.$formSteps.removeClass(lastFormStepClass);

        // move the new fieldset/pass choice before the form control elements
        // and add a class to show it
        $nextStep.insertBefore(this.$formControls).addClass(visibleClass);
        // enable the textarea and input in the newly-added choice
        $nextStep.find('input[type=text], textarea').prop('disabled', false);

        // add the lastFormStepClass to the last visible choice fieldset
        visibleElements = $(this.find('.PlanAVisit-form-step.visible'));
        visibleElementsCount = visibleElements.length;
        $(visibleElements[visibleElementsCount - 1]).addClass(lastFormStepClass);

        // if this is the last choice to be added, hide the Add Choice button
        if (visibleElementsCount === this.$formSteps.length) {
            $(event.target).addClass(hiddenClass);
        }
    };

    /**
     * onRemoveChoice - hide the choice fieldset being removed, disable it
     * and make sure the Add Choice button is visible.
     */
    this.onRemoveChoice = function (event) {
        // get the choice fieldset that is being removed
        var $formStep = $(event.target).parent();

        // show the Add Choice button
        this.$formControls.find('button').removeClass(hiddenClass);

        // hide the choice fieldset being removed
        $formStep.removeClass(visibleClass);
        // and disable its text inputs
        $formStep.find('input[type=text], textarea').val('').prop('disabled', true);

        // reset the radio selections for this choice to the default first one (day pass)
        $($formStep.find('input[type=radio]')[0]).prop('checked', true);
    };

    /**
     * disableDates - this is the datepicker beforeShowDay function that
     * determines if a specific day is in the array of disabled dates.
     * @return Boolean current day is disabled
     */
    this.disableDates = function (date) {
        var currentDate = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();

        if ($.inArray(currentDate, this.disabledDates) !== -1) {
            return [false];
        } else {
            return [true];
        }
    };

    /**
     * calcDisabledDates - this updates the array of disabled dates by disabling
     * the number of days before each disabled date specific to the number of
     * nights the currently-selected pass is for. In other words, if a 2-night
     * pass is selected, any disabled date should also have the date
     * before it disabled.
     * @return Array of disabled dates
     */
    this.calcDisabledDates = function (daysToAdd) {
        var dates = [],
            dateArray = [],
            date = null,
            prevDate = null,
            nextDate = null,
            i, k;

        for (i = 0; i < this.disabledNights.length; i++) {
            dateArray = this.disabledNights[i].split('/');
            date = new Date(dateArray[2], dateArray[0] - 1, dateArray[1]);
            dates.push(date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear());

            for (k = 1; k < daysToAdd + 1; k++) {
                prevDate = new Date(date);
                prevDate = new Date(prevDate.setDate(prevDate.getDate() - k));
                dates.push(prevDate.getMonth() + 1 + "/" + prevDate.getDate() + "/" + prevDate.getFullYear());
            }
        }

        return dates;
    }

    /**
     * beforeShowCalendar - put a jQuery UI Datepicker widget after the
     * arrival date input, then determine which kind of pass is selected
     * and update the array of disabled dates accordingly.
     */
    this.beforeShowCalendar = function (input) {
        var visitInputName = '',
            inputVal;

        $(input).after($(input).datepicker('widget'));

        // use the arrival date's input name to determine which
        // set of radio buttons to read to determine the type of pass
        // for this visit choice fieldset
        switch (input.name) {
            case 'arrivalDayFirstChoice':
                visitInputName = 'visitTypeFirstChoice';
                break;
            case 'arrivalDaySecondChoice':
                visitInputName = 'visitTypeSecondChoice';
                break;
            case 'arrivalDayThirdChoice':
                visitInputName = 'visitTypeThirdChoice';
                break;
        }

        // get the value of the radio button set
        inputVal = $($(this.element).find('[name="'+ visitInputName +'"]:checked')).val();

        // and update the disabledDates array accordingly
        switch (inputVal.toLowerCase()) {
            case 'daypass':
                this.disabledDates = this.disabledDays;
                break;
            case '1nightpass':
                this.disabledDates = this.calcDisabledDates(0);
                break;
            case '2nightpass':
                this.disabledDates = this.calcDisabledDates(1);
                break;
            case '3nightpass':
                this.disabledDates = this.calcDisabledDates(2);
                break;
            case '4nightpass':
                this.disabledDates = this.calcDisabledDates(3);
                break;
            case '5nightpass':
                this.disabledDates = this.calcDisabledDates(4);
                break;
        }
    };

    /**
     * getFormData - create the data object to be posted to the form AJAX request.
     * @return Object parsed/cleaned form data for AJAX submission
     */
    this.getFormData = function () {
        var fields = this.$form.find('.PlanAVisit-personal-data input, .PlanAVisit-personal-data select'),
            visitSets = this.$form.find('.PlanAVisit-form-step.visible'),
            data = {},
            field,
            visitSet,
            i,
            j;

        data.comment = '';

        // write the field values into the data object
        for (i = 0, j = fields.length; i < j; i += 1) {
            field = fields[i];

            if ($(field).attr('type') !== 'checkbox') {
                data[field.name] = $(field).val();
            } else {
                data[field.name] = $(field).is(':checked');
            }
        }

        // create the comment property and details for the data object
        for (i = 0, j = visitSets.length; i < j; i += 1) {
            visitSet = visitSets[i];

            switch (i) {
                case 0:
                    data.comment += 'First choice: ';
                    break;
                case 1:
                    data.comment += 'Second choice: ';
                    break;
                case 2:
                    data.comment += 'Third choice: ';
                    break;
            }

            data.comment += $($(visitSet).find('input[type=radio]:checked')[0]).val();
            data.comment += ' arriving ' + $($(visitSet).find('input[type=text]')[0]).val();
            data.comment += ' message ' + $($(visitSet).find('textarea')[0]).val() + ' | ';
        }

        return data;
    };

    /**
     * handleFormSubmission - handle the AJAX request to submit the Plan a Visit request.
     */
    this.handleFormSubmission = function (event, form) {
        this.$errorMessage.hide();
        $.ajax({
            method: form.options.method,
            url: form.options.action,
            data: this.getFormData(),
            dataType: "json",
            context: this,
            success: function (data) {
                var errorMessage = [];
                if (!data.success) {
                    for (var field in data.fieldErrors) {
                        errorMessage.push(data.fieldErrors[field]);
                    }
                    this.onError(data, errorMessage.join('. '), event);
                } else {
                    this.$formWrapper.addClass(hiddenClass);
                    this.$confirmationScreen.addClass(visibleClass);
                    this.$modalDialog.animate({ scrollTop: 0 }, 'slow');

                    // update Analytics data for AJAX-submitted form
                    analyticsForm.RewriteObjectForm(data, event);

                    FacebookPixel.pushFormSubmissionEvent(FacebookPixel.FORM_MODAL_TYPE, FacebookPixel.FORM_PLAN_A_VISIT_NAME);
                }
            }
        })
        .fail(this.onError.bind(this));

        return false;
    };

    /**
     * onError - handle form submission errors by updating and showing the error message.
     */
    this.onError = function (data, textStatus, event) {
        var top = this.$errorMessage.offset().top
        this.$errorMessage.html(data && data.responseJSON ? data.responseJSON.error : textStatus).show();
        this.$modalDialog.animate({ scrollTop: 0 }, 'slow');

        // update Analytics data for AJAX-submitted form
        analyticsForm.RewriteObjectForm(data, event);
    };

    /**
     * resetModalElements - reset the Plan a Visit modal to its default state.
     */
    this.resetModalElements = function () {
        this.$formWrapper.removeClass(hiddenClass);
        this.$confirmationScreen.removeClass(visibleClass);
        this.$formIntro.removeClass(hiddenClass);
        this.$formSteps.removeClass(visibleClass);
        this.$formControls.removeClass(visibleClass);
        this.$formControls.find('button').removeClass(hiddenClass);
        this.$formSteps.find('input[type=text], textarea').val('').prop('disabled', 'true');
        this.$formSteps.find('input[type=text], textarea').parent().removeClass('has-error').removeClass('is-empty');
        this.$errorMessage.hide();
        this.$formSteps.each(function () {
            $($(this).find('input[type=radio]')[0]).prop('checked', true);
        });
    };
}

module.exports = component(PlanAVisit);
