var tooltip = require('bootstrap-sass/assets/javascripts/bootstrap/tooltip');
var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');

/*
 * Fix the issue when
 * the user try to close a tooltip
 * making click outside of them.
 *
 */
function CloseTooltips() {
    var $document = $(document),
        $body = $('body'),
        $fakeLink = $('.close-tooltip'),
        CLOSE_TOOLTIP = '<a class="close-tooltip" href="#"></a>',
        CLICK = 'click';

    if (window.Modernizr.touchevents) {
        // trigger event when any tooltip is open
        $document.on('show.bs.tooltip', function (e) {
            if (e.target.dataset.preventOddBehavior !== "true") {
                $(e.target).addClass('tooltip-active');
                setTimeout(function () {
                    $body.append(CLOSE_TOOLTIP);
                    $fakeLink.on(CLICK, function (e) {
                        e.preventDefault();
                    });
                }, 300);
            }
        });

        // trigger event when any tooltip is close
        $document.on('hide.bs.tooltip', function (e) {
            if (e.target.dataset.preventOddBehavior !== "true") {
                $(e.target).removeClass('tooltip-active');
                $('.close-tooltip').remove();
            }
        });
    } else {
        return;
    }
}
module.exports = component(CloseTooltips);
