var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');

function Sitemap() {

    this.initialize = function (element, options) {

        this.$element.find('.Sitemap__itemDropdown').on('click touch', function(){
            $iconElement = $(this).find('.Sitemap__icon');

            if ($iconElement.hasClass('icon-plus')) {
                $iconElement.removeClass('icon-plus');
                $iconElement.addClass('icon-minus');
            } else {
                $iconElement.removeClass('icon-minus');
                $iconElement.addClass('icon-plus');
            }
        });

        // Stops dropdown function if <a> is clicked
        this.$element.find('.Sitemap__link').on('click touch', function(e) {
            e.stopPropagation();
        });
    };
}
module.exports = component(Sitemap);
