var deeplinkProperties = {};

(function() {

    var _this = this,
        deeplink = "",
        deeplinkInterval,
        windowIsLoaded = false,
        automatedScroll = false,
        $document = $(document),
        documentHeight = 0,
        currentScrollY = 0;

    var deeplinkDeferred = $.Deferred();

    deeplinkProperties.deeplinkProcessed = deeplinkDeferred.promise();
    deeplinkProperties.processDeeplink = processDeeplink;

    $(window).on('hashchange', processDeeplink);

    function processDeeplink() {
        documentHeight = $document.height();
        $(window).one("load", handleWindowLoaded);
                
        deeplink = getDeeplinkFromUrl();
    
        if (deeplink && deeplink.length > 0) {
            var $deeplinks = $(document).find('[data-deeplink]');
            
            $deeplinks.each(function(idx, el) {
                var $el = $(el);
                var deeplinkTarget = $el.attr('data-deeplink').toLowerCase();

                // check if the deplink matches the target deeplink value or if the target has the always trigger deeplink attribute
                if (deeplink === deeplinkTarget) {
                    if (!windowIsLoaded) {
                        $(window).on("scroll touchmove", handleWindowScrolled);
                    }

                    triggerDeeplink(el, deeplink);

                    return false; // stop iterating each
                }
            });
        }

        deeplinkDeferred.resolve();
    }

    function triggerDeeplink(el, deeplinkHash) {
        var $el = $(el);

        if ( $el.hasClass('modal')) {
            $el.modal('show');
        }
        else {
            // if component is initialized, document.ready
            documentHeight = $document.height();
        
            $el.trigger("deeplink", deeplinkHash);
        
            scrollToDeeplink(getScrollToElement(el));
        }
    }

    function getScrollToElement(el) {

        var scrollToElement = $(el).attr('data-deeplink-scrollTo');

        if (scrollToElement) {
            return $(scrollToElement);
        } else {
            return $(el);
        }

    }

    function scrollToDeeplink($target) {

        if (windowIsLoaded) {
            moveScrollPoint($target); // in this case, always do smooth scroll
        } else {
            moveScrollPoint($target);
            deeplinkInterval = setInterval(function() {
                moveScrollPoint($target);
                documentHeight = $document.height();
            }, 250);
        }
    }

    function handleWindowLoaded() {
        windowIsLoaded = true;
        clearInterval(deeplinkInterval);
        $(window).off("scroll touchmove", handleWindowScrolled);
    }
    
    function handleWindowScrolled(evt) {
        // if was NOT automated scroll or a document Height change causing scroll
        if (!automatedScroll && (documentHeight == $document.height())) {
            clearInterval(deeplinkInterval);
            $(window).off("scroll touchmove", handleWindowScrolled);
        } else {
            automatedScroll = false;
        }
    }
    
    function moveScrollPoint($target) {

        // and scroll to component keeping in mind affix
        var $subnavs = $('.affix, .affix-top'),
        mainPositionTop = Math.round($('main').position().top),
        mainOffsetTop = $('main').offset().top,
        targetOffsetTop = $target.offset().top,
        targetOffset = 0;

        $subnavs.each(function() {
            var subnavPositionTop = Math.round($(this).position().top),
            subnavOffsetTop = $(this).offset().top;

            if ((subnavOffsetTop === mainOffsetTop) || ((subnavOffsetTop !== targetOffsetTop) && (subnavPositionTop !== mainPositionTop))) {
                if ($(this).hasClass('affix-top')) {
                    targetOffset = targetOffset + ($(this).outerHeight() * 2);
                } else {
                    targetOffset = targetOffset + $(this).outerHeight();
                }
            }
        });

        var newScrollY = Math.round($target.offset().top - targetOffset);
        
        if (windowIsLoaded) {
            // do smooth scroll
            $('html, body').animate({
                scrollTop: newScrollY
            }, 1000, function() {
                $(window).trigger('Header.hide');
            });
        } else {
            if (currentScrollY != newScrollY) {
                automatedScroll = true;
                window.scrollTo(0, newScrollY);
                currentScrollY = newScrollY;
            }
        }
    }

    function getDeeplinkFromUrl() {
        var deeplink;

        if (window.location.hash != '') {
            deeplink = (location.href.split("#")[1] || "").toLowerCase();
        }

        return deeplink;
    }
    
    // This is a hash re-triggering function, helping to manually trigger a hashChange event if a click event on an href referencing 
    // a deeplink did not trigger a new hash value
    $(document).on('click', "[href]", function () {
        var href = this.getAttribute('href');
        var deeplink = getDeeplinkFromUrl();

        // using indexOf instead of startsWith because of IE 11 compatibility
        var startsWithHashtag = href.indexOf('#', 0) === 0;

        if (href && startsWithHashtag && href.length > 1 && href.split('#')[1] === deeplink) {
            // using createEvent and initEvent for IE 11 compatibility
            var event = document.createEvent("Event");
            event.initEvent("hashchange", true, true);
            window.dispatchEvent(event);
        }
    });

}());

module.exports = deeplinkProperties;