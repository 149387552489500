var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');
var evt = require('./slideshow-events.js');
var SlideshowCarousel = require('./slideshow-carousel.js');

function SlideshowModal() {

    var CLASS = {
        OPEN: 'is-open'
    };

    /**
     * Init. It's time to get started.
     * @param  {Object} element HTML element
     * @param  {Object} options Component options
     */
    this.initialize = function (element, options) {

        var _this = this;

        _this.options = options || {};

        _this.find('.SlideshowCarousel').attach(SlideshowCarousel);

        // cache title element
        _this.$title = _this.$element.find('.SlideshowModal-title');

        _this.$element.on('click', '.SlideshowModal-close', _this.closeModal.bind(this));

        // listeners
        _this.listen(evt.OPEN, this.open);
        _this.listen(evt.CLOSE, this.close);
        _this.on(evt.UPDATE_TITLE, this.updateTitle.bind(this));
    };

    /**
     * Trigger close event.
     * @param  {jQuery Event} event click event
     */
    this.closeModal = function (event) {
        this.trigger(evt.CLOSE);
        event.preventDefault();
    };

    /**
     * Open the modal.
     * @param  {jQuery Event} event   Event
     * @param  {Object} options Options object
     */
    this.open = function (event, options) {
        var _this = this;

        _this.$element.removeClass(CLASS.OPEN);

        _this.updateTitle(options.title);

        // On mobile devices add inline css to offset the top
        // the modal. Desktop css has styles that override the inline
        // styles.
        _this.$element.addClass(CLASS.OPEN).css({
            top: options.offset
        });

        _this.trigger(evt.OPENED);
    };

    /**
     * Close the modal.
     * @param  {jQuery Event} event   Event
     * @param  {Object} options Options object
     */
    this.close = function(event, options) {
        var _this = this;

        // if a video is playing, trigger next carousel slide to stop it and remove the iframe via owl-carousel
        if ($(_this.element).find('iframe').length > 0) {
            $(_this.element).find('.SlideshowCarousel-main .owl-nav .owl-next').trigger('click');
        }

        _this.$element.removeClass(CLASS.OPEN);
        _this.$element.closest('.Slideshow--modalGallery').removeClass('active');
        _this.trigger(evt.CLOSED);
    };

    /**
     * Update the modal title.
     * @param  {jQuery Event} event Event
     * @param  {String} title New title
     */
    this.updateTitle = function (event, title) {
        if(!title) title = event;
        this.$title.html(title);
    }

}

module.exports = component(SlideshowModal);
