var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');

function AboutTheArea() {

    this.initialize = function(element, options) {
        // instance variables
        this.main = $(element);
        this.foundVisible = false;

        // elements and their event bindings
        var $primaryTabs = this.main.find('.primary-tab');
        var $secondaryTabs = this.main.find('.secondary-tab');
        var $mapFeaturesTabs = this.main.find('.Map-featuresTab');
        $primaryTabs.on('click touch', this.filterPrimaryContent.bind(this));
        $secondaryTabs.on('click touch', this.filterSecondaryContent.bind(this));
        $mapFeaturesTabs.on('click touch', this.triggerMapUpdate.bind(this));

        this.updatePrimaryTabsDataAnalyticsCTAAttributes($primaryTabs);

        // mobile elements and their event bindings
        var $accordionSelection = this.main.find('.accordion-selection');
        var $accordionOption = this.main.find('.accordion-option');
        $accordionSelection.on('click touch', this.toggleAccordion.bind(this));
        $accordionOption.on('click touch', this.toggleAccordion.bind(this));

        //Hide Secondary Tabs with no Content
        this.emptyTabContent($(".AboutTheArea .main-row .content-container"), ".AboutTheArea");
        //Hide Explore Del Webb if no content
        var child = $('.AboutTheArea .content-container[data-option="Explore"] .content-container-child').children().length;
        if (child === 0) {
            $('.AboutTheArea .primary-tab[data-option="Explore"]').addClass('hidden');
        }

        // first primary option is default, store its data-option value
        this.selectedPrimaryOption = $primaryTabs.first().data().option;
        this.selectedSecondaryOption = $secondaryTabs.first().data().option;
        this.selectedSecondaryIndex = $secondaryTabs.first().data().index;
        this.innerHTMLPrimary = '';

        // set visible content accordingly
        this.setActiveContent();

        // Map elements and bindings
        this.$poiMapTitle = this.find('.Map-AreaDescription h3');
        this.$poiMapCopy = this.find('.Map-AreaDescription .copy');
        this.$poiMap = this.find('.Map');
    }

    // appends the data-analytics-cta of the first secondary tab to the data-analytics-cta of the primary tab
    this.updatePrimaryTabsDataAnalyticsCTAAttributes = function($primaryTabs) {
        var _this = this;
        $primaryTabs.each(function () {
            var $primaryTab = $(this);

            var primaryTabData = $primaryTab.data();

            if (primaryTabData) {
                var $primaryTabContent = _this.$element.find('.main-row[data-option="' + primaryTabData.option + '"]');
                var firstSecondaryTabData = $primaryTabContent.find('.secondary-tab').first().data();

                if (firstSecondaryTabData) {
                    var primaryTabAnalyticsCTA = primaryTabData.analyticsCta;
                    var secondaryTabAnalyticsCTA = firstSecondaryTabData.analyticsCta;

                    $primaryTab.attr('data-analytics-cta', primaryTabAnalyticsCTA + '|' + secondaryTabAnalyticsCTA);
                }
            }

        });
    }

    this.emptyTabContent = function (elementsArray, parentClass) {
        elementsArray.each(function (i, val) {
            var target = $(val).attr('data-option');

            if ($(parentClass + ' .content-container[data-option="' + target + '"] .content-container-child').length > 0) {
                var child = $(parentClass + ' .content-container[data-option="' + target + '"] .content-container-child').children().length;

                if (child === 0) {
                    $(parentClass + ' .secondary-tab[data-option="' + target + '"]').remove();
                }
            }

        })
    }

    this.setFirstVisibleActive = function (elementsArray) {
        elementsArray.each($.proxy(function (i, val) {

            if (!$(val).hasClass("hidden") && !this.foundVisible) {
                this.selectedOption = $(val).data().option;
                this.selectedSecondaryIndex = $(val).data().index;
                this.foundVisible = true;
            }

        }, this))
    }

    this.filterPrimaryContent = function (e) {
        var $el = $(e.currentTarget);

        this.activatePrimaryTab($el);
    }

    this.activatePrimaryTab = function ($selectedTab) {
        if ($selectedTab[0]) {
            this.innerHTMLPrimary = $selectedTab[0].innerHTML;
        }
        
        var selectedTabData = $selectedTab.data();

        if (selectedTabData) {
            this.selectedPrimaryOption = selectedTabData.option;

            this.selectedSecondaryIndex = 0;

            // if this primary tab has a secondary tab, set the first secondary tab as selected
            var $selectedPrimaryTabContent = this.$element.find('.main-row[data-option="' + this.selectedPrimaryOption + '"]');
            if ($selectedPrimaryTabContent.length) {
                var firstSecondaryTabData = $selectedPrimaryTabContent.find('.secondary-tab').first().data();

                if (firstSecondaryTabData) {
                    this.selectedSecondaryOption = firstSecondaryTabData.option;
                }
            }

            this.setActiveContent();

            // initialize first feature map
            if (this.selectedPrimaryOption !== "Community Features") {
                var $featuresTabs = this.main.find('.Map-featuresTab');
                if ($featuresTabs.length > 0) {
                    $('body').trigger('InitTabbedMaps');
                }
            }
        }
    }

    this.filterSecondaryContent = function(e) {
        var $el = $(e.currentTarget);

        this.activateSecondaryTab($el);
    }

    this.activateSecondaryTab = function($selectedTab) {
        this.selectedSecondaryOption = $selectedTab.data().option;
        this.selectedSecondaryIndex = $selectedTab.data().index;

        this.setActiveContent();
    }

    this.setActiveContent = function () {
        if (this.selectedPrimaryOption === "Community Features" && this.selectedSecondaryIndex===0) {
            var $secondaryTab = this.main.find('.secondary-tab');
            this.foundVisible = false;
            $.proxy(this.setFirstVisibleActive($secondaryTab), this);
        }

        // toggle active primary tab
        this.main.find('.primary-tab.is-active').removeClass('is-active');
        this.main.find('.primary-tab[data-option="' + this.selectedPrimaryOption + '"]').addClass('is-active');

        // toggle active main row
        this.main.find('.main-row.is-active').removeClass('is-active');
        this.main.find('.main-row[data-option="' + this.selectedPrimaryOption + '"]').addClass('is-active');

        // toggle active secondary tab
        this.main.find('.main-row.is-active .secondary-tab.is-active').removeClass('is-active');
        this.main.find('.main-row.is-active .secondary-tab[data-option="' + this.selectedSecondaryOption + '"]').addClass('is-active');
        // mobile view 
        if(this.innerHTMLPrimary == '') {
            this.main.find('.primary-accordion .accordion-selection-text').text(this.selectedPrimaryOption.trim());
        } 
        else {  
            this.main.find('.primary-accordion .accordion-selection-text').text(this.innerHTMLPrimary);
        }
        this.main.find('.secondary-accordion.is-active').removeClass('is-active');
        this.main.find('.secondary-accordion[data-option="' + this.selectedPrimaryOption + '"]').addClass('is-active');
        this.main.find('.secondary-accordion .secondary-tab.is-active').removeClass('is-active');
        this.main.find('.secondary-accordion.is-active .secondary-tab[data-option="' + this.selectedSecondaryOption + '"]').addClass('is-active');
        this.selectedSecondaryOption = this.main.find('.secondary-accordion.is-active .secondary-tab.is-active').text().trim();
        this.main.find('.secondary-accordion.is-active .accordion-selection-text').text(this.selectedSecondaryOption);

        // filter view
        this.main.find('.content-container.is-active').removeClass('is-active');
        this.main.find('.main-row.is-active .content-container[data-index="' + this.selectedSecondaryIndex + '"]').addClass('is-active');

        // set tab
        if (!this.main.find('.main-row.is-active .secondary-tab[data-index="' + this.selectedSecondaryIndex + '"]').hasClass('Map-featuresTab')) {
            this.main.find('.main-row.is-active .secondary-tab.is-active').removeClass('is-active');
            this.main.find('.main-row.is-active .secondary-tab[data-index="' + this.selectedSecondaryIndex + '"]').addClass('is-active');
            //mobile
            this.main.find('.secondary-accordion.is-active .secondary-tab[data-index="' + this.selectedSecondaryIndex + '"]').addClass('is-active');
            this.selectedSecondaryOption = this.main.find('.secondary-accordion.is-active .secondary-tab.is-active').text().trim();
            this.main.find('.secondary-accordion.is-active .accordion-selection-text').text(this.selectedSecondaryOption);
        }

        // bugfix for avoiding blurry imagery in the embedded Inspiration component
        $(window).trigger('resize');
    }

    this.toggleAccordion = function(e) {
        var $el = $(e.currentTarget);
        var $accordion = $el.closest('.accordion');
        // toggle expanded
        $accordion.toggleClass('expanded');
    }

    this.triggerMapUpdate = function(e) {
        // get selected title and feature from secondary tab
        var $el = $(e.currentTarget);
        var $selectedFeature = $el.data().feature;
        var poiMapTitle = $el.data().title;
        var poiMapCopy = $el.data().copy;
        var poiPinColor = $el.data().color;

        // pass data into embedded map features component
        featureTabData = {
            curFeatureColor: poiPinColor,
            curSelectedFeature: $selectedFeature,
            target: e.target
        };

        // container of the map to update
        this.$poiMap.trigger('featureTabSelected', featureTabData);
        this.$poiMapTitle.text(poiMapTitle);
        this.$poiMapCopy.text(poiMapCopy);

    }

    this.handleResize = function() {
        var windowWidth = $(window).outerWidth();
        if (windowWidth < 768) {
            $('.scrollable .scrollable-inner').each(function() {
                var spaceBetween = $(this).attr('data-space-between');
                var responsiveWidth = 0;
                var childCount = $(this).children('.scrollable-item').length;
                $(this).children('.scrollable-item').each(function(i) {
                    responsiveWidth += $(this).outerWidth() + parseInt(spaceBetween);
                    if (i === childCount - 1) {
                        responsiveWidth -= parseInt(spaceBetween);
                    }
                });
                responsiveWidth += 30; // account for the margin-left and margin-right
                $(this).css('width', responsiveWidth);

                if (responsiveWidth > windowWidth) {
                    $(this).closest('.scrollable').addClass('with-hint');
                } else {
                    $(this).closest('.scrollable').removeClass('with-hint');
                }
            });
        } else {
            $('.scrollable .scrollable-inner').each(function() {
                $(this).css('width', 'auto');
            });
        }
    }

    this.deeplink = function(event, val) {
        var $deeplinkElement = $(event.currentTarget).find('[data-deeplink=' + val + ']');

        if ($deeplinkElement.hasClass('primary-tab')) {
            // if it's a primary tab, activate it
            this.activatePrimaryTab($deeplinkElement);
        } else {
            // if it's a secondary tab, find the data-option of the closest primary tab, set it, then activate the secondary tab
            var primaryTabContentData = $deeplinkElement.closest('.main-row').data();

            if (primaryTabContentData) {
                this.selectedPrimaryOption = primaryTabContentData.option;
                this.activateSecondaryTab($deeplinkElement);
            }
        }
    }
}

module.exports = component(AboutTheArea);
