var component = require('../../../lib/js/component.js');
var validator = require('./validator.js');
var constants = require('./constants.js');

var FieldGroupZipCountry = function () {

    this.initialize = function (el, options) {
        this.options = options || {};
        this.data = this.$element.data();

        var fieldSelectors = ['.Field-text', '.Field-select'];
        this.$element.on(constants.FIELD_INIT, fieldSelectors.join(), this.handleFieldInit.bind(this));
        this.$element.on(constants.FIELD_CHECKED, fieldSelectors.join(), this.handleFieldCheck.bind(this));

        this.$toggle = this.find('.CheckboxControl input');
        this.$element.on('change', '.CheckboxControl', this.handleToggleChange.bind(this));
        this.$countryWrapper = this.$element.find('.country-selector-wrapper');
        this.trigger(constants.FIELD_INIT, this);
    };

    this.handleFieldInit = function (event, field) {
        if (field.$element.hasClass('Field-zip')) {
            this.$zip = field;
            if (Pulte.user && Pulte.user.OutsideUS === "True"){
                this.$zip.disable();
                this.$zip.element.value = '';
            }
        } else if (field.$element.hasClass('Field-select')) {
            this.$country = field;
            if (Pulte.user && Pulte.user.OutsideUS === "True"){
                this.$toggle.prop('checked', true);
                this.$country.enable();
                this.$countryWrapper.toggle(this.toggleChecked());
            }
        }

        event.stopPropagation();
    };

    this.handleFieldCheck = function (event) {
        event.stopPropagation();
        this.trigger(constants.FIELD_CHECKED, this);
    };

    this.handleToggleChange = function (event) {
        if (this.toggleChecked()) {
            // activate country, deactivate zip
            this.$country.enable();
            // pass true to force error class assignment
            this.$country.checkValue(true);
            this.$zip.disable();
            // clear the zip code field when the user select a country 
            this.$zip.element.value = '';
            this.$zip.$element.removeClass("has-error");
            this.$zip.$element.removeClass("is-empty");
    } else {
            // deactivate country, activate zip
            this.$country.disable();
            this.$zip.enable();
            // pass true to force error class assignment
            this.$zip.checkValue(true);
        }
        // form validation needs to be updated
        this.trigger(constants.FIELD_CHECKED, this);
        this.$countryWrapper.toggle(this.toggleChecked());
    };

    this.isValid = function (event) {
        var valid = false;
        if (this.toggleChecked()) {
            valid = this.$country.isValid();
        } else {
            valid = this.$zip.isValid();
        }
        // should remove any error classes attached to field children
        return valid;
    };

    this.getName = function () {
        return (this.toggleChecked() ? this.$country.getName() : this.$zip.getName());
    };

    this.getValue = function () {
        return (this.toggleChecked() ? this.$country.getValue() : this.$zip.getValue());
    };

    this.appendData = function (data) {
        data[this.getName()] = this.getValue();
        return data;
    };

    this.toggleChecked = function () {
        return this.$toggle.is(':checked');
    };

};

module.exports = component(FieldGroupZipCountry);
