var component = require('../../../lib/js/component.js');
var Form = require('../forms/form.js');
var constants = require('../forms/constants.js')
var AdobeLaunch = require('../../../lib/js/adobelaunch.js');
var FacebookPixel = require('../../../lib/js/facebookpixel.js');
var utils = require('../../../lib/js/utils.js');

var RegisterHome = function () {
    this.initialize = function (element) {
        this.$form = this.$element.find('.RegisterHomeLite__form');
        this.$errorMessage = $('.MsgModal--error');

        this.$form
            .attach(Form)
            .on(constants.FORM_AJAX_SUBMIT, this.handleFormSubmission.bind(this))
            .on(constants.FORM_CHANGED, this.handleFormChanged.bind(this));

        utils.handleVisible(this.$form, function () {
            AdobeLaunch.pushFormViewedEvent(AdobeLaunch.FORM_GLOBAL_ID, AdobeLaunch.FORM_INLINE_TYPE, AdobeLaunch.FORM_HOME_REGISTRATION_NAME);
        });
    };

    this.handleFormSubmission = function (event, form) {
        if (this.$errorMessage)
            this.$errorMessage.hide();

        $.ajax({
            method: form.options.method,
            url: form.options.action,
            data: form.data,
            context: this,
            dataType: "json"
        })
            .done(this.onSuccess)
            .fail(this.onError);
    }

    this.handleFormChanged = function () {
        this.$form.off(constants.FORM_CHANGED);
        AdobeLaunch.pushFormStartedEvent(AdobeLaunch.FORM_GLOBAL_ID, AdobeLaunch.FORM_INLINE_TYPE, AdobeLaunch.FORM_HOME_REGISTRATION_NAME);
    }

    this.onSuccess = function(data) {
        if (data) {
            if (data.Success) {
                //registration was successful, push analytics events, then redirect to success url
                AdobeLaunch.pushFormSubmitSucceededEvent(AdobeLaunch.FORM_GLOBAL_ID, AdobeLaunch.FORM_INLINE_TYPE, AdobeLaunch.FORM_HOME_REGISTRATION_NAME);
                FacebookPixel.pushFormSubmissionEvent(FacebookPixel.FORM_INLINE_TYPE, FacebookPixel.FORM_HOME_REGISTRATION_NAME);
                
            }
            else {
                //validation failed on the server, push the form submit failed analytics event then redirect to failure url
                AdobeLaunch.pushFormSubmitFailedEvent(AdobeLaunch.FORM_GLOBAL_ID, AdobeLaunch.FORM_INLINE_TYPE, AdobeLaunch.FORM_HOME_REGISTRATION_NAME);
            }
            location.href = data.RedirectUrl;
        } else {
            //if data is null back from the ajax request, show a generic error message
            data = { error: "An error has occured. Please try again." }
            this.onError(data, null)
        }
    }

    this.onError = function(data, jqXHR) {
        AdobeLaunch.pushFormSubmitFailedEvent(AdobeLaunch.FORM_GLOBAL_ID, AdobeLaunch.FORM_INLINE_TYPE, AdobeLaunch.FORM_HOME_REGISTRATION_NAME);
        if (this.$errorMessage) {
            this.$errorMessage.html(jqXHR && jqXHR.responseJSON ? jqXHR.responseJSON.error : data.error);
            this.$errorMessage.show();
        }
    }
}

module.exports = component(RegisterHome);