var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');
var markerclusterer =  require('../../../vendor/markerclusterer.js');

var MapCore = function () {

    this.initialize = function (el, options) {
        this.options = {};
        $.extend(this.options, options, this.$element.data());

        this.mapStyles = Pulte.map.styles;

        if (window.google && window.google.maps) {
            // init map
            this.configureMapSettings.call(this);
        } else {
            // Set listener for MapScriptLoaded event
            $('body').on('MapScriptLoaded', function () {
                this.configureMapSettings.call(this);
            });
        }
    };

    /**
     * configureMapSettings: Configures the mapSettings object
     */
    this.configureMapSettings = function () {
        // Set defaults incase no geolocation data
        var center = {
            lat: parseFloat(this.options.mapConfig.default_lat, 10),
            lng: parseFloat(this.options.mapConfig.default_lng, 10)
        };

        // If geolocation data provided
        if (this.options.lat && this.options.lng) {
            center = {
                lat: parseFloat(this.options.lat, 10),
                lng: parseFloat(this.options.lng, 10)
            };
        }

        var zoomControls = {
            position: google.maps.ControlPosition.RIGHT_BOTTOM
        };

        var mapSettings = {
            center: center,
            zoom: this.options.mapConfig.default_zoom,
            draggable: !utils.has.touch(), // no scroll on touch devices
            mapStyles: this.mapStyles,
            pulteMapType: this.options.pulteMapType,
            zoomControls: zoomControls, // position
            showZoomControls: this.options.showZoomControls,
            enableDoubleClickZoom: this.options.enableDoubleClickZoom,
            enableScrollWheelZoom: this.options.enableScrollWheelZoom
        };
        this.initMap(mapSettings);
    };

    /**
     * initMap: Instantiates a map and places it at this.$element
     * @param  {Object} mapSettings - Object containing map settings
     */
    this.initMap = function (mapSettings) {
        var _this = this;
        var map = new google.maps.Map(this.$element[0], {
            keyboardShortcuts: false,
            scaleControl: false,
            zoomControl: mapSettings.showZoomControls,
            zoomControlOptions: mapSettings.zoomControls,
            disableDefaultUI: true,
            disableDoubleClickZoom: !mapSettings.enableDoubleClickZoom,
            center: mapSettings.center,
            zoom: mapSettings.zoom,
            draggable: mapSettings.draggable,
            styles: mapSettings.mapStyles,
            scrollwheel: mapSettings.enableScrollWheelZoom,
            pulteMapType: mapSettings.pulteMapType // custom key
        });

        // Alert parent controllers a map has been added
        // and pass a reference to the map with it.
        this.$element.trigger(map.pulteMapType + 'MapInstanceAdded', map);
    };
};
module.exports = component(MapCore);
