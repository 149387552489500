var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');
function Community3DTour() {

    /**
     * Init for the Community 3D Tour module
     * @param  {Object} element HTML element
     */
    this.initialize = function (element) {
        $element = $(element);
        var _this = this;
        var $ctaButtons = $element.find('.Community3dTour--button');
        var $lightBoxes = $element.find('.Lightbox');
        var $tabs = $element.find('.tab');
        var $hasLightbox = $element.find('.has-lightbox');
        var $tabPanels = $element.find('.tab-pane');
 

        $tabs.each(function(){
            var $activeElement = $(this);
            
            if($activeElement.hasClass('active')){  
                _this.lazyLoad($activeElement);
            }
        })

        // Activates the appropriate tab so the lightbox modal can be opened by other modules.
        $lightBoxes.on('show.bs.modal', function() {
            var tabID = this.closest('.tab-pane').id;

            _this.$element.find('[href="#'+tabID+'"]').tab('show');
        });

        $tabs.on('show.bs.tab', function(){
            _this.lazyLoad($(this));
        });

        if ($ctaButtons != null && $ctaButtons.length > 0) {
            $ctaButtons.each(function(idx, el) {

                $(el).click(function() {
                    if ($lightBoxes != null && $lightBoxes.length >= (idx + 1)) {
                        $($lightBoxes[idx]).modal('show');
                    }
                });
            });
        }

        this.handleResize();

        $(window).resize(utils.debounce(this.handleResize.bind(this), 250, false));

        $element.find('.Community3dTour--nav-tabs-container-inner').on('touchmove scroll', function() {
            $(this).removeClass('with-hint');
        });

        $element.find('.Community3dTour--button').on('click touch', function(e) {
            if (window.location.hash === e.currentTarget.hash) {
                //fire hashchange event to trigger deeplink scrolling
                $(window).trigger('hashchange');
            }
        });
    };

    this.lazyLoad = function (tab){
        var $tabID = tab.data('tab-id');
        var $tabPanel= $element.find('#' + $tabID);
        var $iFrame = $tabPanel.find('iframe:not(.Lightbox-tour)');

        if ($iFrame != null && $iFrame.length > 0) {
            var iFrameSrc = $iFrame.attr('src');

            if (iFrameSrc != null && iFrameSrc.length == 0) {
                var $dataTour = $tabPanel.find('[data-tour]');

                if ($dataTour != null && $dataTour.length > 0) {
                    var dataTourUrl = $dataTour.data('tour');

                    $iFrame.attr('src', dataTourUrl);
                }
            }
        }
    }

    // Using same functionality that exists in Toggle-Options but excluded the code unrelated to resizing
    this.handleResize = function () {
        var windowWidth = $(window).outerWidth();
        var items = $element.find('.nav-tabs');
        if (windowWidth < 768) {
            items.each(function() {
                var spaceBetween = $(this).data('space-between');
                var responsiveWidth = 0;
                var childCount = $(this).children('.tab').length;
                $(this).children('.tab').each(function(i) {
                    responsiveWidth += $(this).outerWidth() + parseInt(spaceBetween);
                    if (i === childCount - 1) {
                        responsiveWidth -= parseInt(spaceBetween);
                    }
                });
                responsiveWidth += 30; // account for the margin-left and margin-right
                $(this).css('width', responsiveWidth);

                if (responsiveWidth > windowWidth) {
                    $(this).closest('.Community3dTour--nav-tabs-container-inner').addClass('with-hint');
                } else {
                    $(this).closest('.Community3dTour--nav-tabs-container-inner').removeClass('with-hint');
                }
            });
        } else {
            items.each(function() {
                $(this).removeAttr('style');
            });
        }
    }
}

module.exports = component(Community3DTour);