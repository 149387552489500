var observerInitialized = false;
var observer;

function addElementToIntersectionObserver(targetElement) {
    var $targetElement = $(targetElement);
    var imageSrc = $targetElement.data('csrc');
    var options = { 
        rootMargin: '30%' // pixel distance away from viewing ViewPort before element gets loaded into DOM.
    }; 

    if (!observerInitialized) {
        try {
            initializeObserver();
        }
        catch (err) {
            setImageElemSrc($targetElement, imageSrc);
        }
    } else if (observer) {
        observer.observe(targetElement);
    } else {
        setImageElemSrc($targetElement, imageSrc);
    }

    function callback(entries, observer) {
        entries.forEach(function (entry) {
            if (entry.isIntersecting) {
                var image = entry.target;

                // If image is part of a Carousel, load all images in Carousel
                var carousel = $(image).closest('.owl-carousel').first();
                if (carousel && carousel.length) {
                    var carouselImages = $(carousel).find('img.u-responsiveMedia')
                    $.each($(carouselImages), function(index, carouselImage){
                        loadImage(carouselImage);
                    });
                }
                else {
                    loadImage(image);
                }
            }
        });
    }

    function initializeObserver() {
        observer = observer || new IntersectionObserver(callback, options);
        observer.observe(targetElement);
        observerInitialized = true;
    }

    function setImageElemSrc($elem, src) {
        if ($elem) {
            $elem.data('src', src);

            $elem.cloudinary_update({
                resizing: true
            });
        }
    }

    function loadImage(image){
        var src = $(image).data('csrc');
        if (src) {
            setImageElemSrc($(image), src);
        }
        
        observer.unobserve(image);
    }
}

module.exports = {
    addElementToIntersectionObserver: addElementToIntersectionObserver
};
