var component = require('../../../lib/js/component.js');

var AccountCreateAutoFill = function () {
  var $document = $(document);

  this.initialize = function (el, options) {
    this.options = options || {};
    var fields = this.$element.find('input:not([type=hidden]):not([type=submit]):not([type=radio]),select');
    var checkElem = this.$element.find('input[type=checkbox]');
    var radioElem = this.$element.find('input[type=radio]');
    var selectsElem = this.$element.find('select[name=Country]');
    $document.on('dataFields', function (e, data){
        autoFillFields(e, data, fields, checkElem, selectsElem, radioElem);
    });
  }

  /*
   * autoFillFields: fill the inputs after send the contact forms
   *
  */
  function autoFillFields(e, data, fields, checkElem, selectsElem, radioElem){
    var i, j, field;
    if(data.OutsideUS){
      for (i = 0, j = fields.length; i < j; i += 1) {
        field = fields[i];
        $(field).val(data[$(field).attr('name')]);

        if($(field).is(selectsElem)){
          $(field).find('option').each( function(index, optionEl) {
            if ( optionEl.value.toLowerCase() === data.Country.toLowerCase() ) {
              optionEl.selected = true;
            }
          });
        }
      }

      if(!$(checkElem).is(":checked")){
        $(checkElem).trigger('click');
      }else {
        return
      }

    }else {
      for (i = 0, j = fields.length; i < j; i += 1) {
        field = fields[i];
        $(field).val(data[$(field).attr('name')]);
      }
    }

    if(data.SpeakToSalesAgent === 'false'){
      $(radioElem).last().prop("checked", true);
    }
  }
}

module.exports = component(AccountCreateAutoFill);
