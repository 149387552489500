var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');

function FinancialToolbar() {

    /**
     * Init for the Financial Toolbar module
     * @param  {Object} element HTML element
     */
    this.initialize = function ( element ) {
        var $element = $( element ),
            $listElements = $element.find( 'li' ),
            $modal = $element.find( '.FinancialToolbar-modal' ),
            $closeIcons = $element.find( '.icon-close' );

        $listElements.on( 'click', this.openModalFromTab.bind( this, $listElements, $modal, $element ) );
        $closeIcons.on( 'click', this.hideModal.bind( this, $listElements, $modal, $element ) );

    };

    this.openModalFromTab = function( listElements, modal, $element, evt ) {
        var $listItem = $( evt.currentTarget ),
            modalTarget = $listItem.data( 'target' );

        this.hideModal( listElements, modal, $element );

        $element.on('affix.bs.affix', function(e){
            e.preventDefault();
        });

        $( 'body' ).addClass( 'toolbar-modal-open' );
        $listItem.addClass( 'is-active' );

        if ( modalTarget !== '' ){
            $(modalTarget).addClass( 'is-active' );
        }
    };

    this.hideModal = function ( listElements, modal, $element ){
        listElements.removeClass( 'is-active' );
        modal.removeClass( 'is-active' );
        $( 'body').removeClass( 'toolbar-modal-open' );

        $element.unbind('affix.bs.affix');

    };
}

module.exports = component( FinancialToolbar );
