var component = require('../../../lib/js/component.js');
var pfCriteriaLocation = require('./pfCriteriaLocation.js');
var pfCriteriaAttributes = require('./pfCriteriaAttributes.js');
var pfProductType = require('./pfProductType.js');
var pfDisplayMode = require('./pfDisplayMode.js');

function ProductFilter() {

    $noResultsContainer = $('.no-more-content');

  this.initialize = function (element, options) {

    var _this = this;

    $(window).on("components.loaded", function() {
        $(window).trigger("Header.disableFixed");
    });        

    var $locationSelection = this.$element.find('.LocationSelection');
    _this.initState = '';
    _this.initRegion = '';
    _this.initCity = '';

    if ($locationSelection && $locationSelection.length) {
        // initial location
        _this.initState = $locationSelection.data("state");
        _this.initRegion = $locationSelection.data("region");
        _this.initCity = $locationSelection.data("city");
    }

    this.pfData = {};
    this.pfData.productType = "community";

    // Used to suppress unneccessary actions on first load of page
    
    this.find('.pfCriteria--location').attach(pfCriteriaLocation);
    this.find('.pfCriteria--attributes').attach(pfCriteriaAttributes);
    this.find('.pfProductType').attach(pfProductType);
    this.find('.pfDisplayMode').attach(pfDisplayMode);

    // listen for location change
    this.$element.on('ploc-change', function (event, plocData) {

        _this.initState = plocData.state;
        _this.initRegion = plocData.region;
        _this.initCity = plocData.cities;
        _this.pfData.state = plocData.state;
        _this.pfData.region = plocData.region;
        _this.pfData.cities = plocData.cities;
        _this.pfData.minPrice = "";
        _this.pfData.maxPrice = "";
        _this.pfData.minBedrooms = "";
        _this.pfData.maxBedrooms = "";
        _this.pfData.minBathrooms = "";
        _this.pfData.maxBathrooms = "";
        _this.pfData.homeType = "";
        _this.pfData.changeType = "location";

        // send location change to pf-listeners
        $('.pf-listener').trigger("pf-change", _this.pfData);
        $('.ProductFilter').trigger('resetProductFilter');  

    });

    var initLocation = {
        "selectionSource": "location",
        "state": _this.initState,
        "region": _this.initRegion,
        "cityNames": _this.initCity
      }

    this.$element.trigger('updateAttributeRanges', initLocation);

    // Change in filter ranges
    this.$element.on('pf-filter-update', function (event, filterData) {

        _this.pfData.state = _this.initState;
        _this.pfData.region = _this.initRegion;
        _this.pfData.cities = _this.initCity;
        _this.pfData.minPrice = filterData.minPrice;
        _this.pfData.maxPrice = filterData.maxPrice;
        _this.pfData.minBedrooms = filterData.minBedrooms;
        _this.pfData.maxBedrooms = filterData.maxBedrooms;
        _this.pfData.minBathrooms = filterData.minBathrooms;
        _this.pfData.maxBathrooms = filterData.maxBathrooms;
        _this.pfData.homeType = filterData.homeType;
        _this.pfData.changeType = 'filter';

        $('.pf-listener').trigger("pf-change", _this.pfData);
        $('.ProductFilter').trigger('resetProductFilter');  
    });

    // Change in product type (community, qmi)
    this.$element.on('pf-product-update', function(event, newProductType) {
      _this.pfData.productType = newProductType;
      _this.pfData.changeType = 'product';

      $('.pf-listener').trigger("pf-change", _this.pfData);
      $('.ProductFilter').trigger('resetProductFilter');  
    });

    $(document).ready(function() {
        _this.$element.affix({
            offset: {
                top: _this.$element.offset().top
            }
        });
    });

  };

}

module.exports = component(ProductFilter);
