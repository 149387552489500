var component = require('../../../lib/js/component.js');
var Form = require('../forms/form.js');
var constants = require('../forms/constants.js');

var ResetPassword = function () {
    this.initialize = function (element, options) {
        this.$errorBanner = this.$element.find('.ResetPassword__error-banner');
        this.$formContainer = this.$element.find('.ResetPassword__form-container');
        this.$descriptionContainer = this.$formContainer.find('.ResetPassword__description');
        this.$confirmationContainer = this.$element.find('.ResetPassword__confirmation-container');
        this.$form = this.$element.find('.Form--resetPassword');

        this.$form.attach(Form).on(constants.FORM_AJAX_SUBMIT, this.handleResetPasswordFormSubmision.bind(this));
    }

    this.handleResetPasswordFormSubmision = function(event, form) {
        $.ajax({
            method: form.options.method,
            url: form.options.action,
            data: form.data,
            dataType: "json",
            context: this
        }).done(function (data) {
            if (data && data.Success) {
                // hide the form container and show the confirmation container
                this.$formContainer.addClass('d-none');
                this.$confirmationContainer.removeClass('d-none');
            } else {
                // hide the form and description and show the error banner
                this.$form.addClass('d-none');
                this.$descriptionContainer.addClass('d-none');
                this.$errorBanner.removeClass('d-none');
            }
        });
    }
};

module.exports = component(ResetPassword);
