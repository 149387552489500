var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');
var EVT = require('./type-ahead-events');

function TypeAheadInput() {

    this.initialize = function (element, options) {
        this.on('focus', this.onInputFocus.bind(this));
        this.on('blur', this.onInputBlur.bind(this));
        this.on('keyup', this.onKeyUp.bind(this));

        this.listen(EVT.UPDATE_INPUT, this.onUpdate.bind(this));
    };

    this.onInputFocus = function (event) {
        this.trigger(EVT.FOCUS);
    };

    this.onInputBlur = function (event) {
        this.trigger(EVT.BLUR);
    };

    this.onKeyUp = function (event) {
        var value = this.$element.val();

        if(!value) {
            return this.trigger(EVT.CLEAR);
        }

        if(event.keyCode === 40) {
            return this.trigger(EVT.DOWN);
        }

        if(event.keyCode === 38) {
            return this.trigger(EVT.UP);
        }

        return this.trigger(EVT.UPDATE, {
            value: value
        });
        // console.log('input keyup', event, this.$element.val());
    };

    this.onUpdate = function (event, data) {
        this.$element.val(data.value);
    };
}

module.exports = component(TypeAheadInput);
