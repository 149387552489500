var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');
var EVT = require('./type-ahead-events');
var template = require('./type-ahead-result.hbs');

function TypeAheadResults() {

    this.initialize = function (element, options) {
        this.index = -1;
        this.endpoint = options.endpoint || 'no-endpoint-provided';

        this.$list = this.find('.TypeAhead-resultsList');

        this.listen(EVT.UPDATE_RESULTS, this.update.bind(this));
        this.listen(EVT.NEXT, this.next.bind(this));
        this.listen(EVT.PREV, this.prev.bind(this));
        this.listen(EVT.UP, this.onUpTest);
    };

    this.update = function (event, data) {

        var html = [];

        this.results = data.results;

        if(this.results.length) {
            this.$element.addClass('is-showing')
        } else {
            this.$element.removeClass('is-showing');
        }

        for(var i = 0, l = this.results.length; i < l; i++) {
            var item = this.results[i];
            html.push(template({
                href: this.endpoint + encodeURI(this.results[i]),
                result: this.results[i]
            }));
        }

        this.$list.html(html);
        this.index = -1;
    };

    this.updateIndex = function (value) {

        var newValue = this.index + value;

        if(newValue > this.results.length-1) {
            this.index = 0;
        }
        else if(newValue <= -1) {
            this.index = this.results.length-1;
        }
        else {
            this.index = newValue;
        }
    };

    this.next = function (event, data) {

        this.updateIndex(1);
        this.updateUI();

    };

    this.prev = function (event, data) {

        this.updateIndex(-1);
        this.updateUI();
    };

    this.updateUI = function (index) {
        this.find('.TypeAhead-result').removeClass('is-active');
        this.find('.TypeAhead-result').eq(this.index).addClass('is-active');

        this.trigger(EVT.UPDATE_VALUE, {
            value: this.results[this.index]
        });
    };
}

module.exports = component(TypeAheadResults);
