var component = require('../../../lib/js/component.js');

function HersScoreMeter() {

    this.initialize = function (element, options) {
      var colorGraph = d3.select('.HersScoreMeter__graph');
      var colorGraphPin = colorGraph.select('.HersScoreMeter__pin');
      var colorGraphPinText = colorGraph.select('.HersScoreMeter__pinText');

      this.$element.on("hers-score-meter-update", function(e, hersScore) {
        if (hersScore && colorGraphPin && colorGraphPinText) {
          var pinYPosition = 300 - hersScore * 2.163846153846154;

          colorGraphPin.attr("y", pinYPosition);
          colorGraphPinText.text(hersScore);
        }
      });
    }
}

module.exports = component(HersScoreMeter);
