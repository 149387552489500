var component = require('../../../lib/js/component.js');
var Form = require('../forms/form.js');
var constants = require('../forms/constants.js')
var AdobeLaunch = require('../../../lib/js/adobelaunch.js');
var FacebookPixel = require('../../../lib/js/facebookpixel.js');
var utils = require('../../../lib/js/utils.js');

var RegisterHome = function () {

    var self = this;

    this.initialize = function (element) {
        self.$form = this.$element.find('#RegisterHomeForm');
        self.$errorMessage = $('.MsgModal--error');

        self.$form
            .attach(Form)
            .on(constants.FORM_AJAX_SUBMIT, self.handleFormSubmissionQuickCode)
            .on(constants.FORM_CHANGED, self.handleFormChanged);

        utils.handleVisible(self.$form, function () {
            AdobeLaunch.pushFormViewedEvent(AdobeLaunch.FORM_GLOBAL_ID, AdobeLaunch.FORM_INLINE_TYPE, AdobeLaunch.FORM_HOME_REGISTRATION_NAME);
        });
    };

    this.handleFormSubmissionQuickCode = function (event, form) {
        if (self.$errorMessage)
            self.$errorMessage.hide();

        $.ajax({
            method: form.options.method,
            url: form.options.action,
            data: form.data,
            dataType: "json"
        })
        .done(onSuccess)
        .fail(onError);
    }

    this.handleFormChanged = function () {
        self.$form.off(constants.FORM_CHANGED);
        AdobeLaunch.pushFormStartedEvent(AdobeLaunch.FORM_GLOBAL_ID, AdobeLaunch.FORM_INLINE_TYPE, AdobeLaunch.FORM_HOME_REGISTRATION_NAME);
    }

    function onSuccess(data) {
        if (!data.Success) {
            onError(data, null);
        }
        else {
            AdobeLaunch.pushFormSubmitSucceededEvent(AdobeLaunch.FORM_GLOBAL_ID, AdobeLaunch.FORM_INLINE_TYPE, AdobeLaunch.FORM_HOME_REGISTRATION_NAME);
            FacebookPixel.pushFormSubmissionEvent(FacebookPixel.FORM_INLINE_TYPE, FacebookPixel.FORM_HOME_REGISTRATION_NAME);
            location.href = data.RedirectUrl;
        }
    }

    function onError(data, jqXHR) {
        if (self.$errorMessage)
            self.$errorMessage.html(jqXHR && jqXHR.responseJSON ? jqXHR.responseJSON.error : data.error);
            self.$errorMessage.show();

        if (self.$labels)
            self.$labels.toggleClass('has-server-error', true);

        AdobeLaunch.pushFormSubmitFailedEvent(AdobeLaunch.FORM_GLOBAL_ID, AdobeLaunch.FORM_INLINE_TYPE, AdobeLaunch.FORM_HOME_REGISTRATION_NAME);
        location.href = data.RedirectUrl;
    }
}

module.exports = component(RegisterHome);
