var component = require('../../../lib/js/component.js');

function TilesModuleCarousel() {

    /**
     * Init for the load more functionality of the tile module
     * @param  {Object} element HTML element
     */
    this.initialize = function (element) {
        this.element = element;
        this.dragging = false;
        this.dragThresholdMet = false;
        this.cancel = false;
        this.deltaX = 0;
        this.deltaY = 0;
        this.startClientX = 0;
        this.startClientY = 0;
        this.isTouch = 'ontouchend' in document;

        if ( this.isTouch ) {
            this.element.addEventListener('touchstart', this._dragStart.bind(this));
            this.element.addEventListener('touchmove', this._drag.bind(this));
            this.element.addEventListener('touchend', this._dragEnd.bind(this));
            this.element.addEventListener('touchcancel', this._dragEnd.bind(this));
        }
    };

    /**
     * Start dragging (via touch)
     * @param  {event} e Touch event
     * @return {void}
     */
    this._dragStart = function(e) {
        var touches;

        e = e.originalEvent || e;
        touches = e.touches !== undefined ? e.touches : false;

        this.dragThresholdMet = false;
        this.dragging = true;
        this.cancel = false;
        this.startClientX = touches ? touches[0].pageX : e.clientX;
        this.startClientY = touches ? touches[0].pageY : e.clientY;
        this.deltaX = 0;	// reset for the case when user does 0,0 touch
        this.deltaY = 0;	// reset for the case when user does 0,0 touch
    }

    /**
     * Update slides positions according to user's touch
     * @param  {event} e Touch event
     * @return {void}
     */
    this._drag = function(e) {
        var abs = Math.abs,
            touches;

        if (!this.dragging || this.cancel) {
            return;
        }

        e = e.originalEvent || e;
        touches = e.touches !== undefined ? e.touches : false;
        this.deltaX = (touches ? touches[0].pageX : e.clientX) - this.startClientX;
        this.deltaY = (touches ? touches[0].pageY : e.clientY) - this.startClientY;

        if (this.dragThresholdMet || (abs(this.deltaX) > abs(this.deltaY) && abs(this.deltaX) > 10)) { // 10 from empirical testing
            e.preventDefault();
            this.dragThresholdMet = true;
        } else if ((abs(this.deltaY) > abs(this.deltaX) && abs(this.deltaY) > 10)) {
            this.cancel = true;
        }
    }

    /**
     * Drag end, calculate slides' new positions
     * @param  {event} e Touch event
     * @return {void}
     */
    this._dragEnd = function() {
        if (!this.dragging || this.cancel) {
            return;
        }

        this.dragging = false;

        if ( this.deltaX > 0 ) {
            $(this.element).carousel('prev');
        }
        else if ( this.deltaX < 0 ) {
            $(this.element).carousel('next');
        }
    }
}

module.exports = component(TilesModuleCarousel);
