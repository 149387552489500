var component = require('../../../lib/js/component.js');
var constants = require('../forms/constants.js');

var NavigateAway = function () {

  this.initialize = function (el, options) {
    var _this = this;
    var $header = $('.Header');
    var leavePageMgs = $header.attr('data-navigate-message');
    var isDirty = false;
    var elemOffsetTop = _this.$element.offset().top - $header.height();
    var DESIGN_MODAL_OPEN = 'DesignBoardModal:open';
    var $document = $(document);
    var $window = $(window);

    closeEditorWarning = function () {
      if (isDirty) {
        return leavePageMgs;
      }else{
        return;
      }
    };

      _this.$element.on(constants.FORM_CHANGED, function(){
      if(_this.$element.hasClass('is-dirty') && _this.$element.is(':visible')){
        isDirty = true;
      }
    });

    this.$element.submit(function () {
      isDirty = false;
    });

    // specific case to create or remove a design board event
    $document.on('board:updated', function(){
      isDirty = false;
    });

    $document.on('shown.bs.modal', function (e) {
      if(e.target.id === 'DesignBoard-addItemsModal') {
        isDirty = false;
      }
    });

    $window.on('beforeunload', closeEditorWarning);
  }
}

module.exports = component(NavigateAway);
