var component = require('../../../lib/js/component.js');
var Form = require('../forms/form.js');
var AdobeLaunch = require('../../../lib/js/adobelaunch.js');
var FacebookPixel = require('../../../lib/js/facebookpixel.js');
var utils = require('../../../lib/js/utils.js');
var constants = require('../forms/constants.js');

var RegisterYourHomeEditorial = function () {

    var self = this;

    this.initialize = function (element) {
        self.$form = this.$element;

        self.$form
            .attach(Form)
            .on(constants.FORM_AJAX_SUBMIT, self.handleFormSubmissionQuickCode)
            .on(constants.FORM_CHANGED, self.handleFormChanged);

        utils.handleVisible(self.$form, function () {
            AdobeLaunch.pushFormViewedEvent(AdobeLaunch.FORM_GLOBAL_ID, AdobeLaunch.FORM_INLINE_TYPE, AdobeLaunch.FORM_HOME_REGISTRATION_NAME);
        });
    };

    this.handleFormSubmissionQuickCode = function (event, form) {
        $.ajax({
            method: form.options.method,
            url: form.options.action,
            data: form.data,
            dataType: "json"
        })
            .done(onSuccess)
            .fail(onError);
    };

    this.handleFormChanged = function () {
        self.$form.off(constants.FORM_CHANGED);
        AdobeLaunch.pushFormStartedEvent(AdobeLaunch.FORM_GLOBAL_ID, AdobeLaunch.FORM_INLINE_TYPE, AdobeLaunch.FORM_HOME_REGISTRATION_NAME);
    };

    function onSuccess(data) {
        if (!data.Success) {
            onError(data);
        }
        else {
            AdobeLaunch.pushFormSubmitSucceededEvent(AdobeLaunch.FORM_GLOBAL_ID, AdobeLaunch.FORM_INLINE_TYPE, AdobeLaunch.FORM_HOME_REGISTRATION_NAME);
            FacebookPixel.pushFormSubmissionEvent(FacebookPixel.FORM_INLINE_TYPE, FacebookPixel.FORM_HOME_REGISTRATION_NAME);
            location.href = data.RedirectUrl;
        }
    }

    function onError(data) {
        AdobeLaunch.pushFormSubmitFailedEvent(AdobeLaunch.FORM_GLOBAL_ID, AdobeLaunch.FORM_INLINE_TYPE, AdobeLaunch.FORM_HOME_REGISTRATION_NAME);
        location.href = data.RedirectUrl;
    }
};

module.exports = component(RegisterYourHomeEditorial);
