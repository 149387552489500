var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');

function Glossary() {
	this.initialize = function () {
		this.classes = {
			"activeListTypeAhead": "active-term",
			"typeAheadOpen": "type-open",
			"resultsShown": "results-shown",
			"noResultsFound": "no-results-found",
			"inputOpen": "search-filled"
		}

		this.listIndex = 1;

		this.termsWrapper = $(this.element).find( '.Glossary-wrapper' );
		this.inputSearchWrapper = $(this.element).find( '.Glossary-search-wrapper' );
		this.inputSearch = $(this.element).find( '.Glossary-search' );
		this.inputSearchCta = $(this.element).find( '.Glossary-search-cta .icon-search' );
		this.inputSearchCtaClose = $(this.element).find( '.Glossary-search-cta .icon-close' );

		this.resultsWrapper = $(this.element).find( '.Glossary-results' );
		this.alphabetWrapper = $(this.element).find( '.Glossary-alphabet' );


		this.typeWrapper = $(this.element).find( '.Glossary-typeAheadOptions' );
		this.typeList = this.typeWrapper.find( 'ul' );

		this.defaultViewResults = '<div class="Glossary-results">' + this.resultsWrapper.html() + '</div>';

		this.events();
	}

	this.scrollTo = function( $element ){
		$('html, body').animate({
	        scrollTop: $element.offset().top
	    }, 5);
	}

	this.resetInput = function(){
		this.inputSearch.val( '' );
		this.inputSearchWrapper.removeClass( this.classes.inputOpen );
	}

	this.alphabetEvents = function(){
		var self = this;

		$( '.Glossary-alphabet li:not(.noContent)' ).on( 'click', function( e ){
			var elementToScrollName = this.getAttribute( 'data-scrollTo' ),
				anchorElement = e.target.hash;

			e.preventDefault();
			self.resetInput();

			if ( $( self.element ).hasClass( 'results-shown' ) ){
				self.defaultGlossaryView();
			}

			if ( anchorElement === window.location.hash){
				self.scrollTo( $( anchorElement ) );
			}
			else{
				window.location.hash = anchorElement;
			}

		});
	}

	this.events = function(){
		var self = this,
			allTerms = self.getAllTermsToSearch();

		this.alphabetEvents();

		this.inputSearchCtaClose.on( 'click', function () {
			self.inputSearch.val( '' );
			self.inputSearchWrapper.removeClass( self.classes.inputOpen );
			self.inputSearchCta.trigger( 'click' );
			self.deleteTermsTypeAhead();
			self.defaultGlossaryView();

		});

		this.inputSearchCta.on( 'click', function(){
			var inputValue = self.inputSearch.val(),
				matchedTerms = self.compareTerms( inputValue, allTerms);

			if ( inputValue !== '' ){
				self.deleteTerms();
				self.deleteTermsTypeAhead();

                window.Analytics.search = {
                    count: matchedTerms.length,
                    query: inputValue
                };

			    // Analytics data tracking
                if (typeof window._satellite !== 'undefined') {
                    window._satellite.track("glossary_searchresults_loaded");
                }
                
                // Analytics data tracking
                if ( matchedTerms && matchedTerms.length !== 0){
                    self.populateTerms( matchedTerms );
				}
				else{
					self.populateNoResults();
				}
			}


		});

		this.inputSearch.on('keydown', function( e ){
			var keyCode = e.which;
			if ( keyCode === 9 ){
				self.listenTabKey( e );
			}

		});

		this.inputSearch.on('keyup', function(e){
			var inputValue = self.inputSearch.val(),
				inputSplittedValues = inputValue.split( ' ' ),
				discoverableTerms = self.discoverableTerms( allTerms );
				allDiscoverableTerms = [],
				resultTerms = [],
				resultsToRender = [],
				index = [],
				keyCode = e.which;


			//Creating the all terms to compare
			$.each( discoverableTerms, function( index, value ){
				var splittedTerm = value.split( ' ' );

				$.each( splittedTerm, function( indexSplittled, splittedValue ){
					allDiscoverableTerms.push( splittedValue );
				});

			});


			//compare each discoverable term vs the input value
			$.each( allDiscoverableTerms, function( index, value ){

				// Looking for each string from the input
				$.each( inputSplittedValues, function( index, splittedValue ){

					if ( splittedValue !== '' ){
						if ( value.toLowerCase().contains( splittedValue.toLowerCase() ) ){

							if ( !resultTerms.containsEl( value ) ){
								resultTerms.push( value );
							}

						}
					}

				});

			});

			//exponer

			$.each( discoverableTerms, function( index, term ){

				$.each( resultTerms, function( index, result ){
					if ( term.toLowerCase().contains( result.toLowerCase() ) ){
						if ( resultsToRender.indexOf( term ) === -1 ){
							resultsToRender.push ( term );
						}

					}
				});

			});

			//Rendering the type ahead list
			self.deleteTermsTypeAhead();

			if ( resultsToRender.length !== 0 ){
				self.populateMatchedTerms( resultsToRender.slice(0, 10) );
				self.listElementsEvent();
			}

			self.listenKeyBoard( keyCode, e );


			//Populate with default values (alphabet and results)
			if ( inputValue === '' ){
				$( self.inputSearchWrapper).removeClass( self.classes.inputOpen );

				if ( $( self.element ).hasClass( self.classes.resultsShown ) ){
					self.defaultGlossaryView();
					//self.alphabetEvents();
				}
			}
			else{
				$( self.inputSearchWrapper ).addClass( self.classes.inputOpen );
			}

		});

	}

	this.listElementsEvent = function(){
		var self = this;
		this.typeList.find( 'li' ).on( 'click', function(){
			self.inputSearch.val( $(this).html() );
			self.inputSearchCta.trigger( 'click' );
		});
	}

	//Data manipulation type ahead

	this.discoverableTerms = function( listOfTerms ){
		var terms = [];

		$.each( listOfTerms, function( index, value ){
			terms.push( value.name );
		});

		return terms;
	}

	//Data manipulation filter

	this.getAllTermsToSearch = function() {
		var self = this,
			discoverable = [];

		if ( window.glossaryTerms ){
			$.each( window.glossaryTerms.terms, function( index, element ){
				$.each( element.content, function( index, content ){
					discoverable.push(content);

				});
			});
		}

		return discoverable;
	}

	this.compareTerms = function( termToCompare, listOfTerms ){
		var self = this,
			splittedTermsToCompare = termToCompare.split( ' ' ),
			matched = [];

		$.each( splittedTermsToCompare, function( index, value ){
			$.each( listOfTerms, function( index, termToSearch ){
				if ( self.filterResults( value, termToSearch ) ){

					if ( self.ifTermExistInList( termToSearch.name, matched ) ){
						matched.push( termToSearch )
					}

				}
			});
		});

		return matched;
	}

	this.filterResults = function( termToCompare, termOfList ){
		var splittedValues = termOfList.name.split( ' ' ),
			termFound = false;

		$.each( splittedValues, function( index, value ){
			if ( value.toLowerCase() === termToCompare.toLowerCase() ) {
				termFound = true;
			}
		});

		return termFound;
	}

	this.ifTermExistInList = function( term, listOfTerms ){
		var ifExist = true;

		$.each( listOfTerms, function( index, termFromList ){
			if ( termFromList.name.toLowerCase() === term.toLowerCase() ){
				ifExist = false;
			}

		});

		return ifExist;
	}

	//DOM functions for filter

	this.deleteTerms = function(){
		var self = this;
		self.resultsWrapper.html( '' );
	}

	this.populateTerms = function( listOfTerms ){
		var self = this;

		$(this.element).addClass( this.classes.resultsShown );
		$(this.element).removeClass( this.classes.noResultsFound );

		$.each( listOfTerms, function( index, term ){
			self.resultsWrapper.append( '<div class="Glossary-term"><h5>'+term.name+'</h5><p>'+term.desc+'</p></div>' );

		});
	}

	this.populateNoResults = function(){
		$(this.element).addClass( this.classes.resultsShown );
		$(this.element).addClass( this.classes.noResultsFound );
		this.resultsWrapper.html( '<div class="Glossary-term"><h5>0 results</h5></div>' );
	}

	//DOM functions for type ahead

	this.deleteTermsTypeAhead = function(){

		this.typeWrapper.removeClass( this.classes.typeAheadOpen );
		this.typeList.html(' ');
	}

	this.populateMatchedTerms = function( listOfMatchedTerms ){
		var self = this;

		this.typeWrapper.addClass( this.classes.typeAheadOpen );

		$.each( listOfMatchedTerms, function( index, value ){
			self.typeList.append( '<li>'+value+'</li>' );
		});
	}

	this.resetActiveList = function(){
		this.typeList.find('li').removeClass( this.classes.activeListTypeAhead );
	}

	this.updateActiveList = function( index ){
		this.resetActiveList();
		this.typeList.find( 'li:nth-child(' +index+ ')' ).addClass( this.classes.activeListTypeAhead );
	}

	this.defaultGlossaryView = function(){
		var content = this.defaultViewResults;

		$(this.element).removeClass( this.classes.resultsShown );
		$(this.element).removeClass( this.classes.noResultsFound );
		this.resultsWrapper.html( content );

	}

	this.listenKeyBoard = function( keyCode, e ){
		var self = this,
			listLength = this.typeList.find( 'li' ).length;

		switch ( keyCode ){
			case 13: //enter
				self.inputSearchCta.trigger( 'click' );
			break;

			case 38: //up
				var index = 0;
				if ( self.listIndex > 1 ){
					self.listIndex -= 1;
					index = self.listIndex
				}
				else {
					index = 1
				}
				self.updateActiveList( index );
			break;

			case 40: //down
				var inIndex = 0;

				if ( self.listIndex < listLength ){
					inIndex = self.listIndex;
					self.listIndex += 1;

				}
				else{
					inIndex = listLength;
				}

				self.updateActiveList( inIndex );

			break;

			default:
			break;

		}
	}

	this.listenTabKey = function( e ){
		var self = this,
			activeTerm = this.typeList.find( '.'+this.classes.activeListTypeAhead );

		if ( activeTerm.length > 0 ){
			e.preventDefault();
			self.listIndex = 1;
			self.inputSearch.val( activeTerm.first().html() );

		}
	}
}

module.exports = component(Glossary);
