var component = require("../../../lib/js/component.js");
var utils = require("../../../lib/js/utils.js");

function LifeTestedFeature() {
    this.initialize = function (element) {
        // Cache frequently used jQuery selectors
        this.main = this.$element;
        this.foundVisible = false;

        var $secondaryTab = this.main.find(".secondary-tab");
        var $owlCarousel = this.$element.find(".dfy-carousel");
        var $compareScreen = $('.LifeTestedCompare');
        var $LifeTestedFeature = $('.LifeTestedFeature');
        var $tableContainer = $compareScreen.find(".table-container");
        var $prevButton = $compareScreen.find(".prev-button");
        var $nextButton = $compareScreen.find(".next-button");
        var $screenXsValue = $("html").css("--screen-xs-max");
        var $screenXsNumber = parseInt($screenXsValue, 10);
        var $tempTab = null;
        var $lastFocusedElement = null;

        $compareScreen.hide();

        // Event handling for secondary tabs
        $secondaryTab.on("click touch", function(e) {
            this.filterContent(e);
            this.handleScrollbar();
        }.bind(this));

        $secondaryTab.on("keydown", function(event) {
            if (event.keyCode === 13) { // Enter key code
                $LifeTestedFeature.find('.scrollable-inner *[tabindex]').attr('tabindex', '-1');
                $LifeTestedFeature.find('.btn-primary').attr('tabindex', '-1');
                $LifeTestedFeature.find('.title-row *[tabindex]').attr('tabindex', '-1');
                this.filterContent(event);
                this.handleScrollbar();
                $tempTab = $(event.target).next('.secondary-tab');
                $lastFocusedElement = null;
            }else if(event.keyCode===9 && $lastFocusedElement === "content-container"){ 
                // will update only when previous focused element is content container
                $LifeTestedFeature.find('.scrollable-inner *[tabindex]').attr('tabindex', '0');
                $LifeTestedFeature.find('.btn-primary').attr('tabindex', '0');
                $LifeTestedFeature.find('.title-row *[tabindex]').attr('tabindex', '0');
                $lastFocusedElement = null;
                $tempTab = null
            }
        }.bind(this));

        $LifeTestedFeature.find(".main-row .content-container").on("keydown", function() {
            $($tempTab).attr('tabindex', '0');

            if(!$lastFocusedElement){
                $lastFocusedElement = "content-container";
            }
        })

        this.handleCompareScrollbar = function () {
            var $tableContainer = $(".LifeTestedCompare .table-container");

            // Check if content is overflowing vertically
            var isOverflowing =
                $tableContainer[0].scrollHeight > $tableContainer.innerHeight();

            $tableContainer
                .find("tbody tr th:first-child")
                .toggleClass("min-width-applied", !isOverflowing);
        };
        
        this.$element.find(".btn-primary").on("click", function () {
            $compareScreen.show();
            $LifeTestedFeature.hide();

            this.handleCompareScrollbar();
            // Set the scroll position dynamically to the end of the content
            $tableContainer.scrollLeft(
                $tableContainer.width() - $tableContainer[0].scrollWidth
            );

            $tableContainer.addClass('scroll-smooth')
            var $firstTh = $tableContainer.find(".tbody tr:first-child th:first-child");
            var $firstThHeight = $firstTh.height();

            // updating vertical scroll position
            $tableContainer.css(
                "--scrollbar-margin-top",
                $firstThHeight + "px"
            );

            if ($(window).width() <= $screenXsNumber) {
                var $offset = $firstTh.offset();

                var $lifeTestedCompareTop = $(".LifeTestedCompare").offset().top;
                var $adjustedTopPosition = $offset.top - $lifeTestedCompareTop;

                $(".screen-overlay")
                    .css({
                        position: "absolute",
                        top: $adjustedTopPosition + "px",
                        left: $offset.left + "px",
                        width: $firstTh.outerWidth() + "px",
                        height: $firstThHeight + "px",
                        background: "linear-gradient(90deg, rgba(246, 246, 246, 1) 54%, rgba(246, 246, 246, 0) 100%)",
                        "z-index": "1000"
                    })
                    .show();
            }else {
                $(".screen-overlay").hide();
            }
        }.bind(this));

        // Update button visibility on scroll
        $tableContainer.on("scroll", function () {
            var $scrollLeft = Math.abs($tableContainer.scrollLeft());
            var $maxScroll = $tableContainer[0].scrollWidth - $tableContainer.width();

            $nextButton.toggle($scrollLeft !== 0 && $(window).width() > $screenXsNumber);
            $prevButton.toggle(!(Math.abs($scrollLeft - $maxScroll) <= 40) && $(window).width() > $screenXsNumber);

            $tableContainer.find("table tr:first-child th").each(function() {
                var thOffset = $(this).offset().left - $tableContainer.offset().left;
                // Calculate offset relative to container
                if ($(this).css("position") === "sticky" && thOffset <= 25) {
                    $(this).find("h3").addClass("hide-text");
                } else {
                    $(this).find("h3").removeClass("hide-text");
                }
            });
        });

        $prevButton.on("click", function () {
            var $planWidth = $tableContainer.find("th:eq(1)").outerWidth();
            var $currentScrollLeft = $tableContainer.scrollLeft() - 15;
            var $alignedScrollLeft = Math.floor($currentScrollLeft / $planWidth) * $planWidth;

            $tableContainer.scrollLeft($alignedScrollLeft);
        });

        $nextButton.on("click", function () {
            var $planWidth = $tableContainer.find("th:eq(1)").outerWidth();
            var $currentScrollLeft = $tableContainer.scrollLeft();

            // Calculated the next closest position aligned with the plan width
            var $alignedScrollLeft =
                $currentScrollLeft % $planWidth === 0 ||
                    $currentScrollLeft % $planWidth > -15
                    ? Math.ceil($currentScrollLeft / $planWidth) * $planWidth
                    : Math.floor($currentScrollLeft / $planWidth) * $planWidth;

            // Calculate the next position
            var $newScrollLeft = $alignedScrollLeft + $planWidth;
            $tableContainer.scrollLeft($newScrollLeft);
        });

        $compareScreen.find(".back-button").on("click", function () {
            $tableContainer.removeClass('scroll-smooth')
            $compareScreen.hide();
            $LifeTestedFeature.show();
        });

        this.manageTabindex = function (updatedIndex) {

            var $items = $owlCarousel.find('.carousel__item');

            $items.find('.LifeTestedFeature-video-image *[tabindex], .u-xs-noPad *[tabindex], .owl-item.active:not(.cloned) *[tabindex], .owl-prev, .owl-next').attr('tabindex', '-1');
            if (updatedIndex === undefined || updatedIndex === null) {
                
                var $visibleItems = $owlCarousel.find(' > .owl-stage-outer > .owl-stage > .owl-item.active:not(.cloned)');
                $visibleItems.find('.LifeTestedFeature-video-image *[tabindex], .u-xs-noPad *[tabindex], .owl-item.active:not(.cloned) *[tabindex], .owl-prev, .owl-next').attr('tabindex', '0');
                return;
            }

            var $activeItems = $items.eq(updatedIndex);
            $activeItems.find('.LifeTestedFeature-video-image *[tabindex], .u-xs-noPad *[tabindex], .owl-item.active:not(.cloned) *[tabindex],  .owl-prev, .owl-next').attr('tabindex', '0');
        }.bind(this)

        // Event handling for mobile slider
        $owlCarousel.owlCarousel({
            lazyLoad: true,
            loop: true,
            dots: false,
            margin: 10,
            autoHeight: true,
            nav: false,
            responsive: {
                0: {
                    items: 1,
                    touchDrag: false
                }
            },
            onRefreshed: function(){
                setTimeout(this.manageTabindex, 100)
            }.bind(this) 
        });

        // Event handling for slider navigation
        this.$element.find(".slider-nav__next, .slider-nav__prev").on("click", function (event) {
            event.stopPropagation();
            $owlCarousel.trigger((this.className.includes("next") ? "next" : "prev") + ".owl.carousel");
        });

        // Update tabindex based on active item
        $owlCarousel.on( "changed.owl.carousel", function (event) {
                if (event.target === $owlCarousel.get(0)) {
                    this.manageTabindex(event.item.index);
                    this.centerSliderNav();
                }
            }.bind(this)
        );

        // Set default values
        this.selectedOption = $secondaryTab.first().data().option;
        this.selectedSecondaryIndex = $secondaryTab.first().data().index;

        // Set visible content accordingly
        this.setActiveContent();

        // Handle mobile scrollable options
        this.handleResize();
        $(window).resize(utils.debounce(this.handleResize.bind(this), 250, true));
        $(".scrollable").on("touchmove scroll", function () {
            $(this).removeClass("with-hint");
        });

        // Video handling
        this.setupVideoHandlers();

        // update slider nav location
        this.centerSliderNav()

        // Initialize scrollbar handling
        this.handleScrollbar();

        // Bugfix for avoiding blurry imagery in the embedded  component
        $(window).trigger("resize");
    };

    this.setupVideoHandlers = function () {
        var _this = this,
            $element = _this.$element,

            $modalPlayer = $element.find('.LifeTestedFeature-has-video'),
            $modalPlayerImg = $modalPlayer.find('img'),
            $initialPlayButton = $element.find('.btn-play-video');

        _this.$overlayVideoTarget = $('#LifeTestedFeature-video-player');

        _this.$playButton = _this.$overlayVideoTarget.find('.start-video');
        _this.$videoWrapper = _this.$overlayVideoTarget.find('.LifeTestedFeature-video-player-wrapper');
        _this.$videoPlayerImage = _this.$videoWrapper.find('img');
        _this.$videoCaption = _this.$overlayVideoTarget.find('.LifeTestedFeature-video-caption');
        _this.player = {};
        _this.videoIsReady = false;

        // Set attributes for modal trigger elements
        $modalPlayerImg.add($initialPlayButton).attr({
            'data-toggle': 'modal',
            'data-target': '#LifeTestedFeature-video-player'
        });

        // Start YouTube API
        startYoutubeAPI();

        onPlayerReady = function (event) {
            // console.log('Player is ready');
            _this.videoIsReady = true;
        };

        onPlayerStateChange = function (event) {
            // console.log('Player state changed:', event.data);
            // event states
            //-1: Unstarted > 0: Ended > 1: Playing
            //> 2: Paused > 3: Buffering > 5: Video cued

            if (event.data == YT.PlayerState.ENDED) {
                _this.$playButton.fadeIn('normal');
            }
        };

        // Set up modal trigger events
        $modalPlayerImg.add($initialPlayButton).on('click', function (e) {
            e.preventDefault();

            var caption = $(e.currentTarget.parentNode).attr('data-heading');

            _this.renderModal($(e.currentTarget.parentNode).attr('data-idvideo'), caption);
        });

        // Set up video wrapper click event
        _this.$videoWrapper.on('click', function () {
            //  console.log('Video wrapper clicked');
            _this.playVideo();
        });

        // Set up modal hide event
        _this.$overlayVideoTarget.on('hide.bs.modal', function () {
            // console.log('Modal removed or hidden');
            if (_this.videoIsReady) {
                _this.player.pauseVideo();
            }
            $('body').removeClass('video-player-from-modal');
        });

        _this.playVideo = function () {
            // console.log('Play Video function called');
            if (_this.videoIsReady) {
                var heightVideo = _this.$videoPlayerImage.height();
                _this.$videoPlayer = _this.$overlayVideoTarget.find('.LifeTestedFeature-video-player-wrapper iframe');

                _this.$videoPlayerImage.hide();

                _this.$videoPlayer.height(heightVideo);
                _this.player.loadVideoById(_this.idVideo);
                _this.$videoPlayer.show();

                _this.$playButton.fadeOut('normal');
                _this.player.playVideo();
            } else {
                setTimeout(function () {
                    _this.playVideo()
                }, 1000);
            }
        };

        // Set up YouTube video stack
        youtubeVideosStack.push({
            context: _this,
            player: 'player',
            id: 'playerLifeTestedFeature',
            options: {
                height: '100%',
                width: '100%',
                videoId: '',
                playerVars: {
                    'autoplay': 0,
                    'rel': 0,
                    'showinfo': 0,
                    'enablejsapi': 1
                },
                events: {
                    'onReady': onPlayerReady,
                    'onStateChange': onPlayerStateChange
                }
            }
        });
    };

    this.renderModal = function (idVideo, caption) {
        var _this = this,
            sourceImageHd = '//i.ytimg.com/vi/' + idVideo + '/maxresdefault.jpg';

        $('body').addClass('video-player-from-modal');
        _this.$overlayVideoTarget.modal('hide');
        _this.$videoPlayerImage.attr("src", sourceImageHd);
        _this.$videoCaption.html(caption);
        _this.idVideo = idVideo;


        setTimeout(function () {
            var img = _this.$videoPlayerImage[0];

            _this.$videoPlayerImage.show();
            _this.$playButton.show();
            _this.$videoPlayer = _this.$overlayVideoTarget.find('.LifeTestedFeature-video-player-wrapper iframe');
            _this.$videoPlayer.hide();
        }, 350);
    };

    this.filterContent = function (e) {
        var $el = $(e.currentTarget);
        this.selectedOption = $el.data().option;
        this.selectedSecondaryIndex = $el.data().index;

        this.setActiveContent();
    };

    this.handleScrollbar = function () {
        
        var $contentHeight = this.main.find('.content-container.is-active').height();
        var $secondaryTabs = this.main.find('.secondary-tabs');
      
        $secondaryTabs.css('max-height', $contentHeight >= 330 ? $contentHeight : 330);
    
        var $scrollable = this.main.find('.scrollable');
        var $scrollableInner = $scrollable.find('.scrollable-inner');
        
        if ($scrollableInner[0].scrollHeight > $scrollable[0].clientHeight) {
            // Apply class if scrollbar appears
            $scrollableInner.addClass('scrollbar-visible');
        } else {
            // Remove class if no scrollbar
            $scrollableInner.removeClass('scrollbar-visible');
        }
    };
    
    this.setActiveContent = function () {
        // toggle active secondary tab
        this.main.find(".secondary-tab.is-active").removeClass("is-active");
        this.main
            .find('.secondary-tab[data-option="' + this.selectedOption + '"]')
            .addClass("is-active");
        // filter view
        this.main.find(".content-container.is-active").removeClass("is-active");
        var $activeContentContainer = this.main
            .find(
                '.main-row .content-container[data-index="' +
                this.selectedSecondaryIndex +
                '"]'
            )
            .addClass("is-active");
            // Reset tabindex for all children elements of content containers
            this.main.find('.content-container *[tabindex]').attr('tabindex', '-1');
            // Set tabindex to 0 for elements within the active content container
            $activeContentContainer.find('*[tabindex]').attr('tabindex', '0');     
    };

    this.handleResize = function () {
        var windowWidth = $(window).outerWidth();
        if (windowWidth < 768) {
            $(".scrollable .scrollable-inner").each(function () {
                var spaceBetween = $(this).attr("data-space-between");
                var responsiveWidth = 0;
                var childCount = $(this).children(".scrollable-item").length;
                $(this)
                    .children(".scrollable-item")
                    .each(function (i) {
                        responsiveWidth +=
                            $(this).outerWidth() + parseInt(spaceBetween);
                        if (i === childCount - 1) {
                            responsiveWidth -= parseInt(spaceBetween);
                        }
                    });
                responsiveWidth += 30; // account for the margin-left and margin-right
                $(this).css("width", responsiveWidth);

                if (responsiveWidth > windowWidth) {
                    $(this).closest(".scrollable").addClass("with-hint");
                } else {
                    $(this).closest(".scrollable").removeClass("with-hint");
                }
            });
        } else {
            $(".scrollable .scrollable-inner").each(function () {
                $(this).css("width", "auto");
            });
        }
    };

    this.centerSliderNav = function () {
        var _this = this;

        $(document).ready(function () {
        var $activeContentContainer = _this.$element.find(".owl-item.active .LifeTestedFeature-image-container");
     
           if($activeContentContainer[0]){
            var $containerHeight = $activeContentContainer[0].offsetHeight;
            var $sliderNav = _this.$element.find(".slider-nav");
            var $offset = ($containerHeight - $sliderNav.height()) / 2;
            $sliderNav.css("top", $offset + "px");
           }
        });
    };
}

module.exports = component(LifeTestedFeature);