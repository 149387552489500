/*
 * Plugin to save items (homes / Communities / images / floor plans )
 *
*/

var AdobeLaunch = require('./adobelaunch.js');
var savedItemsService = require('./savedItemsService.js');

(function ($) {

  this.$saveBtn = 'save-item';

  // vars definition
  var ITEM_ADDED = 'item:addedToMyAccount',
    $document = $(document),
    $body = $(document.body);

  $(window).on('changeTab', changeFloorPlanTabs);

  /*
   * changeFloorPlanTabs: listen the tabs changing and
   * reset the save button.
   * @param {data} data
  */
  function changeFloorPlanTabs(data) {
    FLOOR_PLAN_DATA_SAVE = $('.' + this.$saveBtn).attr('data-save');
    $('.' + this.$saveBtn).attr('data-ifp-id', data.target.dataset.ifpId);
    //@TODO include the save/start customizing btns into the tabs function
    if (FLOOR_PLAN_DATA_SAVE === 'true') {
      var saveTxt = $('.' + this.$saveBtn).data('saveText');
      $('.' + this.$saveBtn).attr('data-save', false);
      $('.' + this.$saveBtn).text(saveTxt);
    }
  }

  /*
   * bind click events
   *
  */
  $document.on('click', '.' + this.$saveBtn, saveItem.bind());
  $document.on('keydown', '.' + this.$saveBtn, function(e) { if (e.key == 'ENTER') $(e.currentTarget).trigger('click') });

  /*
   *
   * toggleStatus: changes the save status of that element
   * @param {$target} the element to check
   * @param {saveAction} wheter the action performed was a Save or an Unsave
   *
  */
  function toggleStatus($target, saveAction) {
    var doSaveText = $target.data('save-text'),
      doUnsaveText = $target.data('unsave-text'),
      doSaveClass = 'icon-heart',
      doSaveClassBtn = 'btn-black',
      doUnSaveClass = 'icon-heart-filled';

    if (saveAction) {
      $target.text(doUnsaveText);
      $target.data('is-saved', "True");
      $target.attr('data-is-saved', "True");
      if($target.is('img')) {
        $target.attr('src', $target.data('saveImgSavedSrc'));
        $target.attr('alt', $target.data('saveImgSavedAlt'));
      }
      if ($target.hasClass(doSaveClass)) {
        $target.removeClass(doSaveClass);
        $target.addClass(doUnSaveClass);
      }
      if($target.data("media-location")){
        var commonUnsaveLocations =  $("[data-media-location='" + $target.data("media-location") +"']"); 
        commonUnsaveLocations.removeClass(doSaveClass)
        commonUnsaveLocations.addClass(doUnSaveClass)
        commonUnsaveLocations.text(doUnsaveText)
        commonUnsaveLocations.data('is-saved', "True")
        commonUnsaveLocations.attr('data-is-saved', "True");
      }
    } else {
      $target.text(doSaveText);
      $target.data('is-saved', "False");
      $target.attr('data-is-saved', "False");
      if($target.is('img')) {
        $target.attr('src', $target.data('saveImgUnsavedSrc'));
        $target.attr('alt', $target.data('saveImgUnsavedAlt'));
      }
      if ($target.hasClass(doUnSaveClass)) {
        $target.removeClass(doUnSaveClass);
        $target.addClass(doSaveClass);
      }
      if($target.data("media-location")){
        var commonSaveLocations =  $("[data-media-location='" + $target.data("media-location") +"']"); 
        commonSaveLocations.removeClass(doUnSaveClass)
        commonSaveLocations.addClass(doSaveClass)
        commonSaveLocations.text(doSaveText)
        commonSaveLocations.data('is-saved', "False")
        commonSaveLocations.attr('data-is-saved', "False");
      }
    }

    if ($target.hasClass('btn')) {
      $target.toggleClass(doSaveClassBtn, saveAction);
    }
  }

  /*
   *
   * isSaved: determines whether the item is saved, based on the class or text
   * @param {$target} the element to check
   *
  */
  function isSaved($target) {
    return $target.hasClass('icon-heart-filled') || $target.hasClass('btn-black') || $target.hasClass('label-unsaved') || $target.data('is-saved') === "True";
  }

  /*
   *
   * saveItem: main ajax request type POST to send a data item to the API
   * @elem {e} e clicked element
   *
  */
  function saveItem(e) {
    var saveIcon, $saveIcon, actionToPerform, itemData;

    saveIcon = e.target;
    
    ensureDataAttributesArePresent();
    
    $saveIcon = $(saveIcon);

    actionToPerform = isSaved($saveIcon); //true: do Save; false: do Unsave

    e.preventDefault();

    itemData = {
      CommunityBrand: saveIcon.dataset.communityBrand,
      CommunityId: saveIcon.dataset.communityId,
      PlanId: saveIcon.dataset.planId,
      QmiId: saveIcon.dataset.qmiId,
      MediaLocation: saveIcon.dataset.mediaLocation || saveIcon.dataset.ifpId,
      ToolType: saveIcon.dataset.toolType,
      Caption: saveIcon.dataset.imageCaption,
      DeleteAction: actionToPerform
    };

    // if this save icon already has a page url in it's dataset, use that instead of the current url
    itemData.pageUrl = saveIcon.dataset.pageUrl ? saveIcon.dataset.pageUrl : window.location.origin + window.location.pathname;

    if (window.Pulte.user === undefined) {
      //Unauthenticated user: display login/create account modal
      itemData.SaveElement = true;
      $body.trigger('OPEN_ACCOUNT_MODAL', itemData);

    } else {
      //Authenticated user: Save item
      itemData.UserId = window.Pulte.user.UserId;
      itemData.SessionId = window.Pulte.user.SessionId;

      $.ajax({
        context: this,
        url: '/api/save/SaveItem',
        dataType: 'text',
        type: 'post',
        contentType: 'application/x-www-form-urlencoded',
        data: itemData,
        success: function (data, textStatus, sResponse) {
          if (sResponse.status === 200) {

            toggleStatus($saveIcon, !actionToPerform);

            updateAriaMessaging();

            // if we're saving the item, trigger the Adobe Launch event
            if (!itemData.DeleteAction) {
              switch (itemData.ToolType) {
                case savedItemsService.itemTypes.Community:
                  AdobeLaunch.pushItemSavedEvent(itemData.CommunityId, itemData.CommunityBrand, itemData.PlanId, itemData.QmiId, AdobeLaunch.COMMUNITY_SAVED_EVENT_NAME);
                  break;
                case savedItemsService.itemTypes.Plan:
                case savedItemsService.itemTypes.QMI:
                  AdobeLaunch.pushItemSavedEvent(itemData.CommunityId, itemData.CommunityBrand, itemData.PlanId, itemData.QmiId, AdobeLaunch.PLAN_SAVED_EVENT_NAME);
                  break;
              }
            }

            // toggle other save buttons on page as necessary (for communities/plans/qmis in Home Design Summary List, Product Map, Product Summary, 
            // and Grid View)
            if (itemData.CommunityId && itemData.ToolType) {
              var $saveButtons;

              if (itemData.QmiId && itemData.ToolType == savedItemsService.itemTypes.QMI) {
                $saveButtons = $("[data-qmi-id='" + itemData.QmiId + "'][data-plan-id='" + itemData.PlanId + "'][data-community-id='" + itemData.CommunityId + "'][data-tool-type='" + itemData.ToolType + "']");
              } else if (itemData.PlanId && itemData.ToolType == savedItemsService.itemTypes.Plan) {
                $saveButtons = $("[data-plan-id='" + itemData.PlanId + "'][data-community-id='" + itemData.CommunityId + "'][data-tool-type='" + itemData.ToolType + "']");
              } else if (itemData.ToolType == savedItemsService.itemTypes.Community) {
                $saveButtons = $("[data-community-id='" + itemData.CommunityId + "'][data-tool-type='" + itemData.ToolType + "']");
              }

              if ($saveButtons) {
                $saveButtons.each(function () {
                  if ($(this) != $saveIcon) {
                    toggleStatus($(this), !actionToPerform);
                  }
                });
              }
            }

            if(itemData.ToolType){
              //Temporary Save
              if(!actionToPerform){
                savedItemsService.addTemporarySavedItem(itemData)
              }
              //Temporary Delete 
              else{
                if(itemData.QmiId && itemData.ToolType == savedItemsService.itemTypes.QMI){
                  savedItemsService.removeTemporarySavedItem(itemData.ToolType, itemData.QmiId, "qmiId")
                }
                else if(itemData.PlanId && itemData.ToolType == savedItemsService.itemTypes.Plan){
                  savedItemsService.removeTemporarySavedItem(itemData.ToolType, itemData.PlanId, "planId")
                }
                else if (itemData.ToolType == savedItemsService.itemTypes.Community) {
                  savedItemsService.removeTemporarySavedItem(itemData.ToolType, itemData.CommunityId, "communityId")
                }else if (itemData.ToolType == savedItemsService.itemTypes.Image) {
                  savedItemsService.removeTemporarySavedItem(itemData.ToolType, itemData.MediaLocation, "url")
                }
              }    
            }
          }

          function updateAriaMessaging() {
            var $parentA = $saveIcon.parent('a');
            var $messageSpan = $parentA.find('.sr-only');

            if ($messageSpan) { // if the save has not been updated for ADA, nothing will happen
              var carousel = itemData.ToolType == "Image" ? "Carousel ": "";
              var tootText = itemData.ToolType == "QMI" ? "Quick Move In" : itemData.ToolType;
              var saveOrDeletedText = actionToPerform ? "removed from" : "saved to";
              $messageSpan.html(carousel + 'Save ' + tootText + '. This ' + tootText + ' has been ' + saveOrDeletedText + ' your saved items');
            }
          }

        }
      });
    }

    return false;

    function ensureDataAttributesArePresent() {
      if (saveIcon.tagName == 'SPAN' && saveIcon.classList.contains("sr-only")) {
        saveIcon = $(saveIcon).parent('A')[0];
      }
      if (saveIcon.tagName == 'A') {
        var newSaveIcon = 
          $(saveIcon).find('img')[0] ||
          $(saveIcon).find('i')[0] ||
          $(saveIcon).find('span')[0];

        if(newSaveIcon && newSaveIcon.dataset) {
          saveIcon = newSaveIcon;
        }          
      }
    }
  }

})(jQuery);