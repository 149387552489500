var component = require('../../../lib/js/component.js');
var AdobeLaunch = require('../../../lib/js/adobelaunch.js');

function ConfirmationReview() {
    var self = this;

    this.initialize = function (element) {
        self.$element = $(element);
        self.$input = document.getElementsByName("contractNumber")[0].value;
        self.pushTHOAdobeAnalytics(self.$input);
    };

    this.pushTHOAdobeAnalytics = function (input) {
        AdobeLaunch.pushTHOOrderConfirmationEvent(AdobeLaunch.THO_ORDER_CONFIRMATION_EVENT_NAME, input);
    }
}

module.exports = component(ConfirmationReview);
