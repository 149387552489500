var component = require("../../../lib/js/component.js");

function LifeTestedCompare() {
    this.initialize = function (element, options) {
        this.main = element;
        this.foundVisible = false;

        this.createDynamicTable();
    };

    this.createDynamicTable = function () {
        // Get the dynamic table container element using jQuery
        var tableContainer = $(".table-container");

        // Set the scroll position dynamically to the end of the content
        tableContainer.scrollLeft(
            tableContainer.width() - tableContainer[0].scrollWidth
        );
    };
}

module.exports = component(LifeTestedCompare);
