var component = require('../../../lib/js/component.js');
var customTabs = require('../../../lib/js/custom-tabs.js');
var utils = require("../../../lib/js/utils.js");

function AmenityOverview() {

    this.initialize = function (element) {
        _this = this;

        var tabs = document.querySelectorAll('.AmenityOverview [role="tab"]');
        var tabList = document.querySelector('.AmenityOverview [role="tablist"]');
      
        // Add a click event handler to each tab
        tabs.forEach(function (tab) {
          tab.addEventListener("click", function(e) {
            var target = e.target;
            var parent = target.parentNode;
          
            // Remove all current selected tabs
            parent
              .querySelectorAll('[aria-selected="true"]')
              .forEach(function(t) {
                t.setAttribute("aria-selected", false); 
                t.setAttribute("tabindex",-1)
              });
          
            // Set this tab as selected
            target.setAttribute("aria-selected", true);
            target.setAttribute("tabindex",0);
    
            // Hide all tab panels
            document
              .querySelectorAll('.AmenityOverview__image-container [role="tabpanel"]')
              .forEach(function(p){p.setAttribute("hidden", true)});
          
            var selector = target.getAttribute("aria-controls");
            // Show the selected panel
            document
              .querySelector(".AmenityOverview__image-container #"+selector)
              .removeAttribute('hidden');
              });
        });

        // Enable arrow navigation between tabs in the tab list
        var tabFocus = 0;
      
        tabList.addEventListener("keydown", function (e) {
          // Move right
          if (e.keyCode === 39 || e.keyCode === 37) {
            tabs[tabFocus].setAttribute("tabindex", -1);
            if (e.keyCode === 39) {
              tabFocus++;
              // If we're at the end, go to the start
              if (tabFocus >= tabs.length) {
                tabFocus = 0;
              }
              // Move left
            } else if (e.keyCode === 37) {
              tabFocus--;
              // If we're at the start, move to the end
              if (tabFocus < 0) {
                tabFocus = tabs.length - 1;
              }
            }
      
            tabs[tabFocus].setAttribute("tabindex", 0);
            tabs[tabFocus].focus();
          }
        });
 
        $element = $(element);

        this.brand = $('body').data('brand');

        // Amenities Module Carousel Mobile
        var $owlCarousel = this.$element.find('.am-carousel');

        $owlCarousel.owlCarousel({
            lazyLoad: true,
            loop: true,
            dots: false,
            margin: 10,
            autoHeight: true,
            nav: false,
            responsive: {
                0: {
                    items: 1
                }
            }
        });

        this.$element.find('.slider-nav__next').on('click', function (event) {
            event.stopPropagation();
            $owlCarousel.trigger('next.owl.carousel');
            $('.slider-nav__next').attr('data-analytics-cta',$('.am-carousel .owl-item.active .carousel__item .info__heading')[0].innerHTML + "|next-button");
            $('.slider-nav__next').attr('data-analytics',$('.am-carousel .owl-item.active .carousel__item .info__heading')[0].innerHTML + "|next-button");    
       });

        this.$element.find('.slider-nav__prev').on('click', function (event) {
            event.stopPropagation();
            $owlCarousel.trigger('prev.owl.carousel');
            $('.slider-nav__prev').attr('data-analytics-cta',$('.am-carousel .owl-item.active .carousel__item .info__heading')[0].innerHTML + "|prev-button");
            $('.slider-nav__prev').attr('data-analytics',$('.am-carousel .owl-item.active .carousel__item .info__heading')[0].innerHTML + "|prev-button");  
      });

        var imageSlider = this.$element.find('.mobile-slider .image-slider');
        var arrowColor = imageSlider.data('arrowColor');
        var arrowBackgroundColor = imageSlider.data('arrowBackground');

        // Activities Carousels Mobile
        var $owlCarousel2 = this.$element.find('.owl-carousel-2');
        $owlCarousel2.owlCarousel({
            lazyLoad: true,
            loop: true,
            dots: false,
            margin: 10,
            nav: true,
            navText : ['<div class="icon-arrow-wrapper" style="border-color:' + arrowColor + '; background-color:' + arrowBackgroundColor + '"> <i class="icon-arrow-left" style="color:' + arrowColor + '"></i></div>',
                    '<div class="icon-arrow-wrapper" style="border-color:' + arrowColor + '; background-color:' + arrowBackgroundColor + '"> <i class="icon-arrow-right" style="color:' + arrowColor + '"></i></div>'],
            items: 1,
            autoHeight: true
        });

        this.updateDesktopCarouselV2Colors(arrowColor, arrowBackgroundColor);

        customTabs.createCustomTabEvents(this.$element);

        $(window).on("resize", utils.debounce(this.handleWindowResize.bind(this), 250, false)); 
    }

    this.changeTabs = function(e) {
        var target = e.target;
        var parent = target.parentNode;
      
        // Remove all current selected tabs
        parent
          .querySelectorAll('[aria-selected="true"]')
          .forEach(function(t) {
            t.setAttribute("aria-selected", false); 
            t.setAttribute("tabindex",-1)
          });
      
        // Set this tab as selected
        target.setAttribute("aria-selected", true);
        target.setAttribute("tabindex",0);

        // Hide all tab panels
        document
          .querySelectorAll('.AmenityOverview__image-container [role="tabpanel"]')
          .forEach(function(p){p.setAttribute("hidden", true)});
      
        var selector = target.getAttribute("aria-controls");
        // Show the selected panel
        document
          .querySelector(".AmenityOverview__image-container #"+selector)
          .removeAttribute('hidden');
    }

    this.updateDesktopCarouselV2Colors = function(arrowColor, arrowBackgroundColor) {
        this.$element.find('.Carousel .icon-arrow-right').css('color', arrowColor);
        this.$element.find('.Carousel .icon-arrow-left').css('color', arrowColor);
        this.$element.find('.Carousel .owl-prev').css('border-color', arrowColor);
        this.$element.find('.Carousel .owl-next').css('border-color', arrowColor);
        this.$element.find('.Carousel .owl-prev').css('background-color', arrowBackgroundColor);
        this.$element.find('.Carousel .owl-next').css('background-color', arrowBackgroundColor);
    } 

    this.handleWindowResize = function(){
      var carousels = this.$element.find(".carousel-2-wrapper"); 
      carousels.find(".owl-carousel").trigger("refresh.owl.carousel");

      if ($(window).outerWidth() < 1025) {
        $('.slider-nav__next').attr('data-analytics-cta',$('.am-carousel .owl-item.active .carousel__item .info__heading')[0].innerHTML + "|next-button");
        $('.slider-nav__next').attr('data-analytics',$('.am-carousel .owl-item.active .carousel__item .info__heading')[0].innerHTML + "|next-button");    

        $('.slider-nav__prev').attr('data-analytics-cta',$('.am-carousel .owl-item.active .carousel__item .info__heading')[0].innerHTML + "|prev-button");
        $('.slider-nav__prev').attr('data-analytics',$('.am-carousel .owl-item.active .carousel__item .info__heading')[0].innerHTML + "|prev-button");  
      }
    }

}

module.exports = component(AmenityOverview);