var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');
var MapCore = require('./map-core.js');
var mapsHelper = require('./maps-helper.js');
var mapPinHelper = require('./map-pin-helper.js');

var DirectionsMapController = function () {

    this.initialize = function (el, options) {
        this.$mapModal = this.$element.parents('.modal-mapDirections');

        var _this = this,
            $backToForm = $('.mapDirections-externalLink', this.$mapModal),
            $closeModal = $('.icon-close', this.$mapModal);

        this.options = {};
        this.$mapElement = this.$element.find('.Map-googleMap');

        $.extend(this.options, options, this.$mapElement.data());
        this.brand = $('body').data('brand');

        // SVG Pin properties
        this.MARKER_PIN_SVG_SCALE_SELECTED = this.options.map.defaults.MARKER_PIN_SVG_SCALE_SELECTED;
        this.MARKER_PIN_SVG_SCALE_UNSELECTED = this.options.map.defaults.MARKER_PIN_SVG_SCALE_UNSELECTED;

        // Placeholder for reference to map instance
        this.map = {};

        // Set Map Type
        this.options.map.pulteMapType = 'directions';

        // Enable zoom
        this.options.map.showZoomControls = true;
        this.options.map.enableDoubleClickZoom = true;
        this.options.map.enableScrollWheelZoom = true;

        // Placeholder for marker list
        this.markerList = [];

        // Make sure the markers have been loaded into the page
        if (GlobalMapsObj.communitiesDirectionsMarkers) {
            var communityId = this.$element.data("community-id");
            if (communityId) {
                var found = false;
                for (var i = 0, len = GlobalMapsObj.communitiesDirectionsMarkers.length; i < len; i++) {
                    var communityMarker = GlobalMapsObj.communitiesDirectionsMarkers[i];
                    if (communityMarker.Id == communityId) {
                        found = true;
                        var communityMarkers = [];
                        communityMarkers.push(communityMarker);
                        this.markers = communityMarkers;
                        break;
                    }
                }
                if (!found) {
                    console.error('DirectionsMapController :: Marker data failed to load')
                }
            }
        } else {
            console.error('DirectionsMapController :: Marker data failed to load')
        }

        //events
        $backToForm.on('click', function (e) {
            e.preventDefault();
            _this.cleanMap();
        });

        $closeModal.on('click', function (e) {
            e.preventDefault();
            _this.cleanMap();
        });

        $('body').on('CustomPopupAdded', function (e) {
            e.preventDefault();
            if (_this.map) {
                _this.map.panBy(0, -125);
            }
        });

        // If map is lives in a modal only load the map once the modal has been shown.
        if (this.$mapModal != null) {
            // Only kick off loading of map if modal has been shown
            this.$mapModal.on('shown.bs.modal', function () {
                _this.mapScriptCheck.call(_this);
            });
            // Clean up when modal has been closed.
            this.$mapModal.on('hidden.bs.modal', function () {
                // Remove event listeners
                $('body').off('MapScriptLoaded');
                // As there is no good (proper) way to destroy the map it is
                // suggested to set it's reference to null
                _this.map = null;
            });
        } else {
            this.mapScriptCheck.call(_this);
        }
    };

    this.cleanMap = function () {
        var _this = this;

        _this.$mapModal.modal('hide');
        $('body').removeClass('directions-map-modal-contactUs');
        $('.Map-directionsSteps div[id^="directions-step-accordion"].collapse.in', _this.$mapModal).removeClass('in');
        $('.Map-directionsSteps', _this.$mapModal).find('.accordion-title').addClass('collapsed');
    };

    this.mapScriptCheck = function () {
        var _this = this;

        if (window.Pulte.isMapScriptLoaded) {
            // Setup markers
            this.setupMap();
        } else {
            $('body').on('MapScriptLoaded', function () {
                // Setup markers
                _this.setupMap();

                // TODO: need to manage the proper removal of this listener
                $('body').off('MapScriptLoaded');
            });
        };
    };

    /**
     * setupMap: attach mapCore and listen for added map instance
     */
    this.setupMap = function () {
        var _this = this;
        // Set up map specific listener for when map is ready
        var listener = this.options.map.pulteMapType + 'MapInstanceAdded';

        this.$mapElement.on(listener, function (e, map) {
            // Store reference to returned map
            _this.map = map;
            // Add click listener for clicks off of infowindows to close the infowindow
            google.maps.event.addListener(_this.map, "click", function (e) {
                // Added marker check for clicks on maps with no markers
                if (_this.markers.length) {
                    mapsHelper.closeAllInfoWindows.call(_this);
                    _this.resetMarkerSize.call(_this);
                    // There's map interactions on touch devices. To keep
                    // all pins visible map should recenter on infoWindow close
                    if (utils.has.touch()) {
                        _this.fitToBounds.call(_this, _this.markers);
                    };
                }
            });
            // Setup markers
            _this.setupMarkers.call(_this);
            // Remove map instance listener
            $(this).off(listener);
        });
        // Get instance of map
        mapsHelper.attachMapCore.call(this, MapCore, this.options);
    };

    /**
    * Setup and add markers to map
    */
    this.setupMarkers = function () {
        var _this = this;

        $(this.markers).each(function (i) {
            var marker = _this.markers[i];
            var position = mapsHelper.getItemPosition(marker.Latitude, marker.Longitude);
            // - Make all markers small
            _this.resetMarkerSize.call(_this);

            var iconSvgProperties = {
                scale: _this.MARKER_PIN_SVG_SCALE_SELECTED,
                brandName: this.BrandName,
                pinType: 'exact',
                isActiveAdult: this.IsActiveAdult
            };

            // Replace special character in Address
            marker.Address.Street1 = marker.Address.Street1.replace('&#39;', "'").replace("&amp;", "&");
            marker.Address.Street2 = marker.Address.Street2.replace('&#39;', "'").replace("&amp;", "&");

            var mapMkr = new google.maps.Marker({
                position: position,
                map: _this.map,
                icon: mapPinHelper.getGoogleMapPinObject(iconSvgProperties),
                name: marker.Name,
                templateType: _this.options.type,
                address: marker.Address,
                googleMapsDirectionsAnalyticsCTAName: marker.GoogleMapsDirectionsAnalyticsCTAName,
                phone: marker.Phone,
                phoneAnalyticsCTAName: marker.PhoneAnalyticsCTAName,
                DisplayGoogleDirections: marker.DisplayGoogleDirections,
                brandName: this.BrandName,
                pinType: 'exact',
                isActiveAdult: this.IsActiveAdult
            });

            // Update the maker data to reflect new Google Maps formated position
            marker.position = position;
            // Store updated marker
            _this.markerList.push(mapMkr);

            mapsHelper.makeCustomPopup.call(_this, mapMkr);
            mapMkr.iw.setMap(mapMkr.map);
            _this.addMarkerClickEvent.call(_this, mapMkr);

            // Using double bang incase boolean isnt passed
            if (mapMkr.DisplayGoogleDirections) {
                // Open the infowindow by default
                // Since it could live in a modal we need to open it after the map has
                // fully loaded so it positions correctly.
                google.maps.event.addListener(_this.map, "tilesloaded", function (e) {
                    // 'click' the first marker to open it's infoWindow
                    // and size everything correctly
                    new google.maps.event.trigger(mapMkr, 'click');
                    // Remove event listener to prevent reopening this infowwindow
                    // if map is moved and more tiles are loaded.
                    google.maps.event.clearListeners(_this.map, "tilesloaded");
                    $('.gm-style-iw').removeClass('openIW');
                });
            }
        });

        // Recenter map to include all markers
        this.fitToBounds.call(_this, _this.markers);
    };

    /**
     * addMarkerClickEvent: Attach click event to marker for infoWindows
     * @param {Object} mapMkr - Google Maps Object containing marker data
     */
    this.addMarkerClickEvent = function (mapMkr) {
        var _this = this;
        google.maps.event.addListener(mapMkr, 'click', function (e) {
            // - Make all markers small
            _this.resetMarkerSize.call(_this);

            var iconSvgProperties = {
                scale: _this.MARKER_PIN_SVG_SCALE_SELECTED,
                brandName: this.brandName,
                pinType: 'exact',
                isActiveAdult: this.isActiveAdult
            };

            this.setIcon(mapPinHelper.getGoogleMapPinObject(iconSvgProperties));
            // Close any open infoWindows
            _this.closeAllInfoWindows.call(_this);
            // Open this markers infoWindow
            this.iw.open();

            $('.gm-style-iw').addClass('openIW');
        });
    };

    this.closeAllInfoWindows = function () {
        var _this = this;
        // loop through all markers and close their InfoWindows
        $(this.markerList).each(function (i) {
            if (_this.markerList[i].iw) {
                _this.markerList[i].iw.close();
            }
        });
    };

    this.resetMarkerSize = function () {
        var _this = this;
        $(this.markerList).each(function (i) {
            // Marker icon object
            var iconSvgProperties = {
                scale: _this.MARKER_PIN_SVG_SCALE_UNSELECTED,
                brandName: this.brandName,
                pinType: 'exact',
                isActiveAdult: this.isActiveAdult
            };

            _this.markerList[i].setIcon(mapPinHelper.getGoogleMapPinObject(iconSvgProperties));
        });
    };

    /**
     * fitToBounds: Update bounds of markers and recenter map
     */
    this.fitToBounds = function (markers) {
        var bounds = mapsHelper.getBounds(markers);
        this.map.fitBounds(bounds);
    };
};
module.exports = component(DirectionsMapController);
