var component = require('../../../../lib/js/component.js');

function CompareErrorModal() {

    this.initialize = function (element, options) {


    }
}

 module.exports = component(CompareErrorModal);


