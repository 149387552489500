var component = require("../../../lib/js/component.js");
var utils = require("../../../lib/js/utils.js");

function SpecialOffersV2() {

    this.initialize = function (element, options) {
        _this = this;
        
        this.carouselGuid = utils.generateGuid();
        this.$element.attr("id", "SpecialOffersV2-" + this.carouselGuid);
        this.$element.attr("data-guid", this.carouselGuid);
       
        var $carousel = this.$element.find(".SpecialOffersV2__carousel");
        var $wrapper = this.$element.find(".SpecialOffersV2__wrapper");
        var $head = this.$element.find(".SpecialOffersV2__head");
        var $heading = this.$element.find(".SpecialOffersV2__heading");
        var $line = this.$element.find(".SpecialOffersV2__line");
        var $content = this.$element.find(".SpecialOffersV2__content"); 
        var $title = this.$element.find(".SpecialOffersV2__title"); 
        var $image = this.$element.find(".SpecialOffersV2__image"); 
        var $cta = this.$element.find(".SpecialOffersV2__cta");
        var $slide = this.$element.find(".SpecialOffersV2__slide"); 
        var numOfCarouselSlides = $slide.length; 

        $line.css("border-color", $line.data("lineColor"))
        $line.css("border-width", parseInt($line.data("lineWeight")) + "px")
        $title.css("color", $title.data("eventTitleColor"))
        $cta.css("color", $cta.data("ctaColor"))

        if(numOfCarouselSlides > 1 ){
            this.options = {
                dots: false,
                nav: true,
                navSpeed: 300,
                responsive:{
                    0:{
                        items:1, 
                        loop: true,
                        autoHeight: true
                    },
                    768:{
                        items: 2,
                        loop: (numOfCarouselSlides > 2),
                        autoHeight: false
                    },
                    1440:{
                        items: 2,
                        loop: (numOfCarouselSlides > 2),
                        autoHeight: false
                    }
                }
            };
            this.$element.find(".owl-carousel").owlCarousel(this.options);
            this.$element.find(".owl-carousel").on('translate.owl.carousel', this.handleBeforeTranslate.bind(this))
            this.$element.find(".owl-carousel").on('translated.owl.carousel', this.handleAfterTranslate.bind(this))
            this.$element.find(".owl-carousel").on('refreshed.owl.carousel', this.handleCarouselADA.bind(this))
            this.$element.find(".owl-item").addClass("justify-content-center")

            $(window).on("resize", utils.debounce(this.handleWindowResize.bind(this), 250, false)); 
        }
        else{
            var $singleImage = this.$element.find(".u-responsiveMedia")

            $wrapper.addClass("d-md-inline-flex align-items-center px-md-5 px-lg-6 py-md-7 single-item-width"); 
           
            $carousel.removeClass("owl-carousel owl-theme owl-drag px-9 px-md-10 py-lg-7");
            
            $carousel.addClass("py-sm-0 px-md-5 px-lg-9 my-sm-7 my-md-3 my-lg-6");
            
            $head.addClass("pr-md-6 pr-lg-3 mb-lg-6 mb-md-4")
            $heading.addClass("single-item-heading");
           
            $content.removeClass("flex-lg-row align-items-center mt-md-0 mt-lg-3 mx-sm-6 mx-md-9 mx-lg-8")
            $content.addClass("flex-sm-row align-items-top mb-sm-3 mb-md-0 ml-sm-6 mr-sm-5 ml-md-6 mr-md-4 ml-lg-5 mr-lg-0")
          
            $slide.removeClass("md-centered-text");
            $slide.addClass("xs-centered-text");
            $slide.css("height", "")
           
            $image.removeClass("mb-lg-0 mr-lg-6")
            $image.addClass("xs-centered-text mb-sm-0 mr-sm-6")
            
            $line.addClass("single-item-line");
            
            $singleImage.css("height", "220px");
            $singleImage.css("width", "220px");
            
            this.handleSingleItemLineHeight();
            
            $(window).on("resize", utils.debounce(this.handleSingleItemLineHeight.bind(this), 250, false));
        }

        var prev = this.$element.find(".prev-nav-button");
        var next = this.$element.find(".next-nav-button");

        prev.addClass("owl-prev")
        prev.css("color", this.$element.find(".owl-carousel").data("carouselBtnColor"));
        prev.css("background-color", this.$element.find(".owl-carousel").data("carouselBtnBackgroundColor"));
        $(prev).on("click", function (event) {
            event.stopPropagation();
            $carousel.trigger('prev.owl.carousel');
        });

        next.addClass("owl-next")
        next.css("color", this.$element.find(".owl-carousel").data("carouselBtnColor"));
        next.css("background-color", this.$element.find(".owl-carousel").data("carouselBtnBackgroundColor"));
        $(next).on("click", function (event) {
            event.stopPropagation(); 
            $carousel.trigger('next.owl.carousel');
        });

        $wrapper.on('click', 'a.popover-close-btn', function (e) {
            e.preventDefault();
            $("[data-toggle=popover]").popover('hide');
        });

        this.$element.on('hidden.bs.popover', function(){
            $(".popover").remove();
        })

        this.handleCarouselADA()
 
    };

    this.handleBeforeTranslate = function(){
        var carousel = this.$element.find(".owl-carousel"); 
        var allCards = carousel.find(".owl-item");
        allCards.removeAttr("aria-hidden"); 
    }

    this.handleAfterTranslate = function(){
        this.handleCarouselNaviagationVisibility()
        this.hideNonVisibleCarouselCards()
        this.showVisibleCarouselCards()
    }

    this.handleWindowResize = function(){
       
        var owlStage = $(".owl-stage-outer");
        owlStage.css("height", "" );
        

        $(".owl-carousel").trigger("to.owl.carousel", [0,1]);
        $(".owl-carousel").trigger("refresh.owl.carousel");
       
    }

    this.handleCarouselADA = function(){
        this.handleBeforeTranslate()
        this.handleAfterTranslate()
    }

    this.handleSingleItemLineHeight = function(){
        var line = this.$element.find(".SpecialOffersV2__line");
        var head = this.$element.find(".SpecialOffersV2__head");

        if ($(window).outerWidth() >= 1025) {
            var height = $(window).outerWidth() <= 1440 ? "186px" : "240px" 
            $(".single-item-line").css("height", height);
            line.parent().removeClass("col-xs-12");
            head.removeClass("col-xs-12");
        } 
        else{
            $(".single-item-line").css("height", 0);
            line.parent().addClass("col-xs-12");
            head.addClass("col-xs-12");
        }

    }

    this.handleCarouselNaviagationVisibility = function(){
        var topBuffer = $(window).outerWidth() < 768 ? -20 : 0
        var prev = this.$element.find(".prev-nav-button");
        var next = this.$element.find(".next-nav-button");
        var owlNav = this.$element.find(".owl-nav");

        if(owlNav.not(".disabled").length > 0){
            prev.removeClass("hide");
            next.removeClass("hide");
        }
        else{
            prev.addClass("hide");
            next.addClass("hide");
        }
       
 
        prev.css("top", topBuffer + 210)
        next.css("top", topBuffer + 210)

    }

    this.hideNonVisibleCarouselCards = function(){
        var carousel = this.$element.find(".owl-carousel"); 
        var hiddenCards = carousel.find(".owl-item:not(.active)");
        hiddenCards.attr("aria-hidden", true);
        hiddenCards.find(".SpecialOffersV2__cta").attr("tabIndex", -1);
    }

    this.showVisibleCarouselCards = function(){
        var carousel = this.$element.find(".owl-carousel"); 
        var activeCards = carousel.find(".owl-item.active");
        activeCards.removeAttr("aria-hidden");
        activeCards.find(".SpecialOffersV2__cta").removeAttr("tabIndex");
    }

}

module.exports = component(SpecialOffersV2);
