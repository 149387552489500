var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');

function InspirationTabs() {

  this.initialize = function (element, options) {

    this.$tabsMainContainer = this.find("#InspirationTabs-bar");
    this.$dropdownText = this.find(".viewing");
    this.$activeTabMain = $(".is-active", this.$tabsMainContainer);
    this.getActiveTab();

    this.$dropdownButton = this.find('.InspirationTabs-dropdown-button');
    this.$tabMain = this.find('.InspirationTabs-bar .InspirationTabs-tab');

    if(this.$tabMain.length <= 1){
      this.$dropdownButton.addClass("is-disabled");
    }

    this.$dropdownButton.on('click', this.toggleOpen.bind(this));
    this.$tabMain.on('click', this.selectTab.bind(this));

    $(window).resize(utils.debounce(this.handleResize.bind(this), 250, true));

  };

  this.handleResize = function (e) {
    this.$tabsMainContainer.removeClass("is-open");
  };

  this.toggleOpen = function (e){
    e.preventDefault();
    if(!this.$dropdownButton.hasClass("is-disabled")){
      this.$tabsMainContainer.toggleClass("is-open");
    }
  }

  this.selectTab = function (e){
    e.preventDefault();

    var $el = $(e.currentTarget);

    var tabsMainContent = $("a", $el).attr("href");
    $(".InspirationTab-main.is-active").toggleClass("is-active");
    $(tabsMainContent).toggleClass("is-active");
    this.$activeTabMain.toggleClass("is-active");

    $el.toggleClass("is-active");

    this.$tabsMainContainer.removeClass("is-open");
    this.getActiveTab();
    setTimeout(function(){
      $('.InspirationTab-main img').cloudinary_update();  
    }, 200);
    

    //ajax call fn
  }

  this.getActiveTab = function (){
    this.$activeTabMain = $(".is-active", this.$tabsMainContainer);
    this.$dropdownText.text(this.$activeTabMain.text());
    this.$activeTabMain.trigger('changeTab', this.$activeTabMain);
  }

}

module.exports = component(InspirationTabs);
