var DesignBoardTemplates = require('./design-board-templates.js');
var DesignBoardHelpers = require('./design-board-helpers.js');


module.exports = {
	updateBoard : function( parentEl, boardId, itemsToSave, boardNameVal, evt ){
        var $document = $(document);
        var boardName = boardNameVal,        
        data = {},
        itemsToSaveOnBoard = itemsToSave;

        $document.trigger('board:updated', this);
        if ( evt ){
            evt.preventDefault();
        }

        if ( !boardNameVal ){
            var selectedItems = $('.DesignBoard-grid-item-edit').not( '.DesignBoard-item-selected' );

            boardName = $( parentEl ).find( '.form-control' ).val();
            itemsToSaveOnBoard = DesignBoardHelpers.transformElementsToItems( selectedItems );
        }
        data = {
            "Id": boardId,
            "Name": boardName,
            "DesignBoardItems": itemsToSaveOnBoard
        };

        DesignBoardHelpers.showLoading();

        $.ajax({
            url: '/api/designboard/'+boardId,
            data: data,
            method: "PUT",
            dataType: "json", 
            cache: false
        })
        .fail( function(){
            DesignBoardHelpers.hideLoading();
            //TODO: show error message, to be defined
        })
        .done( function(){
            if ( !boardNameVal ){
                DesignBoardHelpers.enterToDetailMode( boardId );
            }
            else {
                DesignBoardHelpers.hideLoading();
            }

        });
    },

    deleteBoard : function( boardId, urlToGo, evt ){
        evt.preventDefault();
        DesignBoardHelpers.showLoading();

        $.ajax({
            url: '/api/designboard/'+boardId,
            method: "DELETE",
            cache: false
        })
        .fail( function(){
            DesignBoardHelpers.hideLoading();
            window.location.href = urlToGo;
        })
        .done( function(){
            window.location.href = urlToGo;
        });

    },

    saveBoard : function( parentEl, evt ){
        var boardName = $( parentEl ).find( '.DesignBoard-input-wrapper .text' ).val(),
            errorEl = $( '.DesignBoardWrapper' ).find( '.MsgModal--error' ),
            data = { "Name": boardName };

        evt.preventDefault();
        DesignBoardHelpers.showLoading();

        $.ajax({
            url: '/api/designboard/',
            data: data,
            method: "POST",
            dataType: "json",
            cache: false
        })
        .fail( function(){
            errorEl.show();
            DesignBoardHelpers.hideLoading();
        })
        .done( function( response ){
            DesignBoardHelpers.hideLoading();
            $( parentEl ).trigger( 'enterToAddMode', response.result.id );
            $( parentEl ).attr( 'data-id', response.result.id);
        });

    },

    addItemsBoard : function( boardId ){
        var itemsToAdd = $('.DesignBoard-grid-item-edit.DesignBoard-item-selected'),
            itemsToAddObj = DesignBoardHelpers.transformElementsToItems( itemsToAdd ),
            data = {};

        if ( !boardId ){
            boardId = $( this.element ).data( 'id' );
        }
        data = {
            "Id": boardId,
            "DesignBoardItems": itemsToAddObj
        };
        DesignBoardHelpers.showLoading();
        $.ajax({
            url: '/api/DesignBoardItem/'+boardId,
            data: data,
            method: "POST",
            dataType: "json",
            cache: false
        })
        .fail( function(){
            //TODO: show error message, to be defined
        })
        .done( function( response ){
            DesignBoardHelpers.enterToDetailMode( boardId );
        });

    },

    loadAllItems: function( wrapperEl, firstLoad, boardId, evt ){
        var itemsWrapperEl = wrapperEl.find( '.DesignBoard-grid-list' ),
            filterWrapperEl = wrapperEl.find( '.Filter' ),
            valueWrapper = filterWrapperEl.find( '.FilterButton-value' ),
            itemType = DesignBoardHelpers.itemsTypes.homes,
            currentBoardId = boardId,
            modalItems = {},
            itemsWrapperPerType = {},
            valueText = '',
            valueData = '',
            itemsContent = '';
            
        evt.preventDefault();
        if ( !firstLoad ){
            valueText = $( evt.target ).text().trim();
            valueData = $( evt.target ).data( 'filter' );
            valueWrapper.text( valueText );
            itemType = valueData;
            filterWrapperEl.removeClass( 'is-open' );
        }
        if ( !currentBoardId ){
            currentBoardId = $( this.element ).data( 'id' );
        }
        itemsWrapperEl.removeClass( 'item-active' );
        itemsWrapperPerType = $( '.DesignBoard-grid-list-'+itemType );
        if ( itemsWrapperPerType.html().trim() === '' ){
            DesignBoardHelpers.showLoading();
            $.ajax({
                url: '/api/designboard/saveditems?tooltype='+itemType+'&id='+currentBoardId,
                method: "GET",
                dataType: "json",
                cache: false
            })
            .fail( function(){
                DesignBoardHelpers.hideLoading();
                //TODO: show error message, to be defined
            })
            .done( function( response ){
                itemsWrapperPerType.addClass( 'item-active' );

                itemsContent = DesignBoardTemplates.populateEditPage( response );
                itemsWrapperPerType.html( itemsContent );
                modalItems = $( wrapperEl ).find( '.DesignBoard-grid-item' );
                DesignBoardHelpers.onSelectItemsEvent( modalItems, 'fromAddItems' );
                DesignBoardHelpers.hideLoading();
            });
        }
        else{
            modalItems = $( wrapperEl ).find( '.DesignBoard-grid-item' );
            DesignBoardHelpers.onSelectItemsEvent( modalItems, 'fromAddItems' );
            itemsWrapperPerType.addClass( 'item-active' );
        }
    }
}
