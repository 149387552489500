var component = require('../../../lib/js/component.js');
var requestInfo = require('../../../lib/js/request-info.js');

var CommunitySummary = function () {

    this.initialize = function (el, options) {

        requestInfo.initRequestInfoModals();

    }
}

module.exports = component(CommunitySummary);

