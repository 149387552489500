var component = require('../../../lib/js/component.js');
var Form = require('../forms/form.js');

var AccountInformationForm = function () {

    this.initialize = function (el, options) {
        this.options = options || {};
        this.data = this.$element.data();
        this.find('.Form').attach(Form);
    }

}

module.exports = component(AccountInformationForm);
