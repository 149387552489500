var component = require('../../../lib/js/component.js');

function TilesModuleAnchor() {

    /**
     * Init for the Tiles Module Anchor
     * @param  {Object} element HTML element
     */
    this.initialize = function (element) {
        var $body = $('html, body'),
            $tileItem = $('.tileLink');

        //events
        $tileItem.on('click', function (e) {
            e.preventDefault();

            var $idContainer = $(e.currentTarget).attr('href');
            var $scrollTo = $($idContainer);

            $body.animate({
                scrollTop: $scrollTo.offset().top
            });
        });
    };
}

module.exports = component(TilesModuleAnchor);
