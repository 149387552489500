var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');

function Footer() {
    this.initialize = function (element) {
        var headingSelector = "[data-select='toggle']";
        $(element)
            .find(headingSelector)
            .on("click", 
                function() {
                    if (utils.is.mobileOrTabletScreen()) 
                        $(this).toggleClass('open');
            });
    };
} 

module.exports = component(Footer);