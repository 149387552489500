var component = require('../../../lib/js/component.js');

function pfDisplayMode() {

    var $mapButton, $listButton;

    this.initialize = function (element, options) {

        var _this = this;

        $mapButton = this.find('.pfDisplayMode__button[data-display-mode="map"]');
        $listButton = this.find('.pfDisplayMode__button[data-display-mode="list"]');
        var $gridContainer = $('.GridContainer');
        var $productMap = $('.ProductMap');

        this.find('.pfDisplayMode__button').on('click', function (e) {

            var dmButton = $(e.currentTarget);

            if (!dmButton.hasClass('is-selected')) {
                dmButton.siblings().removeClass('is-selected');
                dmButton.addClass('is-selected');

                if (dmButton.data('display-mode') === "map") {
                    $gridContainer.hide();
                    $productMap.show();
                    if($gridContainer.length){
                        this.data = {
                            "brand": "",
                            "state": "",
                            "region": "",
                            "cityNames": "",
                            "minPrice": "",
                            "maxPrice": "",
                            "minBedrooms": "",
                            "maxBedrooms": "",
                            "minBathrooms": "",
                            "maxBathrooms": "",
                            "homeType": "",
                            "productType": "",
                            "pageSize": 8,
                            "pageNumber": 0,
                            "flag": "",
                            "data": "",
                            "qmi": ""
                        }
                            
                        this.data.brand = $('body').data('brand');
                        this.data.state = $productMap.data('state');
                        this.data.region = $productMap.data('region');
                        this.data.cityNames = $productMap.data('cities');
                    
                        if (this.data.cityNames) {
                            this.data.flag = "any";
                            this.data.data = this.data.cityNames;
                        } else if (this.data.region) {
                            this.data.flag = "region";
                            this.data.data = this.data.region;
                        } else {
                            this.data.flag = "state";
                            this.data.data = this.data.state;
                        }

                        if($('.pfProductType__button.is-selected').data('productType') === "qmi") {
                            this.data.qmi = "true";
                        }
                            
                        $('.Map').trigger('filterSelected', this.data);
                    }
                    _this.scrollToSection("ProductMap");
                } else if ($gridContainer.length) {
                    $productMap.hide();
                    $gridContainer.show();
                    _this.scrollToSection("GridContainer");
                } else {
                    _this.scrollToSection("ProductMap--Totals");
                }
            }

            $.cloudinary.responsive();
        });

        $('.ProductFilter').on('resetProductFilter', function () {
            if ($mapButton.hasClass('is-selected')) {
                _this.scrollToSection("ProductMap");
            }
            else if ($listButton.hasClass('is-selected')) {
                _this.scrollToSection("ProductFilter");
            }
        });

        $('.ProductFilter').on('positionOnInteraction', function () {
            var $ProductFilter = $('.ProductFilter');

            if (window.pageYOffset < $('.ProductMap').position().top && $ProductFilter && $ProductFilter.length > 0 && !$ProductFilter.hasClass('affix'))
            {
                _this.scrollToSection("ProductMap");
            }
        });

        $(window).on('scroll', function () {

            var $listViewEl = $('.ProductSummary');

            if ($listViewEl && $listViewEl.length > 0) {
                if ($(window).scrollTop() >= $listViewEl.offset().top - 200) {
                    if (!$listButton.hasClass('is-selected')) {
                        $listButton.addClass('is-selected');
                        $mapButton.removeClass('is-selected');
                    }
                } else {
                    if (!$mapButton.hasClass('is-selected')) {
                        $mapButton.addClass('is-selected');
                        $listButton.removeClass('is-selected');
                    }
                }
            }
        });
    };

    this.scrollToSection = function (scrollToClass) {

        var targetOffset = 0;
        var $ProductFilter = $('.ProductFilter');

        if (scrollToClass === "ProductMap--Totals") {
            targetOffset = $ProductFilter.height();
        } else if (scrollToClass === "ProductMap" || scrollToClass === "GridContainer") {
            targetOffset = $ProductFilter.height() + 10;
        }

        var $scrollToEl = $("." + scrollToClass);

        if ($scrollToEl.length > 0) {

            $('html, body').animate({
                scrollTop: $scrollToEl.offset().top - targetOffset
            }, 1000, function () {
                setTimeout(function () {
                    $(window).trigger('Header.hide');
                }, 100);
            });
        }
    }
}

module.exports = component(pfDisplayMode);
