var component = require('../../../lib/js/component.js');
var LoginForm = require('./account-login-form.js');

var AccountLoginPage = function () {

    this.initialize = function (el, options) {
        this.options = options || {};
        this.find('.AccountLoginForm').attach(LoginForm);
    }
}

module.exports = component(AccountLoginPage);
