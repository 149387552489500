var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');

function PlanOverview(){
    this.initialize = function (element, options) {
    
    }
}

module.exports = component(PlanOverview);
