var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');

var ResponsiveMedia = require('../responsive-media/responsive-media.js');

function DualCarousel() {
    var component;

    this.initialize = function (element, options) {
        component = this;
        component.slidesAreLoaded = false;

        component.carouselGuid = utils.generateGuid();
        component.$element.attr("id", "Dual-Carousel-" + component.carouselGuid);
        component.$element.attr("data-guid", component.carouselGuid);

        var navIconLeft = "icon-arrow-left",
            navIconRight = "icon-arrow-right";

        var navText = [
            '<span class="sr-only">Previous</span><i class="' + navIconLeft + '"></i>',
            '<span class="sr-only">Next</span><i class="' + navIconRight + '"></i>'
        ];

        component.options = {
            dots: false,
            items: 1,
            loop: true,
            nav: true,
            navSpeed: 300,
            navText: navText,
            onInitialized: component.onCarouselInitialized
        };

        // Load all images in carousel and related slide show on any interaction
        component.$element.on('changed.owl.carousel', function (event) {
            component.handleChangedOwlCarousel(component.$element, component.slidesAreLoaded, event);
        });

        // Initialize nav buttons
        var prev = component.$element.find(".owl-prev");
        $(prev).on("click", function(event){
            event.stopPropagation(); // Prevents modal from appearing
        });

        var next = component.$element.find(".owl-next");
        $(next).on("click", function(event){
            event.stopPropagation(); // Prevents modal from appearing
        });

        // Add event listener when Pinterest icon is clicked
        $(document).on('click', '.Carousel-v2 .PinterestLink', function(event){
            event.stopPropagation();
        });
    }

    this.onCarouselInitialized = function(event){
        // window.dispatchEvent(new Event('resize')); // This is called to force the Carousel to recalculate its visible dimensions.
    }

    this.handleChangedOwlCarousel = function(carousel, slidesAreLoaded, event){
        if(event.property && event.property.name == 'position'){ // Only listen to the position changed event
            if (!slidesAreLoaded) {

                var items = $(carousel).find('.owl-item');

                items.each(function() {
                    $responsiveImg = $(this).find('img.u-responsiveMedia');

                    // Cloned items are not in DOM at page load and not processed by responsive media
                    if ($(this).hasClass('cloned')) {
                        $responsiveImg.attach(ResponsiveMedia);
                    }

                    $responsiveImg.trigger("RESPONSIVE_LAZYLOAD");
                    $responsiveImg.on('load', function() {
                        $(this).css('display', 'block');
                    });
                });

                if(items.length > 0) {
                    $(carousel).cloudinary_update();
                    $.cloudinary.responsive();

                    slidesAreLoaded = true;
                }
            }
        }
    }

}

module.exports = component(DualCarousel);
