var component = require('../../../lib/js/component.js');

function CompareHomesModal() {
    var selectedClass = 'selected',
        disabledClass = 'disabled',
        compareHomesMax = 4,
        compareHomesMin = 2;

    this.initialize = function (element) {
        this.selectBtns = $(element).find('.CompareHomes-select-home');
        this.parentPage = element.dataset.parentPage;
        this.parentHeader = $('.' + element.dataset.parentHeaderDiv)[0];
        this.compareBtn = $(element).find('.CompareHomes-compare-btn')[0];
        this.addHomeBtn = $(element).find('.CompareHomes-add-home-modal-btn')[0];

        if(this.parentPage === 'saved-items') {
            $(this.selectBtns).on('click', this.onSelectBtnSavedPage.bind(this));
        }

        if(this.parentPage === 'compare-homes') {
            $(this.selectBtns).on('click', this.onSelectBtnComparePage.bind(this));
            $(this.addHomeBtn).on('click', this.onAddHomeClick.bind(this));
        }

        $('#modal-compare-homes').on('shown.bs.modal', this.updateModal.bind(this));
    }

    this.updateModal = function () {
        var filledColumns = $('.CompareHomes-table-body .col-md-3:not(.CompareHomes-empty)'),
            column,
            i,
            j;

        $('.modal-backdrop').addClass('white-modal');
        $(document.body).removeClass('modal-open');
        $(this.element).css({'top' : $(this.parentHeader).offset().top + $(this.parentHeader).outerHeight()});
        $('body, html').scrollTop($(this.element).offset().top);
        $('#compare-items-carousel').carousel(0);

        if(this.parentPage === 'saved-items') {
            $(this.selectBtns).removeClass(selectedClass).removeClass(disabledClass);
            $(this.compareBtn).addClass(disabledClass);
        }

        if(this.parentPage === 'compare-homes') {
            $(this.addHomeBtn).addClass(disabledClass);
            $(this.selectBtns).removeClass(selectedClass).removeClass(disabledClass);

            for(i = 0, j = filledColumns.length; i < j; i += 1) {
                column = filledColumns[i];
                $(this.selectBtns).filter(function () {
                    return this.dataset.homeId === column.dataset.homeId;
                }).addClass(disabledClass).addClass(selectedClass);
            }
        }
    };

    this.onSelectBtnComparePage = function(evt) {
        var btn = evt.target;

        if(!$(btn).hasClass(disabledClass)) {
            $(this.selectBtns).filter(':not(.' + disabledClass + '.' + selectedClass + ')').removeClass(selectedClass);
            $(btn).addClass(selectedClass);
            $(this.addHomeBtn).removeClass(disabledClass);
        }
    };

    this.onSelectBtnSavedPage = function(evt) {
        var btn = evt.target,
            selectedHomesBtns,
            href = window.location.protocol + '//' + window.location.hostname + window.location.pathname + '/' + this.compareBtn.dataset.servicePath,
            i,
            j;

        if(!$(btn).hasClass(disabledClass)) {
            $(btn).hasClass(selectedClass) ? $(btn).removeClass(selectedClass) : $(btn).addClass(selectedClass);
        }

        selectedHomesBtns = $(this.element).find('.' + selectedClass);

        for(i = 0, j = selectedHomesBtns.length; i < j; i += 1) {
            href += selectedHomesBtns[i].dataset.homeId;
            i < j - 1 ? href += ',' : '';
        }

        $(this.compareBtn).attr('href', href);

        if(selectedHomesBtns.length >= compareHomesMin) {
            $(this.compareBtn).removeClass(disabledClass);
        } else {
            $(this.compareBtn).addClass(disabledClass);
        }

        if(selectedHomesBtns.length >= compareHomesMax) {
            $(this.selectBtns).not('.' + selectedClass).addClass(disabledClass);
        } else {
            $(this.selectBtns).removeClass(disabledClass);
        }
    };

    this.onAddHomeClick = function() {
        var homeId = $(this.selectBtns).filter('.' + selectedClass + ':not(.' + disabledClass +')')[0].dataset.homeId;
        $(this.element).trigger('addHomeToCompare', [homeId]);
    };
}

module.exports = component(CompareHomesModal);
