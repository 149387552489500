var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');

/**
 * This component should only be included as a sub-component.
 * In a parent component...
 * ie) this.find('.Legend').attach(Legend);
 *
 * This component communicates via the LEGEND_CHANGE event.
 * @returns {Object} Contains previous/current data objects
 * @param {Object} previous Previously selected tab data attributes and html
 * @param {Object} current Currently selected tab data attributes and html
 * ie)
 * {
 *     previous: {
 *         type: 'tab-one',  // data-type="tab-one"
 *         html: 'Tab One'   // <tab>Tab One</tab>
 *     },
 *     current: {
 *         type: 'tab-two',  // data-type="tab-two"
 *         name: 'tab-name', // data-name="tab-name"
 *         html: 'Tab Two'   // <tab>Tab Two</tab>
 *     }
 * }
 *
 */
function Legend() {

    var EVT = {
        CHANGE: 'LEGEND_CHANGE'
    };
    this.activeTab = $( '.fhProductType__button.is-selected' ).data( 'related-tab' );
    this.dropDown = $( '.Legend-small-tabs' );
    this.dropDownBtn = this.dropDown.find( '.Legend-small-btn' );
    this.dropDownItems = this.dropDown.find( '.Legend-small-dropdown-item a' );
    this.dropDownValue = this.dropDown.find( '.Legend-small-value' );

    this.initialize = function (element, options) {
        window.isLastLegendItem = false;
        var self = this;
        this.previousValue = null;
        this.currentValue = null;
        this.$tabs = this.find("#Legend-tabs");
        this.$dropdownButton = this.find('.Legend-dropdown-button');
        this.$dropdownButton.on('click', this.toggleOpen.bind(this));
        this.$dropdownIcon = this.$dropdownButton.find('.icon');
        this.$tab = this.find('.LegendTabs-tab');
        
        this.communitiesTab = this.$element.find( "[data-related-tab='communities']" ).find( '.item-count' );
        this.plansTab = this.$element.find( "[data-related-tab='unique-home-designs']" ).find( '.item-count' );
        this.quickMoveInTab = this.$element.find( "[data-related-tab='quick-move-in']" ).find( '.item-count' );
        this.activeAdultCommunity = this.$element.find( "[data-related-tab='adult-design-community']" ).find( '.item-count' );

        this.communitiesTabText = this.$element.find("[data-related-tab='communities'] a");
        this.plansTabText = this.$element.find("[data-related-tab='unique-home-designs'] a");
        this.quickMoveInTabText = this.$element.find("[data-related-tab='quick-move-in'] a");
        this.activeAdultTabText = this.$element.find("[data-related-tab='adult-design-community'] a");

        if (element.dataset.dropdownMode !== "true") {
            this.$tab.on('click', this.onSelectTab.bind(this));
        }

        this.listen('LEGEND_CHANGE', this.onChange);

        // This change will update the dropdown label with the active option, rather than the first one as done in:
        ////this.setViewing(this.$tab.eq(0).find('.LegendTabs-select').html());
        //this.setViewing(this.$tabs.find('.is-active').eq(0).find('.LegendTabs-select').html());

        $(window).resize(utils.debounce(this.handleResize.bind(this), 250, true));

        //Daniel Larrotta
        if ( $( element ).hasClass( 'Legend--homepage' ) ){
            this.detectLastTabItemShown( self.activeTab );
            this.changeLegendTabs();
            this.legendDropDown();
        }

        $( element ).on( 'tabWasPopulated', function(){
            self.activeTab = $( '.fhProductType__button.is-selected' ).data( 'related-tab' );
            self.detectLastTabItemShown( self.activeTab );
        });

        // Initialize the media query
        var mediaQuery = window.matchMedia('(min-width: 768px)'); 
        // Add a listen event
        mediaQuery.addListener(this.toggleTabsClass);
        this.toggleTabsClass(mediaQuery);

        //Request data from last item on legends into the scroll event
        this.on( 'requestFromLastItem', function(){
            //$('.Filter-sorter .Filter-option').first().trigger( 'sorterChanged', 'fromLegendTabs' );
            $('.FindHomes .fhCriteria').first().trigger( 'loadProductList', 'fromLegendTabs' );
        } );

        this.$element.on('updateTabCopies', this.updateTabCopies.bind(this));

        //Check for a hash value of qmi.  If it is present, then show the qmi tab.  behrreich 11/28/2016 112399
        if (utils.hasHashValue('qmi')) {
            var qmiTab = this.$tabs.find('[aria-controls="quick-move-in"]');
            if (qmiTab.length > 0) {
                qmiTab.click();

                //Scroll to the element
                $('html, body').animate({
                    scrollTop: qmiTab.parent().parent().offset().top
                }, 1000, function () { });
            }
        }
    };
    
    this.updateTabCopies = function( el, data){
        this.$tabs.data("last-update",el.timeStamp);
        var singleLabelAttr = 'single-text',
            pluralLabelAttr = 'plural-text',
            communityCount = data.CommunityCount,
            planCount = data.PlanCount,
            qmiCount = data.QmiCount,
            activeAdultCommunityCount = data.ActiveAdultCommunityCount,

            communityCopy = ' ' + this.communitiesTabText.data( pluralLabelAttr ),
            planCopy = ' ' + this.plansTabText.data( pluralLabelAttr ),
            qmiCopy = ' ' + this.quickMoveInTabText.data( pluralLabelAttr ),
            activeAdultCommunityCopy = ' ' + this.activeAdultTabText.data( pluralLabelAttr );

        if ( communityCount == 1 )
            communityCopy = ' ' + this.communitiesTabText.data( singleLabelAttr );
        if ( planCount == 1 )
            planCopy = ' ' + this.plansTabText.data( singleLabelAttr );
        if ( qmiCount == 1 )
            qmiCopy = ' ' + this.quickMoveInTabText.data( singleLabelAttr );
        if ( activeAdultCommunityCount == 1 )
            activeAdultCommunityCopy = ' ' + this.activeAdultTabText.data( singleLabelAttr );

        this.communitiesTab.text(' ' + communityCount);
        this.plansTab.text(' ' + planCount);
        this.quickMoveInTab.text(' ' + qmiCount);
        this.activeAdultCommunity.text(' ' + activeAdultCommunityCount);
          
        this.communitiesTab.next('.item-text').text( communityCopy );
        this.plansTab.next('.item-text').text( planCopy );
        this.quickMoveInTab.next('.item-text').text( qmiCopy );
        this.activeAdultCommunity.next('.item-text').text( activeAdultCommunityCopy );
    }

    this.toggleTabsClass = function(mediaQuery) {
        var $smallTabs = $('.Legend-groupItem .Legend-small-dropdown-item');

        if (mediaQuery.matches) {
            $smallTabs.removeClass('LegendTabs-tab');
        } else {
            $smallTabs.addClass('LegendTabs-tab');
        }
        
    }

    this.detectLastTabItemShown = function( activeTab ){
        var lastItem = $('#'+activeTab ).find( '.community.row:nth-last-child(10)' ),
            legendWrapper = $('#'+activeTab ).closest('.Legend--homepage');

        switch ( activeTab ){
            case 'communities':
                lastItem = $('#'+activeTab ).find( '.community.row:nth-last-child(10)' );
            break;

            case 'unique-home-designs':
                lastItem = $('#'+activeTab ).find( '.HomeDesignSummary.container:nth-last-child(10)' );
            break;

            case 'quick-move-in':
                lastItem = $('#'+activeTab ).find( '.HomeDesignSummary--qmi:nth-last-child(10)' );
            break;
        }

        if ( lastItem.length !== 0 ){
            var lastItemTop = lastItem.offset().top - (window.innerHeight);
            $( window ).on( 'scroll', function(){
                var y = $( window ).scrollTop();
                if ( y >= lastItemTop ){
                    $( window ).trigger('scrollingContent'); 
                    if ( $.active === 0 && !window.isLastLegendItem ){
                        legendWrapper.trigger( 'requestFromLastItem' );    
                    }
                }
            });
        }
    }

    this.changeLegendTabs = function(){
        var self = this;

        $('.LegendTabs a').on( 'shown.bs.tab', function(){
            var tabLink = $(this);
            var tabContent = $(tabLink.attr("href"));
            var sourceCountZero = tabLink.find(".item-count").html() == 0;
            var lastTabsUpdated = $("#Legend-tabs").data("last-update") || 0;
            var newState = tabContent.data("last-update") != lastTabsUpdated;
            if(sourceCountZero && tabContent.data("load-content-once-if-zero-results"))
            {
                if($(tabLink.attr("href")).html() == "" || newState)
                {
                    tabContent.empty();
                    $('.Filter-sorter .Filter-option').first().trigger( 'sorterChanged', 'fromFilter' );
                }
                $('.no-more-content').addClass("hidden");
                window.isLastLegendItem = true;
            }
            else if(sourceCountZero)
            {
                tabContent.empty();
                $('.no-more-content').removeClass("hidden");
                window.isLastLegendItem = true;
            }
            else
            {
                if(newState)
                {
                    tabContent.empty();
                    $('.Filter-sorter .Filter-option').first().trigger( 'sorterChanged', 'fromFilter' );
                }
            }
            tabContent.data("last-update",lastTabsUpdated);
        });
    }

    this.legendDropDown = function(){
        var self = this;

        this.dropDownBtn.on( 'click', function(){
            $( this ).closest( '.Legend-groupItem' ).toggleClass( 'dropdown-open' );

        });

        this.dropDownItems.on( 'click', function( e ){
            var value = $( this ).html();

            e.preventDefault();
            self.dropDownValue.html( value );
            $( this ).closest( '.Legend-groupItem' ).removeClass( 'dropdown-open' );
            $('li[data-related-tab="'+value+'"] a').trigger( 'click' );
        })

    }

    this.onChange = function (event, data) {
        var activeTab = $( '.fhProductType__button.is-selected' ).data( 'related-tab' );
        $('.no-more-content').removeClass('hidden-by-scroll');
        //this.setViewing(data.current.html);
        this.$tabs.removeClass('is-open');
        this.toggleIcon();

        this.detectLastTabItemShown(activeTab)

    };

    this.onSelectTab = function (event) {
        var $current = $(event.currentTarget);
        var $select = $current.find('.LegendTabs-select');

        this.$tab.removeClass('is-active');
        $current.addClass('is-active');

        this.setValue($.extend({}, $select.data(), {
            html: $select.html()
        }));
        event.preventDefault();
    };

    this.setValue = function (value) {
        if(this.previousValue === value) return;
        this.previousValue = this.currentValue;
        this.currentValue = value;
        return this.trigger(EVT.CHANGE, this.getValue());
    };

    this.getValue = function () {
        return {
            previous: this.previousValue,
            current: this.currentValue
        };
    };

    //this.setViewing = function (value) {
    //    this.find('.currentSelection').html(value);
    //};

    this.handleResize = function (e) {
        this.$tabs.removeClass("is-open");
        this.toggleIcon();
    };

    this.toggleOpen = function (event) {
        event.preventDefault();
        this.$tabs.toggleClass("is-open");
        this.toggleIcon();
    }

    this.toggleIcon = function() {
        if(this.$tabs.hasClass("is-open")){
            this.$dropdownIcon.text("-");
        } else{
            this.$dropdownIcon.text("+");
        }
    }
}

module.exports = component(Legend);
