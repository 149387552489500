var templater = require("handlebars/runtime")["default"].template;module.exports = templater({"1":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "      <span class=\"LocalAreaInfoWindow__rating\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.location : depth0)) != null ? stack1.Rating : stack1), depth0))
    + "</span>\r\n\r\n      <span class=\"LocalAreaInfoWindow__rating-stars\">\r\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.goldStars : depth0),{"name":"each","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.grayStars : depth0),{"name":"each","hash":{},"fn":this.program(4, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "      </span>\r\n\r\n      <span class=\"LocalAreaInfoWindow__total-ratings\">("
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.location : depth0)) != null ? stack1.TotalRatings : stack1), depth0))
    + ")</span>\r\n";
},"2":function(depth0,helpers,partials,data) {
    return "          <i class=\"LocalAreaInfoWindow__star font-icon-star-filled info-window-gold-star\"></i>\r\n";
},"4":function(depth0,helpers,partials,data) {
    return "          <i class=\"LocalAreaInfoWindow__star font-icon-star-filled info-window-gray-star\"></i>\r\n";
},"6":function(depth0,helpers,partials,data) {
    var helper;

  return "      <span class=\"LocalAreaInfoWindow__no-reviews\">"
    + this.escapeExpression(((helper = (helper = helpers.noReviewsLabel || (depth0 != null ? depth0.noReviewsLabel : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"noReviewsLabel","hash":{},"data":data}) : helper)))
    + "</span>\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=this.escapeExpression;

  return "<div id=\"info-window-local-area-template\" class=\"Map-markerContent LocalAreaInfoWindow hidden-xs clearfix\">\r\n  <h4 class=\"LocalAreaInfoWindow__location-name\">"
    + alias1(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"name","hash":{},"data":data}) : helper)))
    + "</h4>\r\n\r\n  <div class=\"LocalAreaInfoWindow__distance\">"
    + alias1(this.lambda(((stack1 = (depth0 != null ? depth0.location : depth0)) != null ? stack1.DistanceFromCommunity : stack1), depth0))
    + " Miles</div>\r\n\r\n  <div class=\"LocalAreInfoWindow__rating-data d-inline-flex justify-content-center align-items-end\">\r\n"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.location : depth0)) != null ? stack1.Rating : stack1),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + "  </div>\r\n\r\n</div>\r\n";
},"useData":true});