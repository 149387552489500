var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');
var AdobeLaunch = require('../../../lib/js/adobelaunch.js');

var Compare = function () {

    var $compareTray = $('.CompareTray'),
        $compareTrayTitle = $('.CompareTray__trayTitle'),
        $openedFromCompareModal = false,
        $errormodal = $(".CompareErrorModal");

    if ($compareTray == undefined || $compareTray.length == 0) return;

    this.comparePlanItemsList = []; //this is the list of Plans to compare
    this.compareQMIItemsList = []; //this is the list of QMIs to compare
    this.compareCommunitiesItemsList = []; //this is the list of Communities to compare

    this.viewType = ""; //this value can be "plan", "qmi" or "community"
    this.brand = $("#compare-tray").data('analyticsCommunityBrand');
    this.state = $("#compare-tray").data('state');
    this.region = $("#compare-tray").data('region');
    this.cityNames = $("#compare-tray").data('cities');
    this.dataAnalyticsZone = $('#compare-tray').attr('data-analytics-zone');
    this.communityName= $("#compare-tray").data('analyticsCommunityName').toLowerCase().replaceAll(' ', '-');

    // Get page type
    this.pageType = $compareTray.data('pageType');

    if (this.pageType == "Community") {
        this.communityID = $("#compare-tray").data('analyticsCommunityId');
        var plans;
        var qmis;

        if (this.communityID) {
            this.getPlans = function () {
                $.ajax({
                    type: 'GET',
                    context: this,
                    url: '/api/plan/homeplans?communityId=' + this.communityID,
                    // Uncomment Line below to run Frontend on localhost
                    // url: 'https://cdk.dev.pulte.com/api/plan/homeplans?communityId=' + this.communityID,
                    success: function (response) {
                        plans = response;
                    }
                });
            }

            this.getQMIs = function () {
                $.ajax({
                    type: 'GET',
                    context: this,
                    url: '/api/plan/qmiplans?communityId=' + this.communityID,
                    // Uncomment Line below to run Frontend on localhost
                    // url: 'https://cdk.dev.pulte.com/api/plan/qmiplans?communityId=' + this.communityID,
                    success: function (response) {
                        qmis = response;
                    }
                });
            }
        }
    }
    else if (this.pageType == "Product") {
        var communities;
        this.getCommunities = function () {
            $.ajax({
                type: 'GET',
                context: this,
                url: '/api/community/getcommunities?brand=' + this.brand + '&state=' + this.state + '&region=' + this.region + '&cityNames=' + this.cityNames + '&pageSize=1000000&pageNumber=0',
                // Uncomment Line below to run Frontend on localhost
                // url: 'https://cdk.dev.pulte.com/api/community/getcommunities?brand=' + this.brand + '&state=' + this.state + '&region=' + this.region + '&cityNames=' + this.cityNames + '&pageSize=1000000&pageNumber=0',
                success: function (response) {
                    communities = response;
                }
            });
        }
    }



    this.initialize = function (element) {

        var _this = this;

        if (this.pageType == "Community" && this.communityID) { // Get all Plans and QMIs in the Community
            this.getPlans();
            this.getQMIs();

            // Handles when view is changed btwn Plans/QMI or Gallery/Grid
            $(document).on("compare-view-change", this.handleViewChange.bind(this));
        }
        else if (this.pageType == "Product") { // Get all communities in the Location
            this.getCommunities();
        }

        // If Sticky Bottom Bar is also on page, offset bottom
        if ($('.StickyBottomBar').length) {
            $compareTray.css('bottom', $('.StickyBottomBar').outerHeight() + 2);
        }

        // Click of Compare CTA from Plan/QMI
        // handleSelectItem() can be updated to also handle selecting a community to compare on Region pages
        $(".Compare-icon").on('click', this.handleSelectItem.bind(this));

        // Click of Remove CTA on a Selected Item for Comparision in the Compare Tray
        $(".remove-icon").on('click', this.handleRemoveItemFromCompareTray.bind(this));

        // Compare Results Modal
        var $compareResultsTrigger = _this.$element.find("#compare-results");
        $compareResultsTrigger.on('click', this.triggerCompareModal.bind(this));
    }

    this.triggerCompareModal = function () {

        var itemList = [];

        if (this.viewType == "community") {
            itemList = this.compareCommunitiesItemsList;
        }
        else if (this.viewType == "plan") {
            itemList = this.comparePlanItemsList;
        }
        else if (this.viewType == "qmi") {
            if (this.compareQMIItemsList.length > 0) {
                var item;
                for (var i = 0; i < this.compareQMIItemsList.length; i++) {
                    item = this.compareQMIItemsList[i][0];
                    itemList.push(item);
                }
            }
        }

        $(".compare-listener").trigger("compare-results", [this.viewType, itemList]);

        // Sends info to launch adobe analytics when the Compare Results button is clicked
        if (this.viewType == "plan") {
            AdobeLaunch.pushItemCompareEvent(this.communityID, this.brand, this.comparePlanItemsList, AdobeLaunch.PLAN_COMPARED_EVENT_NAME);
        }
        else if (this.viewType == "qmi") {
            AdobeLaunch.pushItemCompareEvent(this.communityID, this.brand, this.compareQMIItemsList, AdobeLaunch.QMI_COMPARED_EVENT_NAME);
        }
        else if (this.viewType == "community") {
            // update this once when we compare communities
        }

        $('.CompareResults__carousel').on('click', function (e) {
            if (!$(e.target).hasClass('icon-heart-filled') && !$(e.target).hasClass('icon-heart')) {
                e.stopPropagation();
            }
        });

        $('.Footer a').attr("tabindex", -1);

        $('.CompareResults__closeButton').on('click', function (e) {
            $('.Footer a').attr("tabindex", 0);
        });


        // closes the compare modal when the login modal is opened from it
        $('#modal-account').on('show.bs.modal', function (e) {
            if (($('#modal-compare-results').data('bs.modal') || {}).isShown) {
                $openedFromCompareModal = true;
                $('#modal-compare-results').modal('hide');
            }
        });

        // opens the compare modal back up when the login modal is closed
        // this avoids errors with having two bootstrap modals up at one time
        $('#modal-account').on('hidden.bs.modal', function (e) {
            if ($openedFromCompareModal) {
                $openedFromCompareModal = false;
                $('#modal-compare-results').modal('show');
            }
        });
    }

    // On Click of Compare Item CTA
    this.handleViewChange = function (event, itemType) {

        var $compareCTA;
        var selectedItemList = [];

        if (itemType == "Homes" || itemType == 'home-gallery' || itemType == 'home-grid') {
            itemType = 'plan';
            selectedItemList = this.comparePlanItemsList;
        }
        else if (itemType == "Quick Move-In" || itemType == 'qmi-gallery' || itemType == 'qmi-grid') {
            itemType = 'qmi';
            var item;

            if (this.compareQMIItemsList.length > 0) {
                for (var i = 0; i < this.compareQMIItemsList.length; i++) {
                    item = this.compareQMIItemsList[i][0];
                    selectedItemList.push(item);
                }
            }
        }

        // Reset Compare CTA
        $(".Compare-icon img").attr('src', $(".Compare-icon img").attr('data-compare-icon-default-src'));
        $(".Compare-icon img").attr('alt', $(".Compare-icon img").attr('data-compare-icon-default-alt'));

        if (selectedItemList != null) {
            // Handle updating selected compare cta across Grid and Gallery
            for (var j = 0; j < selectedItemList.length; j++) {
                $compareCTA = $('.compare-cta-' + selectedItemList[j])
                $compareCTA.attr('src', $compareCTA.attr('data-compare-icon-selected-src'));
                $compareCTA.attr('alt', $compareCTA.attr('data-compare-icon-selected-alt'));
            }
        }

        // Set Compare Tray Title
        this.setCompareTrayTitle(itemType);

        // Set Compare Tray
        this.setCompareTrayImageBoxes(itemType);

        this.setCompareItemsCTA(itemType);

        // Click of Compare CTA from Plan/QMI
        // handleSelectItem() can be updated to also handle selecting a community to compare on Region pages
        $(".Compare-icon").on('click', this.handleSelectItem.bind(this));

        // Click of Remove CTA on a Selected Item for Comparision in the Compare Tray
        $(".remove-icon").on('click', this.handleRemoveItemFromCompareTray.bind(this));

    }

    // Changes the Compare Tray Title depending on the Page Type
    this.setCompareTrayTitle = function (itemType) {

        this.compareTrayTitleCommunity = $compareTrayTitle.data('compareTrayTitleCommunity');
        this.compareTrayTitlePlan = $compareTrayTitle.data('compareTrayTitlePlan');
        this.compareTrayTitleQMI = $compareTrayTitle.data('compareTrayTitleQmi');

        if (this.pageType == "Product") { // Product Page
            $compareTrayTitle.html(this.compareTrayTitleCommunity);
        }
        else if (this.pageType == "Community") { // Community Page

            if (itemType == "plan") { // Check if Plan View is Selected
                $compareTrayTitle.html(this.compareTrayTitlePlan);
            }
            else if (itemType == "qmi") { // Check if QMI View is Selected
                $compareTrayTitle.html(this.compareTrayTitleQMI);
            }
        }
    }

    // Removing item from selected items list
    this.removeItemFromSelectedItemsList = function ($cta) {

        var itemType = $cta.attr('data-item-type');
        var itemIndex;
        var compareItemId = $cta.attr('data-compare-item-id');

        if (itemType == "plan") {
            itemIndex = this.comparePlanItemsList.indexOf(compareItemId);
            this.comparePlanItemsList.splice(itemIndex, 1);
        }
        else if (itemType == "qmi") {
            for (var i = 0; i < this.compareQMIItemsList.length; i++) {
                if (this.compareQMIItemsList[i][0] == compareItemId) {
                    itemIndex = i;
                    break;
                }
            }
            this.compareQMIItemsList.splice(itemIndex, 1);
        }
        else if (itemType == "community") {
            itemIndex = this.compareCommunitiesItemsList.indexOf(compareItemId);
            this.compareCommunitiesItemsList.splice(itemIndex, 1);
        }

        // Reset Compare CTA back to default
        $cta.attr('src', $cta.attr('data-compare-icon-default-src'));
        $cta.attr('alt', $cta.attr('data-compare-icon-default-alt'));
    }

    // Clear Compare Tray
    this.clearCompareTray = function () {

        for (var i = 1; i <= 3; i++) {
            $unselectedBox = $('#unselected-box-' + (i));
            $selectedBox = $('#selected-box-' + (i));
            $selectedImageBox = $('#selected-image-box-' + (i));
            $removeIconCTA = $('#selected-image-box-' + (i) + '-remove-icon');

            $unselectedBox.removeClass('hidden');
            $selectedBox.addClass('hidden');
            $selectedImageBox.attr("src", "");
            $selectedImageBox.attr("alt", "");
            $selectedImageBox.attr('data-compare-item-id', '');
            $removeIconCTA.attr('data-compare-item-id', '');
            $removeIconCTA.attr('data-item-type', '');
        }
    }

    // Set the image boxes
    this.setCompareTrayImageBoxes = function (itemType) {

        var itemList = [];
        var image;

        // Get list of all items to be compared
        if (itemType == "plan") {
            itemList = this.comparePlanItemsList;
            this.viewType = "plan"
        }
        else if (itemType == "qmi") {
            for (var x = 0; x < this.compareQMIItemsList.length; x++) {
                if (this.compareQMIItemsList.length > 0) {
                    itemList.push(this.compareQMIItemsList[x][0])
                }
            }
            this.viewType = "qmi"
        } else if (itemType == "community") {
            itemList = this.compareCommunitiesItemsList;
            this.viewType = "community"
        }

        // Clear Compare Tray
        this.clearCompareTray();

        if (itemList != null) {

            var $unselectedBox, $selectedBox, $selectedImageBox, $removeIconCTA, imagePath, imageAltText, compareTrayRemoveIconDataAnalyticsCTA;

            // Get data for items to be compared
            for (var i = 0; i < itemList.length; i++) {

                if (itemType == "plan") {
                    // Filter through all Plans in the Community for the plan that matches the id in the list
                    var planItem = $(plans)
                        .filter(function (index, plan) {
                            return plan.id == itemList[i];
                        });

                    // Filter out any images ranked 0 for this Plan
                    image = planItem[0].images.filter(function (image) {
                        return image.imageRank != 0;
                    });

                    // Sort images in ascending order
                    image = image.sort(function (x, y) {
                        return x.imageRank - y.imageRank;
                    })[0];

                    imagePath= image.path;
                    imageAltText= image.altText;

                    // default format of data-analytics-cta for plan: "nameofmodule|community-name@plan-name|add-compare-item" 
                    compareTrayRemoveIconDataAnalyticsCTA = this.dataAnalyticsZone + '|' + this.communityName +'@'+planItem[0].planName.toLowerCase().replaceAll(' ', '-')  + '|remove-compare-item';

                }
                else if (itemType == "qmi") {
                    // Filter through all QMIs in the Community for the QMI that matches the id in the list

                    var qmiItem = $(qmis)
                        .filter(function (index, qmi) {
                            return qmi.inventoryHomeID == itemList[i];
                        });

                    // Filter out any images ranked 0 for this QMI
                    image = qmiItem[0].images.filter(function (index, image) {
                        return index.imageRank != 0;
                    });

                    // Sort images in ascending order
                    image = image.sort(function (x, y) {
                        return x.imageRank - y.imageRank;
                    })[0];

                    imagePath= image.path;
                    imageAltText= image.altText;

                    // for qmi: "nameofmodule|communityname@nameofqmiplan@lotblock|add-compare-item"
                    compareTrayRemoveIconDataAnalyticsCTA = this.dataAnalyticsZone  + '|' + this.communityName + '@'+ qmiItem[0].planName.toLowerCase().replaceAll(' ', '-') + '@'+ qmiItem[0].lotBlock.toLowerCase().replaceAll(' ', '-')  + '|remove-compare-item';

                } else if (itemType == "community") {

                    // Filter through all Communities in the Product page for the community that matches the id in the list
                    var communityItem;

                    try {
                        communityItem = $(communities)
                            .filter(function (index, community) {
                                return community.id == itemList[i];
                            });

                        imagePath = communityItem[0].primaryImagePath;
                    }
                    catch (err) {
                        imagePath = "";
                    }

                    if (imagePath.substring(0, 7) == '/assets') {
                        var brand;
                        if (this.brand == "Pulte") {
                            brand = 'pulte';
                        }
                        else if (this.brand == "Del Webb") {
                            brand = 'delwebb';
                        }
                        else if (this.brand == "John Wieland") {
                            brand = 'jwhomes';
                        }
                        else if (this.brand == "Centex") {
                            brand = 'centex';
                        }
                        else if (this.brand == "American West") {
                            brand = 'americanwesthomes';
                        }
                        else if (this.brand == "DiVosta") {
                            brand = 'divosta';
                        }

                        imagePath = 'http://images.' + brand + '.com' + imagePath
                    }

                    imageAltText = communityItem[0].primaryImageAltText != null ? communityItem[0].primaryImageAltText : "";
                }

                // needed for images as they pull from cloudinary
                var srcPrefix = "//res.cloudinary.com/dv0jqjrc3/image/fetch/ar_1.5,c_fill,f_auto,q_auto,w_768/"

                $unselectedBox = $('#unselected-box-' + (i + 1));
                $selectedBox = $('#selected-box-' + (i + 1));
                $selectedImageBox = $('#selected-image-box-' + (i + 1));
                $removeIconCTA = $('#selected-image-box-' + (i + 1) + '-remove-icon');

                $unselectedBox.addClass('hidden');
                $selectedBox.removeClass('hidden');
                $selectedImageBox.attr("src", srcPrefix + imagePath);
                $selectedImageBox.attr("alt", imageAltText);
                $selectedImageBox.attr('data-compare-item-id', itemList[i]);
                $removeIconCTA.attr('data-compare-item-id', itemList[i]);
                $removeIconCTA.attr('data-item-type', itemType);
                $removeIconCTA.attr('data-analytics-cta', compareTrayRemoveIconDataAnalyticsCTA);
            }

            // Check that there is at least one item selected for comparsion, else hide the tray
            if (itemList.length < 1) {
                $compareTray.addClass("hidden");
            }
            else {
                $compareTray.removeClass("hidden");
            }

            // Set the Compare Items CTA in the Compare Tray to Disabled/Enabled
            this.setCompareItemsCTA(itemType);
        }
    }

    // On Click of Compare Item CTA
    this.handleSelectItem = function (event) {

        event.preventDefault()

        // Show Compare Tray since an item is selected for comparison
        if ($compareTray.hasClass('hidden')) {
            $compareTray.removeClass('hidden');
        }

        var $compareCTA = $('.compare-cta-' + event.target.getAttribute('data-compare-item-id')); // this is the compare button that launches the Compare Tray

        var itemType = $compareCTA[0].getAttribute('data-item-type');
        if (itemType == "plan" && this.comparePlanItemsList.length == 3 && !($compareCTA.attr("src") == $compareCTA.attr('data-compare-icon-selected-src'))) {
            AdobeLaunch.maxComparedReached(AdobeLaunch.MAX_COMPARED_REACHED);
            $errormodal.modal('show');
            return
        }

        if (itemType == "qmi" && this.compareQMIItemsList.length == 3 && !($compareCTA.attr("src") == $compareCTA.attr('data-compare-icon-selected-src'))) {
            AdobeLaunch.maxComparedReached(AdobeLaunch.MAX_COMPARED_REACHED);
            $errormodal.modal('show');
            return
        }

        if (itemType == "community" && this.compareCommunitiesItemsList.length == 3 && !($compareCTA.attr("src") == $compareCTA.attr('data-compare-icon-selected-src'))) {
            AdobeLaunch.maxComparedReached(AdobeLaunch.MAX_COMPARED_REACHED);
            $errormodal.modal('show');
            return
        }

        // Set Compare Tray Title
        this.setCompareTrayTitle(itemType);

        // default format of data-analytics-cta for plan: "nameofmodule|community-name@plan-name|add-compare-item" 
        // for qmi: "nameofmodule|communityname@nameofqmiplan@lotblock|add-compare-item"
        // grab the prefix of the data-analytics-cta
        var compareIconDataAnalyticsCTAPrefix = $compareCTA.parent().attr('data-analytics-cta').substring(0,$compareCTA.parent().attr('data-analytics-cta').lastIndexOf('|')+1);
        var compareRemoveIconDataAnalyticsCTA = compareIconDataAnalyticsCTAPrefix + 'remove-compare-item';
        var compareAddIconDataAnalyticsCTA = compareIconDataAnalyticsCTAPrefix + 'add-compare-item';  

        if ($compareCTA.attr("src") == $compareCTA.attr('data-compare-icon-selected-src')) { // item is unselected for comparision
            $compareCTA.attr('src', $compareCTA.attr('data-compare-icon-default-src'));
            $compareCTA.attr('alt', $compareCTA.attr('data-compare-icon-default-alt'));
            
            // update the data-analytics-cta attribute for when items are to be selected for comparison
            $compareCTA.parent().attr('data-analytics-cta', compareAddIconDataAnalyticsCTA);
            
            // Removing item from selected items list
            this.removeItemFromSelectedItemsList($compareCTA);
        }
        else { // item is selected for comparision
            $compareCTA.attr('src', $compareCTA.attr('data-compare-icon-selected-src'));
            $compareCTA.attr('alt', $compareCTA.attr('data-compare-icon-selected-alt'));

            // update the data-analytics-cta attribute for when items are to be removed from comparison
            $compareCTA.parent().attr('data-analytics-cta', compareRemoveIconDataAnalyticsCTA);

            // Adding item to selected items list
            if (itemType == "plan") {
                this.comparePlanItemsList.push($compareCTA.attr('data-compare-item-id'));
            }
            else if (itemType == "qmi") {
                this.compareQMIItemsList.push([$compareCTA.attr('data-compare-item-id'), $compareCTA.attr('data-plan-id')]);
            }
            else if (itemType == "community") {
                this.compareCommunitiesItemsList.push($compareCTA.attr('data-compare-item-id'));
            }
        }

        // Set Compare Tray Image Boxes based on selected items
        this.setCompareTrayImageBoxes(itemType);


    }

    // On Click of Remove CTA
    this.handleRemoveItemFromCompareTray = function (event) {

        var $compareCTA = $('.compare-cta-' + event.target.getAttribute('data-compare-item-id')); // this is the compare button that launches the Compare Tray
        var itemType = $compareCTA.attr('data-item-type');

        // Removing item from selected items list
        this.removeItemFromSelectedItemsList($compareCTA);

        // Reset Compare Tray
        this.setCompareTrayImageBoxes(itemType);
    }

    // At least two items must appear on the tray before the Compare Results CTA is enabled
    // Change Compare CTA Color based on Enabled/Disabled
    this.setCompareItemsCTA = function (itemType) {
        var $compareCTA = $("#compare-results");
        this.disabledCompareCTAFontColor = $compareCTA.attr('data-disabled-compare-cta-font-color');
        this.disabledCompareCTAColor = $compareCTA.attr('data-disabled-compare-cta-background-color');
        this.disabledCompareCTABorderColor = $compareCTA.attr('data-disabled-compare-cta-border-color');

        this.enabledCompareCTAFontColor = $compareCTA.attr('data-enabled-compare-cta-font-color');
        this.enabledCompareCTAColor = $compareCTA.attr('data-enabled-compare-cta-background-color');
        this.enabledCompareCTABorderColor = $compareCTA.attr('data-enabled-compare-cta-border-color');

        var selectedItemsListLength;
        if (itemType == "plan") {
            selectedItemsListLength = this.comparePlanItemsList.length;
        }
        else if (itemType == "qmi") {
            selectedItemsListLength = this.compareQMIItemsList.length;
        }
        else if (itemType == "community") {
            selectedItemsListLength = this.compareCommunitiesItemsList.length;
        }

        if (selectedItemsListLength >= 2) {
            // Enabled
            $compareCTA.css('color', this.enabledCompareCTAFontColor);
            $compareCTA.css('background-color', this.enabledCompareCTAColor);
            $compareCTA.css('border', '1px solid ' + this.enabledCompareCTABorderColor);
            $compareCTA.removeAttr('disabled');
        }
        else {
            // Disabled
            $compareCTA.css('color', this.disabledCompareCTAFontColor);
            $compareCTA.css('background-color', this.disabledCompareCTAColor);
            $compareCTA.css('border', '1px solid ' + this.disabledCompareCTABorderColor);
            $compareCTA.attr('disabled', 'disabled');
        }
    }
}

module.exports = component(Compare);
