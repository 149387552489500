var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');

function HomeCollectionsSideTabs() {

    /**
     * Init for the Home Collections Side Tabs module
     * @param  {Object} element HTML element
     */
    this.initialize = function (element) {
        
        $element = $(element);

        // on document ready
        this.handleResize();

        $(window).resize(utils.debounce(this.handleResize.bind(this), 250, false));

        $element.find('.HomeCollectionsSideTabs--nav-tabs-container-inner').on('touchmove scroll', function() {
            $(this).removeClass('with-hint');
        });

        $element.find('.HomeCollectionsSideTabs--button').on('click touch', function(e) {
            if (window.location.hash === e.currentTarget.hash) {
                //fire hashchange event to trigger deeplink scrolling
                $(window).trigger('hashchange');
            }
        });
    }

    // Using same functionality that exists in Toggle-Options but excluded the code unrelated to resizing
    this.handleResize = function () {
        var windowWidth = $(window).outerWidth();
        var items = $element.find('.nav-tabs');
        if (windowWidth < 768) {
            items.each(function() {
                var spaceBetween = $(this).data('space-between');
                var responsiveWidth = 0;
                var childCount = $(this).children('.tab').length;
                $(this).children('.tab').each(function(i) {
                    responsiveWidth += $(this).outerWidth() + parseInt(spaceBetween);
                    if (i === childCount - 1) {
                        responsiveWidth -= parseInt(spaceBetween);
                    }
                });
                responsiveWidth += 30; // account for the margin-left and margin-right
                $(this).css('width', responsiveWidth);

                if (responsiveWidth > windowWidth) {
                    $(this).closest('.HomeCollectionsSideTabs--nav-tabs-container-inner').addClass('with-hint');
                } else {
                    $(this).closest('.HomeCollectionsSideTabs--nav-tabs-container-inner').removeClass('with-hint');
                }
            });
        } else {
            items.each(function() {
                $(this).removeAttr('style');
            });
        }
    }
}

module.exports = component(HomeCollectionsSideTabs);
