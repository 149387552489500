var component = require('../../../lib/js/component.js');
var Form = require('../forms/form.js');
var utils = require('../../../lib/js/utils.js');

var DesignBoardHelpers = require('./design-board-helpers.js');
var DesignBoardTemplates = require('./design-board-templates.js');
var DesignBoardServices = require('./design-board-services.js');
var DESIGN_MODAL_OPEN = 'DesignBoardModal:open';

function DesignBoard() {

    this.listingUrl = '';
    this.boardId = '';
    this.owner = null;

    this.initialize = function (element) {
        var createEl = $(element).find('.DesignBoardCreate'),
            createSaveEl = $(createEl).find('.DesignBoard-create-save'),
            allDeleteEl = $(element).find('.DesignBoard-delete'),

            detailEl = $(element).find('.DesignBoardDetail'),
            detailBackToAllEl = $(detailEl).find('.DesignBoard-back-all'),
            detailEditEl = $(detailEl).find('.DesignBoard-edit'),

            editEl = $(element).find('.DesignBoardEdit'),
            editAddEl = $(editEl).find('.DesignBoard-edit-add'),
            editRemoveEl = $(editEl).find('.DesignBoard-edit-remove'),

            addItemsModalEl = $('.DesignBoard-modal'),
            addItemsFilterEl = addItemsModalEl.find('.Filter'),
            addItemsCtaEl = addItemsModalEl.find('.DesignBoard-add-items'),
            addItemsFilterOptionsEl = addItemsFilterEl.find('.Filter-item a'),

            createArgument = utils.getUrlParameters('create'),
            idArgument = utils.getUrlParameters('id'),
            ownerArgument = utils.getUrlParameters('owner'),
            editArgument = utils.getUrlParameters('edit');

        this.listingUrl = $(element).data('listing-url');
        this.boardId = idArgument;
        this.owner = ownerArgument;

        // @start detect mode
        if (idArgument !== null) {
            DesignBoardHelpers.resetMode(element);

            //enter to detail mode
            if (editArgument === null) {
                $(element).addClass(DesignBoardHelpers.designBoardClasses.detailInit);
                this.populateDetailPageService(idArgument, ownerArgument, detailEl, 'detailMode');
            }

                //enter to edit mode
            else {
                this.enterToEditMode(element);
            }
        }
        else {
            DesignBoardHelpers.hideLoading();
        }

        $(element).on('enterToAddMode', this.enterToAddModeFromEvent.bind(this));
        // @end detect mode

        // @start general board
        allDeleteEl.on('click', DesignBoardServices.deleteBoard.bind(this, idArgument, this.listingUrl));
        // @end general board

        // @start create board
        $(createEl).find('.Form-DesignBoard-create').attach(Form);
        createSaveEl.on('click', DesignBoardServices.saveBoard.bind(this, element));
        // @end create board

        // @start detail board
        detailBackToAllEl.on('click', DesignBoardHelpers.backToListPage.bind(this, this.listingUrl));
        detailEditEl.on('click', DesignBoardHelpers.enterToEditModeFromButton.bind(this, idArgument));
        // @end detail board

        // @start edit board
        $(editEl).find('.Form-DesignBoard-edit').attach(Form);
        editRemoveEl.on('click', DesignBoardServices.updateBoard.bind(this, editEl, idArgument, [], null));
        editAddEl.on('click', this.enterToAddMode.bind(this, idArgument));
        // @end edit board

        // @start add items
        addItemsFilterOptionsEl.on('click', DesignBoardServices.loadAllItems.bind(this, addItemsModalEl, false, idArgument));
        addItemsCtaEl.on('click', DesignBoardServices.addItemsBoard.bind(this, idArgument));
        addItemsModalEl.on('hidden.bs.modal', DesignBoardHelpers.onModalCloseEvent.bind(this, element, idArgument));
        // @start add items
    };

    // @start serives call
    this.populateDetailPageService = function (boardId, owner, wrapperElement, mode) {
        var gridToPopulate = wrapperElement.find('.DesignBoard-grid-list'),
            inputName = wrapperElement.find('.form-control'),
            self = this;
        var url = '/api/designboard/' + boardId;
        if (owner != null) {
            url += '/' + owner;
        }

        $.ajax({
            url:  url,
            method: "GET",
            cache: false
        })
        .fail(function (data) {
            window.location.href = self.listingUrl;
        })
        .done(function (data) {
            var objectData = JSON.parse(data.serializedObject),
                itemsInfo = '',
                listOfItems = [];

            if (mode === 'detailMode') {
                itemsInfo = DesignBoardTemplates.populateDetailPage(objectData.DesignBoardItems);
            }
            if (mode === 'editMode') {
                itemsInfo = DesignBoardTemplates.populateEditPage(objectData.DesignBoardItems);
                inputName.val(objectData.Name);
                self.changeBoardName(inputName);
            }

            gridToPopulate.html(itemsInfo);
            listOfItems = $('.DesignBoard-grid-item-edit');
            DesignBoardHelpers.updateBoardName(objectData.Name);
            DesignBoardHelpers.hideLoading();
            DesignBoardHelpers.onSelectItemsEvent(listOfItems, '');

            //empty state
            if (objectData.DesignBoardItems.length === 0) {
                wrapperElement.addClass(DesignBoardHelpers.designBoardClasses.detailEmptyState);
            }

        });
    };
    // @end serives call

    // @start mode switching
    this.enterToEditMode = function (parentEl) {
        var editEl = $(parentEl).find('.DesignBoardEdit');

        DesignBoardHelpers.resetMode(parentEl);
        $(parentEl).addClass(DesignBoardHelpers.designBoardClasses.editInit);
        this.populateDetailPageService(this.boardId, this.owner, editEl, 'editMode');
    };

    this.enterToAddMode = function (idBoard, evt) {
        var modalEl = $(this.element).find('.DesignBoard-modal');
        modalEl.modal('show');

        DesignBoardServices.loadAllItems(modalEl, true, idBoard, evt);
    };

    this.enterToAddModeFromEvent = function (evt, idBoard) {
        var modalEl = $(this.element).find('.DesignBoard-modal');
        modalEl.modal('show');
        DesignBoardServices.loadAllItems(modalEl, true, idBoard, evt);
    };
    // @end mode switching

    // @start events
    this.changeBoardName = function (inputEl) {
        var idArgument = utils.getUrlParameters('id'),
            ENTER_CODE = 13;

        $(inputEl).on('keydown focusout', function (evt) {
            var keyCode = evt.which;

            if (keyCode === ENTER_CODE || evt.type === 'focusout') {
                var selectedItemsEl = $('.DesignBoard-grid-item-edit').not('.DesignBoard-item-selected'),
                    boardName = $(inputEl).val(),
                    selectedItems = [];

                evt.preventDefault();

                if ($(inputEl).parent().hasClass('has-error') || $(inputEl).val() === '') {
                    $('.DesignBoard-edit-remove').prop("disabled", true);
                    $('.DesignBoard-edit-add').prop("disabled", true);
                }
                else{
                    $('.DesignBoard-edit-remove').prop("disabled", false);
                    $('.DesignBoard-edit-add').prop("disabled", false);
                    selectedItems = DesignBoardHelpers.transformElementsToItems(selectedItemsEl)
                    DesignBoardServices.updateBoard(null, idArgument, selectedItems, boardName)
                }
            }

        });
    };

    // @end events
}

module.exports = component(DesignBoard);
