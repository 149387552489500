var component = require('../../../lib/js/component.js');
var validator = require('./validator.js');
var constants = require('./constants.js');

var Field = function () {

    this.initialize = function (el, options) {
        this.options = options || {};

        this.data = this.$element.data();
        this.$field = this.find('input,select,textarea');
        this.$agreementId = this.find('input[name="agreementId"]');
        this.$form = this.$element.closest('form');
        // fill the inputs on load page
        this.prefill();
        // when one of the form dom elements isn't found,
        // assume this element is the form dom element rather
        // than the parent of a form dom element
        if (this.$field.length === 0) {
            this.$field = this.$element;
        }

        this.required = this.$field.prop('required');

        if (this.$field.prop('tagName').toLowerCase() === 'select') {
            if (this.fieldIsEmpty()) {
                this.$element.addClass(constants.EMPTY_CLASS);
            }
            if (this.required) {
                this.$field.on('focusout', this.checkSelectEmpty.bind(this));
            }
        }

        this.initValidation();
        this.trigger(constants.FIELD_INIT, this);
    };

    this.specialCharacterTransform = function (fieldElement) {
        var rawString = new DOMParser().parseFromString(fieldElement, "text/html");
        var transformedString = rawString.documentElement.textContent;
        return transformedString;
    }

    this.initValidation = function () {
        // certain inputs require change event listeners
        if (
            this.$field.prop('type').toLowerCase() === 'radio' ||
            this.$field.prop('type').toLowerCase() === 'checkbox' ||
            this.$field.prop('tagName').toLowerCase() === 'select' ||
            this.$field.hasClass('hasDatepicker') // validation for date picker component
        ) {
            this.$field.change(this.checkValue.bind(this));
        } else {
            this.$field.blur(this.checkValue.bind(this));
            this.$field.keyup(this.checkValue.bind(this));
            this.$field.blur(this.checkPreviousInputsValidation.bind(this));
            this.$field.focus(this.checkPreviousInputsValidation.bind(this));
            this.$agreementId.on('paste', function (e) {
                var thisField = this;
                setTimeout(function () { thisField.blur(); }, 50);
            });
        }

        if (this.$field.prop('tagName').toLowerCase() === 'select' ||
            this.$field.prop('type').toLowerCase() === 'checkbox') {
            this.$field.change(this.checkPreviousInputsValidation.bind(this));
        }

    };

    this.checkSelectEmpty = function () {
        if (this.fieldIsEmpty()) {
            this.$element.addClass(constants.ERROR_CLASS);
        }
        else {
            this.$element.removeClass(constants.ERROR_CLASS);
        }
    };

    this.checkValue = function (event) {
        var formParent = this.$field[0].form,
            validationRules,
            innerField;

        // TODO probably want to rethink how this works to avoid invalid non-editable fields
        // check for data attribute that overrides this behavior called data-validate-while-disabled
        if ((this.$field.prop('disabled') || this.$field.prop('readonly')) && !this.$field.data('validateWhileDisabled')) {
            return true;
        }

        if (event && this.$field.val() === '' && event.type === 'keyup') {
            return false;
        } else {
            var valid = true;

            if (this.required && !this.data.validate) {
                // for fields that are required but require no specific validation
                // method, the field is not valid only if it's empty
                if (this.fieldIsEmpty()) {
                    valid = false;
                }

            } else if (this.$field.val().length < (this.$field.attr('minlength') || 0)) {
                valid = false;
            }

            else {
                //space-separated markup ie: <input type=text data-validate="zip notEmptyNotRequired" />
                innerField = this.$field;
                validationRules = this.data.validate ? this.data.validate.split(',') : [];
                validationRules.forEach(function (validationRule) {
                    valid = valid && validator.test(validationRule, innerField);
                });

                // override validation results if the field is not required and empty
                if (!this.required && this.fieldIsEmpty()) {
                    valid = true;
                }
            }

            if (event) {
                this.setErrorClass(valid, event.type);
                this.trigger(constants.FIELD_CHECKED);
            }

            return valid;
        }

    };

    this.isValid = function () {
        if (this.data.validate || this.required) {
            return this.checkValue();
        } else {
            return true;
        }
    };

    this.fieldIsEmpty = function () {
        return !this.$field.val();
    };

    this.setErrorClass = function (valid, eventType) {
        // the EMPTY_CLASS below is only used for <select> elements
        if (valid) {
            this.$element
                .removeClass(constants.EMPTY_CLASS)
                .removeClass(constants.ERROR_CLASS);
        } else {
            if (eventType !== 'keyup') {
                this.$element
                    .addClass(constants.EMPTY_CLASS)
                    .addClass(constants.ERROR_CLASS);
            }
        }
    };

    this.updateErrors = function () {
        this.setErrorClass(this.isValid());
    };

    this.getName = function () {
        return this.$field.prop('name');
    };

    this.getValue = function () {
        if (this.$field.length > 1) {
            //get value of checked radio button:
            return this.$field.parent().find(":checked").val();
        }
        if (this.$field.is('[type=checkbox]')) {
            return this.$field.is(':checked');
        }
        return this.$field.val();
    };

    this.appendData = function (data) {
        data[this.getName()] = this.getValue();
        return data;
    };

    this.enable = function () {
        this.$field.prop('disabled', false);
    };

    this.disable = function () {
        this.$field.prop('disabled', true);
        // TODO do this or add a disabled class that optionally overrides the error class?
        this.$element.removeClass(constants.ERROR_CLASS);
    };

    this.prefill = function () {

        if (Pulte.user !== undefined) {
            var $targetField = this.$field.length > 0 ? this.$field : this.$element,
                attrName = $targetField.attr('name'),
                targetValue;
            targetValue = Pulte.user[attrName];
            try {
                if ($targetField.is('select[name=Country]')) {

                    $targetField.find('option').each(function (index, optionEl) {
                        if (optionEl.value.toLowerCase() === targetValue.toLowerCase()) {
                            optionEl.selected = true;
                        }
                    });

                } else if ($targetField.is('[type=checkbox]')) {
                    $targetField.attr("checked", targetValue.toLowerCase() == 'true');

                } else if (!$targetField.is('[type=hidden]') && !$targetField.is('[name=SpeakToSalesAgent]') && !$targetField.is('.DisableAccountInfoAutofill')) {
                    if(targetValue !== undefined){
                        $targetField.val(this.specialCharacterTransform(targetValue));
                    }
                }

            } catch (ex) {
                reason = ex;
            }
        }
    };

    this.checkPreviousInputsValidation = function (event) {
        var _this = this;
        //Gets Your Information invalid fields and forces current element into array to get previous ones
        var $invalidInputs = this.$form.find('input:invalid, select:invalid, [name="' + this.$field[0].name + '"]');
        var inputIndex = $.inArray(this.$field[0], $invalidInputs);
        //If first in array or -1 means no previous errors have been found
        if (inputIndex > 0) {
            var $previousInputs = $invalidInputs.slice(0, inputIndex);
            $previousInputs.each(function (index, input) {
                var $previousField = $(input).closest('.Field, .Field-select');
                $previousField.addClass(constants.ERROR_CLASS);
            });
        }
    }

    this.isCommunitySelectInput = function($input){
        return $input.closest('.GlobalLeadForm__community-info-selection-container').length > 0;
    }
};

module.exports = component(Field);
