var component = require('../../../lib/js/component.js');
var Form = require('../forms/form.js');
var constants = require('../forms/constants.js');
var AdobeLaunch = require('../../../lib/js/adobelaunch');

var FormCreate = function () {

    this.initialize = function (el, options) {
        this.options = options || {};
        this.$form = this.find('.Form--accountCreate');
        this.$form.attach(Form).on(constants.FORM_AJAX_SUBMIT, this.handleFormSubmission.bind(this));
    }

    this.handleFormSubmission = function (event, form) {
        $.ajax({
            method: form.options.method,
            url: form.options.action,
            data: form.data,
            dataType: "json"
        }).done(function (data) {
            if (data) {
                if (data.Success && data.UserId) {
                    AdobeLaunch.pushUserEvent(AdobeLaunch.USER_REGISTERED_EVENT_NAME);
                }
                
                location.href = data.RedirectUrl;
            }
        })
    };
}

module.exports = component(FormCreate);
