var component = require('../../../lib/js/component.js');
var requestInfo = require('../../../lib/js/request-info.js');

function CommunityNav() {
    var $communityNav = $('.CommunityNav'),
        $CommunityNavLinks = $('.CommunityNav__links > ul'),
        $dropdownButton = $communityNav.find('.CommunityNav__linksDropdown'),
        $dropdownButtonIcon = $dropdownButton.find('i'),
        $dropdownButtonText = $dropdownButton.find('.viewing'),
        $defaultLink = $CommunityNavLinks.find('.CommunityNav__defaultLink a'),
        $activeNavLink;

    this.initialize = function (element, options) {

        var _this = this;

        // Disable fixed positioning of header when this module is present
        // Doing it here allows for height change of header (in CSS) without requiring update of HTML "data-offset-top"
        $communityNav.affix({offset: {top: $("header").outerHeight(true), bottom: 200} });

        // Disable fixed positioning of header when this module is present
        $(window).on("components.loaded", function() {
            $(window).trigger("Header.disableFixed");
        });

        // Setup dropdown behavior for tablet/mobile
        $dropdownButton.on('click', this.toggleOpen.bind(this));
        _this.activateLink($defaultLink);

        var navItems = [];

        // Create nav item for each component that has communityNavText
        $('[data-nav-title]').each(function (index) {

            var $this = $(this);

            var navTitle = $this.attr("data-nav-title");
            var navId = $this.attr("id");

            if (navTitle && navId) {
                var navItem = {};
                navItem.title = navTitle;
                navItem.id = navId;
                navItem.unless = $this.attr("data-nav-title-unless");
                navItems.push(navItem);
            }

        });

        navItems.forEach(function(navItem) {

            if (!navItem.unless || (navItem.unless && !_this.navItemIdExists(navItems, navItem.unless))) {
                $CommunityNavLinks.find("> li:last-child").before(
                    $('<li/>')
                        .append(
                            $('<a/>')
                                .attr('href', '#')
                                .attr('data-analytics', 'community-toolbar|community-name|' + navItem.id)
                                .attr('data-analytics-cta', navItem.title)
                                .attr('data-nav-target', "#" + navItem.id)
                                .text(navItem.title)
                                .on('click', function (e) {
                                    _this.toggleOpen(e);
                                    _this.scrollToSection($(e.target).attr('data-nav-target'));
                                })
                        )
                );
            }
        });

        // Scroll to top button
        $(".CommunityNav__backToTop, .CommunityNav__defaultLink").on("click", function (e) {
            e.preventDefault();
            $CommunityNavLinks.removeClass("is-open");
            $dropdownButtonIcon.removeClass("icon-arrow-up").addClass("icon-arrow-down");
            $('html, body').animate({
                scrollTop: 0
            }, 1000);
        });

        // handler for QMI button click
        $communityNav.find("#CommunityNavQMIBtn, #CommunityNavQMIFooterBtn").on('click', function (e) {
            e.preventDefault();
            var qmiTab = $('.toggle--homeType [data-type=qmi]');
            if (qmiTab.length > 0) {
                $CommunityNavLinks.removeClass("is-open");
                _this.scrollToSection('#HomeSummaryGlance');
                qmiTab.click();
            }
        });

        // handler for QMI button click
        $communityNav.find("#PlanNavQMIBtn,#PlanNavQMIFooterBtn").on('click', function (e) {
            e.preventDefault();
            $CommunityNavLinks.removeClass("is-open");
            _this.scrollToSection('#PlanQMISummary');
        });

        // handle adjusting navigation on scroll
        $(window).on('scroll', function () {

            var $newNavLink;
            var moduleTransitionLoc = $(window).scrollTop() + $communityNav.outerHeight() + 150;

            $CommunityNavLinks.find('> li a').each(function (index) {

                if ($(this).attr('data-nav-target')) {
                    if (moduleTransitionLoc >= $($(this).attr('data-nav-target')).offset().top) {
                        $newNavLink = $(this);
                    }
                }

            });

            if ($newNavLink) {
                if (!$activeNavLink || ($activeNavLink.attr('data-nav-target') != $newNavLink.attr('data-nav-target'))) {
                    $activeNavLink = $newNavLink;
                    _this.activateLink($activeNavLink);
                }
            } else {
                if ($activeNavLink) {
                    $activeNavLink = undefined;
                    _this.activateLink($defaultLink);
                }
            }

        });

        $(window).trigger("scroll");

        requestInfo.initRequestInfoModals();
    }

    this.navItemIdExists = function(navItems, id) {

        for (i = 0; i < navItems.length; i++) {
            if (navItems[i].id === id) return true;
        }

        return false;
    }

    this.toggleOpen = function (e){
        e.preventDefault();
        $CommunityNavLinks.toggleClass("is-open");
        if ($CommunityNavLinks.hasClass("is-open")) {
            $dropdownButtonIcon.removeClass("icon-arrow-down").addClass("icon-arrow-up");
        } else {
            $dropdownButtonIcon.removeClass("icon-arrow-up").addClass("icon-arrow-down");
        }
    }

    this.activateLink = function ($theLink) {
        $CommunityNavLinks.find('> li a').each(function (index) {
            $(this).removeClass('is-active');
        });
        $theLink.addClass('is-active');
        $dropdownButtonText.text($theLink.text());
    }

    this.scrollToSection = function (scrollToLoc) {

        var targetOffset = $communityNav.outerHeight();

        if ($communityNav.hasClass('affix-top')) {
            targetOffset = targetOffset * 2;
        }

        $('html, body').animate({
            scrollTop: $(scrollToLoc).offset().top - targetOffset
        }, 1000);

    }

}

module.exports = component(CommunityNav);
