var component = require('../../../lib/js/component.js');

function QuestionAnswer() {

    /**
     * Init for the question-answer module
     * @param  {Object} element HTML element
     */
    this.initialize = function (element) {
        var $content,
            $answerContent,
            $questionText = $('.QuestionAnswer-questionText p'),
            $icon = $('.QuestionAnswer-questionTitle .icon');

        if (Modernizr.touchevents) {
            $questionText.each(function(index, element) {
                $element = $(element);
                $element.data('full-text', $element.html());
                $clamp(element, { clamp: 5, useNativeClamp: false });
                $element.data('clamped-text', $element.html());
            });
        }

        //events
        $icon.on('click', function( e ) {
            $element = $(this);
            $element.toggleClass('icon-plus');
            $content = $element.parents().eq(2);
            $answerContent = $content.find('.QuestionAnswer-answerContent');
            $questionContent = $content.find('.QuestionAnswer-questionText p');
            $answerContent.toggleClass('active');
            fullText = $questionContent.data('full-text');
            clampedText = $questionContent.data('clamped-text');

            if($answerContent.hasClass('active')) {
                $questionContent.html(fullText);
            }
            else {
                $questionContent.html(clampedText);
            }
        });
    };
}

module.exports = component(QuestionAnswer);
