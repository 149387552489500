var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');
var MapCore = require('./map-core.js');
var mapsHelper = require('./maps-helper.js');

var FiltersMapController = function () {
    var $legend = $('.Legend'),
        $MapFooter = $('.FindHomes__MapFooter'),
        $heroCta = $('#location'),
        siteState = $heroCta.val(),
        initialGeolocation = $heroCta.data('geolocation'),
        currentRegion = "";

    this.initialize = function (el, options) {
        var _this = this;
        this.options = {};
        this.brand = $('body').data('brand');
        this.$mapElement = this.$element.find('.Map-googleMap');
        $.extend(this.options, options, this.$mapElement.data());
        clusterRegions = $('.Map').data('cluster-regions'),
        clusterCommunities = $('.Map').data('cluster-communities');

        this.EXACT_MATCH_PIN_COLOR = this.options.map.defaults.EXACT_MATCH_PIN_COLOR;
        this.CLOSE_MATCH_PIN_COLOR = this.options.map.defaults.CLOSE_MATCH_PIN_COLOR;
        this.REGION_PIN_COLOR = this.options.map.defaults.REGION_PIN_COLOR;
        this.CLUSTER_PIN_COLOR = this.options.map.defaults.CLUSTER_PIN_COLOR;
        this.MARKER_PIN_SVG_PATH_GROUP = this.options.map.defaults.MARKER_PIN_SVG_PATH_GROUP;
        this.MARKER_PIN_SVG_OPACITY_WEIGHT = this.options.map.defaults.MARKER_PIN_SVG_OPACITY_WEIGHT;
        this.MARKER_PIN_SVG_SIZE_LG = this.options.map.defaults.MARKER_PIN_SVG_SIZE_LG;
        this.MARKER_PIN_SVG_SIZE_SM = this.options.map.defaults.MARKER_PIN_SVG_SIZE_SM;
        this.MARKER_PIN_SVG_PATH = this.options.map.defaults.MARKER_PIN_SVG_PATH;
        this.MARKER_PIN_SVG_PATH_REGION = this.options.map.defaults.MARKER_PIN_SVG_PATH_REGION;
        // Placeholder for reference to map instance
        this.map = {};
        // Set Map Type
        this.options.map.pulteMapType = 'filters';
        // Placeholder for marker list
        this.markerList = [];
        this.markers = [];
        this.regionMarkers = {};

        // Listeners for Feature filters
        this.$element.on('citySelectedTrigger', function (e, data) {
            _this.fetchFilter.call(_this, data);
        });
        // Region selected Listener
        this.$element.on('filterSelected', function (e, data) {
            _this.fetchFilter.call(_this, data);
        });

        this.$element.on('sorterSelected', function (e, data) {
            _this.updateMapMarkers(data);
        });

        if (window.Pulte.isMapScriptLoaded) {
            // Setup markers
            _this.setupMap();
        } else {

            $('body').on('MapScriptLoaded', function () {
                // Setup markers
                _this.setupMap();
                // TODO: need to manage the proper removal of this listener
            });
        };


        //Load all pins from Regions and States.
        this.$element.on('loadAllPinsRequest', this.loadAllPinsRequest.bind(this));
        this.$element.on('loadAllRegionPins', this.loadAllRegionPins.bind(this));
    };


    /**
     * setupMap: attach mapCore and listen for added map instance
     */
    this.setupMap = function () {
        var _this = this;
        // Set up map specific listener for when map is ready
        var listener = this.options.map.pulteMapType + 'MapInstanceAdded';

        this.$mapElement.on(listener, function (e, map) {
            // Store reference to returned map
            _this.map = map;
            // Setup markers
            _this.setupMarkers.call(_this);
            // Remove map instance listener
            $(this).off(listener);
            google.maps.event.addListener(_this.map, "click", function (e) {
                // Added marker check for clicks on maps with no markers
                if (_this.markerList.length) {
                    mapsHelper.closeAllInfoWindows.call(_this);
                    _this.resetMarkerSize.call(_this);
                }
            });
        });
        // Get instance of map
        mapsHelper.attachMapCore.call(this, MapCore);

        var mapSettings = {
            zoomControls: {
                position: google.maps.ControlPosition.LEFT_TOP
            }
        };

        _this.map.setOptions({
            zoomControl: true,
            draggable: true,
            zoomControlOptions: mapSettings.zoomControls
        });

    };

    this.updateMapMarkers = function (requestData) {

        var _this = this;
        var isQmi = (requestData.qmi === 'true');

        var url = window.location.origin;

        this.removeUndefinedProps(requestData);

        var requestMapMarkersData = JSON.parse(JSON.stringify(requestData));
        requestMapMarkersData.pageSize = 0;

        $.ajax({
            type: 'GET',
            url: url + '/api/marker/mapmarkers',
            data: requestMapMarkersData,
            success: function (response) {

                var exactMatchCount = 0;
                var closeMatchCount = 0;

                function evaluateColorChange(marker) {

                    var anchor = new google.maps.Point(92.334, 220);
                    var iconSvg = {
                        path: _this.MARKER_PIN_SVG_PATH,
                        fillColor: _this.CLOSE_MATCH_PIN_COLOR,
                        fillOpacity: _this.MARKER_PIN_SVG_OPACITY_WEIGHT,
                        scale: _this.MARKER_PIN_SVG_SIZE_SM,
                        strokeColor: _this.CLOSE_MATCH_PIN_COLOR,
                        strokeWeight: _this.MARKER_PIN_SVG_OPACITY_WEIGHT,
                        anchor: anchor
                    }
                    for (var i = 0; i < _this.markerList.length; i++) {
                        if (marker.Name === _this.markerList[i].name) {
                            // Find matching marker in markerList
                            if (marker.PinType !== _this.markerList[i].pinType) {
                                if (marker.PinType === 'exact') {
                                    _this.markerList[i].pinType = 'exact';
                                    iconSvg.fillColor = _this.EXACT_MATCH_PIN_COLOR;
                                    iconSvg.strokeColor = _this.EXACT_MATCH_PIN_COLOR;
                                }
                                else {
                                    _this.markerList[i].pinType = 'close';
                                    iconSvg.fillColor = _this.CLOSE_MATCH_PIN_COLOR;
                                    iconSvg.strokeColor = _this.CLOSE_MATCH_PIN_COLOR;
                                }
                                _this.markerList[i].setIcon(iconSvg);
                            }
                        }
                    }

                }

                for (var i = 0; i < response.length; i++) {
                    evaluateColorChange(response[i]);
                    if (response[i].PinType === 'exact') {
                        exactMatchCount++;
                    }
                    else {
                        closeMatchCount++;
                    }
                }
                if (isQmi) {
                    $MapFooter.trigger('fhMapFooter.updateMatchCount', { "exactMatchQMICommunity": exactMatchCount });
                } else {
                    $MapFooter.trigger('fhMapFooter.updateMatchCount', { "exactMatch": exactMatchCount });
                }
                $('.FindHomes').trigger('fhSearchComplete', { searchType: 'updatePinsComplete', resultsCount: response.length });
            }
        });

    }

    this.removeUndefinedProps = function (obj) {
        for (var prop in obj) {
            if (obj[prop] === undefined) {
                delete obj[prop];
            }
            if (obj[prop] === '') {
                delete obj[prop];
            }
        }
    }

    /**
     * get the service
     *
     */
    this.fetchFilter = function (filterSelected) {
        var flag = filterSelected.flag,
            data = filterSelected.data,
            brand = filterSelected.brand,
            state = filterSelected.state,
            region = filterSelected.region,
            qmi = filterSelected.qmi,
            url = window.location.origin,
            params = 'brand=' + brand + '&state=' + state + '&region=' + data + '&qmi=' + qmi,
            reloadRegions = (filterSelected.region !== currentRegion);

        currentRegion = data;

        if (flag !== 'region') {
            params = 'brand=' + brand + '&state=' + state + '&region=' + region + '&cityNames=' + data + '&qmi=' + qmi;
            currentRegion = region;
        }

        var _this = this;
        var isQmi = (filterSelected.qmi === 'true');

        /*
         * this $.ajax function setup the map
         *
        */

        // if we are in a region, load communities
        if (region) {
            $.ajax({
                context: this,
                url: url + '/api/marker/mapmarkers?' + params,
                success: function (response) {
                    if (isQmi) {
                        $MapFooter.trigger('fhMapFooter.updateMatchCount', { "totalMatchQMICommunity": response.length });
                    } else {
                        $MapFooter.trigger('fhMapFooter.updateMatchCount', { "totalMatch": response.length })
                    }
                    if (reloadRegions) this.loadAllPinsRequest();
                    var self = this;
                    setTimeout(function () {
                        if (response.length > 0) self.mapSuccess(response)
                        $('.FindHomes').trigger('fhSearchComplete', { searchType: 'drawPinsComplete', resultsCount: response.length });
                    }, 300);
                }
            });
        } else { // if not, only load regions

            // load regions for state
            $.ajax({
                context: this,
                url: url + '/api/marker/mapregionmarkersbystate?brand=' + brand + '&state=' + state,
                success: function (response) {
                    this.loadAllPinsRequest();
                    var self = this;
                    setTimeout(function () {
                        self.mapSuccess(response)
                        $('.FindHomes').trigger('fhSearchComplete', { searchType: 'drawPinsComplete', resultsCount: response.length });
                    }, 300);
                }
            });

        }
    }

    /*
     * ajax mapSuccess success response
     * @param {response} response
     *
    */
    this.mapSuccess = function (response) {
        var _this = this;
        if (response) _this.markers = response;
        // clear the previous markers list
        _this.markerList = [];
        var map;
        var mapSettings = {
            mapStyles: this.options.map.styles,
            mapZoom: _this.options.map.defaults.default_zoom,
            pulteMapType: _this.options.map.pulteMapType,
            mapCenter: {
                lat: _this.options.lat,
                lng: _this.options.lng
            },
            zoomControls: {
                position: google.maps.ControlPosition.LEFT_TOP
            }
        };
        var mapOptions = {
            keyboardShortcuts: false,
            zoomControl: true,
            scaleControl: false,
            disableDefaultUI: true,
            disableDoubleClickZoom: true,
            center: mapSettings.mapCenter,
            zoom: mapSettings.mapZoom,
            styles: mapSettings.mapStyles,
            draggable: true,
            scrollwheel: false,
            zoomControlOptions: mapSettings.zoomControls,
            pulteMapType: mapSettings.pulteMapType // custom key
        };

        map = new google.maps.Map(document.getElementById('mp-id'), mapOptions);
        // reset map flag to create a new one in this context
        _this.map = map;
        google.maps.event.addListener(_this.map, "click", function (e) {
            // Added marker check for clicks on maps with no markers
            if (_this.markerList.length) {
                mapsHelper.closeAllInfoWindows.call(_this);
                _this.resetMarkerSize.call(_this);
            }
        });

        //setup the new .ajax map
        _this.setupMarkers.call(_this);
    };

    this.renderMarkerOptions = function (markersArray, fromPinType) {
        var _this = this;
        markersArray.each(function (i, el) {
            var marker = el;
            var position = mapsHelper.getItemPosition(marker.Latitude, marker.Longitude);
            var anchor = new google.maps.Point(92.334, 220);

            var iconSvg = {
                path: _this.MARKER_PIN_SVG_PATH,
                fillOpacity: _this.MARKER_PIN_SVG_OPACITY_WEIGHT,
                scale: _this.MARKER_PIN_SVG_SIZE_SM,
                strokeWeight: _this.MARKER_PIN_SVG_OPACITY_WEIGHT,
                anchor: anchor
            };

            // Set the pin color based on the pin type
            if (marker.PinType === 'exact') {
                iconSvg.fillColor = _this.EXACT_MATCH_PIN_COLOR;
                iconSvg.strokeColor = _this.EXACT_MATCH_PIN_COLOR;
            }
            else if (fromPinType === 'region' || marker.PinType === 'region') {
                iconSvg.fillColor = _this.REGION_PIN_COLOR;
                iconSvg.strokeColor = _this.REGION_PIN_COLOR;
                iconSvg.path = _this.MARKER_PIN_SVG_PATH_REGION;
            }
            else {
                iconSvg.fillColor = _this.CLOSE_MATCH_PIN_COLOR;
                iconSvg.strokeColor = _this.CLOSE_MATCH_PIN_COLOR;
            }


            var formatedPrice = mapsHelper.formatNumber(marker.StartingFromPrice);
            var mapMkr = new google.maps.Marker({
                position: position,
                map: _this.map,
                icon: iconSvg,
                name: marker.Name,
                templateType: _this.options.type,
                pinType: marker.PinType,
                CommunityLink: marker.CommunityLink,
                maxBedrooms: marker.MaxBedrooms,
                minBedrooms: marker.MinBedrooms,
                maxBathrooms: marker.MaxBathrooms,
                minBathrooms: marker.MinBathrooms,
                minGarage: marker.MinGarage,
                maxGarage: marker.MaxGarage,
                startingFromPrice: formatedPrice,
                inventoryCount: marker.InventoryCount,
                priceStatus: marker.PriceStatus,
                communityStatus: marker.CommunityStatus,
                address: marker.Address,
                brandName: marker.BrandName,
                phone: marker.Phone,
                ctaText: marker.CtaText,
                ctaQmiText: marker.CtaQmiText,
                region: marker.Region,
                DisplayGoogleDirections: marker.DisplayGoogleDirections
            });
            if (fromPinType === 'region' || fromPinType === 'state') {
                mapMkr.kind = fromPinType;
            }
            // Update the maker data to reflect new Google Maps formated position
            marker.position = position;
            // Store updated marker
            _this.markerList.push(mapMkr);
            mapsHelper.makeInfoWindow.call(_this, mapMkr);

            //if ((initialGeolocation === "True" || siteState !== "Select a State")) {

            _this.addMarkerAjaxClickEvent.call(_this, mapMkr, fromPinType);

            //}else {
            //    _this.addMarkerClickEvent.call(_this, mapMkr);
            //}

            mapsHelper.styleInfoWindows.call(_this, mapMkr);

        });
    }

    /**
    * Setup and add markers to map
    */
    this.setupMarkers = function () {
        var _this = this,
            arrayToCluster = [];

        this.renderMarkerOptions($(_this.markers), 'pin');
        if (!$.isEmptyObject(_this.regionMarkers)) {
            this.renderMarkerOptions($(_this.regionMarkers), 'region');
        }
        // create MarkerClusterer custom icon
        var Symbol = function (width, height, fill) {

            return ('data:image/svg+xml;base64,' + window.btoa('<svg xmlns="http://www.w3.org/2000/svg" height="' + height + '" viewBox="0 0 512 512" width="' + width + '" >'
			+ '<path transform="translate(136,40)" stroke="#d0e2f8" stroke-width="6" fill="' + fill + '" d="' + _this.MARKER_PIN_SVG_PATH_GROUP + '" />'
			+ '<path transform="translate(104,48)" stroke="#d0e2f8" stroke-width="6" fill="' + fill + '" d="' + _this.MARKER_PIN_SVG_PATH_GROUP + '" />'
			+ '<path transform="translate(72,56)" stroke="#d0e2f8" stroke-width="6" fill="' + fill + '" d="' + _this.MARKER_PIN_SVG_PATH_GROUP + '" />'
			+ '</svg>'));

        }

        // MarkerClusterer style Options
        var mcOptions = {
            gridSize: 35,
            maxZoom: 60,
            styles: [
                {
                    width: 50,
                    height: 50,
                    url: Symbol(80, 80, _this.CLUSTER_PIN_COLOR),
                    fontFamily: 'Arial',
                    textSize: '12',
                    textColor: 'white'
                }
            ]
        }

        // create groups of markers when it's too close
        $(_this.markerList).each(function (index, item) {
            if ((!item.hasOwnProperty('kind') && item.pinType !== 'region' && clusterCommunities === 'True')) {
                arrayToCluster.push(item);
            }
            if (item.pinType === 'region' && clusterRegions === 'True') {
                arrayToCluster.push(item);
            }
        });
        var mc = new MarkerClusterer(_this.map, arrayToCluster, mcOptions);
        // Recenter map to include all markers
        this.fitToBounds.call(_this, _this.markers);
    };

    /**
     * addMarkerClickEvent: Attach click event to get a new URL
     * @param {Object} mapMkr - Google Maps Object containing marker data
     */
    this.addMarkerClickEvent = function (mapMkr) {
        var _this = this;
        google.maps.event.addListener(mapMkr, 'click', function (e) {
            window.location.href = this.CommunityLink;
        });
    };

    /**
     * addMarkerClickEvent: Attach click event to get a new URL
     * @param {Object} mapMkr - Google Maps Object containing marker data
     */
    this.addMarkerAjaxClickEvent = function (mapMkr, fromPinType) {
        var _this = this;
        google.maps.event.addListener(mapMkr, 'click', function (e) {
            // - Make all markers small
            _this.resetMarkerSize.call(_this);
            var anchor = new google.maps.Point(92.334, 220);

            var iconSvg = {
                path: _this.MARKER_PIN_SVG_PATH,
                fillOpacity: _this.MARKER_PIN_SVG_OPACITY_WEIGHT,
                scale: _this.MARKER_PIN_SVG_SIZE_LG,
                strokeColor: _this.EXACT_MATCH_PIN_COLOR,
                strokeWeight: _this.MARKER_PIN_SVG_OPACITY_WEIGHT,
                anchor: anchor
            }


            // Set the pin color based on the pin type
            if (this.pinType === 'exact') {
                iconSvg.fillColor = _this.EXACT_MATCH_PIN_COLOR;
                iconSvg.strokeColor = _this.EXACT_MATCH_PIN_COLOR;
            } else {
                iconSvg.fillColor = _this.CLOSE_MATCH_PIN_COLOR;
                iconSvg.strokeColor = _this.CLOSE_MATCH_PIN_COLOR;
            }
            if (fromPinType === 'region' || this.pinType === 'region') {
                iconSvg.fillColor = _this.REGION_PIN_COLOR;
                iconSvg.strokeColor = _this.REGION_PIN_COLOR;
                iconSvg.path = _this.MARKER_PIN_SVG_PATH_REGION;
            }

            this.setIcon(iconSvg);
            // Close any open infoWindows
            mapsHelper.closeAllInfoWindows.call(_this);
            // Open this markers infoWindow
            this.setZIndex(999);
            this.iw.open(_this.map, this);

            $('.gm-style-iw').addClass('openIW');
        });
    };

    this.resetMarkerSize = function () {
        var _this = this;
        $(this.markerList).each(function (i) {
            // Marker icon object
            var anchor = new google.maps.Point(92.334, 220);
            var iconSvg = {
                path: _this.MARKER_PIN_SVG_PATH,
                fillColor: _this.EXACT_MATCH_PIN_COLOR,
                fillOpacity: _this.MARKER_PIN_SVG_OPACITY_WEIGHT,
                scale: _this.MARKER_PIN_SVG_SIZE_SM,
                strokeColor: _this.EXACT_MATCH_PIN_COLOR,
                strokeWeight: _this.MARKER_PIN_SVG_OPACITY_WEIGHT,
                anchor: anchor
            }

            // Set the pin color based on the pin type
            if (_this.markerList[i].pinType === 'exact') {
                iconSvg.fillColor = _this.EXACT_MATCH_PIN_COLOR;
                iconSvg.strokeColor = _this.EXACT_MATCH_PIN_COLOR;
            }
            else if (_this.markerList[i].pinType === 'region') {
                iconSvg.fillColor = _this.REGION_PIN_COLOR;
                iconSvg.strokeColor = _this.REGION_PIN_COLOR;
                iconSvg.path = _this.MARKER_PIN_SVG_PATH_REGION;
            }
            else {
                iconSvg.fillColor = _this.CLOSE_MATCH_PIN_COLOR;
                iconSvg.strokeColor = _this.CLOSE_MATCH_PIN_COLOR;
            }
            if (this.hasOwnProperty('kind')) {
                if (this.kind === 'region') {
                    iconSvg.fillColor = _this.REGION_PIN_COLOR;
                    iconSvg.strokeColor = _this.REGION_PIN_COLOR;
                    iconSvg.path = _this.MARKER_PIN_SVG_PATH_REGION;
                }
                else {
                    iconSvg.fillColor = _this.EXACT_MATCH_PIN_COLOR_STATE;
                    iconSvg.strokeColor = _this.EXACT_MATCH_PIN_COLOR_STATE;
                }

            }
            _this.markerList[i].setIcon(iconSvg);
        });

    };

    /**
     * fitToBounds: Update bounds of markers and recenter map
     */
    this.fitToBounds = function (markers) {
        var bounds = mapsHelper.getBounds(markers);
        this.map.fitBounds(bounds);
    };

    /**
    * loadAllPins: Load all the pins for Regions and States, then render them
    * if currentRegion, then do not load pins for that region
    */
    this.loadAllPinsRequest = function () {
        var self = this;
        //if (location.pathname !== "/" || utils.getCookie("currentState").length > 0 )
        //{
        $.ajax({
            context: this,
            url: window.location.origin + '/api/marker/mapregionmarkers?brand=' + this.brand + '&currentRegion=' + currentRegion,
            success: function (response) {
                self.regionMarkers = response;

                $(self.regionMarkers).each(function (index, item) {
                    item.kind = 'region';
                });
                self.renderMarkerOptions($(self.regionMarkers), 'region');
            }
        });
        //}
    }

    /* Loads only regions and re-centers map */
    /* TODO: this is similar to loadAllPinsRequest, could refactor to save code? */

    this.loadAllRegionPins = function () {

        var self = this;

        $.ajax({
            context: this,
            url: window.location.origin + '/api/marker/mapregionmarkers?brand=' + this.brand + '&currentRegion=' + currentRegion,
            success: function (response) {
                self.regionMarkers = response;

                $(self.regionMarkers).each(function (index, item) {
                    item.kind = 'region';
                });
                self.renderMarkerOptions($(self.regionMarkers), 'region');
                setTimeout(function () {
                    self.mapSuccess(response)
                    $('.FindHomes').trigger('fhSearchComplete', { searchType: 'regionPinsComplete', resultsCount: response.length });
                }, 300);
            }
        });
    }
}
module.exports = component(FiltersMapController);
