var component = require('../../../lib/js/component.js');

function HomeSummaryGlance() {

    this.initialize = function (element, options) {
        this.$element = $(element);
    };

    this.deeplink = function(event, val) {
        if($(event.target).hasClass('HomeDesignCompact-series')) {
            var planTab = this.$element.find('div.toggle-option[data-type=plan]'),
            galleryTab = this.$element.find('div.toggle-option[data-type=gallery]');

            if (planTab.length > 0) {
                planTab.click();
            }
            
            if (galleryTab.length > 0) {
                galleryTab.click();
            }
        }
    }
}

module.exports = component(HomeSummaryGlance);
