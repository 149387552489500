var component = require('../../../lib/js/component.js');
var AdobeLaunch = require('../../../lib/js/adobelaunch.js');

function QMISummaryListWithTitle() {

  this.initialize = function (element, options) {
    // get each qmi element in the module and push it to Adobe Launch for analytics
    var $qmiElements = this.$element.find('.HomeDesignSummary--qmi');

    AdobeLaunch.pushListingDisplayedEvent($qmiElements, AdobeLaunch.PLAN_LISTING_DISPLAYED_EVENT_NAME);
  };

}

module.exports = component(QMISummaryListWithTitle);
