var component = require('../../../lib/js/component.js');

function ConstructionProgress() {
  this.initialize = function (element, options) {
    this.$iconContainers = this.$element.find('.ConstructionProgress__icon-container');
    this.$iconLabels = this.$iconContainers.find('.ConstructionProgress__icon-label');
    // get the divs that have phase icon images
    this.$phaseIcons = this.$iconContainers.find('.ConstructionProgress__icon');

    // this is the class that adds stylization to the label of the currently active icon
    this.iconActiveClass = 'ConstructionProgress__icon-label--is-active';
    // get the border color from the data attribute that is set via sitecore
    this.activeBorderColor = this.$element.data('active-border-color');

    this.$iconContainers.on('show.bs.tab', this.toggleIconLabels.bind(this));
  }

  this.toggleIconLabels = function (e) {
    if (e && e.currentTarget) {
      var $currentTarget = $(e.currentTarget);
      // get the label of the icon clicked/toggled
      var $currentIconLabel = $currentTarget.find('.ConstructionProgress__icon-label')
      // get the sibling div of the icon label
      var $currentIconImgDiv = $currentTarget.find('.ConstructionProgress__icon')

      // remove icon active class from all icon labels
      this.$iconLabels.removeClass(this.iconActiveClass);
      // remove/hide the active border from all other icons
      this.$phaseIcons.css("border-color", "transparent")

      // set the border color of the sibling div to the sitecore border-color value
      $currentIconImgDiv.css("border-color", this.activeBorderColor);
      // add the icon active class to the icon label that was toggled/clicked
      $currentIconLabel.addClass(this.iconActiveClass);
    }
  }
}

module.exports = component(ConstructionProgress);
