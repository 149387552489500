var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');

function LeavePageModal() {

    this.initialize = function (element, options) {

        var $cta = this.$element.find(".LeavePageModal__button-submit");
        var $ctaGoBack = this.$element.find(".LeavePageModal__button-back");
        var leaveEventHref = null;
        var leaveEventTarget = null;

        $('a').not('.GlobalNav-v2 a').on('click', function(event) {
            var currentHref = event.currentTarget.href;
            if (currentHref && !currentHref.includes(window.location.href) && event.currentTarget.target != "_blank" && !currentHref.includes("mailto:")) {
                event.preventDefault();
                showModal();
                leaveEventHref = currentHref;
                leaveEventTarget = event.currentTarget;
            } 
        });
   
        $('.LeavePageModal').on("hidden.bs.modal", function () { 
            hideModal();
        });

        $cta.on('click', function(e) {
            hideModal();
            window.location = leaveEventHref;
        });

        $ctaGoBack.on('click', function(e) {
            hideModal();
        });
    
        function showModal() {
            $('.LeavePageModal').modal('show');
            $('.LeavePageModal__headline').trigger('focus');
        }

        function hideModal() {
            $('.LeavePageModal').modal('hide');
            $(leaveEventTarget).trigger("focus");
        }

        var dialog = document.querySelector('.LeavePageModal');
        var firstFocusableElement = dialog.querySelector('.LeavePageModal-close');
        var lastFocusableElement = dialog.querySelector('.LeavePageModal__button-back');
        
        dialog.addEventListener('keydown', function(e) {
            if(e.target == firstFocusableElement && e.key == 'Tab' && e.shiftKey) {
            e.preventDefault();
            lastFocusableElement.focus();
            } else if(e.target == lastFocusableElement && e.key == 'Tab' && !e.shiftKey) {
            e.preventDefault();
            firstFocusableElement.focus();
            }
        });
    }


}

 module.exports = component(LeavePageModal);
