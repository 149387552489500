var owl = require('owl.carousel');
var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');

function EditorialTestimonial() {

    this.initialize = function (element, options) {

        var $element = $(element),
            $owlCarousel = $element.find($('.owl-carousel')),
            itemCount = $element.find('.item').length,
            smItems = 2, mdItems = 3, lgItems = 4
            
        if (itemCount > 1) {
            $owlCarousel.addClass('xs-owl-nav-padding'); 
        }
        if (itemCount < 2) {
            smItems = itemCount;
        } else if (itemCount > 2) {
            $owlCarousel.addClass('sm-owl-nav-padding');
        }

        if (itemCount < 3) {
            mdItems = itemCount;
        } else if (itemCount > 3) {
            $owlCarousel.addClass('md-owl-nav-padding');
        }

        if (itemCount < 4) {
            lgItems = itemCount;
        } else if (itemCount > 4) {
            $owlCarousel.addClass('lg-owl-nav-padding');
        }

        var thisOwl = $owlCarousel.owlCarousel({
            loop: true,
            margin: 10,
            dots: false,
            nav: true,
            mouseDrag: false,
            touchDrag: false,
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: smItems
                },
                1025: {
                    items: mdItems
                },
                1920: {
                    items: lgItems
                }
            }
        })
        $(".owl-prev").html('<i class="icon-arrow-left"></i>');
        $(".owl-next").html('<i class="icon-arrow-right"></i>');

    }
}
module.exports = component(EditorialTestimonial);