var mapStyles = require('./map-styles.js');

/**
 * Map:     Set defaults to the Global Pulte Object.
 */
var Map = function Map () {
    // Flag for when Google Maps script has loaded.
    Pulte.isMapScriptLoaded = false;

    // Set defaults
    Pulte.map = {
        defaults: {
            // api_key: $('meta[name="googlemaps_api_key"]').attr('content'),
            default_zoom: 4,
            default_lat: 41.850033,
            default_lng: -95.6500523,
            MARKER_PIN_SVG_PATH: 'M92.334,0C41.339,0,0,41.343,0,92.334c0,27.854,12.336,52.83,31.843,69.759l60.491,60.491l60.489-60.489c19.508-16.929,31.845-41.904,31.845-69.761C184.668,41.343,143.329,0,92.334,0z M92.334,100.458c-5.983,0-10.833-4.85-10.833-10.833s4.85-10.833,10.833-10.833c5.982,0,10.833,4.85,10.833,10.833S98.316,100.458,92.334,100.458z',
            MARKER_PIN_SVG_PATH_GROUP: 'M92.334,0C41.339,0,0,41.343,0,92.334c0,27.854,12.336,52.83,31.843,69.759l60.491,60.491l60.489-60.489  c19.508-16.929,31.845-41.904,31.845-69.761C184.668,41.343,143.329,0,92.334,0z M92.334,100.458',
            MARKER_PIN_SVG_PATH_REGION: 'M95.1,0c-52.3,0-95,42.8-95,95c0,28,12.3,51.8,31.8,70.8l62.7,60.3l63.7-59.9c19.5-19.9,31.8-42.8,31.8-71.2 C190.1,42.8,147.3,0,95.1,0z M122.2,136.3H62.3V90.2l29.9-21.4l29.9,21.4V136.3z M126,84.1L92.7,59.9L59.5,84.1L54.7,77l38.5-27.6 L131.7,77L126,84.1z',
            MARKER_PIN_SVG_OPACITY_WEIGHT: 1,
            MARKER_PIN_SVG_SIZE_LG: 0.3,
            MARKER_PIN_SVG_SIZE_SM: 0.15,
            MARKER_PIN_SVG_SCALE_SELECTED: 1.35,
            MARKER_PIN_SVG_SCALE_UNSELECTED: 1,
            MARKER_TWO_TONE_PIN_PATH_ONE: 'm 150,195.8745 c -2.65437,-4.83403 -7.95836,-14.66582 -13.2113,-25.40978 -4.32744,-8.85103 -7.77221,-16.57605 -10.23858,-22.96052 C 123.53088,139.68863 122,133.96761 122,130.5 c 0,-3.70916 0.73844,-7.30663 2.1948,-10.69247 1.40808,-3.27357 3.42486,-6.21451 5.99429,-8.74113 2.57177,-2.5289 5.56671,-4.51446 8.90162,-5.9015 C 142.5446,103.72837 146.21501,103 150,103 c 3.78499,0 7.4554,0.72837 10.90929,2.1649 3.33491,1.38704 6.32985,3.3726 8.90162,5.9015 2.56943,2.52662 4.5862,5.46756 5.9943,8.74113 C 177.26155,123.19337 178,126.79084 178,130.5 c 0,3.46761 -1.53088,9.18863 -4.55011,17.0042 -2.46638,6.38447 -5.91115,14.10949 -10.23859,22.96052 -5.25294,10.74396 -10.55693,20.57575 -13.2113,25.40978 z',
            MARKER_TWO_TONE_PIN_PATH_TWO: 'm 150,105 c -3.52,0 -6.93202,0.67679 -10.14124,2.01155 -3.09744,1.28829 -5.87899,3.13231 -8.26739,5.4809 -2.38374,2.34401 -4.25415,5.07103 -5.55933,8.10534 C 124.68369,123.73256 124,127.06415 124,130.5 c 0,3.45515 1.89383,13.12537 14.57745,39.06989 4.25466,8.70295 8.54422,16.80751 11.42255,22.12564 2.87833,-5.31813 7.16789,-13.42269 11.42255,-22.12564 C 174.10617,143.62537 176,133.95515 176,130.5 c 0,-3.43585 -0.68369,-6.76744 -2.03204,-9.90221 -1.30518,-3.03431 -3.17559,-5.76133 -5.55933,-8.10534 -2.3884,-2.34859 -5.16995,-4.19261 -8.26739,-5.4809 C 156.93202,105.67679 153.52,105 150,105 m 0,-4 c 16.5685,0 30,13.20761 30,29.5 0,16.2924 -30,69.5 -30,69.5 0,0 -30,-53.2076 -30,-69.5 0,-16.29239 13.4315,-29.5 30,-29.5 z',
            COMMUNITY_PIN_PNG_URL: '/assets/pulte/img/map-community-pin.png'
        }
    };


    // Setting Brand map pin colors
    var activeBrand = Pulte.Brand ? Pulte.Brand.toLowerCase() : 'pulte';
    var formatedBrand = activeBrand.replace(/-/g, "");
    switch(formatedBrand) {
        case 'pulte':
            Pulte.map.defaults.EXACT_MATCH_PIN_COLOR = '#2181b3';
            Pulte.map.defaults.CLOSE_MATCH_PIN_COLOR = '#03314b';
            Pulte.map.defaults.REGION_PIN_COLOR = '#03314b';
            Pulte.map.defaults.CLUSTER_PIN_COLOR = '#2181b3';
            break;
        case 'centex':
            Pulte.map.defaults.EXACT_MATCH_PIN_COLOR = '#ed4030';
            Pulte.map.defaults.CLOSE_MATCH_PIN_COLOR = '#1a1a1a';
            Pulte.map.defaults.REGION_PIN_COLOR = '#1a1a1a';
            Pulte.map.defaults.CLUSTER_PIN_COLOR = '#ed4030';
            break;
        case 'delwebb':
            Pulte.map.defaults.EXACT_MATCH_PIN_COLOR = '#40b9da';
            Pulte.map.defaults.CLOSE_MATCH_PIN_COLOR = '#462900';
            Pulte.map.defaults.REGION_PIN_COLOR = '#462900';
            Pulte.map.defaults.CLUSTER_PIN_COLOR = '#40b9da';
            break;
        case 'divosta':
            Pulte.map.defaults.EXACT_MATCH_PIN_COLOR = '#19405a';
            Pulte.map.defaults.CLOSE_MATCH_PIN_COLOR = '#34a9ab';
            Pulte.map.defaults.REGION_PIN_COLOR = '#34a9ab';
            Pulte.map.defaults.CLUSTER_PIN_COLOR = '#19405a';
            break;
        case 'jw':
            Pulte.map.defaults.EXACT_MATCH_PIN_COLOR = '#002855';
            Pulte.map.defaults.CLOSE_MATCH_PIN_COLOR = '#8B634B';
            Pulte.map.defaults.REGION_PIN_COLOR = '#424242';
            Pulte.map.defaults.CLUSTER_PIN_COLOR = '#002855';
            break;
        case 'americanwest':
            Pulte.map.defaults.EXACT_MATCH_PIN_COLOR = '#002855';
            Pulte.map.defaults.CLOSE_MATCH_PIN_COLOR = '#8B634B';
            Pulte.map.defaults.REGION_PIN_COLOR = '#424242';
            Pulte.map.defaults.CLUSTER_PIN_COLOR = '#002855';
            break;
        default:
            // Pulte colors
            Pulte.map.defaults.EXACT_MATCH_PIN_COLOR = '#2181B3';
            Pulte.map.defaults.CLOSE_MATCH_PIN_COLOR = '#03314b';
            Pulte.map.defaults.REGION_PIN_COLOR = '#03314b';
            Pulte.map.defaults.CLUSTER_PIN_COLOR = '#757575';
    }
};

/**
 * Map.load:    - Make request to load Google Maps script
 * 				- Load component map controllers;
 */
Map.prototype = {
    load: function () {
        // Nearby Map:
        var NearbyMap = require('./map-nearby-controller.js');
        $('.Map--nearby').attach(NearbyMap, Pulte);

        // Directions Map:
        var DirectionsMap = require('./map-directions-controller.js');
        $('.Map--DirectionsMap').attach(DirectionsMap, Pulte);	
        
        var DirectionsMapV2 = require('./map-directions-controller-v2.js');
        $('.Map--DirectionsMapV2').attach(DirectionsMapV2, Pulte);	

        // Features Map:
        var FeaturesMap = require('./map-features-controller.js');
        $('.Map--FeaturesMap').attach(FeaturesMap, Pulte);

        // Filters Map:
        var FiltersMap = require('./map-filters-controller.js');
        $('.Map--FiltersMap').attach(FiltersMap, Pulte);

        // Local Area Map:
        var LocalAreaMap = require('./map-local-area-controller.js');
        $('.Map--LocalAreaMap').attach(LocalAreaMap, Pulte);

        // pulte embed script
        var script = "//maps.googleapis.com/maps/api/js?client=gme-pulte&v=3&libraries=places,geometry";
        $.getScript(script)
            .done(function() {
                // Set flag for other map components to use
                Pulte.isMapScriptLoaded = true;
                // Attach Brand Map styles
                var activeBrand = Pulte.Brand ? Pulte.Brand.toLowerCase() : 'pulte';
                var formatedBrand = activeBrand.replace(/-/g, "");

                Pulte.map.styles = mapStyles[formatedBrand];
                // Fire MapScriptLoaded event
                $('body').trigger('MapScriptLoaded');
            })
            .fail(function(err) {
                console.error("Error loading google maps script.");
                console.log("Error = ", err);
        });
    }
};

module.exports = Map;
