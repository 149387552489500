/*
 * Setup the print CTAs
 * https://github.com/DoersGuild/jQuery.print
*/

(function($) {

  var printElement;

  printElement = function( e ) {
    var $element = $(e.target.dataset.printElement || e.target.parentElement.dataset.printElement),
      $collapsibles = $element.find('.collapse:not(".in")'),
      collapsiblesCount = 0;

    //make sure the accordion panels are not collapsed
    if ($collapsibles.length > 0) {
      $collapsibles.on('shown.bs.collapse', function () {
        collapsiblesCount++;
        if ( collapsiblesCount === $collapsibles.length ) {
          $element.print();
        }
      });
      $collapsibles.collapse('show');
    } else {
      $element.print();
    }
  };


	$(document).on('click', "[data-print-element]", printElement);

})(jQuery);
