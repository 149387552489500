var owl = require('owl.carousel/dist/owl.carousel.js');
var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');
var ResponsiveMedia = require('../responsive-media/responsive-media.js');
var savedItemsService = require('../../../lib/js/savedItemsService.js');

function Carousel() {

    this.initialize = function (element, options) {

        // declare and set autoplay variables
        var isAutoplay = false;
        var autoplayTimeout = 0;
        var autoplayHoverPause = true;

        var _this = this,
        navIconLeft = "icon-arrow-left",
        navIconRight = "icon-arrow-right";

        _this.slidesAreLoaded = true;
        _this.$carouselPlaceholder = _this.$element.prev('.Carousel-placeHolder');
        _this.timer = 0;
        _this.carouselGuid = utils.generateGuid();

        _this.$element.on('carousel.attach-lazy-initialization', _this.attachLazyInitialization.bind(_this));
        _this.$element.on('carousel.remove-lazy-initialization', _this.removeLazyInitialization.bind(_this));
        var carouselRotationInterval = _this.$element.attr('data-carousel-rotation-interval');
        if (carouselRotationInterval > 0) {
            isAutoplay = true;
            autoplayTimeout = carouselRotationInterval;
        } 
        else{
           isAutoplay = false;
        }
        
        if (_this.$element.hasClass('Carousel--lazy')) _this.slidesAreLoaded = false;

        if ($(_this.element).hasClass('Carousel--buttonSmall') || $(_this.element).hasClass('Carousel--buttonSP')) {
            navIconLeft = "icon-arrow-left";
            navIconRight = "icon-arrow-right";
        }

        if (_this.$element.hasClass('Carousel--hero')) {
            navIconLeft = "icon-arrow-light-left";
            navIconRight = "icon-arrow-light-right";
        }

        // define nav after icon conditions are defined
        var navText = [
            '<span class="sr-only">Previous</span><i class="' + navIconLeft + '"></i>',
            '<span class="sr-only">Next</span><i class="' + navIconRight + '"></i>'
        ];

        if (!_this.slidesAreLoaded) {

            /* hideCarouselImagesUntilSrcLoad(); */

            // load first image in carousel
            var $firstImage = _this.$element.find('.Carousel-slide img').first();
            var $crossBrandBannerImage = _this.$element.find('.cross-brand-banner img');

            if ($crossBrandBannerImage.length) {
                $crossBrandBannerImage.trigger("RESPONSIVE_LAZYLOAD");
                $crossBrandBannerImage.cloudinary_update();
            }

            $firstImage.trigger("RESPONSIVE_LAZYLOAD");
            $firstImage.cloudinary_update();

            $.cloudinary.responsive();

        }

        _this.options = $.extend({
            // http://smashingboxes.github.io/OwlCarousel2/docs/api-options.html
            items: 1,
            navSpeed: 500,
            dotsSpeed: 1,
            onInitialized: onInitialized,
            loop: true,
            nav: true,
            navText: navText,
            autoplay: isAutoplay,
            autoplayTimeout: autoplayTimeout,
            autoplayHoverPause: autoplayHoverPause,
            nestedItemSelector: 'Carousel-slide'
        }, options, _this.$element.data());

        /*
        $(document).ready(function() {
            // attach lazy initialization logic to the carousel
            if (_this.$element.hasClass('Carousel--lazyInitialize')) {
                if (_this.$carouselPlaceholder.is(':visible')) {
                    _this.attachLazyInitialization();
                } else {
                    // activate the interval timer that will periodically check to see if the Carousel is visible
                    _this.activateIntervalTimer();
                }
            } else {
                _this.initializeCarousel();
            }
        });
        */

        /**
         * Find the items inside the carousel to asign a new class
         * when the images comes in portrait mode
         }
        * @param  {Object} elem HTML element
        */
        function onInitialized(elem) {
            utils.changeVideoProtocol(elem); //behrreich 20161205 114805

            var items = elem.relatedTarget._items;
            $(items).each(function (index, elem) {
                var image = $(elem).find('img');
                // basic image size validation
                $(image).each(function (index, elem) {
                    if (elem.naturalHeight > elem.naturalWidth) {
                        $(elem).addClass('vertical-image');
                    }
                });
            });

            _this.$element.prev('.Carousel-placeHolder').css('display', 'none');
            _this.$element.addClass('is-owl-initialized');

            savedItemsService.setChildSavedState(_this.element);

            // lazy load second image in carousel
            /*
            
            setTimeout(function() {
                var $secondImage = _this.$element.find('.owl-item:not(.cloned) img').eq(1);
                $secondImage.trigger("RESPONSIVE_LAZYLOAD");
                $secondImage.on('load', function() {
                    $(this).css('display', 'block');
                });
                $secondImage.cloudinary_update();
                $.cloudinary.responsive();
            }, 2000);
            
            */
            

            // Add the custom play button to any videos in a carousel that is intended to trigger an expanded gallery that belongs to another carousel
            var slideshowId = $(elem.currentTarget).data('external-slideshow-id');
            var carouselModalTrigger = $(elem.currentTarget).find('.Carousel-modalTrigger');
            if ((slideshowId != null && slideshowId.length > 0) || (carouselModalTrigger != undefined && carouselModalTrigger.length > 0)) {
                var externalPlayButtons = $(elem.currentTarget).find('.owl-video-play-icon');
                externalPlayButtons.each(function(index) {
                    $(this.parentElement).append('<div class="btn-play-video"></div>');
                    $(this).addClass('hasModalGallery');
                });
            }

            // Trigger an event on the lead form slide and pass in the cloned lead form slide
            var leadFormSlideClone = _this.$element.find('.owl-item.cloned .LeadFormSlide');

            if (leadFormSlideClone.length) {
                _this.$element.find('.owl-item:not(.cloned) .LeadFormSlide').trigger("lead-form-slide-clone-loaded", leadFormSlideClone);
            }

            _this.$element.find('.owl-nav .owl-prev').attr('data-analytics-cta', 'Photo Scrolled');
            _this.$element.find('.owl-nav .owl-next').attr('data-analytics-cta', 'Photo Scrolled');
        }

        // This is required to fix issue with IE11 height calculations causing next/prev arrows to move locations
        function hideCarouselImagesUntilSrcLoad() {
            _this.$element.find('.Carousel-slide img').each(function(index, elem) {
                if (index > 0) { // let first image load normally
                    $(this).css('display', 'none'); // hide all images until load
                }
            });
        }

        // load all images in carousel and related slide show on any interaction
        _this.$element.on('changed.owl.carousel', function (event) {
            if(event.property && event.property.name == 'position'){ //only listen to the position changed event
                if (!_this.slidesAreLoaded) {

                    var items = _this.$element.find('.owl-item');
    
                    items.each(function() {
                        $responsiveImg = $(this).find('img.u-responsiveMedia');
    
                        // Cloned items are not in DOM at page load and not processed by responsive media
                        if ($(this).hasClass('cloned')) {
                            $responsiveImg.attach(ResponsiveMedia);
                        }
    
                        $responsiveImg.trigger("RESPONSIVE_LAZYLOAD");
                        $responsiveImg.on('load', function() {
                            $(this).css('display', 'block');
                        });
                    });
    
                    if(items.length > 0) {
                        _this.$element.cloudinary_update();
                        $.cloudinary.responsive();
    
                        // find all images in related slideshow and load
                        $(this).closest('.owl-carousel').parent().find('.SlideshowCarousel').trigger('slideshow.load.slides');
    
                        _this.slidesAreLoaded = true;
                    }
    
                }
            }

        });

        _this.initializeCarousel();
    }

    // Attach lazy initialization scroll event if the carousel is below the view port and hasn't been initialized
    this.attachLazyInitialization = function() {
        if (!this.$element.hasClass('is-owl-initialized')) {
            if (utils.isBelowViewport(this.$carouselPlaceholder)) {
                $(window).on('scroll.' + this.carouselGuid, this.checkViewportLazyInitialize.bind(this));
            } else {
                this.initializeCarousel();
            }
        }
    }

    // Remove lazy initialization if the owl carousel is not yet initialized
    this.removeLazyInitialization = function() {
        if (!this.$element.hasClass('is-owl-initialized')) {
            $(window).off('scroll.' + this.carouselGuid);
        }
    }

    // Clear the interval timer and attach lazy initialization if the Carousel is visible
    this.checkVisibilityAttachLazyInitialize = function() {
        if (this.$carouselPlaceholder.is(':visible')) {
            clearInterval(this.intervalTimer);
            this.attachLazyInitialization();
        }
    }

    // Initialize the carousel and remove scroll event if carousel is no longer below view port
    this.checkViewportLazyInitialize = function() {
        var _this = this;

        if (this.timer) {
            clearTimeout(this.timer);
        }

        if (this.$carouselPlaceholder.is(':visible')) {
            // Use a buffer so we don't call the below viewport check too often.
            this.timer = setTimeout(function() {
                if (!utils.isBelowViewport(_this.$carouselPlaceholder)) {

                    _this.initializeCarousel();

                    $(window).off('scroll.' + _this.carouselGuid);
                }
            }, 100);
        } else {
            this.removeLazyInitialization();
            this.activateIntervalTimer();
        }
    }

    this.activateIntervalTimer = function() {
        // Calling method that checks if the Carousel has become visible every 500ms
        this.intervalTimer = setInterval(this.checkVisibilityAttachLazyInitialize.bind(this), 500);
    }

    // Initialize the owl carousel
    this.initializeCarousel = function() {
        this.carousel = this.$element.owlCarousel(this.options);
    }
}

module.exports = component(Carousel);

//Override for owl carousel video play
//behrreich 20161206 114805  Change protocol for iframe

(function ($, window, document, undefined) {

    var _temp = $.fn.owlCarousel.Constructor.Plugins.Video;

    $.fn.owlCarousel.Constructor.Plugins.Video.prototype.play = function (event) {
        var target = $(event.target),
        item = target.closest('.' + this._core.settings.itemClass),
        video = this._videos[item.attr('data-video')],
        width = video.width || '100%',
        height = video.height || this._core.$stage.height(),
        html;

        if (this._playing) {
            return;
        }

        this._core.enter('playing');
        this._core.trigger('play', null, 'video');

        item = this._core.items(this._core.relative(item.index()));

        this._core.reset(item.index());

        var baseUrl = '';
        if (video.type === 'youtube') {
            html = '<iframe width="' + width + '" height="' + height + '" src="//www.youtube-nocookie.com/embed/' +
				video.id + '?autoplay=1&v=' + video.id + '" frameborder="0" allowfullscreen></iframe>';
        } else if (video.type === 'vimeo') {
            html = '<iframe src="//player.vimeo.com/video/' + video.id +
				'?autoplay=1" width="' + width + '" height="' + height +
				'" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>';
        }

        $('<div class="owl-video-frame">' + html + '</div>').insertAfter(item.find('.owl-video'));

        this._playing = item.addClass('owl-video-playing');
    }

})(window.Zepto || window.jQuery, window, document);
