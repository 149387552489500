var component = require('../../../lib/js/component.js');

function CommunityLeadForm() {
    this.initialize = function (element) {
        var _this = this;
        _this.$communityLeadFormGetDirectionsCta = _this.$element.find(".CommunityLeadForm__getDirections-cta");
        _this.$communityLeadFormPhoneNumberCta = _this.$element.find(".CommunityLeadForm__phoneNumber-cta");
        _this.$element.on("click", ".CommunityLeadForm__availableHoursToggle", function (event) {
            var seeHoursLabel = _this.$element.find(".CommunityLeadForm__seeHoursLabel");
            var closeHoursLabel = _this.$element.find(".CommunityLeadForm__closeHoursLabel");
            var hoursList = _this.$element.find(".CommunityLeadForm__hours-info-container");
            seeHoursLabel.toggleClass("d-none");
            closeHoursLabel.toggleClass("d-inline");
            hoursList.toggle("fast");
        });
        var communityId = _this.$element.find("input[name=CommunityIds]");
        var directionsMapModalVersion = _this.find("form[name=CommunityLeadForm]").data("directionsMapVersion")
        if (communityId && _this.$communityLeadFormGetDirectionsCta) {
            _this.$communityLeadFormGetDirectionsCta.attr('data-target', "." + directionsMapModalVersion + "[data-community-id='" + communityId.val() + "']");
        }

        if (_this.$communityLeadFormPhoneNumberCta) {
            var phoneNumber = _this.$communityLeadFormPhoneNumberCta.data("phone");
            _this.$communityLeadFormPhoneNumberCta.attr('href', 'tel:' + phoneNumber);
        }

    }
}

module.exports = component(CommunityLeadForm);
