var component = require('../../../lib/js/component.js');
var Carousel = require('../../../templates/components/carousel/carousel');
var ResponsiveMedia = require('../../../templates/components/responsive-media/responsive-media');
var Tooltip = require('../../../templates/components/tooltip/tooltip');
var SlideShow = require('../../../templates/components/slideshow/slideshow');
var savedItemsService = require('../../../lib/js/savedItemsService.js');
var AdobeLaunch = require('../../../lib/js/adobelaunch.js');
var deeplink = require('../../../lib/js/deeplink.js');

function GridContainer() {

    this.initialize = function (element, options) {
        var _this = this;
        this.apiUrl = window.location.origin;
        var overrideApiUrl = $('body').data('api-url');
        if (overrideApiUrl && overrideApiUrl.length) {
          this.apiUrl = overrideApiUrl;
        }
        this.$qmiGrid = this.$element.find('.GridItem__qmi');
        this.$communityGrid = this.$element.find('.GridItem__community');
        this.$productsLoadingSpinner = this.$element.find( ".GridItems-productsLoading" );
        this.$prevArrow = this.$element.find('.GridItems__buttonArrow-left');
        this.$nextArrow = this.$element.find('.GridItems__buttonArrow-right');
        this.$GridCount = this.$element.find('.GridItems__count');
        this.$GridCountButtonContainer = this.$element.find('.GridItems__buttonContainer');
        this.hasExperienceMessage = $('.ExperienceMessage').length ? true : false;

        this.communityPageNumber = 1;
        this.qmiPageNumber = 1;
        this.totalCommunities = 0;
        this.totalQMIs = 0;
        this.exactMatchCommunitiesCount = 0;
        this.exactMatchCountsUpdated = false;
        this.communityMaxPageCount = 0;
        this.qmiMaxPageCount = 0;

        if ($('.pfDisplayMode__button[data-display-mode="list"]').length) {
            $('.pfDisplayMode__button[data-display-mode="list"]').html('Grid');
        }

        $('.ProductFilter').addClass('ProductFilter--hasGrid');

        this.data = {
			"brand": "",
			"state": "",
			"region": "",
			"cityNames": "",
			"minPrice": "",
			"maxPrice": "",
			"minBedrooms": "",
			"maxBedrooms": "",
			"minBathrooms": "",
            "maxBathrooms": "",
            "homeType": "",
            "productType": "",
			"pageSize": 8,
            "pageNumber": 0,
            "dataSource": ""
        }
        
        this.data.brand = $('body').data('brand');
        this.data.state = this.$element.data('state');
        this.data.region = this.$element.data('region');
        this.data.cityNames = this.$element.data('cities');
        this.data.dataSource = this.$element.data('grid-datasource');

        this.$prevArrow.on('click touch', function() {
            _this.$GridCount.empty();
            if (_this.data.productType === "qmi") {
                _this.qmiPageNumber = _this.qmiPageNumber - 1;
                if(_this.qmiPageNumber <= 0) {
                    _this.qmiPageNumber = _this.qmiMaxPageCount;
                }

                _this.$GridCount.text((_this.qmiPageNumber + '/' + _this.qmiMaxPageCount));
                _this.GetMoreGridQmis();
             } else {
                _this.communityPageNumber = _this.communityPageNumber - 1;
                if(_this.communityPageNumber <= 0) {
                    _this.communityPageNumber = _this.communityMaxPageCount;
                }

                _this.$GridCount.text((_this.communityPageNumber + '/' + _this.communityMaxPageCount));
                _this.GetMoreGridCommunities();
             }
        });

        this.$nextArrow.on('click touch', function() {
            _this.$GridCount.empty();
            if (_this.data.productType === "qmi") {
                _this.qmiPageNumber++;
                if(_this.qmiPageNumber > _this.qmiMaxPageCount) {
                    _this.qmiPageNumber = 1;
                    _this.$GridCount.text((_this.qmiPageNumber + '/' + _this.qmiMaxPageCount));
                } else {
                    _this.$GridCount.text((_this.qmiPageNumber + '/' + _this.qmiMaxPageCount));
                }
                _this.GetMoreGridQmis();
             } else {
                _this.communityPageNumber++;
                if(_this.communityPageNumber > _this.communityMaxPageCount) {
                    _this.communityPageNumber = 1;
                    _this.$GridCount.text((_this.communityPageNumber + '/' + _this.communityMaxPageCount));
                } else {
                    _this.$GridCount.text((_this.communityPageNumber + '/' + _this.communityMaxPageCount));
                }
                _this.GetMoreGridCommunities();
             }
        });

        this.findTotals();
        this.setExperienceModalButtons();
        this.$productsLoadingSpinner.hide();

        this.$element.on("pf-change", function(event, pfData) {
            switch(pfData.changeType) {
                case "product":
                    _this.data.productType = pfData.productType;
                    break;

                case "filter":
                    _this.data.minPrice = pfData.minPrice;
                    _this.data.maxPrice = pfData.maxPrice;
                    _this.data.minBedrooms = pfData.minBedrooms;
                    _this.data.maxBedrooms = pfData.maxBedrooms;
                    _this.data.minBathrooms = pfData.minBathrooms;
                    _this.data.maxBathrooms = pfData.maxBathrooms;
                    _this.data.homeType = pfData.homeType;
                    _this.exactMatchCountsUpdated = false;
                    break;

                case "location":
                    _this.data.state = pfData.state;
                    _this.data.region = pfData.region;
                    _this.data.cityNames = pfData.cities;
                    _this.exactMatchCountsUpdated = false;
                    break;
            }

            _this.findTotals();

            if (_this.data.productType === "qmi") {
                _this.ShowQmiGrid();
            } else {
                _this.showCommunityGrid();
            }
        });

        this.on('productsReceived', function() {
            this.find('img.u-responsiveMedia:not([src])').attach(ResponsiveMedia);
            this.find('.Carousel:not(.is-initialized,.SlideshowCarousel-main)').attach(Carousel);
            this.find('.Slideshow:not(.is-initialized)').attach(SlideShow);
            this.find("[data-toggle='popover']").popover({html:true});
            this.find(".PopoverBtn:not(.is-initialized)").attach(Tooltip);
        });

        this.$element.on('grid.show-qmi', this.ShowQmiGrid.bind(this));
        this.$element.on('grid.show-community', this.showCommunityGrid.bind(this));

        // wait for deeplink processing to finish
        deeplink.deeplinkProcessed.then(function() {
            // if product type isn't already set to qmi, then load the communities
            if (_this.data.productType !== 'qmi') {
                _this.showCommunityGrid();
            }
        });
    }

    this.findTotals = function() {
        $.ajax({
            type: 'GET',
            context: this,
            url: this.apiUrl + '/api/map/search?' + 'brand=' + this.data.brand + '&state=' + this.data.state + '&region=' + this.data.region + '&cityNames=' + this.data.cityNames,
            success: function(response) {
                this.totalCommunities = response.CommunityCount;
                this.totalQMIs = response.QmiCount;
                this.communityMaxPageCount = Math.ceil(this.totalCommunities/8);
                this.qmiMaxPageCount = Math.ceil(this.totalQMIs/8);

                if(this.data.productType == "qmi") {
                    if(this.totalQMIs <= 8){
                        this.$GridCount.empty();
                        this.$GridCountButtonContainer.hide();
                    } else {
                        this.$GridCount.text((this.qmiPageNumber + '/' + this.qmiMaxPageCount));
                        this.$GridCountButtonContainer.show();
                    } 
                } else {
                    if(this.totalCommunities <= 8) {
                        this.$GridCount.empty();
                        this.$GridCountButtonContainer.hide();
                    } else {
                        this.$GridCount.text((this.communityPageNumber + '/' + this.communityMaxPageCount));
                        this.$GridCountButtonContainer.show();
                    }
                }
            }
        });
    }

    this.showCommunityGrid = function(){
        this.$qmiGrid.hide();
        this.$qmiGrid.empty();
        this.$communityGrid.empty();
        this.$communityGrid.show();

        if(this.totalCommunities <= 8){
            this.$GridCount.empty();
            this.$GridCountButtonContainer.hide();
        } else {
            this.$GridCount.text((this.communityPageNumber + '/' + this.communityMaxPageCount));
            this.$GridCountButtonContainer.show();
        }
        this.GetMoreGridCommunities();
    }

    this.ShowQmiGrid = function() {
        this.qmiPageNumber = 1;
        this.$communityGrid.empty();
        this.$qmiGrid.empty();
        this.$qmiGrid.show();
        if(this.totalQMIs <= 8){
            this.$GridCount.empty();
            this.$GridCountButtonContainer.hide();
        } else {
            
            this.$GridCount.text((this.qmiPageNumber + '/' + this.qmiMaxPageCount));
            this.$GridCountButtonContainer.show();
        }
        this.GetMoreGridQmis();
    }

    this.GetMoreGridCommunities = function() {
        this.data.pageSize = 8;
        this.data.pageNumber = this.communityPageNumber - 1;
        var url = this.apiUrl + '/api/Community/GridCommunitySearch?';
        var params = 'brand=' + this.data.brand + '&state=' + this.data.state + '&region=' + this.data.region + '&cityNames=' + this.data.cityNames
        + '&minPrice=' + this.data.minPrice + '&maxPrice=' + this.data.maxPrice + '&minBedrooms=' + this.data.minBedrooms + '&maxBedrooms='
            + this.data.maxBedrooms + '&minBathrooms=' + this.data.minBathrooms + '&maxBathrooms=' + this.data.maxBathrooms + '&homeType=' + this.data.homeType + '&dataSource=' + this.data.dataSource + '&pageSize='
        + this.data.pageSize + '&pageNumber=' + this.data.pageNumber;
        this.$communityGrid.empty();
        this.$productsLoadingSpinner.show();
        $.ajax({
            type: 'GET',
            url: url + params,
            context: this,
            success: function(response) {
                this.$communityGrid.append(response);
                this.$element.trigger('productsReceived');
                this.$productsLoadingSpinner.hide();
                this.setExperienceModalButtons();

                // update save buttons for the newly loaded communities
                savedItemsService.setChildSavedState(this.$communityGrid[0]);

                // wrap the response in a jquery object and pass it into the analytics trigger
                this.triggerAnalyticsDataLayerEvent($(response), AdobeLaunch.COMMUNITY_LISTING_DISPLAYED_EVENT_NAME);
            }
        });

    }

    this.GetMoreGridQmis = function(element){
        this.data.pageSize = 8;
        this.data.pageNumber = this.qmiPageNumber -1;
        var url = this.apiUrl + '/api/Qmi/GridQmiSearch?';
        var params = 'brand=' + this.data.brand + '&state=' + this.data.state + '&region=' + this.data.region + '&cityNames=' + this.data.cityNames
        + '&minPrice=' + this.data.minPrice + '&maxPrice=' + this.data.maxPrice + '&minBedrooms=' + this.data.minBedrooms + '&maxBedrooms='
        + this.data.maxBedrooms + '&minBathrooms=' + this.data.minBathrooms + '&maxBathrooms=' + this.data.maxBathrooms + '&dataSource=' + this.data.dataSource + '&pageSize='
        + this.data.pageSize + '&pageNumber=' + this.data.pageNumber;
        
        this.$qmiGrid.empty();
        this.$productsLoadingSpinner.show();
        $.ajax({
            type: 'GET',
            url: url + params,
            context: this,
            success: function(response) {
                this.$qmiGrid.append(response);
                this.$element.trigger('productsReceived');
                this.$productsLoadingSpinner.hide();
                this.setExperienceModalButtons();

                // update save buttons for the newly loaded qmis
                savedItemsService.setChildSavedState(this.$qmiGrid[0]);

                // wrap the response in a jquery object and pass it into the analytics trigger
                this.triggerAnalyticsDataLayerEvent($(response), AdobeLaunch.PLAN_LISTING_DISPLAYED_EVENT_NAME);
            }
        });

    }

    this.triggerAnalyticsDataLayerEvent = function($elements, eventName) {
        if ($elements && $elements.length && eventName) {
            if (eventName === AdobeLaunch.COMMUNITY_LISTING_DISPLAYED_EVENT_NAME) {
                // update the exact match counts before calling analytics if they haven't been set yet for current products
                if (!this.exactMatchCountsUpdated) {
                    $.ajax({
                        type: 'GET',
                        context: this,
                        url: this.apiUrl + '/api/map/search?' + 'brand=' + this.data.brand + '&state=' + this.data.state + '&region=' + this.data.region + '&cityNames=' + this.data.cityNames +
                            '&minPrice=' + this.data.minPrice + '&maxPrice=' + this.data.maxPrice + '&minBedrooms=' + this.data.minBedrooms + '&maxBedrooms=' + this.data.maxBedrooms +
                            '&minBathrooms=' + this.data.minBathrooms + '&maxBathrooms=' + this.data.maxBathrooms + '&homeType=' + this.data.homeType,
                        success: function(response) {
                            this.exactMatchCommunitiesCount = response.CommunityCount;
                            this.exactMatchCountsUpdated = true;
                            
                            AdobeLaunch.pushListingDisplayedEvent($elements, eventName, this.data, this.exactMatchCommunitiesCount);
                        }
                    });
                } else {
                    AdobeLaunch.pushListingDisplayedEvent($elements, eventName, this.data, this.exactMatchCommunitiesCount);
                }
            } else if (eventName === AdobeLaunch.PLAN_LISTING_DISPLAYED_EVENT_NAME) {
                AdobeLaunch.pushListingDisplayedEvent($elements, eventName, this.data);
            }
        }
    }

    //If the experience modal is not on the page, the button clicks should navigate the user to the appropriate community/qmi page without the experience message popup.
    this.setExperienceModalButtons = function() {
        if (!this.hasExperienceMessage) {
            this.$element.find('.experience-modal-button').each( function() {
                var $this = $(this);
                $this.attr("href", $this.data('href'));
                $this.removeAttr('data-toggle');
            });
        }
    }

    this.deeplink = function(event, val) {
        if (val == "qmi") {
            this.data.productType = "qmi";

            var qmiButton = $('[data-product-type="qmi"]');

            qmiButton.siblings().removeClass('is-selected');
            qmiButton.addClass('is-selected');

            this.ShowQmiGrid();
        }
    }
}
module.exports = component(GridContainer);
