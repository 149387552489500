var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');

function CommunityTabs() {

    this.initialize = function (element, options) {
        this.$tabsMainContainer = this.find("#CommunityTabs-Tabs");
        this.$activeTabMain = $(".is-active", this.$tabsMainContainer);
        this.$dropdownText = this.find(".viewing");
        this.$dropdownButton = this.find('.CommunityTabs-secondaryTabsDropdownButton');

        this.getActiveTab();

        this.$tabMain = this.find('.CommunityTabs-Tabs .CommunityTabs-Tab');
        this.$tabSecondary = this.find(".CommunityTabs-secondaryTabs .CommunityTabs-secondaryTab");
        this.$tabMain.on('click', this.selectTab.bind(this));
        this.$tabSecondary.on('click', this.selectTab.bind(this));

        var tabSecondaryContent = this.$tabSecondary.find('a'),
            tabMainContent = this.$tabMain.find( 'a' );

        var activeTab = {};

        $(window).resize(utils.debounce(this.handleResize.bind(this), 250, true));

        if(this.$tabMain.length <= 1){
          this.$dropdownButton.addClass("is-disabled");
        }
        this.$dropdownButton.on('click', this.toggleOpen.bind(this));
        this.$dropdownText.text($(".CommunityTabs-secondaryTabs .is-active").first().text());

        this.emptyTabContent(tabMainContent, '#');
        this.emptyTabContent(tabSecondaryContent, '.');

        activeTab = this.$tabSecondary.not('.hidden').first();
        var visibleSecondaryTabs = this.$tabSecondary.not('hidden');
        this.setActiveTab(activeTab);
        this.setCursor(visibleSecondaryTabs);

        //Check for a hash value of the name of the secondary tab.  If it is present, then show the tab.  behrreich 12/16/2016 116590
        this.$tabSecondary.each(function (i, val) {
            var anchor = $(val).find('a');
            var tabText = anchor.text().toLowerCase().replace(/ /g, ''); //Ensure lower case and remove spaces

            if (utils.hasHashValue(tabText)) {
                anchor.click();

                //Scroll to the element
                $('html, body').animate({
                    scrollTop: anchor.parent().offset().top - 200
                }, 1000, function () { });
            }
        });
    };

    this.emptyTabContent = function( elementsArray, kindOfTarget ) {
        elementsArray.each(function( i, val ) {
            var target = $(val).attr('href'),
                child = [];
            if ( kindOfTarget === '#' ){
                target = target.split('#')[1];
                child = $( kindOfTarget + target ).find( '.CommunityTabs-content' ).children().length;
            }
            else {
                child = $( kindOfTarget + target ).children().length;
            }
            if (child === 0) {
                $(val).parent().addClass('hidden');
            }
            
        })
    }

    this.setActiveTab = function (element) {
        var id = $(element).find('a').attr('href');
        $('.' + id).addClass('is-active');
        $(element).addClass('is-active');
    }

    this.setCursor = function(itemArray) {
        var count = itemArray.length;
        if (count === 1) {
            $(itemArray[0]).find('a').addClass('no-cursor');
        }
    }

    this.handleResize = function (e) {
        this.$tabsMainContainer.removeClass("is-open");
    };

    this.toggleOpen = function (e){
        e.preventDefault();
        $(".CommunityTabs-secondaryTabs").toggleClass("is-open");
    }

    this.selectTab = function (e){
        e.preventDefault();
        var $el = $(e.currentTarget);
        if ($el.hasClass('secondary-tab')) {
            var parent = "#" + $el.parents(".CommunityTabs-tabContent").attr("id");
            var tabsSecondaryContent = $("a", $el).attr("href");
            $(".secondary-tab.is-active", parent).toggleClass("is-active");
            $(".CommunityTabs-secondaryContent .is-active", parent).toggleClass("is-active");
            $("." + tabsSecondaryContent, parent).toggleClass("is-active");
            this.$dropdownText.text($el.text());
        } else {
            var tabsMainContent = $("a", $el).attr("href");
            $(".CommunityTabs-tabContent.is-active").toggleClass("is-active");
            $(tabsMainContent).toggleClass("is-active");
            this.$dropdownText.text($(".CommunityTabs-secondaryTab.is-active", $(tabsMainContent)).text());
            this.$activeTabMain.toggleClass("is-active");
        }

        $el.toggleClass("is-active");

        this.$tabsMainContainer.removeClass("is-open");
        $(".CommunityTabs-secondaryTabs").removeClass("is-open");

        this.getActiveTab();

        // re-initialize maps when tab is clicked
        $("body").trigger('InitTabbedMaps');

        setTimeout(this.refreshImages.bind(this), 500);
    }

    this.getActiveTab = function() {
        this.$activeTabMain = $(".is-active", this.$tabsMainContainer);
    };

    this.refreshImages = function () {
        this.$element.find('img').cloudinary_update();
        $.cloudinary.responsive();
    }

}

module.exports = component(CommunityTabs);
