var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');
var requestInfo = require('../../../lib/js/request-info.js');

function CommunityPersistentNavigation() {
    var $communityPersistentNav = $('.CommunityPersistentNav'),
        $communityPersistentNavLinks = $('.CommunityPersistentNav__tabs'),
        $persistentDropdownMenuContainer = $communityPersistentNav.find('.CommunityPersistentNav__dropdown-menu-container'),
        $persistentDropdownMenu = $communityPersistentNav.find('.CommunityPersistentNav__dropdown-menu'),
        $persistentDropdownButton = $communityPersistentNav.find('.CommunityPersistentNav__dropdown-button'),
        $persistentDropdownButtonText = $persistentDropdownButton.find('.viewing'),
        $persistentLinkCarouselLeft = $communityPersistentNav.find('.CommunityPersistentNav__carousel-left'),
        $persistentLinkCarouselRight = $communityPersistentNav.find('.CommunityPersistentNav__carousel-right'),
        $persistentDefaultLink,
        $activePersistentNavLink,
        $firstLinkShowing,
        $lastLinkShowing,
        $numLinks;

    this.initialize = function (element, options) {

        var _this = this;

        var navItems = [];

        $communityPersistentNav.affix({ offset: { top: $("header").outerHeight(true), bottom: 200 } });

        // Disable fixed positioning of header when this module is present
        $(window).on("components.loaded", function () {
            $(window).trigger("Header.disableFixed");
        });

        this.stickyBoxShadowColor = $communityPersistentNav.data('stickyBoxShadowColor');

        // Create nav item for each component that has communityNavText
        $('[data-nav-title]').each(function (index) {

            var $this = $(this);

            var navTitle = $this.attr("data-nav-title");
            var navId = $this.attr("id");

            if (navTitle && navId) {
                var navItem = {};
                navItem.title = navTitle;
                navItem.id = navId;
                navItem.unless = $this.attr("data-nav-title-unless");
                navItems.push(navItem);
            }

        });

        var navItemsHtml = [];
        var dropdownItemsHtml = [];

        navItems.forEach(function (navItem) {
            if (!navItem.unless || (navItem.unless && !_this.navItemIdExists(navItems, navItem.unless))) {
                navItemsHtml.push(
                    $('<li/>')
                        .attr('class', 'pt-2')
                        .html(
                            $('<a/>')
                                .attr('href', '#')
                            .attr('class', 'CommunityPersistentNav__tab mr-4')
                                .attr('data-analytics', 'community-persistent-nav|community-name|' + navItem.id)
                                .attr('data-analytics-cta', navItem.title)
                                .attr('data-nav-target', "#" + navItem.id)
                                .text(navItem.title)
                                .on('click', function (e) {
                                    e.preventDefault();
                                    _this.scrollToSection($(e.target).attr('data-nav-target'));
                                }))
                );

                dropdownItemsHtml.push(
                    $('<li/>')
                        .attr('class', 'd-inline-flex')
                        .html(
                            $('<a/>')
                                .attr('href', '#')
                                .attr('class', 'CommunityPersistentNav__dropdown-link d-inline-flex justify-content-between align-items-center')
                                .attr('data-analytics', 'community-persistent-nav|community-name|' + navItem.id)
                                .attr('data-analytics-cta', navItem.title)
                                .attr('data-nav-target', "#" + navItem.id)
                                .text(navItem.title)
                                .on('click', function (e) {
                                    e.preventDefault();
                                    _this.scrollToSection($(e.target).attr('data-nav-target'));
                                }).append(
                                    $('<i/>')
                                        .attr('class', 'CommunityPersistentNav__right-arrow icon-arrow-right px-2')))
                );
            }
        });

        $numLinks = navItemsHtml.length;
        $firstLinkShowing = 0;
        $lastLinkShowing = $numLinks - 1;

        if (navItemsHtml.length) {
            if ($communityPersistentNavLinks && $communityPersistentNavLinks.length > 0) {
                $communityPersistentNavLinks.html(navItemsHtml);
            }

            if ($persistentDropdownMenu && $persistentDropdownMenu.length > 0) {
                $persistentDropdownMenu.html(dropdownItemsHtml);

                _this.toggleDropdownPersistentLinks.bind(this);
                // attach sublink primary link listeners
                $persistentDropdownButton.on('click touch', _this.toggleDropdownPersistentLinks);
            }

            $persistentDefaultLink = navItemsHtml[0].find('a');
            _this.firstNavItem = navItems[0];
            this.activateLink($persistentDefaultLink);
        }

        // Scroll to top button
        $(".CommunityPersistentNav__back-to-top").on("click", function (e) {
            e.preventDefault();
            $('html, body').animate({
                scrollTop: 0
            }, 1000);
        });

        // handler for QMI button click
        var communityQMINavBtn = $communityPersistentNav.find("[id='CommunityNavQMIBtn']"); 
        if(communityQMINavBtn.length){
            communityQMINavBtn.on('click', function (e) {
                e.preventDefault();
       
                _this.qmiHomeDesignFilter();
                var qmiTab = $('.toggle--homeType [data-type=qmi]');
                var planSummary = $("#PlanQMISummary")
                if (qmiTab.length > 0) {
                    // $CommunityNavLinks.removeClass("is-open");
                    _this.scrollToSection('#HomeSummaryGlance');
                    qmiTab.click();
                }
                else if (planSummary) {
                    _this.scrollToSection("#PlanQMISummary");
                }
            });

        }

        var isQmiUrlHashtag = utils.hasHashValue('qmi');
        if (isQmiUrlHashtag) {
            _this.qmiHomeDesignFilter();
        }

        // handler for QMI button click
        $communityPersistentNav.find("#PlanNavQMIBtn").on('click', function (e) {
            e.preventDefault();
            //$CommunityNavLinks.removeClass("is-open");
            _this.scrollToSection('#PlanQMISummary');
        });

        // handle adjusting navigation on scroll
        $(window).on('scroll', function () {

            var $newNavLink;
            var moduleTransitionLoc = $(window).scrollTop() + $communityPersistentNav.outerHeight() + 150;

            $communityPersistentNavLinks.find('> li a').each(function (index) {

                if ($(this).attr('data-nav-target')) {
                    if (moduleTransitionLoc >= $($(this).attr('data-nav-target')).offset().top) {
                        $newNavLink = $(this);
                    }
                }

            });

            if ($newNavLink) {
                if (!$activePersistentNavLink || ($activePersistentNavLink.attr('data-nav-target') != $newNavLink.attr('data-nav-target'))) {
                    $activePersistentNavLink = $newNavLink;

                    _this.activateLink($activePersistentNavLink);

                    if ($(window).outerWidth() > 1024) {
                        var $activeLi = $communityPersistentNavLinks.last().children('.is-active')
                        var activeLinkIndex = $communityPersistentNavLinks.last().children().index($activeLi);
                        var idx;

                        if (activeLinkIndex < $firstLinkShowing) {
                            _this.shiftLinksLeft();

                            _this.addOrRemoveLinkRight();

                            if ($firstLinkShowing === 0) {
                                $persistentLinkCarouselLeft.addClass('d-none');
                                _this.addLinkRight();
                            }

                            if ($lastLinkShowing < $numLinks - 1) {
                                $persistentLinkCarouselRight.removeClass('d-none');
                            }

                        } else if (activeLinkIndex > $lastLinkShowing) {
                            _this.shiftLinksRight();

                            _this.addOrRemoveLinkLeft();

                            if ($firstLinkShowing > 0) {
                                $persistentLinkCarouselLeft.removeClass('d-none');
                            }

                            if ($lastLinkShowing === $numLinks - 1) {
                                $persistentLinkCarouselRight.addClass('d-none');
                                _this.addLinkLeft();
                            }
                        }
                    }
                }
            } else {
                if ($activePersistentNavLink) {
                    $activePersistentNavLink = undefined;
                    _this.activateLink($persistentDefaultLink);
                }
            }

            // If the dropdown menu is open, close when scrolling
            if (!$persistentDropdownMenuContainer.first().hasClass('d-none')) {
                $persistentDropdownMenuContainer.first().addClass('d-none');
                $persistentDropdownButton.first().parent().removeClass('open');
            }

            if (!$persistentDropdownMenuContainer.last().hasClass('d-none')) {
                $persistentDropdownMenuContainer.last().addClass('d-none');
                $persistentDropdownButton.last().parent().removeClass('open');
            }

        });

        $(window).trigger('scroll');

        // Checks to see if we need to create the link carousel
        $(window).on('resize', function (event) {
            if ($communityPersistentNav.hasClass('affix') && $(window).outerWidth() > 1024) {
                // Reset the links so that we can check if there is overlap or line-wrap
                $communityPersistentNavLinks.last().children().removeClass('d-none');
                $persistentLinkCarouselLeft.addClass('d-none');
                $persistentLinkCarouselRight.addClass('d-none');
                $firstLinkShowing = 0;
                $lastLinkShowing = $numLinks - 1;
                var linksRightCoord = $communityPersistentNavLinks.last().parent()[0].getBoundingClientRect().right;
                var iconsLeftCoord = $('.CommunityPersistentNav__icons').children().first()[0].getBoundingClientRect().left - 32;

                if (linksRightCoord > iconsLeftCoord) {
                    $persistentLinkCarouselRight.removeClass('d-none');
                }

                // Hide last link until there is no overlap
                while (linksRightCoord > iconsLeftCoord) {
                    $communityPersistentNavLinks.last().children().eq($lastLinkShowing).addClass('d-none');
                    $lastLinkShowing--;
                    linksRightCoord = $communityPersistentNavLinks.last().parent()[0].getBoundingClientRect().right;
                }
            }
        });

        $(window).trigger('resize');

        $persistentLinkCarouselRight.find('a > img, i').on('click', function (event) {
            event.preventDefault();

            $persistentLinkCarouselLeft.removeClass('d-none');

            _this.shiftLinksRight();

            _this.addOrRemoveLinkLeft();

            if ($lastLinkShowing == $numLinks - 1) {
                $persistentLinkCarouselRight.addClass('d-none');
                _this.addLinkLeft();
            }
        });

        $persistentLinkCarouselLeft.find('a > img, i').on('click', function (event) {
            event.preventDefault();

            if ($lastLinkShowing == $numLinks - 1) {
                $persistentLinkCarouselRight.removeClass('d-none');
            }

            _this.shiftLinksLeft();

            _this.addOrRemoveLinkRight();

            if ($firstLinkShowing == 0) {
                $persistentLinkCarouselLeft.addClass('d-none');
                _this.addLinkRight();
            }
        });

        // Watch to see whenever the class list of the nav changes
        // Looking for the affix class to be added, and call resize to trigger the carousel if needed
        var $persistentNavClassObserver = new MutationObserver(function (mutationList, observer) {
            if ($(window).outerWidth() > 1024) {
                $(window).trigger('resize');
            }

            if ($communityPersistentNav.hasClass('affix')) {
                $communityPersistentNav.css('box-shadow', '0px 10px 29px 3px ' + _this.stickyBoxShadowColor);
            } else {
                $communityPersistentNav.css('box-shadow', '');
            }
        });

        $persistentNavClassObserver.observe($communityPersistentNav[0], {
            attributes: true,
            attributeFilter: ['class'],
            childList: false,
            characterData: false
        });

        // Request Info window
        requestInfo.initRequestInfoModals();

        // ADA - Close a modal, re-focus on previous event
        var previousActiveElement = document.activeElement;

        $('#login-modal-close-btn, share-item-close-btn').on("click", function () {
            if (document.body.contains(previousActiveElement)) {
                previousActiveElement.focus();
            }
        });
    }

    this.qmiHomeDesignFilter = function () {
        var qmiOnlyModule = $("#QMIOnlyModule")
        if ($('#r-QMIs')) {
            $('#r-QMIs').click();
            $("label[for='r-QMIs']").click();    
        }
        if (qmiOnlyModule.length > 0) {
            this.scrollToSection("#QMIOnlyModule");
        }
        this.scrollToSection('#HomeDesignFilter');
    }

    // Checks if we can add or remove a link at the beginning of the link carousel as we move the carousel to the right
    this.addOrRemoveLinkLeft = function () {
        var linksRightCoord = $communityPersistentNavLinks.last().parent()[0].getBoundingClientRect().right;
        var iconsLeftCoord = $('.CommunityPersistentNav__icons').children().first()[0].getBoundingClientRect().left - 32;

        if (linksRightCoord > iconsLeftCoord) {
            $communityPersistentNavLinks.last().children().eq($firstLinkShowing).addClass('d-none');
            $firstLinkShowing++;
        } else {
            this.addLinkLeft();
        }
    }

    // Checks if we can display a link to the right of the link carousel as we move the carousel to the left
    this.addOrRemoveLinkRight = function () {
        var linksRightCoord = $communityPersistentNavLinks.last().parent()[0].getBoundingClientRect().right;
        var iconsLeftCoord = $('.CommunityPersistentNav__icons').children().first()[0].getBoundingClientRect().left - 32;

        if (linksRightCoord > iconsLeftCoord) {
            $communityPersistentNavLinks.last().children().eq($lastLinkShowing).addClass('d-none');
            $lastLinkShowing--;
        } else {
            this.addLinkRight();
        }
    }

    // Displays a link at the beginning of the link carousel
    // After adding, it checks to make sure that the link doesn't cause any overlap, and if it does, it re-hides it
    this.addLinkLeft = function () {
        if ($firstLinkShowing != 0) {
            $firstLinkShowing--;
            $communityPersistentNavLinks.last().children().eq($firstLinkShowing).removeClass('d-none');

            this.removeFirstLinkShowing();

            if ($firstLinkShowing === 0) {
                $persistentLinkCarouselLeft.addClass('d-none');
            }
        }
    }

    // Displays a link at the end of the link carousel
    // After adding, it checks to make sure that the link doesn't cause any overlap, and if it does, it re-hides it
    this.addLinkRight = function () {
        if ($lastLinkShowing != $numLinks - 1) {
            $lastLinkShowing++;
            $communityPersistentNavLinks.last().children().eq($lastLinkShowing).removeClass('d-none');

            this.removeLastLinkShowing();

            if ($lastLinkShowing === $numLinks - 1) {
                $persistentLinkCarouselRight.addClass('d-none');
            }
        }
    }

    // Hides the first link showing in the link carousel
    this.removeFirstLinkShowing = function () {
        var linksRightCoord = $communityPersistentNavLinks.last().parent()[0].getBoundingClientRect().right;
        var iconsLeftCoord = $('.CommunityPersistentNav__icons').children().first()[0].getBoundingClientRect().left - 32;

        if (linksRightCoord > iconsLeftCoord) {
            $communityPersistentNavLinks.last().children().eq($firstLinkShowing).addClass('d-none');
            $firstLinkShowing++;
        }
    }

    // Hides the last link showing the link carousel
    this.removeLastLinkShowing = function () {
        var linksRightCoord = $communityPersistentNavLinks.last().parent()[0].getBoundingClientRect().right;
        var iconsLeftCoord = $('.CommunityPersistentNav__icons').children().first()[0].getBoundingClientRect().left - 32;

        if (linksRightCoord > iconsLeftCoord) {
            $communityPersistentNavLinks.last().children().eq($lastLinkShowing).addClass('d-none');
            $lastLinkShowing--;
        }
    }

    // Moves the link carousel to the left by one
    // Done by changing the window of links shown and then displays each
    this.shiftLinksLeft = function () {
        $firstLinkShowing--;
        $lastLinkShowing--;

        $communityPersistentNavLinks.last().children().addClass('d-none');

        var idx;
        for (idx = $firstLinkShowing; idx <= $lastLinkShowing; idx++) {
            $communityPersistentNavLinks.last().children().eq(idx).removeClass('d-none');
        }
    }

    // Moves the link carousel to the right by one
    // Done by changing the window of links shown and then displays each
    this.shiftLinksRight = function () {
        $firstLinkShowing++;
        $lastLinkShowing++;

        $communityPersistentNavLinks.last().children().addClass('d-none');

        var idx;
        for (idx = $firstLinkShowing; idx <= $lastLinkShowing; idx++) {
            $communityPersistentNavLinks.last().children().eq(idx).removeClass('d-none');
        }
    }

    this.navItemIdExists = function (navItems, id) {

        for (i = 0; i < navItems.length; i++) {
            if (navItems[i].id === id) return true;
        }

        return false;
    }
    // TODO: if they ask for difference in border radius
    // Do it here
    // Line 134-135 in scss
    this.activateLink = function ($theLink) {
        $communityPersistentNavLinks.find('> li a').each(function (index) {
            $(this).parent().removeClass('is-active');
        });
        $theLink.parent().addClass('is-active');
        $persistentDropdownButtonText.text($theLink.text());
    }

    this.scrollToSection = function (scrollToLoc) {
        var _this = this;
        var targetId = scrollToLoc.replace('#', '');
        var targetOffset = 100;

        var element = document.getElementById(targetId);
       
        if (element) {
            var boundingClientRect = element.getBoundingClientRect();
            var multiplier = window.scrollY < $communityPersistentNav.outerHeight() ? 2.5 : 1
            var calculatedTop = boundingClientRect.top + window.scrollY - (multiplier * targetOffset);

            var currentTop = calculatedTop;
            var left = boundingClientRect.left

            // Page will scroll to initial calculated position of the target element
            window.scroll({
                top: calculatedTop,
                left: left,
                behavior: "smooth"
            });

            if (element.id !== _this.firstNavItem.id) {
                var scrollCorrectionInterval = setInterval(function () {
                    boundingClientRect = element.getBoundingClientRect();
                    calculatedTop = boundingClientRect.top + window.scrollY - targetOffset;
                    
                   if (currentTop !== calculatedTop) {
                        window.scroll({
                            top: calculatedTop,
                            left: left,
                            behavior: "smooth"
                        });
                        currentTop = calculatedTop;
                    }
                    else if (currentTop === calculatedTop) { // When the correct position for the target element is finally reached, this interval will be cleared
                        clearInterval(scrollCorrectionInterval);
                    }
                }, 400);
            }
        }
    }

    this.toggleDropdownPersistentLinks = function (event) {
        if (event && event.currentTarget) {
            var _this = this;
            // get main link
            var $target = $(event.currentTarget);
            // find sublinks menu sibling element
            var $mobilePersistentLinksMenu;
            if ($communityPersistentNav.hasClass('affix')) {
                $mobilePersistentLinksMenu = $target.parents('.CommunityPersistentNav__sticky').find('.CommunityPersistentNav__dropdown-menu-container');
            } else {
                $mobilePersistentLinksMenu = $target.parents('.CommunityPersistentNav__expanded').find('.CommunityPersistentNav__dropdown-menu-container');
            }
            if ($mobilePersistentLinksMenu) {
                if ($mobilePersistentLinksMenu.hasClass('d-none')) {
                    $mobilePersistentLinksMenu.removeClass('d-none');
                } else {
                    $mobilePersistentLinksMenu.addClass('d-none');
                }
            }
        }
    }
}

module.exports = component(CommunityPersistentNavigation);
