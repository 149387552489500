var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');
var AdobeLaunch = require('../../../lib/js/adobelaunch.js');
var deeplink = require('../../../lib/js/deeplink.js');

function ToggleControls() {

    this.initialize = function (element, options) {
        var _this = this;

        var $imgs = this.$element.find('img');
        
        this.$homeSummaryGlance = this.$element.closest('.HomeSummaryGlance');
        this.qmiListingDisplayedEventFired = false;
        this.planListingDisplayedEventFired = false;

        // on document ready
        this.handleResize();

        $(window).resize(utils.debounce(this.handleResize.bind(this), 250, true));

        this.$element.find('.toggle-option').on('click touch', function(e) {
            e.preventDefault();
            var toggle = $(this).parent();
            if (!$(this).hasClass('active')) {
                // Switch toggle
                toggle.find('.toggle-option.active').removeClass('active');
                $(this).addClass('active');

                // Switch visible home view
                var homeType = _this.$element.find('.toggle--homeType .toggle-option.active').data('type');
                var viewType = _this.$element.find('.toggle--viewType .toggle-option.active').data('type');
                _this.$homeSummaryGlance.find('.GlanceView .GlanceViewSection').removeClass('active');
                _this.$homeSummaryGlance.find('.GlanceView .GlanceViewSection.' + homeType + viewType).addClass('active');

                // Handle Cloudinary
                setTimeout(function () {
                    $imgs.cloudinary_update();
                    $.cloudinary.responsive();
                }, 500);

                var isQmi = homeType === 'qmi';

                _this.pushPlanListingToAdobeLaunch(isQmi);
            }
        });

        this.$element.find('.scrollable').on('touchmove scroll', function() {
            $(this).removeClass('with-hint');
        });

        // wait for deeplink processing to finish
        deeplink.deeplinkProcessed.then(function() {
            // if qmi listing displayed event hasn't hired yet, then fire the plan listing displayed event
            if (!_this.qmiListingDisplayedEventFired) {
                var isQmi = false;
                _this.pushPlanListingToAdobeLaunch(isQmi);
            }
        });
    };

    this.handleResize = function () {
        var windowWidth = $(window).outerWidth();
        if (windowWidth < 768) {
            this.$element.find('.scrollable .scrollable-inner').each(function() {
                var spaceBetween = $(this).attr('data-space-between');
                var responsiveWidth = 0;
                var childCount = $(this).children('.scrollable-item').length;
                $(this).children('.scrollable-item').each(function(i) {
                    responsiveWidth += $(this).outerWidth() + parseInt(spaceBetween);
                    if (i === childCount - 1) {
                        responsiveWidth -= parseInt(spaceBetween);
                    }
                });
                responsiveWidth += 30; // account for the margin-left and margin-right
                $(this).css('width', responsiveWidth);

                if (responsiveWidth > windowWidth) {
                    $(this).closest('.scrollable').addClass('with-hint');
                } else {
                    $(this).closest('.scrollable').removeClass('with-hint');
                }
            });
        } else {
            this.$element.find('.scrollable .scrollable-inner').each(function() {
                $(this).css('width', 'auto');
            });
        }

    }

    this.pushPlanListingToAdobeLaunch = function(isQmi) {
        var $elements;
        if (!isQmi && !this.planListingDisplayedEventFired) {
            $elements = this.$homeSummaryGlance.find('.HomeDesignCompact');

            AdobeLaunch.pushListingDisplayedEvent($elements, AdobeLaunch.PLAN_LISTING_DISPLAYED_EVENT_NAME);

            this.planListingDisplayedEventFired = true;
        } else if (isQmi && !this.qmiListingDisplayedEventFired) {
            $elements = this.$homeSummaryGlance.find('.HomeDesignSummary--qmi');

            AdobeLaunch.pushListingDisplayedEvent($elements, AdobeLaunch.PLAN_LISTING_DISPLAYED_EVENT_NAME);

            this.qmiListingDisplayedEventFired = true;
        }
    }

    this.deeplink = function(event, val) {
        if (val === "qmi") {
            var qmiTab = this.$element.find('.toggle--homeType [data-type=qmi]');

            if (qmiTab.length) {
                qmiTab.click();
            }
        }
    }

}

module.exports = component(ToggleControls);
