/**
 * Validation Service
 */
var validator = {

    test: function (method, input) {
        if (this[method]) {
            return this[method](input);
        } else {
            return true;
        }
    },

    //
    // Validation routines
    //

    agreementId: function (input) {
        return input.val().match(this.patterns.agreementId);
    },

    name: function (input) {
        return input.val().match(this.patterns.name);
    },

    lastname: function (input) {
        return input.val().match(this.patterns.lastname);
    },

    nameCustom: function (input) {
        return input.val().match(this.patterns.nameCustom);
    },

    // TODO determine best place for the max length of the email
    email: function (input) {
        var val = input.val();
        return val.match(this.patterns.email) && val.length < 100;
    },

    radio: function (input) {
        return input.filter(':checked').length > 0;
    },

    check: function (input) {
        return input.filter(':checked').length > 0;
    },

    tel: function (input) {
        var phoneFieldValue = input.val();
        if(phoneFieldValue.length >= 14 && phoneFieldValue.substr(0, 1) === '(' && phoneFieldValue.substr(4, 2) === ') ' 
            && phoneFieldValue.substr(9, 1) === '-') {
                phoneFieldValue = phoneFieldValue.substr(1, 3) + phoneFieldValue.substr(6, 3) + phoneFieldValue.substr(10, 4);
            }
        var numberIsValid = undefined;
        if(input.attr('type') === 'tel') {
            numberIsValid = phoneFieldValue.match(this.patterns.tel);
        } else {
            numberIsValid = phoneFieldValue.match(this.patterns.itel);
        }
        return numberIsValid;
    },

    zip: function (input) {
        return input.val().match(this.patterns.zip);
    },

    password: function (input) {
        return input.val().match(this.patterns.password);
    },

    notEmptyNotRequired: function (input) {
        return input.val().match(this.patterns.notEmpty);
    },

    notWhiteSpace: function (input) {
        if ( input.val().trim() === ''){
            return null;
        }
        else{
            return true
        }
    },

    hasBackslash: function (input) {
        var val = input.val();
        if (val.match(this.patterns.hasBackslash))
        {
            return null;
        }
        return true;
    },

    //
    // Regular expressions used by validation routines
    //

    patterns: {

        /* Examples: HB009239283 or 009239283 */
        agreementId: /^(hb|phd)?\d{7,50}$/i,

        name:  /^([A-zÀ-ÿ\-'\s]{2,50}$)/,
        
        lastname:  /^([A-zÀ-ÿ\-'\s]{2,50}$)/,
        
        nameCustom:  /^([A-zÀ-ÿ0-9\-'\s]{2,50}$)/,

        /*
        Email regex explained:

        ^                 start regular expression
		[a-z0-9._%+-]+    one or more letters, numbers, _ . + or -
		@                 followed by an @ sign
		[a-z0-9.-]+       then one or more letters, numbers, _ . or -
		\.                followed by a period
		([a-z]{2,4})      followed by 2 to 4 letters
		$/i               end of line and ignore case

        */
        email: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,

        // NOTE: This does not follow Pulte's suggested telephone requirements which specify that a phone should be exactly 10 digits if the user is in the US and anywhere from 5-40 digits if the user is outside the US
        tel: /^(\d{10,23})$/,
        itel: /^(\d{5,15})$/,

        // NOTE: this could be improved drastically to follow proper ZIP rules
        // (eg, 11111 is not valid, etc)
        zip: /^([0-9]{5,5})/,

        // TODO: get proper password validation specs
        password: /^(\S){5,20}/,

        //
        notEmpty: /^$|\s+/,

        //validate for not backslash on user input
        hasBackslash: /\\/
    }

};

module.exports = validator;
