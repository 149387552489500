module.exports = {
    FOCUS: 'TYPEAHEAD_FOCUS',
    BLUR: 'TYPEAHEAD_BLUR',
    UPDATE: 'TYPEAHEAD_UDPATE',
    UPDATE_RESULTS: 'TYPEAHEAD_UDPATE_RESULTS',
    UPDATE_INPUT: 'TYPEAHEAD_UPDATE_INPUT',
    UPDATE_VALUE: 'TYPEAHEAD_VALUE',
    CLEAR: 'TYPEAHEAD_CLEAR',
    UP: 'TYPEAHEAD_UP',
    DOWN: 'TYPEAHEAD_DOWN',
    NEXT: 'TYPEAHEAD_NEXT',
    PREV: 'TYPEAHEAD_PREV'
};
