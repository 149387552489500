module.exports = {   
    /** 
     * Description: Custom Tab Events attaches click events to tabs that will allow user to view different
     * content based on the tabbed thats click.
     * 
     * Input: rootElement 
     *  Root Element of Component 
    **/ 
    createCustomTabEvents: function (rootElement) {
        var allCustomTabs = rootElement.find('[data-tab-id^="custom-tab-"]');
        var _this = this;
        allCustomTabs.on('click', function (event) {
            event.stopPropagation();
            var activeCustomTabs = allCustomTabs.filter('.active');
            $(activeCustomTabs).each(function (_, element) {
                $(element).removeClass('active');
                _this.resetAdaTab(activeCustomTabs);
            });

            var currentCustomTab = event.target;
            currentCustomTab.classList.add('active');
            _this.updateAdaActiveTab(currentCustomTab);

            var activeTabTargets = rootElement.find('.custom-tab-content__item.active');
            $(activeTabTargets).each(function (_, element) {
                $(element).removeClass('active');
            });

            var customTabId = currentCustomTab.getAttribute('data-tab-id');
            var customTabTarget = rootElement.find('[data-tab-target="' + customTabId + '"]')[0];
            if(!customTabTarget.classList.contains('active')){
                customTabTarget.classList.add('active');
            }
        });
    },
    resetAdaTab: function(activeCustomTabs) {
        $(activeCustomTabs).each(function (_, element) {
            var attr = $(element).attr('tab-index');
            if (!attr) {
                $(element).attr('tab-index','-1')
            }
            $(element).attr('aria-selected','false');
        });
    },
    updateAdaActiveTab: function(activeCustomTab) {
        var attr = $(activeCustomTab).attr('tab-index');
        if (attr) {
            $(activeCustomTab).removeAttr('tab-index');
        }
        $(activeCustomTab).attr('aria-selected','true');
    }
}