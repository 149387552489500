var DirectionsMap = require('../../templates/components/map/map-directions-controller.js');
var Map = require('../../templates/components/map/map-init.js');

// listen for clicks on links intended to trigger the map directions modal
$(document).on('click', "a[data-target^='.modal-mapDirections']", function (e) {
  // get the data-target from the clicked element
  var target = $(this).data('target');

  // dynamically render the map directions modal if it doesn't exist yet
  if ($(target).length == 0)
  {
    e.preventDefault();

    // if global map object doesn't exist yet, initialize it
    if (!Pulte.map) {
        var MapInit = new Map();
        MapInit.load();
    }

    var communityId = target.replace(".modal-mapDirections[data-community-id='", '').replace("']", '');
    var url = window.location.origin+ '/api/map/GetMapDirections?' + 'commmunityId=' + communityId;
    
    $.ajax({
      context: this,
      url: url ,
      success: function (response)
      {
        var $responseHtml = $(response);
        
        // the response is the html of the map directions modal including the script tag with the global
        // directions object. add it to the body of the page
        $responseHtml.appendTo($('body'));

        // filter the modal jQuery object out of the response jQuery objects
        var $modal = $responseHtml.filter('.modal-mapDirections');

        // attach the directions map js to the map inside the new modal
        $('.Map--DirectionsMap', $modal).attach(DirectionsMap, Pulte);

        // show the modal
        $modal.modal('show');
      }
    }); 
  }
});
