var component = require('../../../lib/js/component.js');

function EditorialNav() {
    var $editorialNav = $('.EditorialNav'),
        $editorialNavLinks = $('.EditorialNav__links > ul'),
        $dropdownButton = $editorialNav.find('.EditorialNav__linksDropdown'),
        $dropdownButtonIcon = $dropdownButton.find('i'),
        $dropdownButtonText = $dropdownButton.find('.viewing'),
        $defaultLink = $editorialNavLinks.find('.EditorialNav__defaultLink a'),
        $activeNavLink;

    this.initialize = function (element, options) {

        var _this = this;
        // Disable fixed positioning of header when this module is present
        // Doing it here allows for height change of header (in CSS) without requiring update of HTML "data-offset-top"
        $editorialNav.affix({ offset: { top: $("header").outerHeight(true), bottom: 200 } });

        // Disable fixed positioning of header when this module is present
        $(window).on("components.loaded", function () {
            $(window).trigger("Header.disableFixed");
        });

        // Setup dropdown behavior for tablet/mobile
        $dropdownButton.on('click', this.toggleOpen.bind(this));
        _this.activateLink($defaultLink);

        // Attach click listener to each link
        // $editorialNavLinks.find("li > a").on("click", function(e) {
        //     _this.toggleOpen(e);
        //     _this.scrollToSection($(e.target).attr('data-nav-target'));
        // });
        

        // Create nav item for each component that has editorialNavText
        $('[data-editorial-nav-title]').each(function (index) {

            var navTitle = $(this).attr("data-editorial-nav-title");
            var navId = $(this).attr("data-nav-id");

            if (navTitle && navId) {
                $editorialNavLinks.find("> li:last-child").before(
                    $('<li/>')
                        .append(
                            $('<a/>')
                                .attr('href', '#')
                                .attr('data-nav-target', navId)
                                .text(navTitle)
                                .on('click', function (e) {
                                    _this.toggleOpen(e);
                                    _this.scrollToSection($(e.target).attr('data-nav-target'));
                                })
                        )
                );
            }


        });

        // Scroll to top button
        $(".EditorialNav__backToTop, .EditorialNav__defaultLink").on("click", function (e) {
            e.preventDefault();
            $editorialNavLinks.removeClass("is-open");
            $dropdownButtonIcon.removeClass("icon-arrow-up").addClass("icon-arrow-down");
            $('html, body').animate({
                scrollTop: 0
            }, 1000);
        });


        // handle adjusting navigation on scroll
        $(window).on('scroll', function () {

            var $newNavLink;
            var moduleTransitionLoc = $(window).scrollTop() + $editorialNav.outerHeight() + 150;

            $editorialNavLinks.find('> li a').each(function (index) {
                var $currentElement = $(this);

                if ($currentElement.attr('data-nav-target')) {
                    var $currentTargetElement = _this.getJQueryObjectByNavTarget($currentElement.attr('data-nav-target'));

                    if ($currentTargetElement && $currentTargetElement.is(':visible') && $currentTargetElement.offset() && moduleTransitionLoc >= $currentTargetElement.offset().top) {
                        $newNavLink = $(this);
                    }
                }

            });

            if ($newNavLink) {
                if (!$activeNavLink || ($activeNavLink.attr('data-nav-target') != $newNavLink.attr('data-nav-target'))) {
                    $activeNavLink = $newNavLink;
                    _this.activateLink($activeNavLink);
                }
            } else {
                if ($activeNavLink) {
                    $activeNavLink = undefined;
                    _this.activateLink($defaultLink);
                }
            }

        });

        $(window).trigger("scroll");

    }

    this.toggleOpen = function (e) {
        e.preventDefault();
        $editorialNavLinks.toggleClass("is-open");
        if ($editorialNavLinks.hasClass("is-open")) {
            $dropdownButtonIcon.removeClass("icon-arrow-down").addClass("icon-arrow-up");
        } else {
            $dropdownButtonIcon.removeClass("icon-arrow-up").addClass("icon-arrow-down");
        }
    }

    this.activateLink = function ($theLink) {
        $editorialNavLinks.find('> li a').each(function (index) {
            $(this).removeClass('is-active');
        });
        $theLink.addClass('is-active');
        $dropdownButtonText.text($theLink.text());
    }

    this.scrollToSection = function (dataNavTarget) {

        var targetOffset = $editorialNav.outerHeight();
        if ($editorialNav.hasClass('affix-top')) {
            targetOffset = targetOffset * 2;
        }

        $targetElement = this.getJQueryObjectByNavTarget(dataNavTarget);

        if ($targetElement && $targetElement.length) {
            // detect if the element is in a tab container and trigger tab change events
            var $HorizontalTabContainer = $targetElement.parents('.HorizontalTabs')
            var $VerticalTabContainer = $targetElement.parents('.VerticalTabs');

            if ($HorizontalTabContainer.length) {
                $HorizontalTabContainer.trigger('ht-tab-change', [$targetElement]);
            }

            if ($VerticalTabContainer.length) {
                $VerticalTabContainer.trigger('vt-tab-change', [$targetElement]);
            }

            if ($targetElement && $targetElement.offset()) {
                $('html, body').animate({
                    scrollTop: $targetElement.offset().top - targetOffset
                }, 1000);

                // trigger scrolling so the correct nav item is selected even if the page didn't scroll
                $(window).trigger('scroll');
            }
        }
    }

    this.getJQueryObjectByNavTarget = function (dataNavTarget) {
        var $targetElement;

        if (dataNavTarget) {
            $targetElement = $("[data-nav-id='" + dataNavTarget + "']");
        }

        return $targetElement;
    }

}

module.exports = component(EditorialNav);
