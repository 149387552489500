var DirectionsMapV2 = require('../../templates/components/map/map-directions-controller-v2.js');
var Map = require('../../templates/components/map/map-init.js');

// listen for clicks on links intended to trigger the map directions modal
$(document).on('click', "a[data-target^='.mapDirectionsModalV2']", function (e) {
  // get the data-target from the clicked element
  var target = $(this).data('target');

  // dynamically render the map directions modal if it doesn't exist yet
  if ($(target).length == 0)
  {
    e.preventDefault();

     // if global map object doesn't exist yet, initialize it
     if (!Pulte.map) {
      var MapInit = new Map();
      MapInit.load();
    }

    var communityId = target.replace(".mapDirectionsModalV2[data-community-id='", '').replace("']", '');
    var url = window.location.origin+ '/api/map/GetMapDirectionsV2?' + 'commmunityId=' + communityId;
    
    $.ajax({
      context: this,
      url: url ,
      success: function (response)
      {
        var $responseHtml = $($.parseHTML(response, document, true));
        
        // the response is the html of the map directions modal including the script tag with the global
        // directions object. add it to the body of the page
        $('body').append($responseHtml);

        // filter the modal jQuery object out of the response jQuery objects
        var $modal = $responseHtml.filter('.mapDirectionsModalV2');

        // attach the directions map js to the map inside the new modal
        $('.Map--DirectionsMapV2', $modal).attach(DirectionsMapV2, Pulte);

        // show the modal
        $modal.modal('show');
      }
    }); 
  }
});
