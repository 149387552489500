var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');

function CommunityOverview(){
    var $component;

    this.initialize = function(element){
        $element = $(element);
        $component = this;

        this.handleVideoLayout();
        this.handleDescriptionSizing();

        $(window).resize(utils.debounce(this.handleVideoLayout.bind(this), 250, false));
        $(window).resize(utils.debounce(this.handleDescriptionSizing.bind(this), 250, false));

        this.brand = $('body').data('brand');
        this.showSchoolRatings = this.$element.data('showSchoolRatings');

        if( (this.showSchoolRatings == 'True') && (this.brand != 'Del Webb') ){ // checks if community displays school ratings
            var $dialog = document.querySelector('.school-ratings-modal-dialog') !== null ? document.querySelector('.school-ratings-modal-dialog') : false;
            if($dialog){
                var $schoolRatingsModalClose = $dialog.querySelector('.school-ratings-close');
                var $schoolRatingsDisclaimerIcon = $dialog.querySelector('.PopoverBtn');
                $dialog.addEventListener('keydown', function(e) {
                    if(e.target == $schoolRatingsModalClose && e.key == 'Tab' && e.shiftKey) {
                        e.preventDefault();
                        if ($('.popover').is(':visible')) {
                            if( $('.popover-close-btn')[0]){
                                $('.popover-close-btn').trigger('focus');
                            }
                        } else {
                            $schoolRatingsDisclaimerIcon.focus();
                        }
                    } else if(e.target == $schoolRatingsDisclaimerIcon && e.key == 'Tab' && !e.shiftKey) {
                        e.preventDefault();
                        if ($('.popover').is(':visible')) {
                            if( $('.popover-close-btn')[0]){
                                $('.popover-close-btn').trigger('focus');
                            }
                        } else {
                            $schoolRatingsModalClose.focus();
                        }
                    } else if (e.target == $('.popover-close-btn')[0] && e.key == 'Tab' && !e.shiftKey) {
                        e.preventDefault();
                        $schoolRatingsModalClose.focus();
                    }
                });
            }
        }

        var amenityOverviewModule = document.querySelector('section[data-hashtag-link="amenity-overview"]');
        if(amenityOverviewModule) {
            var seeMoreAmenities = document.querySelector('#see-more-amenities');
            seeMoreAmenities.removeAttribute('data-toggle');
            seeMoreAmenities.removeAttribute('data-target');
            seeMoreAmenities.setAttribute('href','#');
            seeMoreAmenities.addEventListener('click',function(e) {
                e.preventDefault();
                utils.scrollToTarget(amenityOverviewModule);
            });
            var isAmenitiesOverview = utils.hasHashValue('amenities');
            if(isAmenitiesOverview){
                utils.scrollToTarget(amenityOverviewModule);
            }
        }

        $('.Carousel-cta').on('click', function() {
            utils.scrollToTarget(document.querySelector('section[data-deeplink="' +  $(this).data('deeplink-target') + '"]'));
        });

        //Neighborhood Features ADA
        this.showCommunityFeatures = this.$element.data('showCommunityFeatures');

        if(this.showCommunityFeatures == 'True'){
            var $featuresModal = document.querySelector('.neighborhood-features-modal-dialog');
            var $featuresModalClose = $featuresModal.querySelector('.neighborhood-features-close');    

            $featuresModal.addEventListener('keydown', function(e) {            
                if (e.target == $featuresModalClose && e.key == 'Tab') {
                    e.preventDefault();       
                }
            });
        }

        this.setupSeeMoreDeeplink();
    }

    this.handleDescriptionSizing = function() {
        // The see more is only added if there is no videos
        if ($('.CommunityOverview__video-container').first().children().length == 0) {
            $(document).off('click', '.community-description-see-more');
            $('.community-description-see-more').remove();
            var $amenities = $('.CommunityOverview__amenities').first();
            var $description = $('.description').first();
            $description.css('height', '');
            var amenitiesHeight = $amenities.outerHeight();
            var descriptionHeight = $description.outerHeight();
            var descriptionLineHeight = parseInt($description.css('line-height'));

            if (descriptionHeight > amenitiesHeight && $(window).outerWidth() >= 768) {
                $description.css('height', amenitiesHeight - (2*descriptionLineHeight));
                $description.css('overflow', 'hidden');
                $description.css('text-overflow', 'ellipsis');
                var $seeMoreLink = document.createElement('a');
                $seeMoreLink.classList.add('community-description-see-more');
                $seeMoreLink.innerText = 'See more';
                $seeMoreLink.title = 'see more of the community description';
                $seeMoreLink.href = 'javascript:;';
                
                $(document).on('click', '.community-description-see-more', expandOrCollapseDescription);
                $('.CommunityOverview__description').first().append($seeMoreLink);
            }
        }
        
        function expandOrCollapseDescription() {
            var currDescriptionHeight = $description.css('height');
            currDescriptionHeight = currDescriptionHeight.replace('px', '');

            if (parseInt(currDescriptionHeight) < parseInt(descriptionHeight)) { 
                $description.css('height', '');
                $('.community-description-see-more').text('See less');
            } else {
                $description.css('height', amenitiesHeight - (2*descriptionLineHeight));
                $('.community-description-see-more').text('See more');
            }
        }
    }
    

    // Due to SM breakpoint having two different flex views, this has to be done
    // SM has to be vertical if two videos, and horizontal if 1 or 3
    this.handleVideoLayout = function() {
        var $videoContainer = $('.CommunityOverview__video-container').first();
        var $amenities = $('.CommunityOverview__amenities').first();
        var $videos = $('.CommunityOverview__video');
        var videoContainerChildrenCount = $videoContainer.children().length;
        var windowWidth = $(window).outerWidth();
        $videoContainer.removeClass('flex-vertical flex-horizontal p-3');
        $amenities.removeClass('tall-column');
        $videos.removeClass('mx-2');
        if (windowWidth < 768) {
            $videoContainer.addClass('flex-vertical');
        } else if (windowWidth >= 768 && windowWidth < 1025) {
            if (videoContainerChildrenCount === 3) {
                $videoContainer.addClass('flex-horizontal');
            } else if (videoContainerChildrenCount === 2) {
                $videoContainer.addClass('flex-vertical');
                $amenities.addClass('tall-column border-right');
            } else {
                $videoContainer.addClass('flex-horizontal');
                $amenities.addClass('border-right');
            }
        } else if (windowWidth >= 1025 && windowWidth < 1920) {
            if (videoContainerChildrenCount === 3) {
                $videoContainer.addClass('p-3');
                $videos.addClass('mx-2');
            }
            $videoContainer.addClass('flex-horizontal');
            $amenities.addClass('border-right');
        } else {
            $videoContainer.addClass('flex-horizontal');
            $amenities.addClass('border-right');
        }
    }

    // See More for Amenity Bullets
    this.setupSeeMoreDeeplink = function () {
        var hasCommunityFeatures = $component.$element.data('has-community-features').toLowerCase() === 'true';
        var seeMoreLink = $component.$element.find('.see-more-link');
        var deeplinkTarget = $component.$element.data('see-more-deeplink-target');
        var targetElement;

        if (deeplinkTarget) {
            targetElement = document.getElementById(deeplinkTarget);
        }

        if (!hasCommunityFeatures && !targetElement && seeMoreLink) {
            $(seeMoreLink).remove();

            return;
        }

        $(seeMoreLink).on('click', function (event) {
            if (targetElement) {
                event.stopPropagation();
                utils.scrollToTarget(targetElement);
            }
        });
    }
}

module.exports = component(CommunityOverview);
