var pulte = require('../../../assets/pulte/map-styles.json');
var delwebb = require('../../../assets/del-webb/map-styles.json');
var divosta = require('../../../assets/divosta/map-styles.json');
var centex = require('../../../assets/centex/map-styles.json');
var jw = require('../../../assets/jw/map-styles.json');
var americanwest = require('../../../assets/americanwest/map-styles.json');

module.exports = {
    pulte: pulte,
    delwebb: delwebb,
    divosta: divosta,
    centex: centex,
    jw: jw,
    americanwest: americanwest
};
