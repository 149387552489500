var component = require('../../../lib/js/component.js');
var evt = require('./slideshow-events');
var SlideshowModal = require('./slideshow-modal.js');
var ResponsiveMedia = require('../responsive-media/responsive-media.js');
var LeadFormSlide = require('../lead-form-slide/lead-form-slide.js');


function Slideshow () {

    /**
     * Init. It's time to get started.
     * @param  {Object} element HTML element
     * @param  {Object} options Component options
     */
    this.initialize = function (element, options) {

        var _this = this;
        _this.$main = _this.find('.SlideshowCarousel-main');

        _this.options = options || {};

        _this.find('.SlideshowModal').attach(SlideshowModal);

        _this.$element.on('click', '.SlideshowThumbnail', this.openModal.bind(this));

        // add the custom play button to each video in the carousel this slideshow is attached to
        var playButtons = $(_this.element.parentElement).prev().find('.owl-video-play-icon');
        playButtons.each(function() {
            $(this.parentElement).append('<div class="btn-play-video"></div>');
            $(this).addClass('hasModalGallery');
        });

        // if a carousel image needs to trigger the gallery modal, find the closest slideshow and display it
        $(document).on('click', '.Carousel-modalTrigger', function(event) {
            var data = $(this).data();

            var $sourceCarousel;
            var $sourceTarget = $(event.target);

            // if slideshowid is detected, use it to get the carousel for that section
            if (data.slideshowid) {
                $sourceCarousel = $sourceTarget.closest("section").find(".Carousel[data-external-slideshow-id='" + data.slideshowid + "']");
            } else {
                $sourceCarousel = $sourceTarget.closest(".Carousel");
            }

            // load slides for carousel
            var $carouselSlides = $sourceCarousel.find('.owl-item:not(.cloned)');

            // load settings for carousel
            var excludeThumbnails = $sourceCarousel.data("expanded-noThumbnails");
            var excludeCaptions = $sourceCarousel.data("expanded-noCaptions");
            var excludeSocial = $sourceCarousel.data("expanded-noSocial");
            var videoTourCtaType = $sourceCarousel.data("expanded-tour-video-cta-type");
            var showVideoTourCta = $sourceCarousel.data("show-expanded-tour-video-cta");
            var wrapCaption = $sourceCarousel.data("lightbox-caption-wrap");
            var slidesCount = $sourceCarousel.data("slides-count");

            // create expanded carousel
            var $targetSlideshow = _this.$element.find('.SlideshowCarousel');
            $targetSlideshow.removeClass('is-initialized');
            $targetSlideshow.find('.SlideshowCarousel-main').remove();

            var $targetElement = $('<div class="SlideshowCarousel-main owl-carousel owl-theme Carousel Carousel--withCaption"></div>');

            if (excludeCaptions) $targetElement.addClass('Carousel--noCaptions');
            if (excludeSocial) $targetElement.addClass('Carousel--noSocial');

            var $targetThumbnailSlideshow = _this.$element.find('.SlideshowCarousel-thumbnails');
            $targetThumbnailSlideshow.find('.owl-carousel').remove();

            var $targetThumbnails = $('<div class="owl-carousel owl-theme"></div>');

            $carouselSlides.each(function(idx, slide) {

                $(slide).children().each(function(index, slideContent) {

                    var $slideContent = $(slideContent);

                    // Check type of slide image|video|form
                    var slideType = $slideContent.data("type");
                    var slideIndex = $slideContent.find('.Carousel-modalTrigger').data("index");

                    // Move slide content
                    var $newSlide = $('<div class="Slide"></div>');
                    var $newThumbnailSlide = $('<a href="#" class="Slide" role="button">');

                    if (slideType === "video") {
                        var $itemVideoDiv = $('<div class="item-video"></div>');
                        $slideContent.find('.Carousel-modalTrigger .owl-video').clone().appendTo($itemVideoDiv);
                        $itemVideoDiv.appendTo($newSlide);
                        // Just grab image that is already cached (use a data attribute on item-video data-tn="blah.jpg")
                        var ytVideoId = $slideContent.data("tn");
                        if (ytVideoId.length) {
                            var $ytVideoTn = $('<img src="//res.cloudinary.com/dv0jqjrc3/image/fetch/ar_1.5,c_fill,f_auto,q_auto,w_100/https://img.youtube.com/vi/' + ytVideoId + '/default.jpg" />');
                            $ytVideoTn.appendTo($newThumbnailSlide);
                        }
                    } else if (slideType === "form") {
                        $slideContent.find('.LeadFormSlide').clone().appendTo($newSlide); 
                    } else { // image
                        if (slideIndex === 1 && showVideoTourCta) {
                            // check which type of Cta and then add button
                            if (videoTourCtaType === 'Plan YouTube Video' || videoTourCtaType === 'Inventory YouTube Video' || videoTourCtaType === 'Community YouTube Video') {
                                _this.addTourCta($newSlide, 'Video-Tour');
                            } else if (videoTourCtaType === 'Virtual Tour') {
                                _this.addTourCta($newSlide, 'Virtual-Tour');
                            } else if (videoTourCtaType === '3D Tour' || videoTourCtaType === 'Virtual Reality Tour') {
                                _this.addTourCta($newSlide, 'VR-Tour');
                            }
                        }

                        var $newSlideImage = $slideContent.find('.Carousel-modalTrigger img').clone();
                        $newSlideImage.removeAttr("data-analytics-cta");

                        $newSlideImage.clone().appendTo($newSlide);
                        $slideContent.find('.ImageCaption').clone().appendTo($newSlide);
                        $slideContent.find('.Social-links').clone().appendTo($newSlide);
                        $newSlideImage.appendTo($newThumbnailSlide);
                    }

                    // Add code for slide count
                    if (slideType !== "form") {
                        var $countElement = $('<div class="Carousel-imagesCount"><p>' + slideIndex + ' <span class="lighter">of ' + slidesCount + '</span></p></div>');
                        $newSlide.append($countElement);
                    }

                    $targetElement.append($newSlide);

                    if (slideType == "form") {
                    $targetThumbnails.append($('<a href="#" class="Slide leadFormThumbnail" role="button">Learn more</a>'))
                    } else {
                        $targetThumbnails.append($newThumbnailSlide);
                    }
                    
                    // Attach to lead form slide functionality
                    $targetElement.find('.LeadFormSlide').attach(LeadFormSlide);
                });
            });

            $targetSlideshow.prepend($targetElement);

            if (!excludeThumbnails) {
                $targetThumbnailSlideshow.prepend($targetThumbnails);
            } else {
                $targetThumbnailSlideshow.hide()
            }

            if (wrapCaption) {
                $targetElement.addClass('wrap-caption');
            }

            $responsiveImg = _this.find('img.u-responsiveMedia');
            $responsiveImg.attach(ResponsiveMedia);

            $responsiveImg.trigger("RESPONSIVE_LAZYLOAD");
            $responsiveImg.css('display', 'block');

            $('#ExpandedCarousel').find('.SlideshowCarousel').trigger('slideshow.load.slides');
            $('#ExpandedCarousel').addClass('active');

            _this.trigger(evt.OPEN, data);
        });
    };

    this.addTourCta = function ($slide, tourType) {
        var _this = this;
        var $tourModal = $('[id^='+tourType+']').find('.modal');

        if ($tourModal.length > 0) {
            $slide.append("<div class='btn-play-video Slide__videoTourCta'></div>");
            var videoTourCta = $slide.find('.Slide__videoTourCta');

            videoTourCta.on('click', function() {
                _this.trigger(evt.CLOSE, null);
                $tourModal.modal('show');
            });
        }
    };

    /**
     * Open the modal.
     * @param  {jQuery Event} event jQuery click event
     */
    this.openModal = function (event) {
        var $el = $(event.currentTarget);
        var data = $el.data();
        // for mobile devices, find offset from the top of the
        // current element to pass to modal.
        data.offset = event.currentTarget.offsetTop;
        // trigger the modal event
        this.trigger(evt.OPEN, data);

        event.preventDefault();
    };

}

module.exports = component(Slideshow);
