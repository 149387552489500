var component = require('../../../lib/js/component.js');
var constants = require('./constants.js');
var Field = require('./field.js');
var recaptcha = require('../../../lib/js/recaptcha.js');

var GroupComponents = {};
GroupComponents.FieldGroupZipCountry = require('./field-group-zip-country.js');
GroupComponents.FieldGroupPasswords = require('./field-group-passwords.js');
GroupComponents.FieldGroupPhone = require('./field-group-phone.js');

var Form = function () {

    var fieldSelectors = [
        '.Field-text',
        '.Field-radio',
        '.Field-select',
        '.Field-checkbox',
        '.Field-topic',
        '.Field-form',
        '.Field-comm',
        '.Field-itemId',
        '.Field-redirectUrl',
        '.Field-navId',
        '.Field-brand',
        '.Field-source',
        '.Field-subject',
        '.Field-to',
        '.Field-template'
    ];

    var fieldGroupSelectors = [
        {
            class: '.FieldGroup-zipCountry',
            component: 'FieldGroupZipCountry'
        }, {
            class: '.FieldGroup-passwords',
            component: 'FieldGroupPasswords'
        }, {
            class: '.FieldGroup-phone',
            component: 'FieldGroupPhone'
        }
    ];

    this.initialize = function (element, options) {
        this.options = options || {};

        this.fields = [];

        var selectors = fieldSelectors.join();

        if (fieldGroupSelectors.length > 0) {
            selectors += ',';
        }
        for (i = 0; i < fieldGroupSelectors.length; i++) {
            selectors += fieldGroupSelectors[i].class;
            if (i < fieldGroupSelectors.length - 1) {
                selectors += ',';
            }
        }

        // listen for Field and FieldGroup initialization events
        this.$element.on(constants.FIELD_INIT, selectors, this.handleFieldInit.bind(this));
        this.$element.on(constants.FIELD_CHECKED, selectors, this.handleFieldCheck.bind(this, event));
        this.initializeFields();

        this.$submit = this.find('[type="submit"]');
        this.$element.submit(this.handleSubmit.bind(this));
        //Prefilled form
        if(this.$element.find(':invalid').length === 0){
            this.$submit.prop('disabled', false);
        }

        this.displayErrorMessages();
    };

    this.initializeFields = function () {
        // initialize FieldGroups first
        for (var i = 0; i < fieldGroupSelectors.length; i++) {
            this.find(fieldGroupSelectors[i].class).attach(GroupComponents[fieldGroupSelectors[i].component]);
        }

        // initialize Field components second
        this.find(fieldSelectors.join()).attach(Field);
    };

    this.handleSubmit = function (event) {
        // with valid or invalid forms, disable submit
        this.$submit.prop('disabled', true);

        if (this.isValid()) {
            // handle ajax submissions
            if (this.$element.data('ajax')) {
                this.$element.removeClass('form-to-send');
                event.preventDefault();

                var form = {
                    data: this.gatherFormData(),
                    options: {
                        method: this.$element.attr('method'),
                        action: this.$element.attr('action')

                    }
                }

                // add recaptcha response to form data
                recaptcha.addRecaptchaResponseToForm(form, this.$element);

                // the parent component for this form will handle the ajax call.
                this.$element.trigger(constants.FORM_AJAX_SUBMIT, form);
                return;
            }

            // if we get here, traditional form submission happens
            // since the form/submit event hasn't been blocked

        } else {
            event.preventDefault();
        }
    };

    this.isValid = function (event) {
        for (var i = 0; i < this.fields.length; i++) {
            if (!this.fields[i].isValid()) {
                return false;
            }
        }
        this.$element.removeClass('dirty-form');
        this.$element.addClass('form-to-send');
        return true;
    };

    this.handleFieldInit = function (event, field) {
        this.fields.push(field);
    };

    this.handleFieldCheck = function (event) {
        this.$element.trigger(constants.FORM_CHANGED);
        this.$element.addClass(constants.DIRTY_CLASS);

        var valid = this.isValid();
        this.$submit.prop('disabled', !valid);
    };

    this.gatherFormData = function () {
        var data = {};
        for (var i = 0; i < this.fields.length; i++) {
            data = this.fields[i].appendData(data);
        }
        return data;
    };

    this.displayErrorMessages = function () {
        var _this = this;

        this.requiredInputs = this.find('input, select, textarea');
        this.$validationMsg = this.find('.inline-message');
        this.$validationMsgTarget = this.find('.error-target');

        this.requiredInputs.on('keyup change paste blur focus', checkInputValidation);

        function checkInputValidation(event) {
            var $currentInput = $(event.target);

            setTimeout(function () {
                if ($currentInput.closest('.Field, .Field-select').hasClass('has-error') && event.type !== 'focus' && !_this.$validationMsg.is(':visible')) {
                    if ((event.type !== 'keyup' || event.keyCode === 9) ) {
                        setDisplayMessage($currentInput);
                    }
                }
                else {
                    checkFormValidation($currentInput.closest('form'));
                }
            }, 500);
        }

        function checkFormValidation($form) {
            var $formErrors = $form.find('.has-error input, .has-error select, input.has-error');
            if ($formErrors.length > 0) {
                setDisplayMessage($($formErrors[0]));
            }
            else {
                _this.$validationMsg.hide();
            }
        }

        function setDisplayMessage($input) {
            var fieldName = $input.attr('data-error-desc') ? $input.attr('data-error-desc') : $input.attr('name');
            if ($input != null && $input.attr('name') == 'LastName') {
                _this.$validationMsgTarget.html(fieldName + '. Last name must be 2 or more characters.');
            }
            else {
                _this.$validationMsgTarget.html(fieldName + '. ' + $input.prop('validationMessage'));
            }
            _this.$validationMsg.show();
        }

    }
};
module.exports = component(Form);
