var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');
var intersectionObserver = require('../../../lib/js/intersection-observer.js');

var ResponsiveMedia = function () {

    var EVT = {
        VIDEO_READY: 'canplaythrough'
    };

    var CLASS = {
        VIDEO_READY: 'is-showing-video'
    };

    this.initialize = function (element, options) {

        this.options = $.extend(options, this.$element.data());

        if (this.options.size) {
            this.setSize();
        }

        if(utils.is.mobile() && this.options.hideMobile) {
            this.$element.addClass('hidden-xs hidden-sm');
            return;
        }

        // Do not load lazy images; they will be loaded as needed in their containing component
        if (this.$element.hasClass('u-responsiveMedia--lazy') || this.$element.closest('.Carousel--lazy').length > 0) {
            this.$element.on("RESPONSIVE_LAZYLOAD", this.lazyLoadMedia.bind(this));
        } else {
            this.loadMedia();
        }

        // listen to mediator for resize event
        // if there are multiple sizes
        if(typeof this.options.size === 'object' && this.dataNameExists()) {
            this.listen('RESPONSIVE_RESIZE', this.onResize.bind(this));
        }

    };

    this.dataNameExists = function () {
      return this.options.name && this.options.name !== '';
    }

    /**
     * Handle resize of the browser window.
     * @param  {Object} event jQuery event
     * @param  {Number} width window.innerWidth
     */
    this.onResize = function () {

        if (this.setSize()) {
            this.updateImage(this.getUrl());
        }
    };

    /**
     * Try to set the size based on the inner width of the window.
     */
    this.setSize = function () {

        // get the size object keys
        // filter to obtain the values less than the current width
        // reverse the array to get the largest number
        var keys = Object.keys(this.options.size).filter(function(value) {
            return window.innerWidth > value;
        }).reverse();

        // if no keys match
        if(!keys[0]) return false;

        // set the current size
        this.size = this.options.size[keys[0]];

        return true;

    };

    /**
     * Get the current url of the image.
     * @return {string} the current url of the image
     */
    this.getUrl = function () {
        return [
            this.options.dam,
            (this.options.dam && this.options.dam.substr(-1) !== '/') ? '/' : '',
            this.size ? this.size : this.options.size,
            ',',
            this.options.transformations,
            '/',
            this.options.name
        ].join('');
    };

    /**
     * Update the image.
     * @param {string} value new src of the image
     */
    this.updateImage = function (value) {

        if (this.options.alt) {
            this.$element.attr('alt', this.options.alt);
        }

        // Do not load lazy images; they will be loaded as needed in their containing component
        if (this.$element.hasClass('u-responsiveMedia--lazy') || this.$element.closest('.Carousel--lazy').length > 0) {

            if (this.$element.data('src')) {
                this.$element.data('src', value);
            }

            this.$element.attr('data-csrc', value);

            this.$element.cloudinary_update({
                resizing: true
            });
        } else {
            this.$element.attr('data-csrc', value);
            intersectionObserver.addElementToIntersectionObserver(this.element);
        }
    };

    this.setupVideo = function () {
        var video = $.cloudinary.video(this.options.videoName, {
            controls: false,
            autoplay: false,
            loop: true,
            width: this.$element.width()
        });

        this.$video = $(video);
        this.$video.attr('preload', 'auto');
        this.$video.attr('muted', 'muted'); // Allow auto play on Chrome https://developers.google.com/web/updates/2017/09/autoplay-policy-changes
        this.$video.insertBefore(this.$element);
        this.$video.on(EVT.VIDEO_READY, this.addVideo.bind(this));
    };

    this.addVideo = function () {
        this.$video.addClass(CLASS.VIDEO_READY);
        if (!(this.currentTime > 0 && !this.paused && !this.ended && this.readyState > 2)) this.$video[0].play();
        this.$video.off(EVT.VIDEO_READY);
    };

    this.lazyLoadMedia = function (e, bypassIntersectionObserver) {

        if (!this.$element.hasClass("is-loaded")) {

            this.loadMedia();

            if (!this.$element.hasClass("u-responsiveVideo")) {
                if (bypassIntersectionObserver) {
                    this.$element.data('src', this.$element.attr('data-csrc'));
                } else {
                    intersectionObserver.addElementToIntersectionObserver(this.element);
                }
            }

            this.$element.off("RESPONSIVE_LAZYLOAD");
        }
    }

    this.loadMedia = function () {

        // if video
        if (this.$element.hasClass("u-responsiveVideo")) {

            if(this.options.videoName && this.options.videoName !== '') {
                this.setupVideo();
            }

        } else {

            // if image treated as background
            if(!this.$element.attr('src')) {
                // only update the image src if a data-name property was provided
                if (this.dataNameExists()) {
                    this.updateImage(this.getUrl());
                }
            }
        }

        this.$element.addClass("is-loaded");
        
    }

};

module.exports = component(ResponsiveMedia);
