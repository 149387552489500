module.exports = {
	designBoardClasses: {
        'createInit': 'DesignBoard-mode-create',
        'editInit': 'DesignBoard-mode-edit',
        'detailInit': 'DesignBoard-mode-detail',
        'loadShown': 'DesignBoard-loading',
        'detailEmptyState': 'DesignBoardDetail-empty'
    },

    itemsTypes: {
        'homes': 'Plan',
        'communities': 'Community',
        'images': 'Image'

    },

    resetMode : function( element ){
        $( element ).removeClass( this.designBoardClasses.createInit );
        $( element ).removeClass( this.designBoardClasses.editInit );
        $( element ).removeClass( this.designBoardClasses.detailInit );
    },

    showLoading : function(){
        var designBoardEl = $( '.DesignBoardWrapper' );
        designBoardEl.addClass( this.designBoardClasses.loadShown );
    },

    hideLoading : function(){
        var designBoardEl = $( '.DesignBoardWrapper' );
        designBoardEl.removeClass( this.designBoardClasses.loadShown );
    },

    updateBoardName : function( newName ){
        var nameEl = $( '.DesignBoardDetail .DesignBoard-name-wrapper p' );
        nameEl.html( newName );
    },

    backToListPage : function( urlToGo ){
        window.location.href = urlToGo;
    },

    transformElementsToItems : function( elements ){
        var listOfItems = [];

        $.each( elements, function( index, item ){
            var itemToUpdate = {
                "Id": $( item ).data( 'id' ),
                "Name": $( item ).find( 'h4' ).text(),
                "Order": $( item ).data( 'order' ),
                "Url": $( item ).find( 'img' ).attr( 'src' )
            };

            listOfItems.push( itemToUpdate );

        });

        return listOfItems;
    },

    enterToEditModeFromButton : function( boardId ){
        window.location = '?id='+boardId+'&edit=true';
    },

    enterToDetailMode : function( boardId ){
        window.location = '?id='+boardId;
    },

    onSelectItemsEvent : function( listOfItems, clickFrom ){
        var addItemsBtn = $( '.DesignBoard-modal-footer .DesignBoard-add-items' );

        listOfItems.off();
        listOfItems.on( 'click', function(){
            $( this ).toggleClass( 'DesignBoard-item-selected' );

            if ( clickFrom === 'fromAddItems' ){
                var selectedItems = $( '.DesignBoard-item-selected' );

                if ( selectedItems.length !== 0 ){
                    addItemsBtn.prop("disabled", false);
                }
                else {
                    addItemsBtn.prop("disabled", true);
                }
            }
        });

    },

    onModalCloseEvent : function( boardWrapper, boardId ){
        var parentElement = $( boardWrapper ),
            currentBoardId = boardId;
        if ( !currentBoardId ){
            currentBoardId = parentElement.data( 'id' );
        }
        if ( parentElement.hasClass('DesignBoard-mode-create') ){
            window.location = '?id='+currentBoardId+'&edit=true';
        }
    }

}
