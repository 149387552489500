var component = require('../../../lib/js/component.js');

function VerticalTabs() {
    this.initialize = function (element, options) {
        var $element = $(element);
        var _this = this;
       
        _this.$verticalTabs = $element.find('.vertical-tab-container');
        _this.$accordionArrow = _this.$verticalTabs.find('.accordion-arrow');
        _this.$verticalNavTabsList = $element.find('.vertical-tabs');
        _this.$allVerticalTabs = _this.$verticalTabs.find('.vertical-tab');
        _this.$verticalMobile = $element.find('.vertical-tabs-mobile');
        _this.$collapseMobile = _this.$verticalTabs.find('.panel-collapse');
        _this.$titlePanel = $element.find('.verticalMobile-title');
        _this.$panelTitle = _this.$verticalTabs.find('.panel-title');

        _this.$images = _this.$element.find('img');
        
        var $firstVerticalTab = _this.$verticalTabs.find('.vertical-tab:first');
        var $firstVerticalTabContent = _this.$verticalTabs.find('.vertical-tab-content:first');
        var $sectionTabContent = $element.find('.vertical-tab-content');
        var $tabCards = $element.find('.Card-container');
        var deeplinkScrollToId = element.id;
        var HorizontalTabContainer = $element.parents('.HorizontalTabs');

        // If a Horizontal Tab Container exists as a parent of the vertical tab container, use the ID of the Horizontal Tab Container for deeplink scrolling
        // and set the deeplink scrollto of the Vertical Tab Container
        if (HorizontalTabContainer != null && HorizontalTabContainer.length > 0) {
            deeplinkScrollToId = HorizontalTabContainer[0].id;

            $element.attr("data-deeplink-scrollto", '#' + deeplinkScrollToId);
        }

        // Add the Vertical Tabs section Id as the data-deeplink-scrollto value to each vertical tab and tab card
        $sectionTabContent.each(function () {
            $(this).attr("data-deeplink-scrollto", '#' + deeplinkScrollToId);
        });

        $tabCards.each(function() {
            $(this).attr("data-deeplink-scrollto", '#' + deeplinkScrollToId);
        });

        $firstVerticalTab.addClass('active');
        $firstVerticalTabContent.addClass('active');

        // Load the first image
        if(_this.$images != null && _this.$images.length != 0) {
            $.cloudinary.responsive();
        }

        // When not in experience editor, restructure HTML for bootstrap tabs
        if (!_this.$verticalTabs.hasClass('expEditorEditing')) {
            $cloneToAppend = _this.$allVerticalTabs.clone();
            $cloneToAppend.detach().appendTo(_this.$verticalMobile);
            _this.$allVerticalTabs.detach().appendTo(_this.$verticalNavTabsList);
        }

        _this.$titlePanel[0].innerHTML = $firstVerticalTab.first().text();

        _this.$verticalMobileToggle = $element.find('.vertical-tabs-mobile li');

        _this.$collapseMobile.on('show.bs.collapse hide.bs.collapse', function () {
            _this.$accordionArrow.toggleClass('flip-arrow');
        });
        _this.$allVerticalTabs.on('click touch', _this.activeToggle.bind(_this));
        _this.$verticalMobileToggle.on('click touch', _this.activeToggleMobile.bind(_this));
        _this.$element.on('vt-tab-change', _this.selectTabByElement.bind(_this));
    }

    this.activeToggle = function (e) {
        var $clickedTab = $(e.currentTarget.firstElementChild);

        this.$titlePanel[0].innerHTML = $clickedTab.text();

        this.$verticalMobileToggle.find('a[href="' + $clickedTab[0].hash + '"]').tab('show');
        
        this.refreshTabImage($clickedTab);
    }

    this.activeToggleMobile = function (e) {
        var $clickedTab = $(e.currentTarget.firstElementChild);

        this.$titlePanel[0].innerHTML = $clickedTab.text();
        this.$collapseMobile.collapse('hide');

        this.$allVerticalTabs.find('a[href="' + $clickedTab[0].hash + '"]').tab('show');
        
        this.refreshTabImage($clickedTab);
    }

    this.deeplink = function (event, val) {
        var $deeplinkElement = $(event.currentTarget.firstElementChild).find('[data-deeplink="' + val + '"]');

        this.selectTabByElement(null, $deeplinkElement);
    }

    this.selectTabByElement = function(e, $selectedElement) {
        if ($selectedElement && $selectedElement.length)
        {
            var $href = null;
            if ($selectedElement.hasClass('Card-container')) {
                tabCardParent = $selectedElement[0].parentElement;
                if (tabCardParent != null) {
                    $href = tabCardParent.id;
                }
            } else {
                $href = $selectedElement[0].id;
            }
            
            if ($href != null) {
                var $tab = this.$verticalTabs.find('a[href="#' + $href + '"]');

                if ($tab.length && $tab[0]) {
                    this.$titlePanel[0].innerHTML = $tab[0].innerHTML;
                    this.$allVerticalTabs.find('a[href="#' + $href + '"]').tab('show');
                    this.$verticalMobileToggle.find('a[href="#' + $href + '"]').tab('show');
                }
            }
        } 
    }

    this.refreshTabImage = function(openTab) {
        // Refresh the card image if it hasn't fully loaded yet
        var $openTabImage = this.find(openTab[0].hash).find('img');
        if($openTabImage != null && !$openTabImage.hasClass('is-fully-loaded')) {
            $openTabImage.cloudinary_update();
            $openTabImage.addClass('is-fully-loaded');
        }
    }
}
module.exports = component(VerticalTabs);
