var component = require('../../../lib/js/component.js');
var constants = require('../forms/constants.js');
var Form = require('../forms/form.js');
var analyticsForm = require('../forms/analyticsForm');
var AdobeLaunch = require('../../../lib/js/adobelaunch.js');
var FacebookPixel = require('../../../lib/js/facebookpixel.js');

var ServiceRequests = function () {
    var titleRequest,
        apiKeyCloudinary,
        inputCloudinary,
        ticketLabel,
        $formSteps,
        $formTicket,
        $ticketSelects,
        $addRepairRequest,
        $closeRequest,
        $visibleElement,
        visibleElementsCount,
        $addRepairRequestButton,
        $thankyouModal,
        $removeImage,
        originalURL,
        imageURL,
        cloudinaryName,
        hiddenClass = 'hidden',
        visibleClass = 'visible',
        lastFormStepClass = 'last',
        visibleSteps = '.ServiceRequests-form-step.visible',
        stepTitle = '.ServiceRequests-titleTicket h3',
        app = window.Pulte || {};

    this.initialize = function (element, options) {
        inputCloudinary = $(element).find('.cloudinary-fileupload'),
            cloudinaryName = $('input[name="CloudinaryName"]').val(),
            apiKeyCloudinary = inputCloudinary.first().data('form-data')['api_key'],
            $formSteps = $(element).find('.ServiceRequests-form-step'),
            $ticketSelects = $formSteps.find('select'),
            $formTicket = $(element).find('.ServiceRequests-ticket'),
            ticketLabel = $(element).find(stepTitle).first().data('ticket') + ' ',
            $addRepairRequest = $(element).find('.ServiceRequests-addTicket'),
            $closeRequest = $(element).find('.ServiceRequests-closeTicket'),
            $submitButton = $(element).find('.btn-submmit-repair'),
            $thankyouModal = $(element).find('.ServiceRequests-thank-you'),
            $visibleElement = $(visibleSteps),
            visibleElementsCount = $visibleElement.length,
            $addRepairRequestButton = $addRepairRequest.find('button'),
            $stateDropdown = $(element).find('select[name="State"]'),
            $regionDropdown = $(element).find('select[name="Region"]'),
            $regionInstructions = $(element).find('.ServiceRequests-localReps-instructions'),
            $regionPhone = $(element).find('.ServiceRequests-localReps-phone'),
            $serviceEmail = $("input[name=To].service-to"),
            $defaultEmail = $serviceEmail.val(),
            $removeImage = $(element).find('.ServiceRequests-remove-image');

        this.errorMessage = $(element).find('.MsgModal--error')[0];
        this.options = options || {};
        this.$form = $($(element).find('form')[0]);

        $closeRequest.on('click', this.onRemoveRepair.bind(this));
        $addRepairRequestButton.on('click', this.onAddRepair.bind(this));
        $removeImage.on('click', this.onRemoveImage.bind(this));
        $ticketSelects.on('change', this.onSelectsTicketsChange.bind(this));
        $thankyouModal.on('hide.bs.modal', this.closeThankYouModal.bind(this));
        $stateDropdown.on('change', this.populateRegions.bind(this));
        $regionDropdown.on('change', this.showRegionTelephone.bind(this));

        this.$form
            .attach(Form)
            .on(constants.FORM_AJAX_SUBMIT, this.handleFormSubmission.bind(this))
            .on(constants.FORM_CHANGED, this.handleFormChanged.bind(this));

        $visibleElement.css('order', visibleElementsCount);
        titleRequest = $visibleElement.find(stepTitle);
        titleRequest.text(ticketLabel + visibleElementsCount);

        $.cloudinary.config({ cloud_name: cloudinaryName, api_key: apiKeyCloudinary });

        inputCloudinary.bind('cloudinarydone', function (e, data) {
            plusElement = e.currentTarget;
            $imageElement = $(plusElement.parentElement);
            imageIcon = $imageElement.find('.ServiceRequests-custom-file-upload');
            originalURL = data.result.url.split('upload');
            imageURL = originalURL[0] + 'upload/w_140,c_fill' + originalURL[1];
            $imageElement.attr('data-url', data.result.url);
            $imageElement.css('background-image', 'url(' + imageURL + ')');
            $imageElement.css('background-size', 'cover');
            $imageElement.css('background-position', 'center');
            imageIcon.addClass('hasImage');
            $('body').css('cursor', '');
        }).cloudinary_fileupload({
            start: function (e) {
                $('body').css('cursor', 'wait');
            }
        });

        AdobeLaunch.pushFormViewedEvent(AdobeLaunch.FORM_GLOBAL_ID, AdobeLaunch.FORM_INLINE_TYPE, AdobeLaunch.FORM_SERVICE_REQUEST_NAME);
    };

    this.populateRegions = function (e) {
        var onRegionsFetchSuccess,
            onRegionsFetchComplete,
            $parentform = this.$form,
            $statesList = $(e.currentTarget);

        $regionPhone.text('');

        onRegionsFetchSuccess = function (data) {
            var defaultOption = $regionDropdown.children().first()[0].outerHTML;
            $regionDropdown.empty().append(defaultOption);
            if (Array.isArray(data)) {
                data.forEach(function (region) {
                    $regionDropdown.append('<option value="' + region.Name + '" data-phone ="' + region.TelephoneNumber + '" data-email="' + region.ServiceEmail + '">' + region.Name + '</option>');
                });
                $regionDropdown.prop('disabled', false);
                $regionDropdown.change();
                return;
            }
            return onError(null, 'Unknown Error');
        };
        $regionDropdown.prop('disabled', true);

        if ($statesList.val() !== '') {
            $.ajax({
                url: '/API/Region/GetServiceRegions',
                data: {
                    State: $statesList.val(),
                    Brand: app.Brand.replace("-", " ")

                },
                dataType: 'json'
            })
                .fail(onError)
                .done(onRegionsFetchSuccess);
        }
    };

    this.showRegionTelephone = function (e) {
        var $regionTelephone = $(e.currentTarget).find('option:selected').data('phone');
        var $currentServiceEmail = $(e.currentTarget).find('option:selected').data('email');
        $regionInstructions.hide();
        $regionPhone.text($regionTelephone);
        if ($currentServiceEmail) {
            $serviceEmail.val($currentServiceEmail);
        } else {
            $serviceEmail.val($defaultEmail);
        }
        $regionPhone.show();
    }


    this.onSelectsTicketsChange = function (e) {
        var addRequestButtonDisable = true;
        $(visibleSteps).find('select').each(function () {
            var currentSelect = $(this);
            if (currentSelect.val().length > 0) {
                addRequestButtonDisable = false;
            } else {
                addRequestButtonDisable = true;
                return false;
            }
        });

        $addRepairRequestButton.prop('disabled', addRequestButtonDisable);
    };

    this.onRemoveImage = function (e) {
        closeElement = e.currentTarget;
        $imageElement = $(closeElement.parentElement);
        imageIcon = $imageElement.find('.ServiceRequests-custom-file-upload');
        $imageElement.removeAttr('style');
        imageIcon.removeClass('hasImage');
    };

    this.handleFormSubmission = function (event, form) {
        $(this.errorMessage).hide();

        var data = this.getFormData(form.data),
            $modalFormEl = $('.ServiceRequests-thank-you-create-account'),
            $nameEl = $modalFormEl.find('[name="FirstName"]'),
            $lastNameEl = $modalFormEl.find('[name="LastName"]'),
            $emailEl = $modalFormEl.find('[name="Email"]'),
            $zipEl = $modalFormEl.find('[name="ZipCode"]');

        $nameEl.val(data.FirstName);
        $lastNameEl.val(data.LastName);
        $emailEl.val(data.Email);
        $zipEl.val(data.ZipCode);

        $.ajax({
            method: form.options.method,
            url: form.options.action,
            data: data,
            dataType: 'json',
            context: this,
            success: function (data) {
                var errorMessage = [];
                if (data.success) {
                    $thankyouModal.modal('show');

                    AdobeLaunch.pushFormSubmitSucceededEvent(AdobeLaunch.FORM_GLOBAL_ID, AdobeLaunch.FORM_INLINE_TYPE, AdobeLaunch.FORM_SERVICE_REQUEST_NAME);
                    FacebookPixel.pushFormSubmissionEvent(FacebookPixel.FORM_INLINE_TYPE, FacebookPixel.FORM_SERVICE_REQUEST_NAME);

                    //rewrite error function
                    analyticsForm.RewriteObjectForm(data, event);
                } else {
                    for (var field in data.fieldErrors) {
                        errorMessage.push(data.fieldErrors[field]);
                    }
                    this.onError(data, errorMessage.join('. '), event);
                }
            },
            fail: this.onError.bind(this)
        });

        return false;
    };

    this.getFormData = function (data) {
        var tickets = [],
            images;
        data.emailTemplate = this.$element.find('input[name="EmailTemplate"]').val();
        data.imageTemplate = this.$element.find('input[name="ImageTemplate"]').val();
        data.subject = this.$element.find('input[name="Subject"]').val();
        data.ticketTemplate = this.$element.find('input[name="TicketTemplate"]').val();
        data.to = this.$element.find('input[name="To"]').val();
        data.serviceEmail = this.$element.find('input[name="ServiceEmail"]').val();

        this.$element.find('.ServiceRequests-ticket').children('fieldset.visible').each(function () {
            var currentTicket = $(this);
            images = [];

            currentTicket.find('.ServiceRequests-image').each(function () {
                var urlCurrentImage = $(this).data('url');

                if (urlCurrentImage) {
                    images.push(urlCurrentImage);
                }
            });

            var ticket = {
                'NeedOfRepair': currentTicket.find('select.needToRepair').val(),
                'RepairLocation': currentTicket.find('select.located').val(),
                'ProblemDescription': currentTicket.find('textarea').val(),
                'Images': images
            }

            tickets.push(ticket);
        });

        data.tickets = tickets;

        return data;
    }

    this.onError = function (data, textStatus, event) {
        $(this.errorMessage).html(data && data.responseJSON ? data.responseJSON.error : textStatus);
        $(this.errorMessage).show();

        $('html, body').animate({
            scrollTop: $(this.errorMessage).offset().top
        }, 1000);

        AdobeLaunch.pushFormSubmitFailedEvent(AdobeLaunch.FORM_GLOBAL_ID, AdobeLaunch.FORM_INLINE_TYPE, AdobeLaunch.FORM_SERVICE_REQUEST_NAME);

        //rewrite error function
        analyticsForm.RewriteObjectForm(data, event);
    };

    this.onAddRepair = function () {
        var nextStep = $($formSteps.not('.' + visibleClass)[0]);

        $formSteps.removeClass(lastFormStepClass);
        nextStep.addClass(visibleClass);
        nextStep.find('.Field-select ').addClass('is-empty has-error');
        nextStep.find('select').prop('disabled', false);
        nextStep.find('select.needToRepair').change();

        this.changeItemOrder(nextStep);
        this.goToTheCurrentTicket(nextStep);
        this.onSelectsTicketsChange();
    };

    this.onRemoveRepair = function (e) {
        var ticketIndex,
            currentStep = $($(e.target).parents()[3]),
            previousStep = currentStep.prev();

        this.clearFields(currentStep);

        currentStep.removeClass(visibleClass);
        currentStep.removeClass(lastFormStepClass);

        this.changeItemOrder(currentStep);

        $visibleElement.each(function (index) {
            ticketIndex = index + 1;
            $(this).css('order', ticketIndex);
            titleRequest = $(this).find(stepTitle);
            titleRequest.text(ticketLabel + ticketIndex);
        });

        if (previousStep.length == 0) {
            previousStep = $(visibleSteps).first();
        }

        previousStep.find('select.needToRepair').change();

        this.goToTheCurrentTicket(previousStep);
        this.onSelectsTicketsChange();
    };

    this.changeItemOrder = function (item) {
        var titleRequest = item.find(stepTitle);

        $visibleElement = $(visibleSteps);
        visibleElementsCount = $visibleElement.length;

        item.css('order', visibleElementsCount);
        titleRequest.text(ticketLabel + visibleElementsCount);

        if (visibleElementsCount === 1) {
            $closeRequest.removeClass(visibleClass);
        }
        else {
            $closeRequest.addClass(visibleClass);
        }
    };

    this.goToTheCurrentTicket = function (componentScrollTo) {

        $visibleElement = $(visibleSteps);
        visibleElementsCount = $visibleElement.length;

        $($(visibleSteps)[visibleElementsCount - 1]).addClass(lastFormStepClass);

        $('html, body').animate({
            scrollTop: componentScrollTo.offset().top
        }, 1000);

        if (visibleElementsCount === $formSteps.length) {
            $addRepairRequest.addClass(hiddenClass);
        }
        else {
            $addRepairRequest.removeClass(hiddenClass);
        }
    };

    this.clearFields = function (component) {
        component.find('select').val('');
        component.find('textarea').val('');
        component.find('.ServiceRequests-image').removeAttr('style');
        component.find('.ServiceRequests-custom-file-upload').removeClass('hasImage');
        component.find('select').prop('disabled', true);
    }

    this.handleFormChanged = function () {
        this.$form.off(constants.FORM_CHANGED);
        AdobeLaunch.pushFormStartedEvent(AdobeLaunch.FORM_GLOBAL_ID, AdobeLaunch.FORM_INLINE_TYPE, AdobeLaunch.FORM_SERVICE_REQUEST_NAME);
    }

    this.closeThankYouModal = function (component) {
        $(location).attr('href', '/')
    }
};

module.exports = component(ServiceRequests);
