var component = require('../../../lib/js/component.js');

function EditorialTileContainer() {

    /**
     * Init for the Card module
     * @param  {Object} element HTML element
     */
    this.initialize = function (element) {

        
        $element = $(element);

        if ($element.find('.Text-cta a').length) { 
            
            $element.addClass('Tile--isLink');

                var $body = $element.find('.tile');


                $body.click(function() {
                    var $cta = $(this).find('.Text-cta a');
                    var cta = $cta.attr('href');
                    var ctaTarget = $cta.attr('target');

                    if (cta) window.open(cta, ctaTarget ? ctaTarget : '_self');
                })

                $element.click(function(e) {
                    if(e.target !== e.currentTarget) { return; }

                    var $cta = $(this).find('.Text-cta a');
                    var cta = $cta.attr('href');
                    var ctaTarget = $cta.attr('target');

                    if (cta) window.open(cta, ctaTarget ? ctaTarget : '_self');
                })
            }

        }
    }

module.exports = component(EditorialTileContainer);