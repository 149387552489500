var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');

function HersModal() {

    this.initialize = function (element, options) {
        var _this = this;
        
        var hersModalLiteClass = 'HersModal--lite';
        var $plan = this.$element.find('.HersModal__headerPlan');
        var $location = this.$element.find('.HersModal__headerLocation');
        var $hersScoreMeter = this.$element.find('.HersScoreMeter');
        var $savingsSummary = this.$element.find('.HersModal__summary');
        var $savingsTable = this.$element.find('.HersModal__savingsTableWrapper');
        this.$summaryAmount = $savingsSummary.find('.HersModal__summarySavings');
        this.$savingsTable_row1 = $savingsTable.find('.HersModal__savingsTableRow1');
        this.$savingsTable_row2 = $savingsTable.find('.HersModal__savingsTableRow2');
        this.$savingsTable_row3 = $savingsTable.find('.HersModal__savingsTableRow3');
        this.$savingsTable_row4 = $savingsTable.find('.HersModal__savingsTableRow4');
        this.$savingsTable_row5 = $savingsTable.find('.HersModal__savingsTableRow5');
        this.$savingsTable_row2Difference = $savingsTable.find('.HersModal__savingsTableRow2Difference');
        this.$savingsTable_row3Difference = $savingsTable.find('.HersModal__savingsTableRow3Difference');
        this.$savingsTable_row4Difference = $savingsTable.find('.HersModal__savingsTableRow4Difference');
        this.$savingsTable_row5Difference = $savingsTable.find('.HersModal__savingsTableRow5Difference');

        $('.HersModal').on('show.bs.modal', function (e) {
            var ctaData = $(e.relatedTarget).data();
            
            $plan.text(ctaData.plan);
            $location.text(ctaData.community + ' | ' + ctaData.city + ', ' + ctaData.state);

            $hersScoreMeter.trigger('hers-score-meter-update', ctaData.hersScore);
            
            if (ctaData.row1 > 0 && ctaData.row2 > 0 && ctaData.row3 > 0 && ctaData.row4 > 0 && ctaData.row5 > 0) {
            
                _this.updateSavingsValues(ctaData.row1, ctaData.row2, ctaData.row3, ctaData.row4, ctaData.row5);
                
                _this.$element.removeClass(hersModalLiteClass);
            } else {
                _this.$element.addClass(hersModalLiteClass);
            }
        });
    };

    this.updateSavingsValues = function (row1, row2, row3, row4, row5) {
        var includeCents = true;
        var includeDollarSign = true;
        var summaryData = this.$summaryAmount.data();

        var row2Difference = row2 - row1;
        var row3Difference = row3 - row1;
        var row4Difference = row4 - row1;
        var row5Difference = row5 - row1;
        var maxDifference = Math.max(row2Difference, row3Difference, row4Difference, row5Difference);

        this.$summaryAmount.text(utils.formatPrice(maxDifference, includeCents, includeDollarSign) + summaryData.asterisk);
    
        this.$savingsTable_row1.text(utils.formatPrice(row1, includeCents, includeDollarSign));
        this.$savingsTable_row2.text(utils.formatPrice(row2, includeCents, includeDollarSign));
        this.$savingsTable_row3.text(utils.formatPrice(row3, includeCents, includeDollarSign));
        this.$savingsTable_row4.text(utils.formatPrice(row4, includeCents, includeDollarSign));
        this.$savingsTable_row5.text(utils.formatPrice(row5, includeCents, includeDollarSign));
        
        this.$savingsTable_row2Difference.text(utils.formatPrice(row2Difference, includeCents, includeDollarSign));
        this.$savingsTable_row3Difference.text(utils.formatPrice(row3Difference, includeCents, includeDollarSign));
        this.$savingsTable_row4Difference.text(utils.formatPrice(row4Difference, includeCents, includeDollarSign));
        this.$savingsTable_row5Difference.text(utils.formatPrice(row5Difference, includeCents, includeDollarSign));
    };
}

module.exports = component(HersModal);
