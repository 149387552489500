module.exports = {
  'directions': require('./info-window-directions.hbs'),
  'directionsV2': require('./info-window-directionsV2.hbs'),
  'features': require('./info-window-features.hbs'),
  'nearby': require('./info-window-nearby.hbs'),
  'filters': require('./info-window-filters.hbs'),
  'localArea': require('./info-window-local-area.hbs'),
  'route': require('./info-window-route.hbs')
};

