var component = require('../../../lib/js/component.js');
var analyticsForm = require('../forms/analyticsForm');
var AdobeLaunch = require('../../../lib/js/adobelaunch.js');
var utils = require('../../../lib/js/utils.js');
var constants = require('../forms/constants.js');

function GlobalModalLeadForm() {
    this.initialize = function (element, options) {

        this.$form = this.$element.find('.GlobalLeadForm');
        this.$isRealtorLabel = this.$element.find('.GlobalLeadForm__is-realtor-label');
        this.$isRealtorCheckbox = this.$element.find('input[name=IsRealtor]');
        this.$isRealtorCheckbox.prop("disabled", true);

        this.$realtorInfoContainer = this.$element.find('.GlobalLeadForm__realtor-info-container');
        this.$realtorNameInput = this.$realtorInfoContainer.find('input[name=BrokerOffice]');
        this.$realtorNumberInput = this.$realtorInfoContainer.find('input[name=BrokerId]');

        this.$statesDropdown = this.$element.find('select[name=state]');
        this.$statesDropdown.change(this.onStateDropdownChange.bind(this));

        this.$regionsDropdown = this.$element.find('select[name=region]');
        this.$regionsDropdown.change(this.onRegionDropdownChange.bind(this));

        this.$communitiesDropdown = this.$element.find('select[name=CommunityIds]');
        this.$communitiesDropdown.change(this.onCommunitiesDropdownChange.bind(this));

        this.$state = this.$element.find('.Field-location-state').val();
        this.$region = this.$element.find('.Field-location-region').val();
        this.$edhRegionIdInput = this.$element.find('.Field-edhregionid');

        if (this.$state) {
            this.preselectState();
        }

        //On GLFM Close
        $('#global-modal-lead-form').on('hidden.bs.modal', this.handleHide);

        $('#global-modal-lead-form').on('shown.bs.modal', function () {
            $('.modal-contact').hide()
        });

        $('#global-modal-lead-form').on('show.bs.modal', this.handleShow);

    }

    // Functions
    this.handleShow = function (e) {
        AdobeLaunch.pushFormViewedEvent(AdobeLaunch.FORM_GLOBAL_ID, AdobeLaunch.FORM_MODAL_TYPE, AdobeLaunch.FORM_REQUEST_INFO_NAME);
        
        if (e.relatedTarget) {
            if (e.relatedTarget.dataset.overrideimageurl) {
                // check if the CTA that fired the modal show has an override image url. 
                // the cta with the data-toggle and data-trigger is the related target to the show.bs.modal event
                var leadFormOverrideImageURL = e.relatedTarget.dataset.overrideimageurl;
                var $leadFormImage = $('#global-modal-lead-form').find('img');
                var $overrideImage = $("img.overrideimage[src='" + leadFormOverrideImageURL + "']");

                if ($overrideImage.length > 0) {
                    $leadFormImage.hide();
                    $overrideImage.show();
                } else if (leadFormOverrideImageURL) {
                    $leadFormImage.hide();
                    var dataSize = $leadFormImage.data('size');
                    var dataTransformations = $leadFormImage.data('transformations');
                    var dataDam = $leadFormImage.data('dam');
                    var csrc = dataDam + dataSize[0] + ',' + dataTransformations + '/' + leadFormOverrideImageURL;

                    var imgTag = document.createElement('img');
                    imgTag.src = leadFormOverrideImageURL;
                    $(imgTag).attr('data-csrc', csrc);
                    $(imgTag).attr('csrc', csrc);
                    $(imgTag).attr('data-size', dataSize);
                    $(imgTag).attr('data-transformations', dataTransformations);
                    $(imgTag).attr('data-dam', dataDam);
                    $(imgTag).attr('data-alt', e.relatedTarget.dataset.overridealttext);
                    $(imgTag).attr('alt', e.relatedTarget.dataset.overridealttext);

                    $(imgTag).addClass('overrideimage u-responsiveMedia cld-responsive is-loaded is-initialized');
                    document.querySelector('div.GlobalLeadForm__leftPanel > div.row').appendChild(imgTag);
                }
            }
        }
    }

    this.handleHide = function () {
        var uri = window.location.toString();
        if (uri.indexOf("#") > 0) {
            var cleanUri = uri.substring(0, uri.indexOf("#"));
            window.history.replaceState({},
                document.title, cleanUri);
        }
        $('#global-modal-lead-form').find('img').show();
        $('.overrideimage').each(function () {
            $(this).hide();
        });
    }

    this.preselectState = function () {
        this.$statesDropdown.val(this.$state);

        var state = this.$statesDropdown.val();
        var brand = window.Pulte.Brand ? window.Pulte.Brand.replace("-", " ") : 'Pulte';

        $.ajax({
            url: '/API/Region/Search',
            context: this,
            data: {
                State: state,
                Brand: brand
            },
            dataType: "json",
            success: this.onStatePreselected,
            error: this.onError,
            complete: this.resetCommunitiesDropdown
        });
    }

    this.preselectRegion = function () {
        this.$regionsDropdown.val(this.$region);
        this.onRegionDropdownChange();        
        this.$form.trigger(constants.REGION_SELECTED);
    }

    this.resetCommunitiesDropdown = function () {
        var children = this.$communitiesDropdown.children();
        if (children && children.length) {
            var $defaultOption = $(children).first().clone();

            this.$communitiesDropdown.empty();
            $defaultOption.appendTo(this.$communitiesDropdown);
        }
    }

    // Event handlers
    this.onCommunitiesDropdownChange = function () {
        var selectedCommunities = this.$communitiesDropdown.val();
        var communityIsSelected = selectedCommunities && selectedCommunities.length;

        this.toggleRealtorControls(communityIsSelected);
    }

    this.onError = function (data, textStatus, event) {
        analyticsForm.RewriteObjectForm(data, event);
        AdobeLaunch.pushFormSubmitFailedEvent(AdobeLaunch.FORM_GLOBAL_ID, this.formType, AdobeLaunch.FORM_REQUEST_INFO_NAME);
    }

    this.onGetRegionInfo = function (result, status, xhr) {
        if (result && result.EDHRegionId) {
            this.$edhRegionIdInput.val(result.EDHRegionId);
        }
    }

    this.onRegionDropdownChange = function (event) {
        var state = this.$statesDropdown.val();
        var region = this.$regionsDropdown.val();

        $.ajax({
            url: '/API/Region/GetRegionInfo',
            context: this,
            data: {
                state: state,
                region: region
            },
            success: this.onGetRegionInfo
        });

        this.toggleRealtorControls(false);
    }

    this.onStateDropdownChange = function (event) {
        this.toggleRealtorControls(false);
    }

    this.onStatePreselected = function (data) {
        var _this = this;

        var children = this.$regionsDropdown.children();
        if (children && children.length) {
            var $defaultOption = $(children).first().clone();

            this.$regionsDropdown.empty();
            $defaultOption.appendTo(this.$regionsDropdown);
        }

        if (data && data.length) {
            data.forEach(function (region) {
                var regionOption = document.createElement('option');

                regionOption.value = region.Name;
                regionOption.text = region.Name;

                _this.$regionsDropdown.append(regionOption);
            });

            this.$regionsDropdown.prop('disabled', false);

            if (this.$region) {
                this.preselectRegion();
            }

            return;
        }

        return onError(null, 'Unknown Error');
    }

    this.toggleRealtorControls = function (enabled) {
        if (enabled) {
            this.$isRealtorCheckbox.prop("disabled", false);
            this.$isRealtorCheckbox.addClass("enabled");
            if (Pulte.user && Pulte.user['IsRealtor'] && Pulte.user['IsRealtor'].toLowerCase() === 'true') {
                this.$isRealtorCheckbox.prop("checked", true);
                this.$realtorNameInput.val(Pulte.user['BrokerOffice']);
                this.$realtorNumberInput.val(Pulte.user['BrokerId']);
                this.$isRealtorCheckbox.trigger('change');
            }
        }
        else {
            this.$isRealtorCheckbox.prop("checked", false);
            this.$isRealtorCheckbox.prop("disabled", true);
            this.$isRealtorCheckbox.removeClass("enabled");

            this.$realtorNameInput.prop('disabled', true);
            this.$realtorNumberInput.prop('disabled', true);

            this.$isRealtorLabel.removeClass('d-none');
            this.$realtorInfoContainer.addClass('d-none');
        }
    }
}

module.exports = component(GlobalModalLeadForm);
