var owl = require('owl.carousel');
var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');
var evt = require('./slideshow-events.js');
var ResponsiveMedia = require('../responsive-media/responsive-media.js');

function SlideshowCarousel() {

    var THUMBNAILS_SMALL_SCREEN = 5;
    var THUMBNAILS_LARGE_SCREEN = 9;
    var LOOP = true;
    var indexThumbnail = 0;

    /**
     * Init. It's time to get started.
     * @param  {Object} element HTML element
     * @param  {Object} options Component options
     */
    this.initialize = function (element, options) {

        var _this = this;

        _this.options = options || {};

        _this.$el = $(element);

        _this.slidesAreLoaded = true;

        var $slideshowCarousel = _this.$element.find('.SlideshowCarousel-main');

        if ($slideshowCarousel.hasClass('Carousel--lazy')) _this.slidesAreLoaded = false;

        _this.$thumbnailsMenu = _this.find('.SlideshowCarousel-thumbnails');

        if (!_this.slidesAreLoaded) {
            // load first image in carousel
            var $firstImage = _this.$element.find('.SlideshowCarousel-main img').first();
            $firstImage.trigger("RESPONSIVE_LAZYLOAD");
            $firstImage.cloudinary_update();
            $.cloudinary.responsive();

            // load all images in carousel
            _this.$element.on('slideshow.load.slides', function () {

                if (!_this.slidesAreLoaded) {

                    _this.$element.find('.owl-item').each(function () {
                        $responsiveImg = $(this).find('img.u-responsiveMedia');

                        // Cloned items are not in DOM at page load and not processed by responsive media
                        if ($(this).hasClass('cloned')) {
                            $responsiveImg.attach(ResponsiveMedia);
                        }

                        $responsiveImg.trigger("RESPONSIVE_LAZYLOAD");
                    });

                    _this.$element.cloudinary_update();
                    $.cloudinary.responsive();

                    _this.slidesAreLoaded = true;
                }
            });
        }

        _this.listen(evt.OPEN, _this.onModalOpen.bind(_this));

        this.onThumbnailsResize();
    };

    /**
     * On thumbnail click update the current index.
     * @param  {jQuery Event} event Event
     */
    this.onThumbnailClick = function (event) {
        indexThumbnail = this.slicesThumbnails.index(event.currentTarget);
        this.updateIndex(indexThumbnail, false, true);
        event.preventDefault();
    };

    /**
     * On click of previous button tell thumbnail carousel.
     * @param  {jQuery Event} event Event
     */
    this.onThumbnailPrev = function (event) {
        this.$main.trigger('prev.owl.carousel');
        this.updatePreviousThumbnail();
    };

    this.updatePreviousThumbnail = function () {
        if (indexThumbnail > 0) {
            indexThumbnail--;
        } else {
            indexThumbnail = this.slicesThumbnailSize - 1;
        }

        this.updateSelectedThumbnail();
    };

    /**
    * On click of next button tell thumbnail carousel.
    * @param  {jQuery Event} event Event
    */
    this.onThumbnailNext = function (event) {
        this.$main.trigger('next.owl.carousel');
        this.updateNextThumbnail();
    };

    this.updateNextThumbnail = function () {
        if (indexThumbnail < this.slicesThumbnailSize - 1) {
            indexThumbnail++;
        } else {
            indexThumbnail = 0;
        }

        this.updateSelectedThumbnail();
    };

    this.updateSelectedThumbnail = function () {
        this.$thumbnails.trigger('to.owl.carousel', [indexThumbnail, 250, true]);
        this.slicesThumbnails.removeClass('is-active').removeAttr('aria-current')
        this.slicesThumbnails.eq(indexThumbnail).addClass('is-active').attr('aria-current', true);
    };

    /**
    * On resize of thumbnail carousel hide the custom navigation if it isn't needed.
    * @param  {jQuery Event} event Event
    */
    this.onThumbnailsResize = function (event) {
        var length = this.slicesThumbnailSize;
        if ((window.innerWidth < utils.breakpoints.sm && length < THUMBNAILS_SMALL_SCREEN) || length < THUMBNAILS_LARGE_SCREEN) {
            this.$thumbnailsMenu.find('.SlideshowCarousel-thumbnailMenu').addClass('hidden');
        } else {
            this.$thumbnailsMenu.find('.SlideshowCarousel-thumbnailMenu').removeClass('hidden');
        }
    };

    /**
     * On change of main carousel, do work.
     * @param  {jQuery Event} event Event
     */
    this.onMainChange = function (event) {
        var $slides = this.$main.find('.Slide');
        // If the slideshow is set to loop there are cloned slides.
        var position = LOOP ? event.item.index : event.property.value;
        var title = $slides.eq(event.item.index).data('category') || '';

        $slides.each(function () {
            $(this).attr('aria-hidden', true);
            $(this).attr('tabindex', -1);
        })
        $slides.eq(event.item.index).attr('aria-hidden', false);
        $slides.eq(event.item.index).removeAttr('tabindex');

        if (event.type === 'dragged') {
            // offset the index based on owl carousel cloning half of the slides
            var offset = Math.ceil(event.item.count / 2);
            indexThumbnail = position - offset;
            if (indexThumbnail >= event.item.count) { // dragged past the last slide, go to beginning
                indexThumbnail = 0;
            }
            if (indexThumbnail < 0) { // dragged past the first slide, go to end
                indexThumbnail = event.item.count;
            }
        }

        this.trigger(evt.UPDATE_TITLE, title);
        this.updateIndex(position, true, false);
    };

    /**
     * Update the index
     * @param  {Number} index         Current index
     * @param  {Boolean} tellThumbnail Tell thumbnail to update
     * @param  {Boolean} tellMain      Tell main to update
     */
    this.updateIndex = function (index, tellThumbnail, tellMain) {

        if (tellThumbnail) {
            this.updateSelectedThumbnail();
        }
        if (tellMain) {
            this.$main.trigger('to.owl.carousel', [index, 250, true]);
        }

    };

    /**
     * Open modal
     * @param  {jQuery Event} event   Event
     * @param  {Object} options Options Object
     */
    this.onModalOpen = function (event, options) {
        var _this = this;

        // initialize carousels if it doesn't exist yet
        var $slideshowCarousel = _this.$element.find('.SlideshowCarousel-main');

        if (!$slideshowCarousel.hasClass('is-initialized')) {
            _this.initializeCarousels();
        }

        indexThumbnail = (options.index || 0) - 1;
        this.updateIndex(indexThumbnail, true, true);
    };

    this.onInitialized = function (elem) {
        var _this = this;

        utils.changeVideoProtocol(elem); //behrreich 20161205 114805

        // Trigger an event on the lead form slide and pass in the cloned lead form slide
        var leadFormSlideClone = _this.$element.find('.owl-item.cloned .LeadFormSlide');

        if (leadFormSlideClone.length) {
            _this.$element.find('.owl-item:not(.cloned) .LeadFormSlide').trigger("lead-form-slide-clone-loaded", leadFormSlideClone);
        }
    }

    this.initializeCarousels = function () {
        var _this = this;

        _this.$main = _this.find('.SlideshowCarousel-main').owlCarousel({
            // http://smashingboxes.github.io/OwlCarousel2/docs/api-options.html
            items: 1,
            nav: true,
            loop: LOOP,
            navText: [
                '<span class="sr-only">Previous</span><i class="icon-arrow-left"></i>',
                '<span class="sr-only">Next</span><i class="icon-arrow-right"></i>'
            ],
            dots: false,
            smartSpeed: 500,
            onInitialized: _this.onInitialized
        });

        // Because IE doesn't like bracket syntax
        // in an object literal.
        var responsive = {
            0: {
                items: THUMBNAILS_SMALL_SCREEN
            }
        };
        responsive[utils.breakpoints.sm] = {
            items: THUMBNAILS_LARGE_SCREEN,
            margin: 15
        };
        responsive[utils.breakpoints.md] = {
            items: THUMBNAILS_LARGE_SCREEN,
            margin: 20
        };
        responsive[utils.breakpoints.lg] = {
            items: THUMBNAILS_LARGE_SCREEN,
            margin: 30
        };

        var slideshowSection = _this.$el.closest('.Slideshow');
        if (slideshowSection.hasClass('Slideshow--modalGallery')) {
            responsive = {
                0: {
                    items: THUMBNAILS_SMALL_SCREEN,
                    margin: 7
                }
            };
            responsive[utils.breakpoints.sm] = {
                items: THUMBNAILS_LARGE_SCREEN,
                margin: 7
            };
            responsive[utils.breakpoints.md] = {
                items: THUMBNAILS_LARGE_SCREEN,
                margin: 7
            };
            responsive[utils.breakpoints.lg] = {
                items: THUMBNAILS_LARGE_SCREEN,
                margin: 7
            };
        }

        _this.$main.find('.owl-nav .owl-prev').on('click', _this.updatePreviousThumbnail.bind(_this))
            .attr('data-analytics-cta', 'Photo Scrolled');
        _this.$main.find('.owl-nav .owl-next').on('click', _this.updateNextThumbnail.bind(_this))
            .attr('data-analytics-cta', 'Photo Scrolled');

        _this.$main.on('changed.owl.carousel', _this.onMainChange.bind(_this));
        _this.$main.on('dragged.owl.carousel', _this.onMainChange.bind(_this));

        _this.$thumbnails = _this.find('.SlideshowCarousel-thumbnails .owl-carousel').owlCarousel({
            // http://smashingboxes.github.io/OwlCarousel2/docs/api-options.html
            margin: 10,
            slideBy: 'page',
            dotsContainer: '.SlideshowCarousel-dots',
            responsive: responsive
        });

        _this.slicesThumbnails = _this.$thumbnails.find('.Slide');
        _this.slicesThumbnailSize = _this.slicesThumbnails.length;

        _this.$thumbnailsMenu.find('.owl-prev').on('click', _this.onThumbnailPrev.bind(_this));
        _this.$thumbnailsMenu.find('.owl-next').on('click', _this.onThumbnailNext.bind(_this));
        _this.slicesThumbnails.on('click', _this.onThumbnailClick.bind(_this));

        _this.$thumbnails.on('resized.owl.carousel', _this.onThumbnailsResize.bind(_this));

        _this.onThumbnailsResize();

        var $slideshowCarousel = _this.$element.find('.SlideshowCarousel-main');

        // add aria-hidden = true to non-active slides
        // add tabindex to child divs
        _this.$main.find('.owl-item:not(.active) > div.Slide').each(function () {
            var $slide = $(this);
            $slide.attr('aria-hidden', true);
            $slide.attr('tabindex', -1);
            $slide.find('.Social-links').attr('tabindex', -1);
            $slide.find('.Carousel-imagesCount').attr('tabindex', -1);
            $slide.find('img').attr('tabindex', -1);
        });
        // add aria-hidden = false to active slides
        _this.$main.find('.owl-item.active > div.Slide').each(function () {
            $(this).attr('aria-hidden', false);
            $(this).removeAttr('tabindex');
            $(this).find('.Social-links').removeAttr('tabindex');
            $(this).find('.Carousel-imagesCount').removeAttr('tabindex');
            $(this).find('img').removeAttr('tabindex');
        });

        $slideshowCarousel.addClass('is-initialized');
    }
}

module.exports = component(SlideshowCarousel);
