var component = require('../../../lib/js/component.js');

var PinterestLink = function () {

    this.initialize = function (el, options) {
        this.$element.on('click', this.handleClick.bind(this));
    };

    this.handleClick = function (event) {
        var url = this.$element.attr('href');
        window.open(url, 'Pinterest', 'width=600, height=500');
        event.preventDefault();
    };

};

module.exports = component(PinterestLink);
