var component = require('../../../lib/js/component.js');

function CompareHomes() {
    var emptyClass = 'CompareHomes-empty',
        minHomesClass = 'CompareHomes-min-count',
        imageClass = 'u-responsiveMedia',
        emptyCellText = '-',
        minComparingHomes = 2,
        imageProperties = {
            'dam': '//res.cloudinary.com/dv0jqjrc3/image/fetch/',
            'size': '{\'1025\':\'ar_1.5\',\'1920\':\'ar_1.5\'}',
            'transformations': 'c_fill,dpr_auto,f_auto,g_north,w_auto'
        };

    this.initialize = function (element) {
        this.element = element;
        this.targetColumn = null;
        this.countLabel = $(element).find('.CompareHomes-count-label');
        this.compareHomesModal = $('#modal-compare-homes');

        $($(element).find('.CompareHomes-thumb-wrapper button')).click(this.emptyHomeColumn.bind(this));
        $(element).find('.CompareHomes-add-home-btn').click(this.onAddHomeBtn.bind(this));
        $(this.compareHomesModal).on('addHomeToCompare', this.fillHomeData.bind(this));
    };

    this.emptyHomeColumn = function (evt) {
        var column = $(evt.target).parent().parent();
        $(column).addClass(emptyClass).find('li').html(emptyCellText);
        $(column).find('img').remove();
        this.updateHomesCount();
    };

    this.onAddHomeBtn = function (evt) {
        this.targetColumn = $(evt.target).parent();
    };

    this.fillHomeData = function (evt, homeId) {
        var homeData,
            image = document.createElement('IMG'),
            listItems = $(this.targetColumn).find('li'),
            yesLabel = $('.CompareHomes')[0].dataset.yesLabel,
            noLabel = $('.CompareHomes')[0].dataset.noLabel,
            itemValue,
            i,
            j;

        for(i = 0, j = window.pulteSavedHomes.length; i < j; i += 1) {
            if(window.pulteSavedHomes[i].id === homeId) {
                homeData = window.pulteSavedHomes[i];
                break;
            }
        }

        image.dataset.dam = imageProperties.dam;
        image.dataset.size = imageProperties.size;
        image.dataset.transformations = imageProperties.transformations;

        $(image).attr({'src': homeData.image.src, 'class': imageClass, 'alt': homeData.data.home, 'data-name': homeData.image.name});
        $(image).insertBefore($(this.targetColumn).find('.CompareHomes-thumb-wrapper button')[0]);
        $(this.targetColumn).removeClass(emptyClass).attr('data-home-id', homeData.id);

        for(i = 0, j = listItems.length; i < j; i += 1) {
            itemValue = homeData.data[listItems[i].dataset.label];
            itemValue = itemValue === true ? yesLabel : (itemValue === false ? noLabel : itemValue);
            itemValue = listItems[i].dataset.label === 'price' ? (itemValue !== null ? this.toCurrencyFormat(itemValue) : itemValue) : itemValue;
            itemValue = itemValue === null ? '-' : itemValue;
            $(listItems[i]).html(itemValue);
        }

        this.updateHomesCount();
    };

    this.updateHomesCount = function () {
        if(this.comparingHomesCount() <= minComparingHomes) {
            $('.CompareHomes-table-body').addClass(minHomesClass);
        } else {
            $('.CompareHomes-table-body').removeClass(minHomesClass);
        }

        $(this.countLabel).html(this.comparingHomesCount());
    };

    this.comparingHomesCount = function () {
        return $(this.element).find('.CompareHomes-table-body .col-md-3:not(.' + emptyClass + ')').length;
    };

    this.toCurrencyFormat = function (price) {
        var digits = (parseInt(price, 10).toString().split('')),
            formattedPrice = '',
            i,
            j;

        for (i = digits.length - 1, j = -1; i > j; i -= 1) {
            formattedPrice += digits[i];
            if((digits.length - i) % 3 === 0 && i !== 0){
                formattedPrice += ',';
            }
        }

        return '$' + formattedPrice.split('').reverse().join('');
    };
}

module.exports = component(CompareHomes);
