var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');

function GlobalHeaderV2() {
    var _this;

    // store touch support
    var TOUCH_SUPPORT = utils.has.touch;

    // default threshold for showing menu
    var THRESHOLD = 300;

    // classes used for css logic/animation
    var CLASS = {
        SHOW: 'is-showing',
        SHOW_HEADER: 'is-showing-header',
        FIXED: 'is-fixed',
        ANIMATE: 'can-animate'
    };

    // events
    var EVT = {
        START: 'SCROLL_START',
        STOP: 'SCROLL_STOP',
        MOVE: 'SCROLL_MOVE'
    };

    // listener  specific events
    var LISTENERS = {
        START: 'touchstart',
        STOP: 'touchend',
        MOVE: 'scroll',
        ORIENTATION_CHANGE: 'resize'
    };

    // scroll model
    var scroll = {
        start: 0,
        stop: 0,
        position: 0,
        direction: 1
    };

    var disabledFixed = false;

    /**
     * Init. It's time to get started.
     * @param  {Object} element HTML element
     * @param  {Object} options Component options
     */
    this.initialize = function (element, options) {

        _this = this;
        var $locationSelection = _this.find('.LocationSelection--GlobalNav-v2');

        _this.setLinkColors(this);

        _this.$nav = this.find('.GlobalNav-v2');
        _this.$window = $(window);

        _this.options = options || {};
        _this.open = false;

        if (!_this.options.threshold) {
            _this.options.threshold = THRESHOLD;
        }

        // store reference to debounced/throttled methods
        _this.methods = {
            onStart: utils.debounce(_this.onStart.bind(this), 1, true),
            onStop: utils.debounce(_this.onStop.bind(this), 250),
            onMove: utils.throttle(_this.onMove.bind(this), 1)
        };

        // attach menu toggle listener
        _this.find('.GlobalNav-v2-menuButton, .GlobalNav-v2-menuAction').on('click touch', _this.toggleMobileMenu.bind(this));

        _this.$openMenuButton = _this.find('.GlobalNav-v2__openMenuButton');
        _this.$closeMenuButton = _this.find('.GlobalNav-v2__closeMenuButton');

        // attach sublink primary link listeners
        _this.find('.GlobalNav-v2-mobilePrimaryLinkToggle').on('click touch', _this.openMobileSublinksMenu.bind(this));

        _this.$mobileMenu = _this.find('.GlobalNav-v2__mobile');
        var tabindexed = Array.from(_this.$mobileMenu.find('[tabindex]'));
        var exitButton = Array.from(_this.$closeMenuButton);
        _this.tabbable = exitButton.concat(tabindexed);
        _this.$mobileMenu.on('keydown', _this.changeFocus.bind(_this));

        // attach events to handle aria-expanded for header dropdowns
        var dropDownToggles = _this.find('.GlobalNav-v2-dropdown');
        dropDownToggles.on('mouseover', function (e) {
            $(this).attr('aria-expanded', true);
        });
        dropDownToggles.on('mouseout', function (e) {
            $(this).attr('aria-expanded', false);
        });
        dropDownToggles.on('keypress', function (e) {
            if (e.keyCode === 13) { //13 is enter key
                $(this).children('.dropdown-toggle').dropdown('toggle');

                if (!($(e.target).hasClass('GlobalNav-v2-secondaryLoggedInDropdown'))) { // skip the login dropdown, it doesnt need any extra classes to look right when opened via keyboard
                    if ($(this).children('.dropdown-menu').hasClass('d-flex')) {
                        $(this).children('.dropdown-menu').removeClass('d-flex');
                    } else {
                        $(this).children('.dropdown-menu').addClass('d-flex'); // when the dropdown opens via hover, the display of the drop down menu changes to flex, simulate this by adding and removing d-flex class
                    }
                }
            }
        });
        dropDownToggles.on('shown.bs.dropdown', function (e) {
            $(this).attr('aria-expanded', true);
        });
        dropDownToggles.on('hidden.bs.dropdown', function (e) {
            $(this).attr('aria-expanded', false);
        });

        // prevent global nav dropdown from closing if user is interacting with location selection
        if ($locationSelection != null && $locationSelection.length) {
            $locationSelection.on('click', function (e) {
                var $dropdown = $(e.currentTarget).closest('.GlobalNav-v2-dropdownHover');
                var $eventTarget = $(e.target);

                if ($eventTarget && $eventTarget.length && !$eventTarget.hasClass('LocationSelection--searchHome')) {
                    if (!$dropdown.hasClass('GlobalNav-v2-dropdownHover--keepOpen')) {
                        $dropdown.addClass('GlobalNav-v2-dropdownHover--keepOpen');
                    }

                    e.stopPropagation();
                } else if ($eventTarget.hasClass('LocationSelection--searchHome')) {
                    _this.closeMenu();
                }
            });
        }        

        var $hoverMenus = _this.find('.GlobalNav-v2-dropdownHover:not(.GlobalNav-v2-dropdownHover--dynamic)');
        var $hoverMenusDynamic = _this.find('.GlobalNav-v2-dropdownHover--dynamic');

        // close menus that are forced to stay open if user interacts with other menu items
        if ($hoverMenus && $hoverMenus.length) {
            $hoverMenus.on('mouseover', function () {
                var $hoverMenuLockedOpen = $('.GlobalNav-v2-dropdownHover--keepOpen');

                if ($hoverMenuLockedOpen && $hoverMenuLockedOpen.length) {
                    $hoverMenuLockedOpen.removeClass('GlobalNav-v2-dropdownHover--keepOpen');
                }
            })
        }

        // close menus that are forced to stay open if user clicks the menu item
        if ($hoverMenusDynamic && $hoverMenusDynamic.length) {
            $hoverMenusDynamic.on('click', function (e) {
                if ($hoverMenusDynamic && $hoverMenusDynamic.length) {
                    var $eventTarget = $(e.target);

                    if ($hoverMenusDynamic.hasClass('GlobalNav-v2-dropdownHover--keepOpen')) {
                        $hoverMenusDynamic.removeClass('GlobalNav-v2-dropdownHover--keepOpen');
                        $hoverMenusDynamic.addClass('GlobalNav-v2-dropdownHover--close');
                    } else if ($eventTarget && $eventTarget.length && !$eventTarget.hasClass('LocationSelection--searchHome')) {
                        $hoverMenusDynamic.removeClass('GlobalNav-v2-dropdownHover--close');
                    }
                }
            });

            $hoverMenusDynamic.on('mouseenter', function () {
                if ($hoverMenusDynamic.hasClass('GlobalNav-v2-dropdownHover--close')) {
                    $hoverMenusDynamic.removeClass('GlobalNav-v2-dropdownHover--close');
                }
            });
        }

        // when the user clicks an a tag with an href that contains geo-copy deeplink name
        _this.find('a[href*="geo-copy"]').on('click', function (e) {
            if (window.location.hash === e.currentTarget.hash) {
                //fire hashchange event to trigger deeplink scrolling
                $(window).trigger('hashchange');
            }
        });

        // opens category links in mobile view
        _this.find('.GlobalNav-v2-mobileSublinksDropdown .dropdown-toggle a').on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();

            var target = $(this).attr("target");
            var url = $(this).attr("href");

            window.open(url, target);
        });

        // listen for manual hide of header
        _this.$window.on('Header.hide', function () {
            _this.$element.removeClass(CLASS.SHOW);
            $('body').removeClass(CLASS.SHOW_HEADER);
        });

        // listen for disable of fixed header
        _this.$window.on('Header.disableFixed', function () {
            disabledFixed = true;
            _this.$element.removeClass(CLASS.SHOW);
            _this.$element.removeClass(CLASS.FIXED);
            $('body').removeClass(CLASS.SHOW_HEADER);
        });

        // attach scroll/touch listeners
        _this.$window.on(LISTENERS.START, _this.methods.onStart);
        _this.$window.on(LISTENERS.MOVE, _this.methods.onMove);

        // listen for events
        _this.listen(EVT.START, _this.handleStart);
        _this.listen(EVT.STOP, _this.handleStop);
        _this.listen(EVT.MOVE, _this.handleMove);

        var cookie = document.cookie.replace(/(?:(?:^|.*;\s*)theme\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        Pulte.Brand = cookie || Pulte.Brand;
    };

    /**
     * Method to handle scroll start & touch start
     * @param  {Object} event jQuery Event
     */
    this.onStart = function (event) {

        var _this = this;

        // set scroll
        scroll.start = scroll.position = window.pageYOffset;

        // reset listeners
        _this.$window.off(LISTENERS.START, _this.methods.onStart);
        _this.$window.on(LISTENERS.STOP, _this.methods.onStop);

        // dispatch event
        _this.trigger(EVT.START, scroll);
    };


    /**
     * Method to handle scroll stop & touch end
     * @param  {Object} event jQuery Event
     */
    this.onStop = function (event) {

        var _this = this;

        // set scroll
        scroll.stop = scroll.position = window.pageYOffset;

        // reset listeners
        _this.$window.off(LISTENERS.STOP, _this.methods.onStop);
        _this.$window.on(LISTENERS.START, _this.methods.onStart);

        // dispatch event
        _this.trigger(EVT.STOP, scroll);
    };


    /**
     * Method to handle scroll
     * @param  {Object} event jQuery Event
     */
    this.onMove = function (event) {

        var _this = this;

        var direction = scroll.direction;
        var position = scroll.position;

        // scroll down
        if (position <= window.pageYOffset) {
            direction = 1;
            // scroll up
        } else {
            direction = -1;
        }

        // if scroll position changed
        // set scroll start and direction
        if (scroll.direction !== direction) {
            scroll.start = window.pageYOffset;
            scroll.direction = direction;
        }

        // set scroll
        scroll.position = window.pageYOffset;

        // dispatch event
        _this.trigger(EVT.MOVE, scroll);
    };

    /**
     * Method attached to scroll start events.
     * @param  {Object} event jQuery Event
     * @param  {Object} opts  Scroll model
     */
    this.handleStart = function (event, opts) {
    };

    /**
     * Method attached to scroll stop events.
     * @param  {Object} event jQuery Event
     * @param  {Object} opts  Scroll model
     */
    this.handleStop = function (event, opts) {
    };

    /**
     * Method attached to scroll events.
     * @param  {Object} event jQuery Event
     * @param  {Object} opts  Scroll model
     */
    this.handleMove = function (event, opts) {

        var _this = this;
        var $body = $('body');

        var threshold = (opts.start - opts.position) > _this.options.threshold;
        var top_threshold = opts.position / $body.height() * 100 > 20;

        // return if the menu is open
        if (_this.open) {
            return;
        }

        // if the menu is on screen
        // remove fix
        if (-opts.position >= -parseInt(_this.$element.height())) {
            _this.$element.removeClass(CLASS.FIXED);
            // if the menu is off screen
            // fix the menu
            // add animate class (this is so the menu only animates when reshowing)
        } else {
            if (!disabledFixed) {
                _this.$element.addClass(CLASS.FIXED);
                setTimeout(function () { }, 250);
            }
        }

        // up
        if (opts.direction === -1) {
            // if the scroll threshold has been met
            // the user in top threshold of page
            if (!disabledFixed) {
                _this.$element.addClass(CLASS.FIXED);
                _this.$element.addClass(CLASS.SHOW);
                $body.addClass(CLASS.SHOW_HEADER);
            }
            // down
        } else {
            _this.$element.removeClass(CLASS.SHOW);
            $body.removeClass(CLASS.SHOW_HEADER);
        }
    };


    /**
     * Toggle the open/close state of the mobile menu.
     * @param  {Object} event jQuery event
     */
    this.toggleMobileMenu = function (event) {

        var _this = this;

        // if the menu is open
        // close the menu
        if (_this.open) {
            _this.closeMenu();
            _this.open = false;
            $(_this).attr("aria-expanded", false);
            // if the menu is closed
            // open the menu
        } else {
            _this.openMenu();
            _this.open = true;
            _this.tabbableIndex = 0;
            $(_this).attr("aria-expanded", true);
        }

        event.preventDefault();
    };

    /**
     * Close the mobile menu.
     * @param  {Object} event jQuery event
     */
    this.closeMenu = function (event) {
        var _this = this;
        var $body = $('body');

        $body.removeClass('is-showing-menu').css({
            'height': 'auto',
            'overflow': 'scroll'
        });

        _this.$element.removeClass('is-open');
        _this.$nav.removeClass('is-open');
        _this.closeMobileSublinksMenu();
        _this.$openMenuButton.removeClass('d-none');
        _this.$closeMenuButton.addClass('d-none');
        _this.$openMenuButton.focus();
    };

    /**
     * Open the mobile menu.
     * @param  {Object} event jQuery event
     */
    this.openMenu = function (event) {
        var _this = this;
        var $body = $('body');

        $body.addClass('is-showing-menu').css({
            'height': window.innerHeight || window.outerHeight,
            'overflow': 'hidden'
        });

        _this.$element.addClass('is-open');
        _this.$nav.addClass('is-open');
        _this.$openMenuButton.addClass('d-none');
        _this.$closeMenuButton.removeClass('d-none');
        _this.$closeMenuButton.focus();
    };

    this.changeFocus = function (event) {
        if (_this.open && event.keyCode == 9) {
            event.preventDefault();
            if (event.shiftKey) {
                _this.tabbableIndex--;
                if (_this.tabbableIndex < 0) {
                    _this.tabbableIndex = _this.tabbable.length - 1;
                }
            } else {
                _this.tabbableIndex++;
                if (_this.tabbableIndex >= _this.tabbable.length) {
                    _this.tabbableIndex = 0;
                }
            }
            $(_this.tabbable[_this.tabbableIndex]).trigger('focus');
        }
    };

    /**
     * Find the sublinks menu that corresponds to the clicked sublinks primary link
     * and openstate of the mobile sublinks menu.
     * @param  {Object} event jQuery event
     */
    this.openMobileSublinksMenu = function (event) {
        if (event && event.currentTarget) {
            var _this = this;
            // get main link
            var $target = $(event.currentTarget);
            // find sublinks menu sibling element
            var $sublinksMenu = _this.$nav.find($target).siblings(".GlobalNav-v2-mobileSublinksContainer");
            if ($sublinksMenu) {
                // show sublinks menu and add listener to close
                $sublinksMenu.find('.GlobalNav-v2-mobileSublinksBackButton').on('click touch', _this.closeMobileSublinksMenu.bind(this));
                $sublinksMenu.addClass('slideInDisplay');
            }
        }
    };

    this.closeMobileSublinksMenu = function () {
        var _this = this;
        _this.$nav.find(".GlobalNav-v2-mobileSublinksContainer").removeClass('slideInDisplay');
    }

    this.setLinkColors = function (component) {
        if (component) {
            var header = component.$element;

            if (header) {
                // Primary Links
                var primaryLinks = $(header).find('.GlobalNav-v2-link, .GlobalNav-v2-linkMobile');
                var primaryLinkFontColor = $(header).data('primaryfontcolor');
                var primaryLinkHoverColor = $(header).data('primaryhovercolor');
                this.setFontColor(primaryLinks, primaryLinkFontColor);
                this.setHoverColor(primaryLinks, primaryLinkFontColor, primaryLinkHoverColor);

                // Link Columns
                var linkColumns = $(header).find('.GlobalNav-v2-columnTitle');
                var linkColumnHeaderFontColor = $(header).data('columnheadercolor');
                this.setFontColor(linkColumns, linkColumnHeaderFontColor);

                // Category Links
                var categoryLinks = $(header).find('.GlobalNav-v2-categoryLinkName');
                var categoryLinkFontColor = $(header).data('categoryfontcolor');
                var categoryLinkHoverColor = $(header).data('categoryhovercolor');
                this.setFontColor(categoryLinks, categoryLinkFontColor);
                this.setHoverColor(categoryLinks, categoryLinkFontColor, categoryLinkHoverColor);

                // Sub Category Links
                var subcategoryLinks = $(header).find('.GlobalNav-v2-itemLinkName');
                var subCategoryLinkFontColor = $(header).data('subcategoryfontcolor');
                var subCategoryLinkHoverColor = $(header).data('subcategoryhovercolor');
                this.setFontColor(subcategoryLinks, subCategoryLinkFontColor);
                this.setHoverColor(subcategoryLinks, subCategoryLinkFontColor, subCategoryLinkHoverColor);

                // Account Links
                var accountLinks = $(header).find('.GlobalNav-v2-accountLink');
                var accountLinkMenu = $(header).find('.GlobalNav-v2-accountMenu');
                var accountLinkFontColor = $(header).data('accountlinkfontcolor');
                var accountLinkHoverColor = $(header).data('accountlinkhovercolor');
                var accountLinkBackgroundColor = $(header).data('accountlinkbackgroundcolor');
                this.setFontColor(accountLinks, accountLinkFontColor);
                this.setHoverColor(accountLinks, accountLinkFontColor, accountLinkHoverColor);
                this.setBackgroundColor(accountLinkMenu, accountLinkBackgroundColor);
            }
        }
    }

    this.setFontColor = function (links, fontColor) {
        if (fontColor) {
            $.each($(links), function (index, link) {
                $(link).css('color', fontColor);
            });
        }
    }

    this.setHoverColor = function (links, fontColor, hoverColor) {
        if (hoverColor) {
            $.each($(links), function (index, link) {
                $(link).on('mouseover', function () {
                    $(this).css('color', hoverColor);
                });

                if (!fontColor) {
                    fontColor = $(link).css('color');
                }

                $(link).on('mouseout', function () {
                    $(this).css('color', fontColor);
                });
            });
        }
        else {
            $.each($(links), function (index, link) {
                $(link).on('mouseover', function () {
                    $(this).addClass('hover');
                });

                $(link).on('mouseout', function () {
                    $(this).removeClass('hover');
                });
            });
        }
    }

    this.setBackgroundColor = function (links, backgroundColor) {
        if (backgroundColor) {
            $.each($(links), function (index, link) {
                $(link).css('background-color', backgroundColor);
            });
        }
    }
}

module.exports = component(GlobalHeaderV2);
