var component = require('../../../lib/js/component.js');

function InnovationCard() {
    var component;
    this.initialize = function (element) {
        component = this;

        component.bindCTAFileLinkEvent();
    }

    this.bindCTAFileLinkEvent = function () {
        var fileCta = component.$element.find('.cta-file-link');

        $(fileCta).on("click", function(){
            var link = $(this).data("cta-file-link");
            if (link) {
                window.open(link, '_blank');
            }
        });
    }
}

module.exports = component(InnovationCard);