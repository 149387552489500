var templates = require('./templates/info-window-templates');

/**
 * Shared Maps Helper Service
 */
var mapsHelper = {

    /**
     * getBounds: Update bounds of markers and recenter map
     * @param  {Array} markers - A collection of Google Map Marker objects
     * @return {Object} bounds  - A collection of Google Map Lat/Lng Bounds objects
     */
    getBounds: function (markers) {
        // Get Google formated LatLngBounds (coordinates for view area of map)
        var bounds = new google.maps.LatLngBounds();
        for(var i=0; i<markers.length; i++) {
            // Extend the bounds to contain this marker
            bounds.extend(markers[i].position);
        }

        if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
            bounds = this.extendSingleMarkerBounds(bounds)
        }

        return bounds;
    },

    /**
     * extendSingleMarkerBounds: Don't zoom in too far on only a single marker
     * @param  {Object} bounds - Object containing Google Maps formated
     *                         	 LatLngBounds
     * @return {Object}        - Object containing expanded Google Maps formated
     *                           LatLngBounds
     */
    extendSingleMarkerBounds: function (bounds) {
        var extendPoint1 = new google.maps.LatLng(bounds.getNorthEast().lat() + 0.01, bounds.getNorthEast().lng() + 0.01);
        var extendPoint2 = new google.maps.LatLng(bounds.getNorthEast().lat() - 0.01, bounds.getNorthEast().lng() - 0.01);
        bounds.extend(extendPoint1);
        bounds.extend(extendPoint2);

        return bounds;
    },

    /**
    * Return Google Maps formated Lat/Lng object
    */
   /**
    * getItemPosition: Return Google Maps formated Lat/Lng object
    * @param  {String} lat - String containing the Latitude of the marker.
    * @param  {String} lng - String containing the Longitude of the marker.
    * @return {Object}     - Object containing the Google Maps formated Lat/Lng.
    */
    getItemPosition: function (lat, lng) {
        if (lat && lng) {
            var point = new google.maps.LatLng(lat, lng);
            return point;
        } else {
            // CHAD: This probably should return something even when this method
            // is not passed the appropriate arguments
            console.error('mapshelper.getItemPosition() passed item with no location info.');
        }
    },

    /**
     * styleInfoWindows: Since we can't easily style everything about
     * 					 InfoWindows via css we have to do some styling here...
     * @param  {Object} mapMkr - Google Maps Object containing marker data
     */
    styleInfoWindows: function (mapMkr) {
        google.maps.event.addListener(mapMkr.iw, 'domready', function() {
        // Reference to the DIV which receives the contents of the infowindow using jQuery
            var iwOuter = $('.gm-style-iw');
            iwOuter.parent().addClass('iwOuter');
            // The <div> we want to change is above .gm-style-iw.
            var iwBackground = iwOuter.prev();
            // Add class to Remove the background shadow <div>
            iwBackground.children(':nth-child(2)').addClass('hidden');
            // Add class to Remove the white background <div>
            iwBackground.children(':nth-child(4)').addClass('hidden');
            // Add class to style tail
            iwBackground.children(':nth-child(3)').addClass('iwTail');
            // Add class to Remove box shadow from tail
            iwOuter.prev().children().first().addClass('hidden');
        });
    },

    /**
     * makeInfoWindow: Construct & instantiate new infoWindows for each marker (except features map iw's)
     * @param  {Object} mapMkr - Google Maps Object containing marker data
     */
    makeInfoWindow: function (mapMkr) {
        // Determine which InfoWindow template to use;
        var templateType = mapMkr.templateType;
        // Handlebars precompiled template function
        var templateFunction = templates[this.options.type];
        // Populate template markup with data from mapMkr
        var infoContent = templateFunction(mapMkr);

        // Store the InfoWindow on the marker object for
        // easy reference/manipulation.
        mapMkr.iw = new google.maps.InfoWindow({
            content: infoContent
        });
    },

    /**
     * makeCustomPopupWindow: Construct & instantiate new customPopups for each marker
     * @param  {Object} mapMkr - Google Maps Object containing marker data
     */
    makeCustomPopup: function (mapMkr) {
        // Determine which template to use;
        var templateType = mapMkr.templateType;
        // Handlebars precompiled template function
        var templateFunction = templates[this.options.type];
        // Populate template markup with data from mapMkr
        var infoContent = templateFunction(mapMkr);
          
        // With our current implementation, google cannot be referenced outside of a function call
        // Wrap the CustomPopup definition in a self-calling function here so it can reference google.maps.OverlayView
        var CustomPopup = (function(overlayView) {
            function CustomPopup (position, content) {
                overlayView.call(this);
                this.parent = new google.maps.OverlayView(overlayView)
                this.position = position;

                // Create a DOM element out of the content html
                var contentElement = document.createElement("div");
                contentElement.innerHTML = content;
                this.popupBubble = contentElement.firstChild;                
                this.popupBubble.className += templateType != 'directionsV2' ? " popup-bubble" : " popup-bubbleV2";

                // This zero-height div is positioned at the bottom of the bubble.
                var bubbleAnchor = document.createElement("div");
                bubbleAnchor.className += templateType != 'directionsV2' ? " popup-bubble-anchor" : " popup-bubble-anchorV2";
                bubbleAnchor.appendChild(contentElement);

                
                // This zero-height div is positioned at the bottom of the tip.
                this.containerDiv = document.createElement("div");
                this.containerDiv.className += " popup-container";
                this.containerDiv.appendChild(bubbleAnchor);
                
                // Optionally stop clicks, etc., from bubbling up to the map.
                CustomPopup.preventMapHitsAndGesturesFrom(this.containerDiv);
            };
          
            // Inherit OverlayView the ES5 way
            Object.setPrototypeOf(CustomPopup.prototype, overlayView.prototype);
            Object.setPrototypeOf(CustomPopup, overlayView);
          
            // Called by map controllers to place the popup on the map
            //TODO: when map re-adjustment function is built, change this to the use: mapMkr.iw.setMap(mapMkr.map);
            //TODO: when all info windows are converted to custom popups, call setmap() directly
            CustomPopup.prototype.open = function() {
                if (this.containerDiv) {
                    this.containerDiv.style.visibility = "visible";
                }
            }
          
            // Called automatically when the popup is added to the map.
            CustomPopup.prototype.onAdd = function() {
                this.getPanes().floatPane.appendChild(this.containerDiv);
                //TODO: once 
                $('body').trigger('CustomPopupAdded');
            };
          
            // Called by map controllers to remove the popup from the map
            //TODO: when map re-adjustment function is built, change this to the use: mapMkr.iw.setMap(null);
            //TODO: when all info windows are converted to custom popups, call setmap() directly
            CustomPopup.prototype.close = function() {
                if (this.containerDiv) {
                    this.containerDiv.style.visibility = "hidden";
                }
            }
          
            /** Called automatically when the popup is removed from the map. */
            CustomPopup.prototype.onRemove = function() {
                if (this.containerDiv.parentElement) {
                    this.containerDiv.parentElement.removeChild(this.containerDiv);
                }
            };
          
            /** Called each frame when the popup needs to draw itself. */
            CustomPopup.prototype.draw = function() {
                var divPosition = this.getProjection().fromLatLngToDivPixel(
                    this.position
                );
                // Hide the popup when it is far out of view.
                var display =
                    Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
                    ? "block"
                    : "none";
          
                if (display === "block") {
                    this.containerDiv.style.left = divPosition.x + "px";
                    this.containerDiv.style.top = divPosition.y + "px";
                }
          
                if (this.containerDiv.style.display !== display) {
                    this.containerDiv.style.display = display;
                }
            };

            return CustomPopup;
        })(google.maps.OverlayView);

        //TODO: when all info windows are converted to custom popups, change 'iw' to 'customPopup'
        mapMkr.iw = new CustomPopup(mapMkr.position, infoContent);
    },

    /**
     * formatNumber: Return formated number string.
     * @param  {number} num - Unformated number.
     * @return {string}     - Formated string.
     */
    formatNumber: function (num) {
        if (num) {
            var parts = num.toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

            return parts.join(".");
        }
        return num;
    },

    openInfoWindowOnMapLoad: function (mapMkr) {
        var _this = this;
        // Open the infowindow by default
        // Since it could live in a modal we need to open it after the map has
        // fully loaded so it positions correctly.
        google.maps.event.addListener(_this.map, "tilesloaded", function(e) {
            // 'click' the first marker to open it's infoWindow
            // and size everything correctly
            new google.maps.event.trigger( mapMkr, 'click' );
            // Remove event listener to prevent reopening this infowwindow
            // if map is moved and more tiles are loaded.
            google.maps.event.clearListeners(_this.map, "tilesloaded");
            $('.gm-style-iw').removeClass('openIW');
        });
    },

    /**
     * closeAllInfoWindows: loop through all markers and close
     * their InfoWindows
     */
    closeAllInfoWindows: function () {
        var _this = this;
        for (var mkr in _this.markerList) {
            if (_this.markerList[mkr].iw) {
                _this.markerList[mkr].iw.close();
            }
        };
    },

    /**
     * attachMapCore: Attaches MapCore class and instantiates a map
     */
    attachMapCore: function  (MapCore, options) {
        var mapSettings = {
            enableDoubleClickZoom: this.options.map.enableDoubleClickZoom || false,
            zoom: this.options.map.defaults.default_zoom,
            showZoomControls: this.options.map.showZoomControls || false,
            enableScrollWheelZoom: this.options.map.enableScrollWheelZoom || false,
            mapConfig: this.options.map.defaults,
            lat: this.options.lat,
            lng: this.options.lng,
            pulteMapType: this.options.map.pulteMapType
        };
        this.$mapElement.attach(MapCore, mapSettings);
    },

    compareMinMax: function (mapMkr, param1, param2) {
        $(mapMkr).each(function(i,e){
            if(param1 === param2){
                switch(param2) {
                    case e.maxBedrooms:
                        e.maxBedrooms = null;
                        break;
                    case e.maxBathrooms:
                        e.maxBathrooms = null;
                        break;
                    case e.maxGarage:
                        e.maxGarage = null;
                        break;
                    default:
                        e.maxBedrooms = null;
                        e.maxBathrooms = null;
                        e.maxGarage = null;
                }
            }
        });
    },

    getDistanceInMiles: function (origin, destination) {
        return google.maps.geometry.spherical.computeDistanceBetween(origin, destination) / 1609; // 1609 meters in a mile
    }
};
module.exports = mapsHelper;
