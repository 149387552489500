var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');
var MapCore = require('./map-core.js');
var mapsHelper = require('./maps-helper.js');

// templates
var templates = require('./templates/info-window-templates');

var FeaturesMapController = function () {
    var mc = null;
    this.initialize = function (el, options) {
        var _this = this;
        this.options = {};
        this.$mapElement = this.$element.find('.Map-googleMap');
        $.extend(this.options, options, this.$mapElement.data());
        this.MARKER_PIN_SVG_PATH = this.options.map.defaults.MARKER_PIN_SVG_PATH;
        this.MARKER_PIN_SVG_PATH_GROUP = this.options.map.defaults.MARKER_PIN_SVG_PATH_GROUP;
        this.COMMUNITY_PIN_PNG_URL = this.options.map.defaults.COMMUNITY_PIN_PNG_URL;
        this.MARKER_PIN_SVG_OPACITY_WEIGHT = this.options.map.defaults.MARKER_PIN_SVG_OPACITY_WEIGHT;
        this.MARKER_PIN_SVG_SIZE_LG = this.options.map.defaults.MARKER_PIN_SVG_SIZE_LG;
        this.MARKER_PIN_SVG_SIZE_SM = this.options.map.defaults.MARKER_PIN_SVG_SIZE_SM;
        // Placeholder for reference to map instance
        this.map = {};
        // Placeholder for featureMarkers
        this.featureMarkers = [];
        // Set Map Type
        this.options.map.pulteMapType = 'features';
        // Enable zoom
        this.options.map.showZoomControls = true;
        this.options.map.enableDoubleClickZoom = true;
        this.options.map.enableScrollWheelZoom = true;

        if (GlobalMapsObj.featuresMarkers) {
            // Store markers from global object
            this.markers = GlobalMapsObj.featuresMarkers;
        } else {
            console.error('FeaturesMapController :: Marker data failed to load')
        }

        // Listeners for Feature Tabs
        this.$element.on('featureTabSelected', function (e, data) {
            if (featureTabData.curSelectedFeature.trim().length > 0 && featureTabData.curSelectedFeature.toLowerCase() != "about the area") {
                _this.featureTabSelected.call(_this, data);
            } else {
                _this.setupMap(); //reset map for back btn overview default
            }
        });

        // Listeners for Feature Tabs from a parent component
        this.$element.on('externalTabSelected', function (e, data) {
            _this.setupMap(true);
            _this.featureTabSelected.call(_this, data);
        });

        $('body').on('InitTabbedMaps', function () {
            _this.setupMap();
        });

        if (window.Pulte.isMapScriptLoaded) {
            // The google maps script has already been loaded.
            _this.setupMap();
        } else {
            // Add listener for when the async Google Maps script loads.
            $('body').on('MapScriptLoaded', function () {
                _this.setupMap();
            });
        };
    };

    /**
     * setupMap: attach mapCore and listen for added map instance
     */
    this.setupMap = function (external) {
        var _this = this;
        // Set up map specific listener for when map is ready

        var listener = this.options.map.pulteMapType + 'MapInstanceAdded';

        this.$mapElement.on(listener, function (e, map) {
            // Store reference to map
            _this.map = map;

            // Add click listener for clicks off of infowindows to close the infowindow
            google.maps.event.addListener(_this.map, "click", function(e) {
                // Added marker check for clicks on maps with no markers
                if (_this.featureMarkers.length) {
                    _this.closeAllFeatureInfoWindows.call(_this);
                    _this.resetFeatureMarkerSize.call(_this);
                    // There's map interactions on touch devices. To keep
                    // all pins visable map should recenter on infoWindow close
                    // if (utils.has.touch()){
                    //     _this.fitManyToBounds.call(_this, _this.featureMarkers);
                    // };
                }
            });
            // Setup markers
            _this.setupCommunityMarker(external);
            // Remove this listener
            $(this).off(listener);
        });
        // Get instance of map
        mapsHelper.attachMapCore.call(this, MapCore);
    };

    /**
     * setupCommunityMarker: Setup and place the initial community marker.
     * 	This map has a specific community map pin that doesn't need the
     * 	functionality of the other map pins and can't be an svg due to
     * 	IE11 support.
     */
    this.setupCommunityMarker = function (external) {
        // The first marker is the community (should be only marker in array)
        var mkr = this.markers[0];

        // Get Google formated marker position
        var position = mapsHelper.getItemPosition(mkr.Latitude, mkr.Longitude);

        // external coords are passed in from the data
        if (external) {
            // pull them from the map's data attributes
            var lat = this.$element.find('.Map-googleMap').data().lat;
            var lng = this.$element.find('.Map-googleMap').data().lng;
            
            // set the coords of the center marker
            this.markers[0].Latitude = lat;
            this.markers[0].Longitude = lng;

            // set position accordingly
            position = mapsHelper.getItemPosition(lat, lng);
        }

        // Setup the marker icon
        var iconSvg = {
            url: this.COMMUNITY_PIN_PNG_URL,
            scaledSize: new google.maps.Size(57,69)
        };
        // Instantiate Google Maps Marker
        var mapMkr = new google.maps.Marker({
            position: position,
            map: this.map,
            icon: iconSvg,
            name: mkr.Name,
        });
        // Place marker on map and position correctly
        this.fitSingleToBounds.call(this, mapMkr);
    };

    /**
     * featureTabSelected: Prepare and make Google Places data request with
     * data from selected community feature.
     * @param  {Object} featureTabData - Object from FeatureTabDropdown
     * containing data attributes from the selected feature tab/dropdown
     */
    this.featureTabSelected = function (featureTabData) {

        // only execute if there is a selected feature
        if (featureTabData.curSelectedFeature.trim().length > 0 && featureTabData.curSelectedFeature.toLowerCase() != "about the area") {
            var _this = this;
            // Instantiate a Google Maps InfoWindow
            _this.featureInfoWindow = new google.maps.InfoWindow();
            // Make call to Google Places to get features background
            var community = new google.maps.LatLng(_this.markers[0].Latitude, _this.markers[0].Longitude);
            var request = {
                location: community,
                radius: '10000', // CHAD: The backend should pass us this value.
                query: [featureTabData.curSelectedFeature]
            };
            // Setup Google Places Service
            placesService = new google.maps.places.PlacesService(_this.map);

            // Call service to get list of features

            placesService.textSearch(request, function (results, status) {
                var placeObj = {
                    results: results,
                    status: status,
                    featurePinColor: featureTabData.curFeatureColor
                }
                _this.googlePlacesCallback.call(_this, placeObj, community);

            });
        }
    };

    /**
     * googlePlacesCallback: Callback for Google Places request
     * @param  {Object} placeObj - Object containing results/status from
     * Google Places request and pin color.
     */
    this.googlePlacesCallback = function (placeObj, community) {
        var _this = this;
        var clusterPinColor = _this.CLUSTER_PIN_COLOR;

        _this.map.setOptions({
            zoomControl: true,
            draggable: true
        });
        // Upon success remove current markers and replace with new markers/data
        if (placeObj.status == google.maps.places.PlacesServiceStatus.OK && placeObj.results.length > 0) {
            var features = [];
            // Remove current markers
            this.resetFeaturesMarkers();
            // Loop through results and make new markers
            for (var i = 0; i < placeObj.results.length; i++) {
                if (google.maps.geometry.spherical.computeDistanceBetween(placeObj.results[i].geometry.location, community) < 40000) { // within about 25 miles
                    placeObj.results[i].pinColor = placeObj.featurePinColor;
                    var feature = placeObj.results[i];
                    this.createFeatureMarker.call(_this, feature)
                }
            }
            // Add markers to map and adjust to fit all markers in view.
            this.fitManyToBounds(_this.featureMarkers);
            // create MarkerClusterer custom icon
            clusterPinColor = placeObj.featurePinColor;
            
            var Symbol = function(width, height, fill){

                return ('data:image/svg+xml;base64,'+ window.btoa('<svg xmlns="http://www.w3.org/2000/svg" height="'+ height +'" viewBox="0 0 512 512" width="'+ width +'" >'
                + '<path transform="translate(136,40)" stroke="#ffffff" stroke-width="6" fill="'+ fill +'" d="'+ _this.MARKER_PIN_SVG_PATH_GROUP +'" />'
                + '<path transform="translate(104,48)" stroke="#ffffff" stroke-width="6" fill="'+ fill +'" d="'+ _this.MARKER_PIN_SVG_PATH_GROUP +'" />'
                + '<path transform="translate(72,56)" stroke="#ffffff" stroke-width="6" fill="'+ fill +'" d="'+ _this.MARKER_PIN_SVG_PATH_GROUP +'" />'
                + '</svg>'));

            }

            // MarkerClusterer style Options
            var mcOptions = {
                gridSize: 35,
                maxZoom: 60,
                styles:[
                    {
                        width: 50,
                        height: 50,
                        url: Symbol(80, 80, clusterPinColor),
                        fontFamily: 'Arial',
                        textSize: '12',
                        textColor: 'white'
                    }
                ]
            }
            // create groups of markers when it's too close

            mc = new MarkerClusterer(_this.map, _this.featureMarkers, mcOptions);

        } else {
            console.error("Error requesting data from Google Places. Status = ", placeObj.status);
        }
    };

    /**
     * [createFeatureMarker description]
     * @param  {Object} feature - Object containing place data from
     * Google Places request.
     */
    this.createFeatureMarker = function (feature) {
        var _this = this;
        // Get Google formated anchor location.

        var anchor = new google.maps.Point(92.334,220);
        // Setup marker icon
        var iconSvg = {
            path: this.MARKER_PIN_SVG_PATH,
            fillColor: feature.pinColor,
            fillOpacity: this.MARKER_PIN_SVG_OPACITY_WEIGHT,
            scale: this.MARKER_PIN_SVG_SIZE_SM,
            strokeColor: feature.pinColor,
            strokeWeight: this.MARKER_PIN_SVG_OPACITY_WEIGHT,
            anchor: anchor
        };
        // Instantiate marker
        var mapMkr = new google.maps.Marker({
            map: _this.map,
            position: feature.geometry.location,
            icon: iconSvg
        });

        // Add click listener to marker
        google.maps.event.addListener(mapMkr, 'click', function () {
            // Close any open infoWindows (if any open)
            if (_this.curOpenInfoWindow) {
                _this.closeAllFeatureInfoWindows.call(_this);
            }
            // Reset the other markers size
            _this.resetFeatureMarkerSize.call(_this);
            // Get marker instance
            var icon = mapMkr.getIcon();
            // Make icon larger
            icon.scale = _this.MARKER_PIN_SVG_SIZE_LG;
            // Update marker
            mapMkr.setIcon(icon);
            // The placesService.textSearch call above only returned basic data
            // about this feature. A second different call to 'getDetails' is
            // needed to obtain all data we need to display.
            placesService.getDetails(feature, function (featureDetail, status) {
                mapMkr.Name = featureDetail.name;
                mapMkr.StreetAddress = featureDetail.address_components[0].short_name + " " + featureDetail.address_components[1].short_name;
                mapMkr.City = featureDetail.address_components[2].short_name;
                mapMkr.State = featureDetail.address_components[3].short_name;
                if (featureDetail.address_components[5]) {
                    mapMkr.ZipCode = featureDetail.address_components[5].short_name
                }
                mapMkr.Phone = featureDetail.formatted_phone_number;
                mapMkr.DirectionsAddress = featureDetail.formatted_address;
                mapMkr.Url = featureDetail.website;

                _this.setFeatureInfoWindow.call(_this, mapMkr)
            });
        });
        // Store this marker in the featureMarkers Array.
        if ( mapMkr.position.lat() !== 0 || mapMkr.position.lng() !== 0 ){
            this.featureMarkers.push(mapMkr);
        }

    };

    /**
     * setFeatureInfoWindow: Apply and approiate infoWindow template, set it's
     * content and open.
     *
     * @param {[type]} mapMkr [description]
     */
    this.setFeatureInfoWindow = function (mapMkr) {
        // Get the proper infoWindow template
        var templateFunction = templates[this.options.type];
        // Populate template markup with data from mapMkr
        var infoWindowContent = templateFunction(mapMkr);
        // Set the infoWindow content
        this.featureInfoWindow.setContent(infoWindowContent);
        // Add listener for ready event to style infoWindow
        google.maps.event.addListener(this.featureInfoWindow, 'domready', function() {
           // Reference to the DIV which receives the contents of the infowindow using jQuery
           var iwOuter = $('.gm-style-iw');
           iwOuter.parent().addClass('iwOuter');
           // The <div> we want to change is above .gm-style-iw.
           var iwBackground = iwOuter.prev();
           // Add class to Remove the background shadow <div>
           iwBackground.children(':nth-child(2)').addClass('hidden');
           // Add class to Remove the white background <div>
           iwBackground.children(':nth-child(4)').addClass('hidden');
           // Add class to Remove box shadow from tail
           iwBackground.children(':nth-child(3)').addClass('iwTail');
           iwOuter.prev().children().first().addClass('hidden');
        });
        // Open this markers infoWindow
        this.featureInfoWindow.open(this.map, mapMkr);
        $('.gm-style-iw').addClass('openIW');
        // Store reference to currently open infoWindow to close when needed
        this.curOpenInfoWindow = mapMkr;
    };

    /**
     * resetFeaturesMarkers: Removes only the feature map markers from the map
     * while preserving the main community marker
     */
    this.resetFeaturesMarkers = function () {
        if(this.featureMarkers.length) {
            mc.removeMarkers(this.featureMarkers, false);
            for(i = 0; i<this.featureMarkers.length; i++){
                this.featureMarkers[i].setMap(null);
            }
            // markers variables reset
            this.featureMarkers = [];
            mc = null; //markerClusterer
        }
    };

    /**
     * resetFeatureMarkerSize: Reset the marker to it's default/small size
     */
    this.resetFeatureMarkerSize = function () {
        // NOTE: EBF
        if (this.curOpenInfoWindow) {
            // Get instance of marker
            var icon = this.curOpenInfoWindow.getIcon();
            // Make icon larger
            icon.scale = this.MARKER_PIN_SVG_SIZE_SM;
            // Update marker
            this.curOpenInfoWindow.setIcon(icon);
        }
    };

    /**
     * closeAllFeatureInfoWindows: Close the open infoWindow
     */
    this.closeAllFeatureInfoWindows = function () {
        this.featureInfoWindow.close();
    };

    /**
     * fitToBounds: Update bounds of a single marker and recenter map
     */
    this.fitSingleToBounds = function (marker) {
        // Get Google formated LatLngBounds (coordinates for view area of map)
        var bounds = new google.maps.LatLngBounds();
        // Extend the bounds to contain this marker
        bounds.extend(marker.position);
        // Don't zoom in too far on only one marker
        // Using this to adjust zoom because a single marker zooms in too far
        if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
            bounds = mapsHelper.extendSingleMarkerBounds(bounds)
        }
        // Place marker on map and recenter.
        this.map.fitBounds(bounds);
    };

    /**
     * fitToBounds: Update bounds of markers and recenter map
     */
    this.fitManyToBounds = function (markers) {
        var bounds = mapsHelper.getBounds(markers);
        // Place markers on map and recenter.
        this.map.fitBounds(bounds);
    };
};
module.exports = component(FeaturesMapController);
