var component = require('../../../lib/js/component.js');
var Form = require('../forms/form.js');
var constants = require('../forms/constants.js');
var utils = require('../../../lib/js/utils.js');
var analyticsForm = require('../forms/analyticsForm');
var Tooltip = require('../tooltip/tooltip');
var AdobeLaunch = require('../../../lib/js/adobelaunch.js');
var FacebookPixel = require('../../../lib/js/facebookpixel.js');

function ContactNavigation() {
    var self = this,
        activeClass = 'active',
        busyClass = 'busy',        
        landingModalsId = {
            promoteAccount : 'Contactnav-account',
            thankYou : 'Contactnav-thankyou'
      };

    // Generic Contact Form Class  --------------------------------------------------------------------

    var ContactForm = function(parentId, $form, $parentModalEl ) {
      var communityId = $form.find("input[name=CommunityIds]").val(),
          communityBrand = $form.find("input[name=CommunityBrand]").val();

      var contactFormObj = {
        id: parentId,
        element: ($form.length === 0 ? undefined : $form),
        communityId: communityId,
        communityBrand: communityBrand,
        adobeFormName: AdobeLaunch.getFormNameFromId(parentId),
        adobeFormId: communityId ? AdobeLaunch.FORM_COMMUNITY_ID : AdobeLaunch.FORM_GLOBAL_ID
      };

      var showForm = function () {
        $( '.ContactNavigation-content' ).toggleClass( activeClass, false );
        $parentModalEl.find('#' + parentId).toggleClass( activeClass, true );
        $parentModalEl.modal( 'show' );
      }

      contactFormObj.show = showForm;

      return contactFormObj;
    };


    //Forms related -----------------------------------------------------------------------------------

    function onError (data, textStatus, event) {
      self.$element.toggleClass( busyClass, false );
      self.$errorMessage.html( data && data.responseJSON ? data.responseJSON.error : textStatus );
      self.$errorMessage.show();
      self.$modalDialog.animate({ scrollTop: 0 }, 'slow');

      //rewrite error function
      analyticsForm.RewriteObjectForm(data, event);
    }

    // all forms are ajax except for the create an account page
    this.handleFormSubmission = function(event, form) {
        self.$errorMessage.hide();
        self.$element.toggleClass(busyClass, true);
        var _this = this;
        var adobeFormName = AdobeLaunch.getFormNameFromId(_this.id);
        var adobeFormId = _this.communityId ? AdobeLaunch.FORM_COMMUNITY_ID : AdobeLaunch.FORM_GLOBAL_ID;
        var facebookPixelFormName = FacebookPixel.getFormNameFromId(_this.id);

        $.ajax({
          method: form.options.method,
          url: form.options.action,
          data: form.data,
          dataType: "json",
          success: function(data) {
            // if this is the create account form, check for success to push adobe launch event and redirect the user
            if (_this.id === 'Contactnav-account') {
              if (data) {
                if (data.Success && data.UserId) {
                  AdobeLaunch.pushUserEvent(AdobeLaunch.USER_REGISTERED_EVENT_NAME);
                }

                location.href = data.RedirectUrl;
              }
            } else {
              var errorMessage = [],
                  nextModal = ( window.Pulte.user !== undefined ) ? landingModalsId.thankYou : landingModalsId.promoteAccount,
                  dataForm = form.data;

              self.$element.trigger('dataFields', dataForm);

              if (!data.success) {
                for (var field in data.fieldErrors) {
                  errorMessage.push(data.fieldErrors[field]);
                }

                AdobeLaunch.pushFormSubmitFailedEvent(adobeFormId, AdobeLaunch.FORM_MODAL_TYPE, adobeFormName, _this.communityId, _this.communityBrand);                
                onError(data, errorMessage.join('. '), event);
                return;
              } else {
                //scroll element top
                var axel = Math.random() + "";
                var a = axel * 10000000000000;
                var iframe = document.createElement('iframe');
                iframe.src = "https://5116500.fls.doubleclick.net/activityi;src=5116500;type=pulcount;cat=pulte00j;u1=[zip];u2=[state];u3=[region];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=' + a + '?";
                iframe.width = "1";
                iframe.height = "1";
                iframe.frameborder = "0";
                iframe.style.display = "none";
                document.body.appendChild(iframe);
                self.$modalDialog.animate({ scrollTop: 0 }, 'slow');
              }
              
              window.Pulte.contactForms.forEach( function(form) {
                if (form.id === nextModal) {
                  form.show();
                }
              });

              //rewrite error function
              analyticsForm.RewriteObjectForm(data, event);

              AdobeLaunch.pushFormSubmitSucceededEvent(adobeFormId, AdobeLaunch.FORM_MODAL_TYPE, adobeFormName, _this.communityId, _this.communityBrand);
              FacebookPixel.pushFormSubmissionEvent(FacebookPixel.FORM_MODAL_TYPE, facebookPixelFormName, _this.communityBrand);
            }
          }
        })
        .fail(function () {
          // do not push form failed event or trigger error for create account form
          if (_this.id !== 'Contactnav-account') {
            AdobeLaunch.pushFormSubmitFailedEvent(adobeFormId, AdobeLaunch.FORM_MODAL_TYPE, adobeFormName, _this.communityId, _this.communityBrand);
            onError();
          }
        });

        return false;
    }

    this.handleFormChanged = function (formId) {
        var contactForm = window.Pulte.contactForms.filter(function (form) {
            return form.id === formId;
        })[0];

        if (contactForm) {
            var adobeFormName = AdobeLaunch.getFormNameFromId(formId);
            var adobeFormId = contactForm.communityId ? AdobeLaunch.FORM_COMMUNITY_ID : AdobeLaunch.FORM_GLOBAL_ID;
            AdobeLaunch.pushFormStartedEvent(adobeFormId, AdobeLaunch.FORM_MODAL_TYPE, adobeFormName, contactForm.communityId, contactForm.communityBrand);
            this.off(constants.FORM_CHANGED);
        }
    }

    populateRegions = function() {
      var onRegionsFetchSuccess, onRegionsFetchComplete,
        $parentform = $(this.form),
        $statesList = $(this),
        $regionsList = $parentform.find('select.Region'),
        $communitiesList = $parentform.find('select[name=CommunityIds]');

      onRegionsFetchSuccess = function (data) {
        var defaultOption = $regionsList.children().first()[0].outerHTML;
        $regionsList.empty().append(defaultOption);
        if ( Array.isArray( data ) ){
          data.forEach( function( region) {
            $regionsList.append('<option value="'+ region.Name +'">'+ region.Name +'</option>');
          } );
          $regionsList.prop('disabled', false);
          return;
        }
        return onError( null, "Unknown Error" );
      };

      onRegionsFetchComplete = function() {
        var defaultOption = $communitiesList.children().first()[0].outerHTML;
        $communitiesList.empty().append(defaultOption);
      };

      $regionsList.prop('disabled', true);
      $communitiesList.prop('disabled', true);
      onRegionsFetchComplete();

      if ( $statesList.val() !== '' ) {
        $.ajax({
            url: '/API/Region/Search',
            data: {
              State: $statesList.val(),
              Brand: window.Pulte.Brand ? window.Pulte.Brand.replace("-"," ") : 'Pulte'
            },
            dataType: "json"
        })
        .fail( onError )
        .done( onRegionsFetchSuccess )
        .always( onRegionsFetchComplete );
      }
    }

    populateCommunities = function() {
      var onCommunitiesFetchSuccess,
        $parentform = $(this.form),
        $regionsList = $(this),
        $statesList = $parentform.find('select.State'),
        $communitiesList = $parentform.find('select[name=CommunityIds]');

      onCommunitiesFetchSuccess = function( data ) {
        var defaultOption = $communitiesList.children().first()[0].outerHTML;
        $communitiesList.empty().append(defaultOption);
        if ( Array.isArray( data ) ){
          data.forEach( function( community ) {
            $communitiesList.append('<option value="'+ community.id +'">'+ community.name +'</option>');
          } );
          $communitiesList.prop('disabled', false);
          return;
        }
        return onError( null, "Unknown Error" );
      };

      $communitiesList.prop('disabled', true);

      if ( $regionsList.val() !== '' ) {
        $.ajax({
            url: '/API/Community/Search',
            data: {
              State: $statesList.val(),
              Region: $regionsList.val(),
              Brand: window.Pulte.Brand ? window.Pulte.Brand.replace("-"," ") : 'Pulte',
              JsonFormat : true,
			  pageSize : 0
            },
            dataType: "json"
        })
        .done( onCommunitiesFetchSuccess )
        .fail( onError );
      }
    }

    this.registerForm = function (form) {
        var $this = $(form),
            parentId = $this.attr('id'),
            $ownForm = $this.find('form').first();

        var newContactForm = new ContactForm(parentId, $ownForm, self.$element);
        window.Pulte.contactForms.push(newContactForm);
        
        if ($ownForm.length > 0) {
          $ownForm.attach(Form).on(constants.FORM_AJAX_SUBMIT, self.handleFormSubmission.bind(newContactForm))
          
          // do not attach the change listener or sales agent logic to the create account form
          if (parentId !== 'Contactnav-account') {
            $ownForm.on(constants.FORM_CHANGED, self.handleFormChanged.bind($ownForm, parentId));
            $ownForm.find("input[type='radio'][name='SpeakToSalesAgent']").on("click touch", this.updateQuestionsFieldDisplay);
          }
        }
    }

    //Component methods -----------------------------------------------------------------------------------
    this.initialize = function (element, options) {
      self.$element = $(element);
      self.$modalContents = self.$element.find('.ContactNavigation-content');
      self.$errorMessage = self.$element.find( '.MsgModal--error' );
      self.$modalDialog = self.$element.find('.modal-dialog');
      self.$questionsBox = self.$element.find('textarea');

      //Expose the contact forms in a way that other components can call it
      window.Pulte.contactForms = [];
      self.$modalContents.each( function() {
        self.registerForm(this);
      });

      self.on("ContactNavigation.RegisterForm", function(el, form) {
        self.registerForm(form);
        $(form).find('.TooltipBtn').attach(Tooltip);
      });

      //initialize form navigation
      $("#Contactnav-menu > a").each( function() {
        var $this = $(this),
          formContainer = $this.attr("data-form");
        var targetFormName = 'Contactnav-' + formContainer;

        $this.click( function( event ) {
          event.preventDefault();
          if( undefined !== formContainer && formContainer.length > 0 ) {
              var $targetContainer = $('#' + targetFormName),
              $firstInput = $targetContainer.find('input').first();

            $( '.ContactNavigation-content' ).toggleClass( activeClass, false );
            $targetContainer.toggleClass( activeClass, true );
            if( $firstInput.length === 1 && $firstInput.visible() === true ) {
              $firstInput.focus();
              $( 'html, body' ).animate( {
                scrollTop: $firstInput.offset().top
              }, 1000 );
            }
      
            // Adobe Launch Analytics Data Layer Push
            var adobeFormName = AdobeLaunch.getFormNameFromId(targetFormName);
            AdobeLaunch.pushFormViewedEvent(AdobeLaunch.FORM_GLOBAL_ID, AdobeLaunch.FORM_MODAL_TYPE, adobeFormName);
            return;
          }
          window.location.href = $this.attr( 'href' );
        } );
      });
      $("#new_lead_form").click(function(){ $("#modal-contact").hide()});
     
      $(document).on('click', '.salesPhoneNumber', function () {
          var axel = Math.random() + "";
          var a = axel * 10000000000000;
          var iframe = document.createElement('iframe');
          iframe.src = "https://5116500.fls.doubleclick.net/activityi;src=5116500;type=pulcount;cat=pulte000;u1=[zip];u2=[state];u3=[region];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=' + a + '?";
          iframe.width = "1";
          iframe.height = "1";
          iframe.frameborder = "0";
          iframe.style.display = "none";
          document.body.appendChild(iframe);
      });

      //reset modal to the first state (menu of possible forms)
      self.$element.on( 'hidden.bs.modal', function() {
        $( '.ContactNavigation-content' ).toggleClass( activeClass, false );
        $( '#Contactnav-menu' ).toggleClass(activeClass, true);
        self.$errorMessage.toggle( false );
        self.$questionsBox.val("");
      } );

      $(document).on('click', '.externalLink' , function (e) {
        e.preventDefault();
        $('body').addClass('directions-map-modal-contactUs');
        self.$element.modal('hide');
      });

      //bind events
      self.$element.find( 'input[name=IsRealtor]' ).click( function() {
        self.$element.find( '.broker-info' ).toggle( this.checked );
      } );

      self.$element.find('select.State').change(populateRegions);
      self.$element.find('select.Region').change(populateCommunities); 
    
    };
    
    this.updateQuestionsFieldDisplay = function () {
      if ($(this).is(":checked") && $(this).val() == 'false') {
        self.$element.find('.Field-question','.Field-textarea').hide();
      }
      else {
        self.$element.find('.Field-question','.Field-textarea').show();
      }
    }     
    $('.GlobalModalSideHeadline__cta-button').on('click', function( event ) {
      event.preventDefault();
      var $this=$(this)
      var dataTargetCTA= $this.attr("data-target")
      if(dataTargetCTA=="#modal-contact")
      {
      var $targetContainer = $('#Contactnav-community');
      $( '.ContactNavigation-content' ).toggleClass( activeClass, false );
      $targetContainer.toggleClass(activeClass, true);
      }
  })
}

module.exports = component(ContactNavigation);
