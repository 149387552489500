var component = require('../../../lib/js/component.js');

function ManageNotifications() {
    var selectedClass = 'is-selected';

    this.initialize = function (element) {
        this.element = element;
        this.communityId = element.dataset.communityId;
        this.popoverId = '';
        this.selectedIndex = 0;
        this.popover = null;
        this.errorMessage = $('.ErrorBanner')[0];

        $('#' + this.element.id).on('inserted.bs.popover', this.onPopOverInserted.bind(this));
    };

    this.onPopOverInserted = function() {
        this.popoverId = $(this.element).attr('aria-describedby');
        this.popover = $('#' + this.popoverId);
        $($(this.popover).find('.Filter-option')[this.element.dataset.notificationStatus]).addClass(selectedClass);
        $(this.popover).find('.Filter-option').on('click', this.onOptionClick.bind(this));
    };

    this.onOptionClick = function(evt) {
        evt.preventDefault();
        if (Pulte.user && (Pulte.user.ZipCode || Pulte.user.Country)) {
            var btn = evt.target,
                notificationsData = {
                    CommunityId: btn.dataset.communityId,
                    ContactPreference: btn.dataset.value,
                    PlanId: btn.dataset.planId,
                    Tooltype: btn.dataset.toolType,
                    UserId: window.Pulte.user.UserId,
                    SessionId: window.Pulte.user.SessionId
                };

            if (!$(btn).hasClass(selectedClass)) {
                window.eval('alert("' + this.element.dataset.alertText + '")');

                $(this.errorMessage).hide();
                $(this.popover).find('.Filter-option').removeClass(selectedClass);
                $(btn).addClass(selectedClass);
                this.selectedIndex = $.inArray(btn, $(this.popover).find('.Filter-option'));

                this.request = $.ajax({
                    type: 'POST',
                    url: $(btn).attr('href'),
                    data: notificationsData,
                    dataType: 'json',
                    success: this.handleResultsSuccess.bind(this),
                    error: this.handleResultsError.bind(this)
                });
            }
        } else {
            var redirectData = $('[data-redirect]').attr('data-redirect');
            windowCurrentLocation = window.location.href;
            window.location = redirectData + "?redirectUrl=" + windowCurrentLocation;
        }
    };

    this.handleResultsSuccess = function(data) {
        var errorMessage = [];

        if(data.success === true) {
            $('#' + this.element.id).popover('hide');
            $('[data-community-id="' + this.communityId + '"]').attr('data-notification-status', this.selectedIndex);
        } else {
            for (var field in data.fieldErrors) {
                errorMessage.push(data.fieldErrors[field]);
            }
            this.handleResultsError(null, errorMessage.join('. '));
        }
    };

    this.handleResultsError = function(jqXHR, textStatus) {
        var top;

        $(this.errorMessage).html(jqXHR && jqXHR.responseJSON ? jqXHR.responseJSON.error : textStatus);
        $(this.errorMessage).show();

        top = $(this.errorMessage).offset().top - $(this.errorMessage).height() - parseInt($(this.errorMessage).css('margin-top'), 10) - parseInt($(this.errorMessage).css('margin-bottom'), 10);

        $('body, html').animate({
            scrollTop: top
        }, 500);
    };

    this.hidePopovers = function() {
        $('[data-toggle="popover"]').popover('hide');
    };
}

module.exports = component(ManageNotifications);
