var component = require('../../../lib/js/component.js');


function TrioGallery() {
    this.initialize = function(element, options) {
        
    }

}

module.exports = component(TrioGallery);