var component = require('../../../lib/js/component.js');

var CloudinaryVideo = function () {
  this.initialize = function (element, options) {
    var videoPath = this.$element.data('video-path');
    var remoteFolder = this.$element.data('remote-folder');

    if (videoPath) {
      var $fallbackImage = this.$element.find('.CloudinaryVideo__fallbackImage');

      // use cloudinary to render the video
      var $video = $($.cloudinary.video(remoteFolder + videoPath, {
        controls: false,
        autoplay: true,
        loop: true,
        muted: true,
        quality: "auto",
        fetch_format: "auto"
      }));

      // move the fallback image into the video tag
      $fallbackImage.appendTo($video);

      $video.addClass('hidden-xs');
      $video.addClass('CloudinaryVideo__video');

      // add the video to the CloudinaryVideo element
      $video.appendTo(this.$element);
    }
  };
};

module.exports = component(CloudinaryVideo);
