var templater = require("handlebars/runtime")["default"].template;module.exports = templater({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "<div id=\"info-window-route-template\" class=\"Map-markerContent hidden-xs text-center clearfix mx-0\">\r\n\r\n  <h4 class=\"LocalAreaInfoWindow__destination-name my-0\">"
    + alias3(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"name","hash":{},"data":data}) : helper)))
    + "</h4>\r\n\r\n  <h4 class=\"LocalAreaInfoWindow__travel-time my-4\">"
    + alias3(((helper = (helper = helpers.travelTime || (depth0 != null ? depth0.travelTime : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"travelTime","hash":{},"data":data}) : helper)))
    + "</h4>\r\n\r\n  <a href=\"https://maps.google.com/maps/dir/?api=1&origin="
    + alias3(((helper = (helper = helpers.originLatitude || (depth0 != null ? depth0.originLatitude : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"originLatitude","hash":{},"data":data}) : helper)))
    + ","
    + alias3(((helper = (helper = helpers.originLongitude || (depth0 != null ? depth0.originLongitude : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"originLongitude","hash":{},"data":data}) : helper)))
    + "&destination="
    + alias3(((helper = (helper = helpers.destinationLatitude || (depth0 != null ? depth0.destinationLatitude : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"destinationLatitude","hash":{},"data":data}) : helper)))
    + ","
    + alias3(((helper = (helper = helpers.destinationLongitude || (depth0 != null ? depth0.destinationLongitude : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"destinationLongitude","hash":{},"data":data}) : helper)))
    + "\"\r\n    target=\"_blank\" rel=\"noopener\" class=\"LocalAreaInfoWindow__google-maps-link\">\r\n    "
    + alias3(((helper = (helper = helpers.googleMapsLinkText || (depth0 != null ? depth0.googleMapsLinkText : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"googleMapsLinkText","hash":{},"data":data}) : helper)))
    + "\r\n  </a>\r\n</div>\r\n";
},"useData":true});