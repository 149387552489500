var component = require('../../../lib/js/component.js');
var Form = require('../forms/form.js');
var constants = require('../forms/constants.js');
var analyticsForm = require('../forms/analyticsForm');
var AdobeLaunch = require('../../../lib/js/adobelaunch.js');
var FacebookPixel = require('../../../lib/js/facebookpixel.js');
var utils = require('../../../lib/js/utils.js');

function ContactInlineCommunity() {
    var self = this,
        busyClass = 'fetching-code',
        headerOffset,
        toolBarOffset,
        formCommunityId;

    function onError(data, textStatus, event) {
        self.$element.toggleClass(busyClass, false);
        self.$errorMessage.html(data && data.responseJSON ? data.responseJSON.error : textStatus);
        self.$errorMessage.show();
        window.scrollTo(0, self.$errorMessage.offset().top - self.$errorMessage.height() - 10);

        AdobeLaunch.pushFormSubmitFailedEvent(AdobeLaunch.FORM_COMMUNITY_ID, AdobeLaunch.FORM_INLINE_TYPE, AdobeLaunch.FORM_REQUEST_INFO_NAME, formCommunityId);

        //rewrite error function
        analyticsForm.RewriteObjectForm(data, event);
    }

    this.initialize = function (element) {
        self.$element = $(element);
        self.$submitRequest = $('.ContactCommunity--inline');
        self.$requestSent = $('.CommunityContact-inline-request-sent');
        self.$errorMessage = self.$element.find('.MsgModal--error');
        self.$form = self.$element.find('form');
        self.$label = self.$form.find('label');
        self.$input = self.$label.find('input');
        self.$input.on('input', function () {
            self.$label.toggleClass('has-server-error', false);
        });

        self.$form.attach(Form).on(constants.FORM_AJAX_SUBMIT, self.handleFormSubmission)

        self.$form.each(function() {
            var $form = $(this);

            // do not attach form changed event listener to create account form
            if (!$form.hasClass('CreateAccountForm')) {
                $form.on(constants.FORM_CHANGED, self.handleFormChanged);
            }
        });
                               
        self.$element.find("input[type='radio'][name='SpeakToSalesAgent']").on("change", this.updateQuestionsFieldDisplay);

        formCommunityId = self.$form.find('[name=\'CommunityId\']').val();
        formCommunityBrand = self.$form.find('[name=\'CommunityBrand\']').val();

        if (formCommunityId) {
            utils.handleVisible(self.$form, function () {
                AdobeLaunch.pushFormViewedEvent(AdobeLaunch.FORM_COMMUNITY_ID, AdobeLaunch.FORM_INLINE_TYPE, AdobeLaunch.FORM_REQUEST_INFO_NAME, formCommunityId, formCommunityBrand);
            });
        }
    };

    this.handleFormSubmission = function (event, form) {
        var isCreateAccountForm = $(this).hasClass('CreateAccountForm');

        self.$errorMessage.hide();
        self.$element.removeClass(busyClass, true);

        headerOffset = $('.Header').height();
        toolBarOffset = $('.CommunityToolbar').height();

        $.ajax({
            method: form.options.method,
            url: form.options.action,
            data: form.data,
            dataType: "json",
            success: function (data, textStatus, jqXHR) {
                if (isCreateAccountForm) {
                    if (data) {
                        if (data.Success && data.UserId) {
                            AdobeLaunch.pushUserEvent(AdobeLaunch.USER_REGISTERED_EVENT_NAME);
                        }
        
                        location.href = data.RedirectUrl;
                    }
                } else {
                    var errorMessage = [];
                    var dataForm = form.data;
                    self.$element.trigger('dataFields', dataForm);

                    if (!data.success) {
                        for (var field in data.fieldErrors) {
                            errorMessage.push(data.fieldErrors[field]);
                        }
                        onError(data, errorMessage.join('. '), event);
                        return;
                    } else {
                        self.$submitRequest.hide();
                        self.$requestSent.show();
                        var elemOffsetTop = self.$element.offset().top - (headerOffset + toolBarOffset);
                        //scroll element top
                        window.scrollTo(0, elemOffsetTop);
                        AdobeLaunch.pushFormSubmitSucceededEvent(AdobeLaunch.FORM_COMMUNITY_ID, AdobeLaunch.FORM_INLINE_TYPE, AdobeLaunch.FORM_REQUEST_INFO_NAME, formCommunityId);
                        FacebookPixel.pushFormSubmissionEvent(FacebookPixel.FORM_INLINE_TYPE, FacebookPixel.FORM_REQUEST_INFO_NAME);
                    }

                    //rewrite error function
                    analyticsForm.RewriteObjectForm(data, event);
                }
            }
        })
        .fail(function() {
            // only trigger error logic if it's not the create account form
            if (!isCreateAccountForm) {
                onError()
            }
        });
    }

    this.handleFormChanged = function () {
        if (formCommunityId) {
            self.$form.off(constants.FORM_CHANGED);
            AdobeLaunch.pushFormStartedEvent(AdobeLaunch.FORM_COMMUNITY_ID, AdobeLaunch.FORM_INLINE_TYPE, AdobeLaunch.FORM_REQUEST_INFO_NAME, formCommunityId, formCommunityBrand);
        }
    }

    this.updateQuestionsFieldDisplay = function () {
        if ($(this).is(":checked") && $(this).val() == 'false') {
            self.$element.find('.Field-question', '.Field-textarea').hide();
        }
        else {
            self.$element.find('.Field-question', '.Field-textarea').show();
        }
    }
}

module.exports = component(ContactInlineCommunity);
