var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');

function LotMap() {

    this.initialize = function (element) {

        $lotmapModal = $('#lotmap-modal');
        $.cloudinary.responsive();

        $('#lotmap-image').click(function() {
            if (!utils.is.mobileOrTabletScreen()) {
                $lotmapModal.modal('show');
            }
        });
        
    };
}

module.exports = component(LotMap);
        