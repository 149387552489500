var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');

function CriteriaLocation() {

  var viewModel = function(defaultTexts, locationDropdown) {

    var self = this;

    var defaultTitle = "Location"
    var defaultSubTitle = "State, region, or city";

    if (defaultTexts) {
      if (defaultTexts.titleText && defaultTexts.titleText.length) {
        defaultTitle = defaultTexts.titleText;
      }

      if (defaultTexts.subTitleText && defaultTexts.subTitleText.length) {
        defaultSubTitle = defaultTexts.subTitleText;
      }
    } 

    self.DEFAULT_CRITERIA_TITLE = defaultTitle;
    self.DEFAULT_CRITERIA_SUBTITLE = "Choose region or city";

    self.criteria = {};

    self.addCriterion = function (key, defaultValue) {
      var criterion = {};
      criterion.defaultValue = defaultValue;
      criterion.savedChoices = ko.observableArray([]);
      criterion.savedDataKey = -1;
      self.criteria[key] = criterion;
    }

    self.addCriterion("state", "Select a State");
    self.addCriterion("region", "Select a Region");
    self.addCriterion("city", "Select a City");
    
    self.copyToSave = function (selectedChoices, savedChoices) {

      savedChoices([]);

      for (var i = 0; i < selectedChoices().length; i++) {
        var choice = {};
        choice.value = selectedChoices()[i].value;
        savedChoices.push(choice);
      }

    }
    
    self.getSavedCityCombinedTitle = function (defaultValue) {

      var newName = "";

      if (self.criteria['city'].savedChoices().length > 0) {
        self.criteria['city'].savedChoices().forEach(function(e) {
          if (newName.length > 0) {
            newName += ", " + e.value;
          } else {
            newName = e.value;
          }
        });
        if (newName) {
          return newName;
        } else {
          return defaultValue;
        }
      } else {
        return defaultValue;
      }
      
    }
    
    self.showTip = ko.computed(function () {
      return (self.criteria['state'].savedChoices().length > 0);
    });

    self.getCriteriaTitle = ko.computed(function () {
      if (self.criteria['region'].savedChoices().length > 0) {
        return self.criteria['region'].savedChoices()[0].value;
      } else if (self.criteria['state'].savedChoices().length > 0) {
        return self.criteria['state'].savedChoices()[0].value;
      } else {
        return self.DEFAULT_CRITERIA_TITLE;
      }
    });

    self.getCriteriaSubtitle = ko.computed(function () {

      if (self.criteria['region'].savedChoices().length > 0) {
        return self.getSavedCityCombinedTitle("All cities");
      } else if (self.criteria['state'].savedChoices().length > 0) {
        return self.DEFAULT_CRITERIA_SUBTITLE;
      } else {
        return defaultSubTitle;
      }
      
    });

    self.cancelCriteria = function(item, event) {
      self.toggleOpen(item, event);
    };

    self.toggleOpen = function (item, event) {
      var eventType = event.type;
      var $body = $('body');

      event.stopPropagation();

      var $element = $(event.currentTarget).closest('.pfCriteria');

      if($element.hasClass('is-disabled')) {
          return;
      }

      if ($element.hasClass('is-open')) {

        $element.removeClass('is-open');

        if ($body && $body.length && $body.hasClass('filter-is-open')) {
          $body.removeClass('filter-is-open');
        }

        // trigger event in location selection
        if (locationDropdown && eventType !== 'ploc-close') {
          locationDropdown.trigger('pf-cancel');
        }

      } else {
        $element.children().find('.pfCriterion').removeClass('is-open');
        $element.parent().siblings().find('.pfCriteria').removeClass('is-open');

        if ($body && $body.length && $body.hasClass('filter-is-open')) {
          $body.removeClass('filter-is-open');
        }

        if (eventType !== 'ploc-close') {
          $element.addClass('is-open');

          if ($body && $body.length && !$body.hasClass('filter-is-open')) {
            $body.addClass('filter-is-open');
          }
        }
      }

      if(eventType === 'click') {
        $('.ProductFilter').trigger('positionOnInteraction');
      }
    };
    
    // Location Selection Listeners
    if (locationDropdown) {
      locationDropdown.on('ploc-cancel', function(event) {
        self.cancelCriteria(self, event);
      });

      locationDropdown.on('ploc-close', function(event) {
        self.toggleOpen(self, event);
      });

      locationDropdown.on('ploc-change-choices', function(event, criteria) {
        self.copyToSave(criteria['state'].selectedChoices, self.criteria['state'].savedChoices);
        self.copyToSave(criteria['region'].selectedChoices, self.criteria['region'].savedChoices);
        self.copyToSave(criteria['city'].selectedChoices, self.criteria['city'].savedChoices);
      });
    }
    
  } // viewModel

  this.initialize = function (element, options) {

    var _this = this;

    $(window).click(function() {
      _this.$element.removeClass('is-open');
    });

    _this.$element.click(function(event){
      event.stopPropagation();
    });

    var productFilterLocationButton = this.$element.find('.pfCriteria__button')[0];

    var defaultTexts = {
      titleText: this.$element.data('title-text'),
      subTitleText: this.$element.data('sub-title-text')
    };

    var locationDropdown = this.$element;

    ko.applyBindings(new viewModel(defaultTexts, locationDropdown), productFilterLocationButton);

  }

}

module.exports = component(CriteriaLocation);
