var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');
var TypeAheadInput = require('./type-ahead-input');
var TypeAheadResults = require('./type-ahead-results');
var EVT = require('./type-ahead-events');
var template = require('./type-ahead-results.hbs');

function TypeAhead() {

    var defaults = {
        endpoint: "/search/suggest",
        term: "",
        siteName: (Pulte && Pulte.Brand) ? Pulte.Brand.replace("-", " ").toLowerCase() : "pulte"
        //fuzzy:, // Boolean, Optional, default = true (uses “true” and “false”)
        //suggestResultsCount, Int, Optional, default = 5 (controlled from config)
    };

    this.initialize = function (element, options) {

        // Add search results container
        this.$element.append(template({
            buttonValue: this.$element.data('buttonValue')
        }));

        //
        this.find('input[type="search"]').attach(TypeAheadInput);
        this.find('.TypeAhead-results').attach(TypeAheadResults, {
            endpoint: this.$element.data('searchEndpoint')
        });

        //
        this.on(EVT.FOCUS, this.onActive.bind(this));
        this.on(EVT.BLUR, this.onInActive.bind(this));
        this.on(EVT.UPDATE, this.onUpdate.bind(this));
        this.on(EVT.CLEAR, this.onClear.bind(this));
        this.on(EVT.UP, this.onKeyUpPress.bind(this));
        this.on(EVT.DOWN, this.onKeyDownPress.bind(this));
        this.on(EVT.UPDATE_VALUE, this.onUpdateValue.bind(this));
    };

    this.onActive = function (event, data) {
        this.active = true;
    };

    this.onInActive = function (event, data) {
        this.inactive = false;
    };


    //TODO: Don't make an ajax call if data.value is 0 and nothing is displayed.
    this.onUpdate = function (event, data) {
        this.getResults($.extend({}, defaults, {
            term: data.value
        }));
    };

    this.onUpdateValue = function (event, data) {
        this.trigger(EVT.UPDATE_INPUT, data);
    }

    this.onClear = function (event, data) {
        this.trigger(EVT.UPDATE_RESULTS, {
            results: []
        });
    };

    this.onKeyUpPress = function (event, data) {
        this.trigger(EVT.PREV);

    };

    this.onKeyDownPress = function (event, data) {
        this.trigger(EVT.NEXT);
    };


    // Get results from endpoint api
    this.getResults = function (data) {

        this.request = $.ajax({
          type: "POST",
          url: data.endpoint,
          data: data,
          success: this.handleResultsSuccess.bind(this),
          error: this.handleResultsError,
          dataType: 'json'
        });

    };

    // Handle response errors
    this.handleResultsError = function (data, statusText) {
        
    };

    // Handle response success
    this.handleResultsSuccess = function (data) {
        this.trigger(EVT.UPDATE_RESULTS, {
            results: data
        });
    };
}

module.exports = component(TypeAhead);
