var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');


function TooltipMultiple() {

    this.classesObj = {
        'tooltipShown': 'tooltip-shown'
    };

    this.initialize = function ( element ) {
        var tooltipWrapper = $( element ).find( '.Tooltip-multiple-wrapper' ),
            tooltipCta = $( element ).find( '.Tooltip-multiple-link' ),
            bodyEl = $( 'body' ),
            tooltipCtaClose = $( element ).find( '.icon-close' );

        //Events
        tooltipCta.on( 'click mouseover', this.tooltipEvent.bind( this ) );
        bodyEl.on( 'click', this.closeAllTooltips.bind( this ) );
        tooltipCtaClose.on( 'click', this.closeTooltip.bind( this ) );
    };

    this.tooltipEvent = function( e ){
        var self = this,
            allTooltips = $( '.Tooltip-multiple' );

        e.preventDefault();
        allTooltips.removeClass( this.classesObj.tooltipShown );
        $( this.element ).addClass( this.classesObj.tooltipShown );
        if ( utils.has.touch() ){
            this.updateArrowPosition();
        }
    };

    this.closeAllTooltips = function( e ){
        var allTooltips = $( '.Tooltip-multiple' );

        if ( $( e.target ).parent().hasClass( 'Tooltip-multiple-wrapper' ) || $( e.target ).hasClass( 'Tooltip-multiple-wrapper' ) || $( e.target ).hasClass( 'Tooltip-multiple-link' ) ) {
            return;
        }
        allTooltips.removeClass( this.classesObj.tooltipShown );
    };

    this.closeTooltip = function( e ){
        $( this.element ).removeClass( this.classesObj.tooltipShown );    
    };

    this.updateArrowPosition = function(){
        var arrowEl = $( this.element ).find( '.arrow' ),
            wrapperWidth = $( this.element ).width(),
            wrapperPosition = $( this.element ).position().left;

        arrowEl.css('left', wrapperPosition + (wrapperWidth/2)+'px');
    };
}

module.exports = component(TooltipMultiple);
