var component = require('../../../lib/js/component.js');
var CLICK = 'click',
    OPEN_CLASS = 'open',
    promotioInfo = 'promotion-info',
    $window = $(window);
/**
 * Init for the Promotion Module module
 * @param  {Object} element HTML element
 */
function PromotionModuleModal() {
    this.initialize = function ( element ) {
        var tab = $(element).find('.' + promotioInfo + ' h3');

        /**
         * @TODO Remove this line after integration
         */
        $.cloudinary.responsive({ loading: 'lazy' });

        /**
         * set the class open when the user clicks the content title
         *
         */
        tab.on(CLICK, function(){
            $(this).parent().toggleClass(OPEN_CLASS);
        });
    };
}


module.exports = component(PromotionModuleModal);
