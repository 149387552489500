var component = require('../../../lib/js/component.js');
var Form = require('../forms/form.js');
var constants = require('../forms/constants.js');
var analyticsForm = require('../forms/analyticsForm');

function QuickCode() {

  var self = this,
      busyClass = 'fetching-code',
      quickCodeError;

  function onError ( data, textStatus, event ) {
      data.formError = true;
      self.$element.toggleClass( busyClass, false );
      self.$errorMessage.html( data && data.responseJSON ? data.responseJSON.error : textStatus );
      self.$errorMessage.show();
      self.$label.toggleClass( 'has-server-error', true );

      //rewrite error function
      analyticsForm.RewriteObjectForm(data, event);
  }

  this.initialize = function ( element ) {
      self.$element = $( element );
      self.$errorMessage = self.$element.find( '.MsgModal--error' );
      self.$form = self.$element.find( 'form' );
      self.$label = self.$form.find( 'label' );
      self.$input = self.$label.find( 'input' );

      self.$input.on( 'input', function() {
          self.$label.toggleClass( 'has-server-error', false );
      });

      self.$form
          .attach( Form )
          .on( constants.FORM_AJAX_SUBMIT, self.handleFormSubmissionQuickCode );
  };

  this.handleFormSubmissionQuickCode = function( event, form ){
    self.$errorMessage.hide();
    self.$element.toggleClass( busyClass, true );

    $.ajax({
      method: form.options.method,
      url: form.options.action,
      data: form.data,
      dataType: "json",
      success: function( data ) {
        var response;

        if ( !data.success ) {
          onError( data, data.error, event );
          return;
        }

        response = JSON.parse( data.serializedObject );
        location.href = response.Url;
      }
    })
    .fail( onError );
  }
}

module.exports = component(QuickCode);
