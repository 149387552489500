var component = require('../../../lib/js/component.js');

function HeroHome() {

    /**
     * Init for the home page hero with greeting
     * @param  {Object} element HTML element
     */
    this.initialize = function ( element ) {
        $element = $(element);

        $('.carousel-home').carousel({
            interval: 4000,
            pause: null
        });

        /*$("html, body").animate({
            scrollTop:window.innerHeight
        }, '500', 'swing');
        return false;
        */

        // when the user clicks an a tag with an href that contains geo-copy deeplink name
        $element.find('a[href*="geo-copy"]').on('click', function (e) {
            if (window.location.hash === e.currentTarget.hash) {
                //fire hashchange event to trigger deeplink scrolling
                $(window).trigger('hashchange');
            }
        });

        /*
        var $ctaButton = $( '.HeroHome-cta' ),
            $overlayTarget = $( '.HeroHomeOptions' ),
            $optionsArray = $overlayTarget.find( 'li' );

        //events
        $optionsArray.on('click', function( e ) {
            var heroState = $( '.HeroHome-cta h2' );
            heroState.text( $(this).text() );
            $( $overlayTarget ).modal('hide')
        });*/

    };

}

module.exports = component(HeroHome);
