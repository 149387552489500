var component = require('../../../lib/js/component.js');

/**
 * Init for the Promotion Module module
 * @param  {Object} element HTML element
 */
function PromotionModuleSingle() {
    this.initialize = function ( element ) {
         
        $.cloudinary.responsive({ loading: 'lazy' });
    };
}


module.exports = component(PromotionModuleSingle);
