var component = require('../../../lib/js/component.js');
var analyticsForm = require('../forms/analyticsForm');
var AdobeLaunch = require('../../../lib/js/adobelaunch.js');
var utils = require('../../../lib/js/utils.js');

var StickyBottomBar = function () {
    this.initialize = function (element) {

        var _this = this;

        // Ensure buttons get defocused/blurred/de-highlighted upon click or press.
        var buttons = $('.StickyBottomBar__cta');
        buttons.each(function () {
            var cta$ = $(this); // raw dom element
            cta$.on("click focus mouseout mouseup touchend", function (event) {
                _this.SetStickyBottomCTATimeOut(cta$);
            });
        });

        _this.$requestMoreInfoButton = $(".StickyBottomBar__cta[data-cta-name='Request Info']");
        _this.$requestMoreInfoButton.on("click", function () {
            AdobeLaunch.pushFormViewedEvent(AdobeLaunch.FORM_COMMUNITY_ID, AdobeLaunch.FORM_MODAL_TYPE, AdobeLaunch.FORM_REQUEST_INFO_NAME);
        });

    }

    this.SetStickyBottomCTATimeOut = function (elem) {
        if (elem) {
            setTimeout(function () { elem.blur(); }, 10);
        }
    }
}

module.exports = component(StickyBottomBar);
