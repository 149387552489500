var component = require('../../../lib/js/component.js');
var Form = require('../forms/form.js');
var constants = require('../forms/constants.js');
var analyticsForm = require('../forms/analyticsForm');
var FacebookPixel = require('../../../lib/js/facebookpixel.js');

function MortgageContactForm() {

    var self = this,
        busyClass = 'fetching-code';

    function onError ( data, textStatus, event ) {
        self.$element.toggleClass( busyClass, false );
        self.$errorMessage.html( data && data.responseJSON ? data.responseJSON.error : textStatus );
        self.$errorMessage.show();
        window.scrollTo(0, self.$errorMessage.offset().top);
        
        //rewrite error function
        analyticsForm.RewriteObjectForm(data, event);
    }

    this.initialize = function ( element ) {
        self.$element = $( element );
        self.$submitRequest = $('.MortgageContact-submit-request');
        self.$requestSent = $('.MortgageContact-submit-sent');
        self.$errorMessage = self.$element.find( '.MsgModal--error' );
        self.$form = self.$element.find( 'form' );
        self.$label = self.$form.find( 'label' );
        self.$input = self.$label.find( 'input' );
        self.$input.on( 'input', function() {
            self.$label.toggleClass( 'has-server-error', false );
        });

        self.$form
            .attach( Form )
            .on( constants.FORM_AJAX_SUBMIT, self.handleFormSubmission );
    };

    this.handleFormSubmission = function( event, form ){
    	self.$errorMessage.hide();
        self.$element.removeClass( busyClass, true );
        $.ajax({
            method: form.options.method,
            url: form.options.action,
            data: form.data,
            dataType: "json",
            success: function ( data ) {
                var errorMessage = [];
                if ( !data.success ) {
                    for (var field in data.fieldErrors ) {
                      errorMessage.push( data.fieldErrors[field] );
                    }
                    onError( data, errorMessage.join('. '), event );
                    return;
                }else {
                    //rewrite error function
                    analyticsForm.RewriteObjectForm(data, event);
                    self.$submitRequest.remove();
                    self.$requestSent.show();
                    window.scrollTo(0, self.$requestSent.offset().top);

                    FacebookPixel.pushFormSubmissionEvent(FacebookPixel.FORM_INLINE_TYPE, FacebookPixel.FORM_MORTGAGE_ADVISOR_NAME);
                }
            }
        })
        .fail( onError );
    }
}

module.exports = component(MortgageContactForm);
