var component = require('../../../lib/js/component.js');

function Article() {

    /**
     * Init for the article module
     * @param  {Object} element HTML element
     */
    this.initialize = function (element) {
        var $linkGlossary = $('.Glossary-tooltip'),
            $linkPopOver = $('.PopoverLink');

        $linkGlossary.replaceWith($linkPopOver.clone());
        $linkPopOver.remove();
    };
}

module.exports = component(Article);
