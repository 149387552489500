var component = require('../../../lib/js/component.js');

function pfProductType() {

  this.initialize = function (element, options) {

    var _this = this;

    this.find('.pfProductType__button').on('click', function (e) {

        var ptButton = $(e.currentTarget);

        if (!ptButton.hasClass('is-selected')) {
            ptButton.siblings().removeClass('is-selected');
            ptButton.addClass('is-selected');
            ptButton.trigger('pf-product-update', ptButton.data('product-type'));
        }

        $.cloudinary.responsive();
    });
  };
}

module.exports = component(pfProductType);
