
// the callback method that google reCAPTCHA will call once it's done loading. This will then trigger all
// reCAPTCHA containers across the page to render their reCAPTCHA widgets
var onRecaptchaLoaded = function() {
  var publicKey = $('body').data('recaptcha-public-key');

  // try to get all recaptcha containers on the site
  var $recaptchaContainers = $('.pulte-recaptcha-container');

  // add recaptche to each container found
  $recaptchaContainers.each(function() {
    var $containerElement = $(this);

    var recaptchaOptions = {
      sitekey: publicKey,
      theme: "light"
    }

    // render and store the widget id of the new recaptcha checkbox
    var widgetId = grecaptcha.render(this, recaptchaOptions);

    // add the widget id to the element via a data attribute
    $containerElement.attr('data-recaptcha-widget-id', widgetId);
  });
}

// add the recaptcha response to the form object passed in using the widget id from the recaptcha container element passed in
var addRecaptchaResponseToForm = function(form, $formElement) {
  // try to get the recaptcha container on the form
  $recaptchaContainer = $formElement.find('.pulte-recaptcha-container');

  if ($recaptchaContainer.length) {
    var widgetId = $recaptchaContainer.data('recaptcha-widget-id');

    if (widgetId >= 0) {
      var recaptchaResponse = grecaptcha.getResponse(widgetId);
      form.data.recaptchaResponse = recaptchaResponse;
    }
  }
}

module.exports = {
  onRecaptchaLoaded: onRecaptchaLoaded,
  addRecaptchaResponseToForm: addRecaptchaResponseToForm
};
