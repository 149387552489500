var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');

function CriteriaAttributes() {

  var viewModel = function(vmData, defaultTexts, homeTypeOptions) {

    var self = this;

    var defaultTitle = "Filter By";
    var defaultSubTitle = "Price, Bedrooms, Bathrooms";

    if (defaultTexts) {
      if (defaultTexts.titleText && defaultTexts.titleText.length) {
        defaultTitle = defaultTexts.titleText;
      }

      if (defaultTexts.subTitleText && defaultTexts.subTitleText.length) {
        defaultSubTitle = defaultTexts.subTitleText;
      }
    }

    //store api url if it exists
    self.apiUrl = window.location.origin;
    var overrideApiUrl = $('body').data('api-url');
    if (overrideApiUrl && overrideApiUrl.length) {
      self.apiUrl = overrideApiUrl;
    }

    self.DEFAULT_CRITERIA_TITLE = defaultTitle;

    self.initializeChoices = function (choice) {
      choice().forEach(function (e) {        
        e.isSelected = ko.observable(false);
        e.isSaved = ko.observable(false);
        e.isDisabled = ko.observable(false);
      });
    };

    self.addCriterion = function (data, key, defaultValue, css) {
      var criterion = {};
      criterion.choices = ko.observableArray(data);
      criterion.defaultValue = defaultValue;
      criterion.cssClass = css;
      criterion.savedChoice = ko.observable();
      criterion.selectedChoice = ko.observable();
        criterion.selectedValue = ko.computed(function () {
          if (criterion.selectedChoice()) {
            return criterion.selectedChoice().label;
          } else {
            return criterion.defaultValue;
          }
        });
      self.initializeChoices(criterion.choices);   
      self.criteria()[key] = ko.observable(criterion);  
    };

    self.updateCriterionChoices = function (rangeData, key) {

      self.criteria()[key]().choices([]);
      
      if(rangeData)
       {
          rangeData.forEach(function (e) {
          e.isSelected = ko.observable(false);
          e.isSaved = ko.observable(false);
          e.isDisabled = ko.observable(false);
          self.criteria()[key]().choices.push(e);
        });
      }
        
      self.criteria()[key]().savedChoice(null);
      self.criteria()[key]().selectedChoice(null);
    };
    

    self.createMaxData = function (data, maxData) {
      for (var i = 0; i < data.length; i++) {
        var obj = {};
        obj.label = data[i].label;
        obj.value = data[i].value;
        maxData.push(obj);
      }
    };

    self.criteria = ko.observable({});

    self.loadViewModel = function (rangeData) {

      if (rangeData) {

        self.criteria({});

        rangeData.pricesMax = [];
        rangeData.bedsMax = [];
        rangeData.bathsMax = [];

        self.createMaxData(rangeData.prices, rangeData.pricesMax)
        self.createMaxData(rangeData.beds, rangeData.bedsMax)
        self.createMaxData(rangeData.baths, rangeData.bathsMax)

        self.addCriterion(rangeData.prices, "MIN_price", "Min.", '.pfCriterion-price-min');
        self.addCriterion(rangeData.beds, "MIN_bedrooms", "Min.", '.pfCriterion-bedrooms-min');
        self.addCriterion(rangeData.baths, "MIN_bathrooms", "Min.", '.pfCriterion-bathrooms-min');
        self.addCriterion(rangeData.pricesMax, "MAX_price", "Max.", '.pfCriterion-price-max');
        self.addCriterion(rangeData.bedsMax, "MAX_bedrooms", "Max.", '.pfCriterion-bedrooms-max');
        self.addCriterion(rangeData.bathsMax, "MAX_bathrooms", "Max.", '.pfCriterion-bathrooms-max');
        self.addCriterion(rangeData.homeTypes, "home", homeTypeOptions.allHomeTypesLabel, '.pfCriterion-home');

      }

    };

    self.reloadViewModel = function (rangeData) {

      if (rangeData) {

        rangeData.pricesMax = [];
        rangeData.bedsMax = [];
        rangeData.bathsMax = [];

        self.createMaxData(rangeData.prices, rangeData.pricesMax);
        self.createMaxData(rangeData.beds, rangeData.bedsMax);
        self.createMaxData(rangeData.baths, rangeData.bathsMax);
        
        self.updateCriterionChoices(rangeData.prices, "MIN_price");
        self.updateCriterionChoices(rangeData.beds, "MIN_bedrooms");
        self.updateCriterionChoices(rangeData.baths, "MIN_bathrooms");
        self.updateCriterionChoices(rangeData.pricesMax, "MAX_price");
        self.updateCriterionChoices(rangeData.bedsMax, "MAX_bedrooms");
        self.updateCriterionChoices(rangeData.bathsMax, "MAX_bathrooms");

        if(rangeData.homeTypes){

          self.updateCriterionChoices(rangeData.homeTypes, "home");

          //initializing the default home type
          var itemFound = false;
          if(rangeData.homeTypes){
            rangeData.homeTypes.forEach(function (htItem, idx) {
              if (!itemFound && htItem.isAvailable) {
                  self.selectDefaultHome('home', self.criteria()['home']().choices()[idx]);
                  self.saveAttributes();
                  itemFound = true;
              } 
            });
          }
        }
      }

    };

    self.loadViewModel(vmData);

    self.updateViewModel = function (locationData) {

      $('.pfCriteria--attributes').addClass('is-disabled');

      if (locationData.state) {

        var brand = $('body').data('brand');
        var rangesRequestObject = {
          'brand' : brand,
          'state' : locationData.state,
          'region' : locationData.region,
          'allHomeTypesLabel' : homeTypeOptions.allHomeTypesLabel,
          'condosOrTownhomesLabel' : homeTypeOptions.condosOrTownhomesLabel,
          'singleFamilyHomesLabel' : homeTypeOptions.singleFamilyHomesLabel
        };

        // call range api based on region data (from trigger event)
        $.ajax({
            context: this,
            url: self.apiUrl + '/api/community/ranges',
            data: rangesRequestObject,
            success: function(response){
              self.reloadViewModel(response);
              $('.pfCriteria--attributes').removeClass('is-disabled');
            }
        });
      }
    };

    self.getProductFilterPacket = function() {

      var data = {
        "minPrice": "",
        "maxPrice": "",
        "minBedrooms": "",
        "maxBedrooms": "",
        "minBathrooms": "",
        "maxBathrooms": "",
        "homeType": ""
      }

      if (self.criteria()['home']().savedChoice()) data.homeType = self.criteria()['home']().savedChoice().value; 
      if (self.criteria()['MIN_price']().savedChoice()) data.minPrice = self.criteria()['MIN_price']().savedChoice().value;
      if (self.criteria()['MAX_price']().savedChoice()) data.maxPrice = self.criteria()['MAX_price']().savedChoice().value;
      if (self.criteria()['MIN_bedrooms']().savedChoice()) data.minBedrooms = self.criteria()['MIN_bedrooms']().savedChoice().value;
      if (self.criteria()['MAX_bedrooms']().savedChoice()) data.maxBedrooms = self.criteria()['MAX_bedrooms']().savedChoice().value;
      if (self.criteria()['MIN_bathrooms']().savedChoice()) data.minBathrooms = self.criteria()['MIN_bathrooms']().savedChoice().value;
      if (self.criteria()['MAX_bathrooms']().savedChoice()) data.maxBathrooms = self.criteria()['MAX_bathrooms']().savedChoice().value;
      
      return data;

    };

    self.noChoicesSaved = function () {

      for (key in self.criteria()) {
          if (self.criteria()[key]().savedChoice()) return false;
      }

      return true;

    };

    self.showTip = ko.computed(function () {

      return !self.noChoicesSaved();

    });
    
    self.getRangeText = function (min, max, defaultValue) {

      var minIsValid = (min);
      var maxIsValid = (max);
      var rangeText = "";

      if (minIsValid && maxIsValid) {
        if (max.label === min.label) {
          rangeText = min.label;
        } else {
          rangeText = min.label + " to " + max.label;
        }
      } else if (minIsValid) {
        rangeText = min.label + "+";
      } else if (maxIsValid) {
        rangeText = "up to " + max.label;
      }

      if (rangeText.length > 0) {
        return rangeText;
      } else {
        return defaultValue;
      }

    }

    self.getCriteriaTitle = ko.computed(function () {

      if (self.noChoicesSaved()) {
        return self.DEFAULT_CRITERIA_TITLE;
      } else {
        return self.getRangeText(self.criteria()["MIN_price"]().savedChoice(), self.criteria()["MAX_price"]().savedChoice(), "All prices");
      }

    });

    self.getCriteriaSubtitle = ko.computed(function () {

      function getAppendedName(name, value) {
        
        if (value.length > 0) {
          if (name.length > 0) {
            name = name + ", " + value;
          } else {
            name = value;
          }
        }

        return name;
      }

      var newName = "";

      if (self.noChoicesSaved()) {
        return defaultSubTitle;
      } else {
        newName = getAppendedName(newName, self.getRangeText(self.criteria()["MIN_bedrooms"]().savedChoice(), self.criteria()["MAX_bedrooms"]().savedChoice(), ""));
        newName = getAppendedName(newName, self.getRangeText(self.criteria()["MIN_bathrooms"]().savedChoice(), self.criteria()["MAX_bathrooms"]().savedChoice(), ""));
      }

      if (newName.length > 0) {
        return newName;
      } else {
        return "Choose bedrooms or bathrooms";
      }

    });
  
    self.getAttributeName = function (key) {

      if (self.criteria()[key]().selectedChoice()) {
        return self.criteria()[key]().selectedChoice().label;
      } else {
        return self.criteria()[key]().defaultValue;
      }
    };
    
    self.processChoice = function (key) {

      var targetKey = "";
      var targetKeyIsMax = true;
      var resetTarget = false;

      switch (key) {
        case 'MIN_price':
          targetKey = 'MAX_price';
          break;
        case 'MIN_bedrooms':
          targetKey = 'MAX_bedrooms';
          break;
        case 'MIN_bathrooms':
          targetKey = 'MAX_bathrooms';
          break;
        case 'MAX_price':
          targetKey = 'MIN_price';
          targetKeyIsMax = false;
          break;
        case 'MAX_bedrooms':
          targetKey = 'MIN_bedrooms';
          targetKeyIsMax = false;
          break;
        case 'MAX_bathrooms':
          targetKey = 'MIN_bathrooms';
          targetKeyIsMax = false;
          break;
        case 'home':
          targetKey = "home";
          break;
      }

      // check corresponding choice
      if (targetKey.length > 0 && self.criteria()[targetKey]().selectedChoice()) {
        if (targetKeyIsMax && (self.criteria()[targetKey]().selectedChoice().value < self.criteria()[key]().selectedChoice().value)) {
          resetTarget = true;
        } else if (!targetKeyIsMax && (self.criteria()[targetKey]().selectedChoice().value > self.criteria()[key]().selectedChoice().value)) {
          resetTarget = true;
        }
        if (resetTarget) {
          self.criteria()[targetKey]().selectedChoice().isSelected(false);
          self.criteria()[targetKey]().selectedChoice(null);                  
        }
      }

    };

    self.selectChoice = function (selectedAttribute, event) {

      if(!($(event.target).hasClass("is-disabled"))){

        for (var key in self.criteria()) {
  
          var criterion = self.criteria()[key]();
  
          if ($(event.target).parents(criterion.cssClass).length > 0) {
            if (!selectedAttribute.isSelected()) {
              selectedAttribute.isSelected(true);
  
              if (criterion.selectedChoice()) criterion.selectedChoice().isSelected(false);
              criterion.selectedChoice(selectedAttribute);
  
              self.processChoice(key);
  
              self.toggleOpenCriterion(selectedAttribute, event);
  
              return;
            }
          }
        }
      }

    }; // viewModel.selectAttribute

    self.cancelCriteria = function(item, event) {
      self.toggleOpen(item, event);
    };

    self.applyCriteria = function (item, event) {

      var $element = $(event.currentTarget).closest('.pfCriteria');
      
      // save Criteria
      self.saveAttributes();

      // trigger search
      $(event.currentTarget).trigger('pf-filter-update', self.getProductFilterPacket());

      this.toggleOpen(item, event);
    };

    self.saveAttributes = function () {

      for (var key in self.criteria()) {

        var criterion = self.criteria()[key]();

        criterion.choices().forEach(function (choice) {
          choice.isSaved(false);
        });
        
        if ((criterion.selectedChoice()) && (criterion.selectedChoice().isSelected())) {
          criterion.selectedChoice().isSaved(true);
          criterion.savedChoice(criterion.selectedChoice());
        } else {
          criterion.savedChoice(null);
        }
      }
    };

    self.toggleOpenCriterion = function (item, event) {
 
      event.stopPropagation();

      if(item && item.value && item.value.length > 0){
        self.criteria()['home']().choices().forEach(function (e) {
          if(e.isSelected() === true){
            e.isSelected(false);
            e.isSaved(false);
            return;
          }
        });
        item.isSelected(true);
        item.isSaved(true);
      }

      var $element = $(event.target).closest('.pfCriterion');
      
      if($element.hasClass('is-disabled')) {
          return;
      }

      $element.toggleClass('is-open');
      $element.parent().siblings().find('.pfCriterion').removeClass('is-open');
      $element.siblings().removeClass('is-open'); // for range
    }
   
    self.ishomeDisabled = ko.computed(function () {
      return false;
    });

    self.toggleOpen = function (item, event) {
      var eventType = event.type;
      var $body = $('body');

      event.stopPropagation();

      var $element = $(event.currentTarget).closest('.pfCriteria');

      if ($element.hasClass('is-disabled')) {
          return;
      }

      if ($element.hasClass('is-open')) {
        $element.removeClass('is-open');

        if ($body && $body.length && $body.hasClass('filter-is-open')) {
          $body.removeClass('filter-is-open');
        }
        
      } else {
        $element.children().find('.pfCriterion').removeClass('is-open');

        var $locationDropdown = $element.parent().siblings().find('.pfCriteria');
        
        // cancel and close location Dropdown selection if its open
        if ($locationDropdown && $locationDropdown.length && $locationDropdown.hasClass('is-open')) {
          $locationDropdown.trigger('ploc-cancel');
        }

        $element.addClass('is-open');
        
        if ($body && $body.length && !$body.hasClass('filter-is-open')) {
          $body.addClass('filter-is-open');
        }

        // Load Saved Criteria
        for (var key in self.criteria()) {
            self.criteria()[key]().selectedChoice(self.criteria()[key]().savedChoice());
            self.criteria()[key]().choices().forEach(function (choice) {
              choice.isSelected(choice.isSaved());
            });
        }

      }

      var cancelButton = false;
      var $event = $(event.currentTarget)
      
      if($event && $event.length && $event.hasClass('pfCriteria__action--cancel')) {
        cancelButton = true;
      }
      
      if(eventType === 'click' && !cancelButton) {
          $('.ProductFilter').trigger('positionOnInteraction');
      }
      
    };

    $('.ProductFilter').on('updateAttributeRanges', function (event, locationData) {
      self.updateViewModel(locationData)
    });

    self.selectDefaultHome = function(key, defaultChoice){
      if(defaultChoice && key){

        defaultChoice.isSelected(true);
        defaultChoice.isSaved(true);
        self.criteria()[key]().defaultValue = defaultChoice.label;
        
        if(self.criteria()[key]().selectedChoice()) 
          self.criteria()[key]().selectedChoice().isSelected(false);
        self.criteria()[key]().selectedChoice(defaultChoice);
        self.processChoice(key);  
      }
    }
  };

  this.initialize = function (element, options) {

    var _this = this;

    $(window).click(function() {
      _this.$element.removeClass('is-open');
    });

    _this.$element.click(function(event){
      event.stopPropagation();
    });

    var defaultTexts = {
      titleText: this.$element.data('title-text'),
      subTitleText: this.$element.data('sub-title-text')
    };

    var homeTypeOptions = {
      allHomeTypesLabel: this.$element.data('all-home-types'),
      condosOrTownhomesLabel: this.$element.data('condos-or-townhomes'),
      singleFamilyHomesLabel: this.$element.data('single-family-homes')
    };
   
    ko.applyBindings(new viewModel(ProductFilterData.attributes, defaultTexts, homeTypeOptions), element);

  };

}

module.exports = component(CriteriaAttributes);
