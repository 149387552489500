var component = require('../../../lib/js/component.js');
var mapPinHelper = require('../map/map-pin-helper.js');

function StatesMap() {

    this.initialize = function (element, options) {

        var _this = this;

        this.activeStateColor = this.$element.data('activeStateColor');
        this.stateNameTextColor = this.$element.data('stateNameTextColor');
        this.stateNameBackgroundColor = this.$element.data('stateNameBackgroundColor');
        this.stateNameBorderColor = this.$element.data('stateNameBorderColor');
        this.regionNameTextColor = this.$element.data('regionNameTextColor');
        this.brand = this.$element.data('brand');

        if (this.brand === 'Pulte' ||
            this.brand === 'Centex' ||
            this.brand === 'Del Webb' ||
            this.brand === 'John Wieland') {
            this.ctaTextColor = this.$element.data('ctaTextColor');
            this.ctaBackgroundColor = this.$element.data('ctaBackgroundColor');
            this.selectedActiveStateColor = this.$element.data('selectedActiveStateColor');
            this.inactiveStateColor = this.$element.data('inactiveStateColor');
        }

        this.toolTipContainer = this.$element.find('#regionToolTip');
        this.toolTipTitleContainer = this.toolTipContainer.find('#regionToolTipTitle');

        if (this.regionNameTextColor) {
            this.toolTipTitleContainer.css('color', this.regionNameTextColor);
        }

        // Setup for Entire Map for the Brand
        this.svgContainer = this.$element.find('#statesMap');
        this.svgContainerDiv = this.$element.find('.StatesMap__Map');
        this.activeState = d3.select(null);
        this.svgMap = d3.select(element).select('#statesMap');

        // Setup for the selected State
        this.selectedStateMapContainer = this.$element.find('.StatesMap__SelectedStateMap')
        this.svgContainerSelectedState = this.$element.find('#selectedStateMap');
        this.svgMapSelectedState = d3.select(element).select('#selectedStateMap');

        this.setupMaps();

        d3.select(window).on('resize', this.resizeMap.bind(this));

        $(function () { // on load of page
            _this.resizeMap();
        });
    }

    this.drawRegions = function (selectedState) {

        var us;
        if (this.brand === 'John Wieland') {
            us = window.JWMap ? this.geoJson : {};
        }
        else {
            us = window.StatesMap ? this.geoJson : {};
        }

        if (us.objects) {
            var statePath = this.gSelectedState.selectAll('path')
                .data(topojson.feature(us, us.objects.states).features.filter(function (d) { return d.properties.STATEFP == selectedState.properties.STATEFP; }))
                .enter().append('path')
                .attr('d', this.pathSelectedState)
                .style('fill', this.activeStateColor);

            statePath.insert('div', 'path.StatesMap__state--hasCommunity');

            var width = this.svgContainerSelectedState.width();
            var height = this.svgContainerSelectedState.height();

            if (selectedState.properties.NAME === 'Tennessee' || selectedState.properties.NAME === 'North Carolina' || selectedState.properties.NAME === 'Maryland' || selectedState.properties.NAME === "Massachusetts") {
                this.scaleSelectedStateForBreakpoint = .45;
            }

            if (selectedState.properties.NAME === 'Pennsylvania' || selectedState.properties.NAME === 'Washington' || selectedState.properties.NAME === 'Virginia') {
                this.scaleSelectedStateForBreakpoint = .6;
            }

            var bounds = this.pathSelectedState.bounds(selectedState),
                dx = bounds[1][0] - bounds[0][0],
                dy = bounds[1][1] - bounds[0][1],
                x = (bounds[0][0] + bounds[1][0]) / 2,
                y = (bounds[0][1] + bounds[1][1]) / 2,
                scale = this.scaleSelectedStateForBreakpoint / Math.max(dx / width, dy / height),
                translate = [width / 2 - scale * x, height / 2 - scale * y];

            this.gSelectedState.transition()
                .duration(0)
                .style("stroke-width", 1.5 / scale + "px")
                .attr("transform", "translate(" + translate + ")scale(" + scale + ")");
        }

        // Region locations
        // https://www.pulte.com/api/marker/mapregionmarkers?brand=Pulte&currentRegion=undefined

        var _this = this;
        var regions = window.StatesMap ? window.StatesMap.regions : {};

        _this.toolTipTitleContainer.on('click', function () {
            if (_this.statesMap.hasClass('StatesMap__stateSelected')) {
                var regionLink = $(this).data('tooltip-link');
                _this.navigateToRegionPage(regionLink);
            }
        });

        // Plot Regions
        for (var i = 0; i < regions.length; i++) {
            if (regions[i].AbbreviatedState == selectedState.properties.STUSPS) {
                var lat = regions[i].Latitude;
                var lon = regions[i].Longitude;
                var name = regions[i].Name + ', ' + regions[i].AbbreviatedState;
                var link = regions[i].CommunityLink;

                var regionProjection = this.projectionSelectedState([lon, lat]);

                if (regionProjection != null) {
                    var pinScale = .2;

                    var mapPin = this.gSelectedState.append('svg');

                    var iconSvgProperties = {
                        brandName: this.BrandName,
                        pinType: 'region'
                    };

                    var mapPinSourceParsed = $.parseHTML(mapPinHelper.getSVGMapPinObject(iconSvgProperties));

                    if (mapPinSourceParsed && mapPinSourceParsed.length && mapPinSourceParsed[0].hasChildNodes()) {

                        var mapPinSource = (mapPinSourceParsed.length ? mapPinSourceParsed[0] : undefined);
                        mapPin.html(mapPinSource.innerHTML);

                        // Default to 30.303 x 50
                        var pinWidth = (mapPinSource.getAttribute('width') > 0 ? mapPinSource.getAttribute('width') : 30.303) * pinScale;
                        var pinHeight = (mapPinSource.getAttribute('height') > 0 ? mapPinSource.getAttribute('height') : 50) * pinScale;
                        var pinViewBox = (mapPinSource.getAttribute('viewBox').length > 0 ? mapPinSource.getAttribute('viewBox') : '0 0 30.303 50');
                        var pinXmlns = (mapPinSource.getAttribute('xmlns').length > 0 ? mapPinSource.getAttribute('xmlns') : 'http://www.w3.org/2000/svg');

                        mapPin.attr('x', regionProjection[0] - (pinWidth / 2))
                            .attr('y', regionProjection[1] - (pinHeight))
                            .attr('width', pinWidth)
                            .attr('height', pinHeight)
                            .attr('viewBox', pinViewBox)
                            .attr('xmlns', pinXmlns)
                            .attr('data-tooltip-title', name)
                            .attr('data-tooltip-link', link)
                            .attr('class', 'StatesMap__regionPinSvg');
                    } else {
                        // Fallback pin (circle)
                        mapPin.attr('data-tooltip-title', name)
                            .attr('data-tooltip-link', link)
                            .attr('class', 'StatesMap__regionPinSvg');
                        mapPin.append('circle')
                            .attr('cx', this.projectionSelectedState([lon, lat])[0])
                            .attr('cy', this.projectionSelectedState([lon, lat])[1])
                            .attr('r', 1);
                    }

                   
                    mapPin.on('click', function () {
                        var regionLink = $(this).data('tooltip-link');
                        _this.navigateToRegionPage(regionLink);
                    });

                    mapPin.on('mouseover', showTooltip);

                    mapPin.on('mouseout', hideTooltip);
                }
            }
        }

        function hideTooltip() {
            _this.toolTipContainer.removeClass('active');
        }

        function showTooltip() {
            var toolTipTitle = $(this).data('tooltip-title');
            var toolTipLink = $(this).data('tooltip-link');

            _this.toolTipTitleContainer.text(toolTipTitle);
            _this.toolTipTitleContainer.data('tooltip-link', toolTipLink);

            var isProductFilter = $(this).parents('#ProductFilterV2').length;
            var pinOffset = $(this).offset();
            var positionX = pinOffset.left - (30 + (toolTipTitle.length * 6));
            var positionY = 0;

            if (isProductFilter) {
                positionY = pinOffset.top - $(document).scrollTop();
            }
            else {
                if (window.innerWidth > 1024) {
                    var mouseCoords = d3.mouse( d3.select(this).node());
                    var tabletModifier = window.innerWidth > 1024 ? 0 : 60;
                    positionY = pinOffset.top + mouseCoords[1] + tabletModifier;
                }
                else {
                    var offSet = $(d3.event.target).parent().offset();
                    var minValueToKeepPinOnMap = 550;
                    positionY = offSet.top < minValueToKeepPinOnMap ? minValueToKeepPinOnMap : offSet.top;
                }
            }
            
            _this.toolTipContainer.css('left', positionX + 'px');
            _this.toolTipContainer.css('top', positionY + 'px');
            _this.toolTipContainer.addClass('active');
        }
    }

    this.drawMap = function () {

        var _this = this;
        var us;
        if (this.brand === 'John Wieland') {
            us = window.JWMap ? this.geoJson : {};
        }
        else {
            us = window.StatesMap ? this.geoJson : {};
        }

        if (us.objects) {
            var statePath = this.g.selectAll('path')
                .data(topojson.feature(us, us.objects.states).features)
                .enter().append('path')
                .attr('d', this.path)
                .attr('class', function (d) {
                    return d.properties.hasCommunity ? 'StatesMap__state StatesMap__state--hasCommunity' : 'StatesMap__state';
                })

            if (this.inactiveStateColor) {
                this.$element.find('.StatesMap__state:not(.StatesMap__state--hasCommunity)').css('fill', this.inactiveStateColor);
            }

            if (this.activeStateColor) {
                this.$element.find('.StatesMap__state--hasCommunity').css('fill', this.activeStateColor);
            }

            statePath.insert('div', 'path.StatesMap__state--hasCommunity');

            // When a state is clicked on
            statePath.on('click', function (d) {
                if (d.properties.hasCommunity) {
                    _this.activeState.classed('active', false);
                    _this.activeState = d3.select(this).classed('active', true);
                    if (_this.activeState && _this.activeState._groups && _this.activeState._groups[0]) {
                        _this.toolTipContainer.removeClass('active');
                        _this.svgContainerDiv.addClass('hidden');
                        _this.selectedStateMapContainer.removeClass('hidden');
                        _this.drawRegions(d);
                        _this.backButton.show()
                            .click(function () {
                                $(_this.activeState._groups[0]).css('fill', _this.activeStateColor);
                                d3.select("#selectedStateMap").selectAll("path").remove(); // Clear the selected state map
                                _this.resetMap();
                            });
                    }
                }
            });

            // Append div for tooltip to SVG, for the state name tooltip
            var div = d3.select("body")
                .append("div")
                .attr("class", "StatesMap__tooltip")
                .style("opacity", 0);

            statePath.on('mouseover', function (d) {

                if (!d.properties.hasCommunity) return;

                _this.activeState.classed('active', false);
                _this.activeState = d3.select(this).classed('active', true);

                if (_this.activeState && _this.activeState._groups && _this.activeState._groups[0]) {
                    $(_this.activeState._groups[0]).css('fill', _this.selectedActiveStateColor);

                    div.transition()
                        .duration(200)
                        .style("opacity", 1);

                    div.text(d.properties.NAME)
                        .style("left", (d3.event.pageX) + "px")
                        .style("top", (d3.event.pageY) + "px")
                        .style("color", _this.stateNameTextColor)
                        .style("background", _this.stateNameBackgroundColor)
                        .style("border-color", _this.stateNameBorderColor);
                }
            });
            statePath.on('mouseout', function (d) {

                if (_this.activeState && _this.activeState._groups && _this.activeState._groups[0]) {
                    $(_this.activeState._groups[0]).css('fill', _this.activeStateColor);
                }
                div.transition()
                    .duration(200)
                    .style("opacity", 0);
            });
        }
    }

    this.setActiveStates = function () {

        // Valid states list
        var regions = window.StatesMap ? window.StatesMap.regions : {};

        var us;
        if (this.brand === 'John Wieland') {
            us = window.JWMap ? this.geoJson : {};
        }
        else {
            us = window.StatesMap ? this.geoJson : {};
        }

        function stateHasCommunity(stateAbbreviation) {

            for (var i = 0; i < regions.length; i++) {
                if (stateAbbreviation === regions[i].AbbreviatedState) {
                    return true;
                }
            }
            return false;
        }

        // Set states that have a community
        if (us.objects) {
            for (var j = 0; j < us.objects.states.geometries.length; j++) {
                us.objects.states.geometries[j].properties.hasCommunity = stateHasCommunity(us.objects.states.geometries[j].properties.STUSPS);
            }
        }
    }

    this.setupMaps = function () {
        // Setup for Entire Map for the Brand
        this.g = this.svgMap.append('g')
            .style('stroke-width', '1.5px')
            .attr('class', 'StatesMap__scaledG');

        if (this.brand === 'American West' || this.brand === 'DiVosta') {
            $(".GlobalNav-v2-findAHome").css('max-height', '450px');
        }

        if (this.brand === 'John Wieland') {
            this.scaleStateMapForBreakpoint = 1.8; // scale for all breakpoints for JW
        }
        else {
            this.scaleStateMapForBreakpoint = .9; // Pulte, Centex & Del Webb
        }

        if ($(window).innerWidth() > 1200) {
            if (this.brand === 'John Wieland') { // lg breakpoint for JW
                this.svgMap.attr("viewBox", Math.round(this.svgContainer.height() / 3) * 2 + " " + Math.round(this.svgContainer.width() / 5) + " " + this.svgContainer.width() + " " + 400);
            }
        }
        else if ($(window).innerWidth() <= 1200 && $(window).innerWidth() >= 1025) { // lg breakpoint
            if (this.brand === 'John Wieland') {
                this.svgMap.attr("viewBox", "525" + " " + Math.round(this.svgContainer.width() / 5) + " " + this.svgContainer.width() + " " + 400);
            }
            else {
                this.scaleStateMapForBreakpoint = .75; // Pulte, Centex & Del Webb
                this.svgMap.attr("viewBox", Math.round(this.svgContainer.width() / 6) + " 0 " + this.svgContainer.width() + " " + 400);
            }
        }
        else if ($(window).innerWidth() <= 1024 && $(window).innerWidth() > 768) { // tablet breakpoint
            if (this.brand === 'John Wieland') {
                this.svgMap.attr("viewBox", ($(window).innerWidth() > 800 ? Math.round(this.svgContainer.width() / 3) : "400") + " " + Math.round(this.svgContainer.width() / 5) + " " + this.svgContainer.width() + " " + 400).attr("preserveAspectRatio", "xMidYMid");
            }
            else { // Pulte, Centex & Del Webb
                this.svgMap.attr("viewBox", ($(window).innerWidth() <= 850 ? Math.round(this.svgContainer.height() / 6) : "0") + " 0 " + this.svgContainer.width() + " " + this.svgContainer.height()).attr("preserveAspectRatio", "xMidYMid");
            }
        }
        else if ($(window).innerWidth() == 768) { // tablet breakpoint, < 767px the map doesn't show
            if (this.brand === 'John Wieland') {
                this.svgMap.attr("viewBox", "400 " + "215" + " " + this.svgContainer.width() + " " + 400).attr("preserveAspectRatio", "xMidYMid");
            }
            else { // Pulte, Centex & Del Webb
                this.svgMap.attr("viewBox", "100 0 " + this.svgContainer.width() + " " + this.svgContainer.height()).attr("preserveAspectRatio", "xMidYMid");
            }
        }

        this.projection = d3.geoAlbersUsa()
            .scale(this.svgContainer.width() * this.scaleStateMapForBreakpoint)
            .translate([this.svgContainer.width() / 2, this.svgContainer.height() / 2]);

        this.path = d3.geoPath()
            .projection(this.projection);

        // Setup for the selected State
        this.scaleSelectedStateForBreakpoint = .85;
        if ($(window).innerWidth() > 1920 && (this.brand != 'American West') && (this.brand != 'DiVosta')) { // account for smaller height of nav bar
            this.svgMapSelectedState.attr("height", 350).attr("viewBox", "0 -20 960 400");
        }

        if ($(window).innerWidth() >= 1025 && (this.brand === 'American West' || this.brand === 'DiVosta')) { // lg breakpoint for AW & JW
            this.scaleSelectedStateForBreakpoint = .9;
            this.svgMapSelectedState.attr("viewBox", Math.round(this.svgContainer.height() / 2) + " 0 " + this.svgContainerSelectedState.width() + " " + this.svgContainerSelectedState.height());
        }
        else if ($(window).innerWidth() >= 2000 && (this.brand != 'American West') && (this.brand != 'DiVosta')) {
            this.scaleSelectedStateForBreakpoint = 1;
            if ($(window).innerWidth() >= 5000) {
                this.scaleSelectedStateForBreakpoint = .75;
                $("#mapBackButton").css('width', '25%');
            }
            else if ($(window).innerWidth() < 5000 && $(window).innerWidth() >= 4000) {
                $("#mapBackButton").css('width', '35%');
            }
            else if ($(window).innerWidth() < 4000 && $(window).innerWidth() >= 3000) {
                $("#mapBackButton").css('width', '45%');
            }
            else if ($(window).innerWidth() < 3000 && $(window).innerWidth() >= 2500) {
                $("#mapBackButton").css('width', '55%');
            }
        }
        else if ($(window).innerWidth() < 2000 && $(window).innerWidth() > 1600) { // lg breakpoint for JW, Pulte, Centex, Del Webb
            $("#mapBackButton").css('width', '90%');
        }
        else if ($(window).innerWidth() > 1300 && $(window).innerWidth() <= 1600) { // lg breakpoint for JW, Pulte, Centex, Del Webb
            this.svgMapSelectedState.attr("viewBox", Math.round(this.svgContainer.width() / 8) + " 0 " + this.svgContainerSelectedState.width() + " " + this.svgContainerSelectedState.height());
            $("#mapBackButton").css('width', '60%');
        }
        else if ($(window).innerWidth() <= 1200 && $(window).innerWidth() >= 1025) { // lg breakpoint for JW, Pulte, Centex, Del Webb
            if (this.brand != 'American West' && this.brand != 'DiVosta') {
                this.scaleSelectedStateForBreakpoint = .75;
                this.svgMapSelectedState.attr("viewBox", Math.round(this.svgContainer.height() / 2) + " 0 " + this.svgContainerSelectedState.width() + " " + this.svgContainerSelectedState.height());
            }
        }
        else if ($(window).innerWidth() <= 1024 && $(window).innerWidth() > 768) { // tablet breakpoint
            this.scaleSelectedStateForBreakpoint = 1;
            this.svgMapSelectedState.attr("viewBox", "0 0 " + this.svgContainerSelectedState.width() + " " + this.svgContainerSelectedState.height()).attr("preserveAspectRatio", "xMidYMid");
        }
        else if ($(window).innerWidth() <= 768) { // sm breakpoint
            this.svgMapSelectedState.attr("viewBox", "100 0 " + this.svgContainerSelectedState.width() + " " + this.svgContainerSelectedState.height()).attr("preserveAspectRatio", "xMidYMid");

            if (this.brand === 'American West' || this.brand === 'DiVosta') {
                this.scaleSelectedStateForBreakpoint = 1;
            }
            else {
                this.scaleSelectedStateForBreakpoint = .9;
            }
        }

        this.gSelectedState = this.svgMapSelectedState.append('g')
            .style('stroke-width', '1.5px')
            .attr('class', 'StatesMap__scaledGSelectedState');

        this.projectionSelectedState = d3.geoAlbersUsa();

        this.pathSelectedState = d3.geoPath()
            .projection(this.projectionSelectedState);

        this.$scaledGSelectedState = this.$element.find('.StatesMap__scaledGSelectedState');

        // Grab GeoJSON data for states from component view
        this.geoJson;
        if (this.brand === 'Pulte' ||
            this.brand === 'Centex' ||
            this.brand === 'Del Webb' ||
            this.brand === 'John Wieland') {

            if (this.brand === 'John Wieland') {
                this.geoJson = window.JWMap.json;
            }
            else {
                this.geoJson = window.StatesMap.json;
            }

            this.setActiveStates();
            this.drawMap();

            this.backButton = this.$element.find('.container-fluid .StatesMap__button .StatesMap__back');
            this.backButton.hide();

            if (this.backButton) {
                if (this.ctaTextColor) {
                    this.backButton.css('color', this.ctaTextColor);
                }

                if (this.ctaBackgroundColor) {
                    this.backButton.css('background-color', this.ctaBackgroundColor);
                }
            }

            this.statesMap = this.$element.find('.StatesMap__Map');
            this.$scaledG = this.$element.find('.StatesMap__scaledG');
        }
        else if (this.brand === 'American West' || this.brand === 'DiVosta') {
            this.geoJson = window.StatesMap.json;
            this.svgContainer.addClass('hidden');
            this.selectedStateMapContainer.removeClass('hidden');

            if (this.brand === 'American West') {
                this.drawRegions(window.AmericanWestMap.json);
            }
            else if (this.brand === 'DiVosta') {
                this.drawRegions(window.DiVostaMap.json);
            }
        }

    }

    this.navigateToRegionPage = function (regionLink) {
        var origin = window.location.origin;
        window.location.href = origin + regionLink;
    }

    this.resetMap = function () {
        this.svgContainerDiv.removeClass('hidden');
        this.gSelectedState.selectAll("*").remove(); // clear selected state map of current selected state
        this.selectedStateMapContainer.addClass('hidden');

        this.$element.find('.StatesMap__state--hasCommunity').css('fill', this.activeStateColor);

        this.activeState.classed('active', false);
        this.activeState = d3.select(null);

        this.statesMap.removeClass('StatesMap__stateSelected');
        this.toolTipContainer.removeClass('active');

        this.backButton.hide();

        this.g.transition()
            .duration(750)
            .style('stroke-width', '1.5px')
            .attr('transform', '');

        // Reset Scale for selcted state and state map
        this.scaleSelectedStateForBreakpoint = .85;
        if (this.brand === 'John Wieland') { // scale for all breakpoints for JW
            this.scaleStateMapForBreakpoint = 1.8;
        }
        else { // Pulte, Centex & Del Webb
            this.scaleStateMapForBreakpoint = .9;
        }

        if ($(window).innerWidth() >= 1025 && (this.brand === 'American West' || this.brand === 'DiVosta')) { // lg breakpoint for AW & JW
            this.scaleSelectedStateForBreakpoint = .9;
            this.svgMapSelectedState.attr("viewBox", Math.round(this.svgContainer.height() / 2) + " 0 " + this.svgContainerSelectedState.width() + " " + this.svgContainerSelectedState.height());
        }
        else if ($(window).innerWidth() >= 2000 && (this.brand != 'American West') && (this.brand != 'DiVosta')) {
            this.scaleSelectedStateForBreakpoint = 1;
            if ($(window).innerWidth() >= 5000) {
                this.scaleSelectedStateForBreakpoint = .75;
            }
        }
        else if ($(window).innerWidth() <= 1200 && $(window).innerWidth() >= 1025) { // lg breakpoint
            if (this.brand != 'American West' && this.brand != 'DiVosta') {
                this.scaleSelectedStateForBreakpoint = .75; // JW, Pulte, Centex & Del Webb
            }
        }
        else if ($(window).innerWidth() <= 1024 && $(window).innerWidth() > 768) { // tablet breakpoint
            this.scaleSelectedStateForBreakpoint = 1;//  Pulte, Centex & Del Webb
        }
        else if ($(window).innerWidth() == 768) { // tablet breakpoint, < 767px the map doesn't show
            if (this.brand === 'American West' || this.brand === 'DiVosta') {
                this.scaleSelectedStateForBreakpoint = 1;
            }
            else {
                this.scaleSelectedStateForBreakpoint = .9; // Pulte, Centex & Del Webb
            }
        }
    }

    this.resizeMap = function () {
        this.setupMaps();
    }
}

module.exports = component(StatesMap);

