var component = require('../../../lib/js/component.js');

function HorizontalTabs() {
    this.initialize = function (element, options) {
        var $element = $(element);
        var _this = this;

        _this.$horizontalTabs = $element.find('.horizontal-tab-container');
        _this.$accordionArrow = _this.$horizontalTabs.find('.Horizontal-arrow');
        _this.$horizontalNavTabsList = $element.find('.horizontal-tabs');
        _this.$allHorizontalTabs = _this.$horizontalTabs.find('.horizontal-tab');
        _this.$horizontalMobile = $element.find('.horizontal-tabs-mobile');
        _this.$verticalMobile = $element.find('.vertical-tabs-mobile');
        _this.$collapseHorizontalMobile = _this.$horizontalTabs.find('.panel-collapse:first');
        _this.$titlePanel = $element.find('.horizontalMobile-title');
        _this.$verticalTitlePanel = $element.find('.verticalMobile-title');
        _this.$panelTitle = _this.$horizontalTabs.find('.horizontal-panel-title');
        
        // Get all card images NOT in vertical tabs
        _this.$images = _this.$element.find('img').filter(function() {
            return $(this).parents('.VerticalTabs').length === 0;
        });

        var $firstHorizontalTab = _this.$horizontalTabs.find('.horizontal-tab:first');
        var $firstHorizontalTabContent = _this.$horizontalTabs.find('.horizontal-tab-content:first');
        var $horizontalTabContent = $element.find('.horizontal-tab-content');
        var $tabCards = $element.find('.Card-container');

        // Add the Horizontal Tabs section Id as the data-deeplink-scrollto value to each horizontal tab, vertical tab container, and tab card
        $horizontalTabContent.each(function () {
            $(this).attr("data-deeplink-scrollto", '#' + element.id);
        });

        $tabCards.each(function() {
            $(this).attr("data-deeplink-scrollto", '#' + element.id);
        });

        $firstHorizontalTab.addClass('active');
        $firstHorizontalTabContent.addClass('active');

        // Load the first image
        if(_this.$images != null && _this.$images.length != 0) {
            $.cloudinary.responsive();
        }

        if (!_this.$horizontalTabs.hasClass('expEditorEditing')) {
            $cloneToAppend = _this.$allHorizontalTabs.clone();
            $cloneToAppend.detach().appendTo(_this.$horizontalMobile);
            _this.$allHorizontalTabs.detach().appendTo(_this.$horizontalNavTabsList);
        }

        _this.$titlePanel[0].innerHTML = $firstHorizontalTab.first().text();

        _this.$horizontalMobileToggle = $element.find('.horizontal-tabs-mobile li');

        _this.$collapseHorizontalMobile.on('show.bs.collapse hide.bs.collapse', function () {
            _this.$accordionArrow.toggleClass('flip-arrow');
        });
        _this.$allHorizontalTabs.on('click touch', _this.activeToggle.bind(_this));
        _this.$horizontalMobileToggle.on('click touch', _this.activeToggleMobile.bind(_this));
        _this.$element.on('ht-tab-change', _this.selectTabByElement.bind(_this));
    }

    this.activeToggle = function (e) {
        $clickedTab = $(e.currentTarget.firstElementChild);

        this.$titlePanel[0].innerHTML = $clickedTab.text();

        this.$horizontalMobileToggle.find('a[href="' + $clickedTab[0].hash + '"]').tab('show');
        this.$allHorizontalTabs.find('a[href="' + $clickedTab[0].hash + '"]').tab('show');
        
        this.refreshTabImage($clickedTab);
    }

    this.activeToggleMobile = function (e) {
        $clickedTab = $(e.currentTarget.firstElementChild);

        this.$titlePanel[0].innerHTML = $clickedTab.text();
        this.$collapseHorizontalMobile.collapse('hide');

        this.$allHorizontalTabs.find('a[href="' + $clickedTab[0].hash + '"]').tab('show');
        
        this.refreshTabImage($clickedTab);
    }

    this.deeplink = function (event, val) {
        var $deeplinkElement = $(event.currentTarget.firstElementChild).find('[data-deeplink="' + val + '"]');

        this.selectTabByElement(null, $deeplinkElement);
    }

    this.selectTabByElement = function(e, $selectedElement) {
        if ($selectedElement != null && $selectedElement.length > 0) {
            var href = null;

            if ($selectedElement.is('.vertical-tab-content, .VerticalTabs, .Card-container')) {
                closestHorizontalTab = $selectedElement.closest('.horizontal-tab-content');
                if (closestHorizontalTab != null && closestHorizontalTab.length > 0) {
                    href = closestHorizontalTab[0].id;
                }
            } else {
                href = $selectedElement[0].id;
            }

            if (href != null) {
                var $tab = this.$horizontalTabs.find('a[href="#' + href + '"]')
                
                if ($tab.length && $tab[0]) {
                    this.$titlePanel[0].innerHTML = $tab[0].innerHTML;
                    this.$allHorizontalTabs.find('a[href="#' + href + '"]').tab('show');
                    this.$horizontalMobileToggle.find('a[href="#' + href + '"]').tab('show');
                }
            }  
        }
    }

    this.refreshTabImage = function(openTab) {
        // Refresh the card image if it hasn't fully loaded yet
        var $openTabImage = this.find(openTab[0].hash).find('img');
        if($openTabImage != null && !$openTabImage.hasClass('is-fully-loaded')) {
            $openTabImage.cloudinary_update();
            $openTabImage.addClass('is-fully-loaded');
        }
    }
}

module.exports = component(HorizontalTabs);
