var component = require("../../../lib/js/component.js");
var utils = require("../../../lib/js/utils.js");

function EventsAndAnnouncements() {

    this.initialize = function (element, options) {
        _this = this;
        
        this.carouselGuid = utils.generateGuid();
        this.$element.attr("id", "EventsAndAnnouncements-" + this.carouselGuid);
        this.$element.attr("data-guid", this.carouselGuid);
       
        var $carousel = this.$element.find("#EventsAndAnnouncements__carousel");
        var $wrapper = this.$element.find(".EventsAndAnnouncements__wrapper");
        var $head = this.$element.find(".EventsAndAnnouncements__head");
        var $heading = this.$element.find(".EventsAndAnnouncements__heading");
        var $line = this.$element.find(".EventsAndAnnouncements__line");
        var $slide = this.$element.find(".EventsAndAnnouncements__slide"); 
        var $title = this.$element.find(".EventsAndAnnouncements__title");
        var $description = this.$element.find(".EventsAndAnnouncements__description"); 
        var $cta = this.$element.find(".EventsAndAnnouncements__cta");
        
        

        $line.css("border-color", $line.data("lineColor"))
        $line.css("border-width", parseInt($line.data("lineWeight")) + "px")
        $title.css("color", $title.data("eventTitleColor"))
        $cta.css("color", $cta.data("ctaColor"))

        // Initialize carousel if there is more than one event or announcement 
        var numOfCarouselSlides = this.$element.find(".EventsAndAnnouncements__slide").length; 
        if(numOfCarouselSlides > 1 ){
            this.options = {
                dots: false,
                nav: true,
                navSpeed: 300,
                responsive:{
                    0:{
                        items:1, 
                        loop: true,
                        autoHeight: true
                    },
                    768:{
                        items: 2,
                        loop: (numOfCarouselSlides > 2),
                        autoHeight: false
                     
                    },
                    1920:{
                        items: (numOfCarouselSlides > 2 ? 3 : 2),
                        loop: (numOfCarouselSlides > 3),
                        autoHeight: false
                    }
                }
            };
            this.$element.find(".owl-carousel").owlCarousel(this.options);
            this.$element.find(".owl-carousel").on('translate.owl.carousel', this.handleBeforeTranslate.bind(this))
            this.$element.find(".owl-carousel").on('translated.owl.carousel', this.handleAfterTranslate.bind(this))
            this.$element.find(".owl-carousel").on('refreshed.owl.carousel', this.handleCarouselADA.bind(this))
            this.$element.find(".owl-item").addClass("justify-content-center")
            $(window).on("resize", utils.debounce(this.handleWindowResize.bind(this), 250, false)); 
           
            
        }
        else{
            $carousel.removeClass("owl-carousel owl-theme owl-drag px-md-9 py-lg-6");
            $carousel.addClass("px-md-6 px-lg-9 py-md-0");
            $wrapper.addClass("d-md-inline-flex align-items-center px-md-5 px-lg-6 py-md-7 ea-single-item-width");
            $head.addClass("pr-md-6 pr-lg-3 mb-lg-7 mb-md-5");
            $heading.addClass("ea-single-item-heading");
            $slide.removeClass("mx-lg-8")
            $slide.addClass("ml-lg-5 mr-lg-8 ea-single-slide mb-md-0")
            $description.removeClass("mb-lg-7")
            $description.addClass("mb-lg-7 mb-sm-5")
            $line.addClass("ea-single-item-line");
        
            this.handleSingleItemLineHeight();
            $(window).on("resize", utils.debounce(this.handleSingleItemLineHeight.bind(this), 250, false));
        }
       
        var prev = this.$element.find(".prev-nav-button");
        var next = this.$element.find(".next-nav-button");

        prev.addClass("owl-prev")
        prev.css("color", this.$element.find(".owl-carousel").data("carouselBtnColor"));
        prev.css("background-color", this.$element.find(".owl-carousel").data("carouselBtnBackgroundColor"));
        $(prev).on("click", function (event) {
            event.stopPropagation();
            $carousel.trigger('prev.owl.carousel');
        });

        next.addClass("owl-next")
        next.css("color", this.$element.find(".owl-carousel").data("carouselBtnColor"));
        next.css("background-color", this.$element.find(".owl-carousel").data("carouselBtnBackgroundColor"));
        $(next).on("click", function (event) {
            event.stopPropagation(); 
            $carousel.trigger('next.owl.carousel');
        });

        $wrapper.on('click', 'a.popover-close-btn', function (e) {
            e.preventDefault();
            $("[data-toggle=popover]").popover('hide');
            
        });

        this.$element.on('hidden.bs.popover', function(){
            $(".popover").remove();
        })

        this.handleCarouselADA()

    };

    this.handleBeforeTranslate = function(){
        var carousel = this.$element.find(".owl-carousel"); 
        var allCards = carousel.find(".owl-item");
        allCards.removeAttr("aria-hidden"); 

    }

    this.handleAfterTranslate = function(){
        this.handleCarouselNaviagationVisibility()
        this.hideNonVisibleCarouselCards()
        this.showVisibleCarouselCards()
    }

    this.handleWindowResize = function(){

        var owlStage = $(".owl-stage-outer");
        owlStage.css("height", "" );
        
        $(".owl-carousel").trigger("to.owl.carousel", [0,1]);
        $(".owl-carousel").trigger("refresh.owl.carousel");
 
    }

    this.handleCarouselADA = function(){
        this.handleBeforeTranslate()
        this.handleAfterTranslate()
    }

    this.handleSingleItemLineHeight = function(){
        var line = this.$element.find(".EventsAndAnnouncements__line");
        var head = this.$element.find(".EventsAndAnnouncements__head");

        if ($(window).outerWidth() >= 1025) {
            $(".ea-single-item-line").css("height", "200px");
            line.parent().removeClass("col-xs-12");
            head.removeClass("col-xs-12");
        } 
        else{
            $(".ea-single-item-line").css("height", 0);
            line.parent().addClass("col-xs-12");
            head.addClass("col-xs-12");
        }
    }

    this.handleCarouselNaviagationVisibility = function(){
        var wrapper = this.$element.find(".EventsAndAnnouncements__wrapper");
        var prev = this.$element.find(".prev-nav-button");
        var next = this.$element.find(".next-nav-button");
        var owlNav = this.$element.find(".owl-nav");

        if(owlNav.not(".disabled").length > 0){
            prev.removeClass("hide");
            next.removeClass("hide");
        }
        else{
            prev.addClass("hide");
            next.addClass("hide");
        }

        prev.css("top", wrapper.innerHeight() / 2)
        next.css("top", wrapper.innerHeight() / 2)

    }

    this.hideNonVisibleCarouselCards = function(){
        var carousel = this.$element.find(".owl-carousel"); 
        var hiddenCards = carousel.find(".owl-item:not(.active)");
        hiddenCards.attr("aria-hidden", true);
        hiddenCards.find(".EventsAndAnnouncements__cta").attr("tabIndex", -1);
    }

    this.showVisibleCarouselCards = function(){
        var carousel = this.$element.find(".owl-carousel"); 
        var activeCards = carousel.find(".owl-item.active");
        activeCards.removeAttr("aria-hidden");
        activeCards.find(".EventsAndAnnouncements__cta").removeAttr("tabIndex");
    }

}

module.exports = component(EventsAndAnnouncements);
