var component = require('../../../lib/js/component.js');

function ArticleToolbar() {
    var $ArticleToolbar = $('.ArticleToolbar'),
        $siblingToolbar = $('.' + $ArticleToolbar.attr('data-sibling'));

    if ($siblingToolbar.length > 0){
        $('.ArticleToolbar').on('affix.bs.affix', function() {
            $(document.body).addClass('SiblingToolbarOn');
        });
    }
}

module.exports = component(ArticleToolbar);
