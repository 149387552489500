var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');
var MapCore = require('./map-core.js');
var mapsHelper = require('./maps-helper.js');
var mapPinHelper = require('./map-pin-helper.js');

var NearbyMapController = function () {

    this.initialize = function (el, options) {
        var _this = this;
        this.options = {};
        this.$mapElement = this.$element.find('.Map-googleMap');
        $.extend(this.options, options, this.$mapElement.data());
        this.brand = $('body').data('brand');

        // SVG Pin properties
        this.MARKER_PIN_SVG_SCALE_SELECTED = this.options.map.defaults.MARKER_PIN_SVG_SCALE_SELECTED;
        this.MARKER_PIN_SVG_SCALE_UNSELECTED = this.options.map.defaults.MARKER_PIN_SVG_SCALE_UNSELECTED;

        // Placeholder for reference to map instance
        this.map = {};

        // Set Map Type
        this.options.map.pulteMapType = 'nearby';

        // Enable Zoom on Double click
        this.options.map.enableDoubleClickZoom = true;

        // And show the zoom controls
        this.options.map.showZoomControls = true;

        // And enable scrollwheel zoom
        this.options.map.enableScrollWheelZoom = true;

        // Placeholder for marker list
        this.markerList = [];

        if (GlobalMapsObj.nearbyMarkers) {
            this.markers = GlobalMapsObj.nearbyMarkers;
        }

        if (window.Pulte.isMapScriptLoaded) {
            // Setup markers
            _this.setupMap();
        } else {

            $('body').on('MapScriptLoaded', function () {
                // Setup markers
                _this.setupMap();
            });
        }
    };

    /**
     * setupMap: attach mapCore and listen for added map instance
     */
    this.setupMap = function () {
        var _this = this;
        // Set up map specific listener for when map is ready
        var listener = this.options.map.pulteMapType + 'MapInstanceAdded';

        this.$mapElement.on(listener, function (e, map) {
            // Store reference to returned map
            _this.map = map;
            // Add click listener for clicks off of infowindows to close the infowindow
            google.maps.event.addListener(_this.map, "click", function (e) {
                // Added marker check for clicks on maps with no markers
                if (_this.markers.length) {
                    mapsHelper.closeAllInfoWindows.call(_this);
                    _this.resetMarkerSize.call(_this);
                    // There's map interactions on touch devices. To keep
                    // all pins visible map should recenter on infoWindow close
                    if (utils.has.touch()) {
                        _this.fitToBounds.call(_this, _this.markers);
                    }
                }
            });
            // Setup markers
            _this.setupMarkers.call(_this);
            // Remove map instance listener
            $(this).off(listener);
        });
        // Get instance of map
        mapsHelper.attachMapCore.call(this, MapCore, this.options);
    };

    /**
    * Setup and add markers to map
    */
    this.setupMarkers = function () {
        var _this = this;

        $(_this.markers).each(function (i) {
            var marker = _this.markers[i];
            var position = mapsHelper.getItemPosition(marker.Latitude, marker.Longitude);

            // - Make all markers small
            _this.resetMarkerSize.call(_this);

            var iconSvgProperties = {
                scale: _this.MARKER_PIN_SVG_SCALE_SELECTED,
                brandName: marker.BrandName,
                pinType: marker.PinType,
                isActiveAdult: marker.IsActiveAdult
            };

            var formatedPrice = mapsHelper.formatNumber(marker.StartingFromPrice);

            var mapMkr = new google.maps.Marker({
                position: position,
                map: _this.map,
                icon: mapPinHelper.getGoogleMapPinObject(iconSvgProperties),
                name: marker.Name,
                templateType: _this.options.type,
                pinType: marker.PinType,
                isActiveAdult: marker.IsActiveAdult,
                CommunityLink: marker.CommunityLink,
                maxBedrooms: marker.MaxBedrooms,
                minBedrooms: marker.MinBedrooms,
                maxBathrooms: marker.MaxBathrooms,
                minBathrooms: marker.MinBathrooms,
                minGarage: marker.MinGarage,
                maxGarage: marker.MaxGarage,
                inventoryCount: marker.InventoryCount,
                startingFromPrice: formatedPrice,
                priceStatus: marker.PriceStatus,
                communityStatus: marker.CommunityStatus,
                address: marker.Address,
                brandName: marker.BrandName,
                communityId: marker.Id,
                phone: marker.Phone,
                region: marker.Region,
                DisplayGoogleDirections: marker.DisplayGoogleDirections,
                isCommunitySoldOut: marker.IsCommunitySoldOut,
                comingPricePrefix: marker.ComingPricePrefix,
                comingPriceRange: marker.ComingPriceRange
            });

            mapsHelper.compareMinMax(mapMkr, mapMkr.minBedrooms, mapMkr.maxBedrooms);
            mapsHelper.compareMinMax(mapMkr, mapMkr.minBathrooms, mapMkr.maxBathrooms);
            mapsHelper.compareMinMax(mapMkr, mapMkr.minGarage, mapMkr.maxGarage);

            // Update the maker data to reflect new Google Maps formated position
            marker.position = position;
            // Store updated marker
            _this.markerList.push(mapMkr);
            mapsHelper.makeInfoWindow.call(_this, mapMkr);
            _this.addMarkerClickEvent.call(_this, mapMkr);
            mapsHelper.styleInfoWindows.call(_this, mapMkr);

            // Using double bang incase boolean isnt passed
            if (mapMkr.DisplayGoogleDirections) {
                mapsHelper.openInfoWindowOnMapLoad.call(_this, mapMkr)
            }

        });
        // Recenter map to include all markers
        this.fitToBounds.call(_this, _this.markers);
    };

    /**
     * addMarkerClickEvent: Attach click event to marker for infoWindows
     * @param {Object} mapMkr - Google Maps Object containing marker data
     */
    this.addMarkerClickEvent = function (mapMkr) {
        var _this = this;
        google.maps.event.addListener(mapMkr, 'click', function (e) {
            // - Make all markers small
            _this.resetMarkerSize.call(_this);

            var iconSvgProperties = {
                scale: _this.MARKER_PIN_SVG_SCALE_SELECTED,
                brandName: this.brandName,
                pinType: this.pinType,
                isActiveAdult: this.isActiveAdult
            };

            this.setIcon(mapPinHelper.getGoogleMapPinObject(iconSvgProperties));
            // Close any open infoWindows
            mapsHelper.closeAllInfoWindows.call(_this);
            // Open this markers infoWindow
            this.iw.open(_this.map, this);

            $('.gm-style-iw').addClass('openIW');
        });
    };

    this.resetMarkerSize = function () {
        var _this = this;
        $(this.markerList).each(function (i) {
            // Marker icon object
            var iconSvgProperties = {
                scale: _this.MARKER_PIN_SVG_SCALE_UNSELECTED,
                brandName: this.brandName,
                pinType: this.pinType,
                isActiveAdult: this.isActiveAdult
            };

            _this.markerList[i].setIcon(mapPinHelper.getGoogleMapPinObject(iconSvgProperties));
        });
    };

    /**
     * fitToBounds: Update bounds of markers and recenter map
     */
    this.fitToBounds = function (markers) {
        var bounds = mapsHelper.getBounds(markers);
        this.map.fitBounds(bounds);
    };
}
module.exports = component(NearbyMapController);
