var component = require('../../../lib/js/component.js');

function PersonalizedNav() {
    this.initialize = function (element, options) {
        var _this = this;
        this.$personalizedNavLinks = this.$element.find('.PersonalizedNav__linksList');
        var $defaultLink = this.$personalizedNavLinks.find('.PersonalizedNav__link--default');
        var $dropdownButton = this.$element.find('.PersonalizedNav__linksDropdown');
        this.$dropdownButtonIcon = $dropdownButton.find('.PersonalizedNav__linksDropdownIcon');
        this.$dropdownButtonText = $dropdownButton.find('.PersonalizedNav__linksDropdownLabel');
        this.$activeNavLink;
        this.linksListIsOpenClass = 'PersonalizedNav__linksList--isOpen';
        this.linkIsActiveClass = 'PersonalizedNav__link--isActive';

        // Disable fixed positioning of header when this module is present
        // Doing it here allows for height change of header (in CSS) without requiring update of HTML 'data-offset-top'
        this.$element.affix({ offset: { top: $('header').outerHeight(true), bottom: 200 } });

        // Disable fixed positioning of header when this module is present
        $(window).on('components.loaded', function () {
            $(window).trigger('Header.disableFixed');
        });

        // Setup dropdown behavior for tablet/mobile
        $dropdownButton.on('click', this.toggleOpen.bind(this));
        _this.activateLink($defaultLink);

        // Attach click listener to each link
        this.$personalizedNavLinks.find('.PersonalizedNav__link').on('click', function(e) {
            _this.toggleOpen(e);
            _this.scrollToSection($(e.target).attr('data-nav-target'));
        });

        // Create nav item for each component that has a data nav title on the page
        $('[data-personalized-nav-title]').each(function (index) {
            var $currentElement = $(this);
            var navTitle = $currentElement.attr('data-personalized-nav-title');
            var navId = $currentElement.attr('data-nav-id');

            if (navTitle && navId) {
                _this.$personalizedNavLinks.find('.PersonalizedNav__linkContainer:last-child').before(
                    $('<li class="PersonalizedNav__linkContainer"/>')
                        .append(
                            $('<a class="PersonalizedNav__link"/>')
                                .attr('href', '#')
                                .attr('data-nav-target', navId)
                                .text(navTitle)
                                .on('click', function (e) {
                                    _this.toggleOpen(e);
                                    _this.scrollToSection($(e.target).attr('data-nav-target'));
                                })
                        )
                );
            }
        });

        // Scroll to top button
        this.$element.find('.PersonalizedNav__backToTop, .PersonalizedNav__linkContainer--default').on('click', function (e) {
            e.preventDefault();
            _this.$personalizedNavLinks.removeClass(_this.linksListIsOpenClass);
            _this.$dropdownButtonIcon.removeClass('icon-arrow-up').addClass('icon-arrow-down');
            $('html, body').animate({
                scrollTop: 0
            }, 1000);
        });


        // handle adjusting navigation on scroll
        $(window).on('scroll', function () {
            var $newNavLink;
            var moduleTransitionLoc = $(window).scrollTop() + _this.$element.outerHeight() + 150;

            _this.$personalizedNavLinks.find('.PersonalizedNav__link').each(function (index) {
                var $currentElement = $(this);

                if ($currentElement.attr('data-nav-target')) {
                    var $currentTargetElement = _this.getJQueryObjectByNavTarget($currentElement.attr('data-nav-target'));

                    if ($currentTargetElement && $currentTargetElement.is(':visible') && $currentTargetElement.offset() && moduleTransitionLoc >= $currentTargetElement.offset().top) {
                        $newNavLink = $currentElement;
                    }
                }
            });

            if ($newNavLink) {
                if (!_this.$activeNavLink || (_this.$activeNavLink.attr('data-nav-target') != $newNavLink.attr('data-nav-target'))) {
                    _this.$activeNavLink = $newNavLink;
                    _this.activateLink(_this.$activeNavLink);
                }
            } else {
                if (_this.$activeNavLink) {
                    _this.$activeNavLink = undefined;
                    _this.activateLink($defaultLink);
                }
            }
        });

        $(window).trigger('scroll');
    }

    this.toggleOpen = function (e) {
        e.preventDefault();
        this.$personalizedNavLinks.toggleClass(this.linksListIsOpenClass);
        if (this.$personalizedNavLinks.hasClass(this.linksListIsOpenClass)) {
            this.$dropdownButtonIcon.removeClass('icon-arrow-down').addClass('icon-arrow-up');
        } else {
            this.$dropdownButtonIcon.removeClass('icon-arrow-up').addClass('icon-arrow-down');
        }
    }

    this.activateLink = function ($link) {
        var _this = this;
        this.$personalizedNavLinks.find('.PersonalizedNav__link').each(function (index) {
            $(this).removeClass(_this.linkIsActiveClass);
        });
        $link.addClass(this.linkIsActiveClass);
        this.$dropdownButtonText.text($link.text());
    }

    this.scrollToSection = function (dataNavTarget) {
        var targetOffset = this.$element.outerHeight();
        if (this.$element.hasClass('affix-top')) {
            targetOffset = targetOffset * 2;
        }

        $targetElement = this.getJQueryObjectByNavTarget(dataNavTarget);

        if ($targetElement && $targetElement.length) {
            // detect if the element is in a tab container and trigger tab change events
            var $HorizontalTabContainer = $targetElement.parents('.HorizontalTabs')
            var $VerticalTabContainer = $targetElement.parents('.VerticalTabs');

            if ($HorizontalTabContainer.length) {
                $HorizontalTabContainer.trigger('ht-tab-change', [$targetElement]);
            }

            if ($VerticalTabContainer.length) {
                $VerticalTabContainer.trigger('vt-tab-change', [$targetElement]);
            }

            if ($targetElement && $targetElement.offset()) {
                $('html, body').animate({
                    scrollTop: $targetElement.offset().top - targetOffset
                }, 1000);

                // trigger scrolling so the correct nav item is selected even if the page didn't scroll
                $(window).trigger('scroll');
            }
        }
    }

    this.getJQueryObjectByNavTarget = function (dataNavTarget) {
        var $targetElement;

        if (dataNavTarget) {
            $targetElement = $("[data-nav-id='" + dataNavTarget + "']");
        }

        return $targetElement;
    }
}

module.exports = component(PersonalizedNav);
