// determine the behaviour for modals
(function ($) {

  $(function () {

    var $modals = $('.modal');

    //Set autofocus for inputs in modals when shown, excluding modal-experience:
    $modals.on('shown.bs.modal', function () {
      if (this.id !== 'modal-experience') {
      var $targetInput,
        focusSet = false,
        inputEls = $(this).find("select, input");
      for (var i = 0; i < inputEls.length; i++) {
        $targetInput = $(inputEls[i]);

        if ($targetInput.visible() === true) {
          $targetInput.focus();
          focusSet = true;
        }
        if (focusSet) {
          break;
        }
      }
    }
    });

    //Bind the corresponding event for elements that trigger a modal
    $(document).on('click', "[data-trigger-modal]", function () {

      var modalId = this.dataset.triggerModal;

      if (window.Pulte !== undefined && window.Pulte.contactForms !== undefined) {
        window.Pulte.contactForms.forEach(function (form) {
          if (form.id === modalId) {
            form.show();
          }
        });
      }
    });
  });

}(jQuery));
