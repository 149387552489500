var component = require("../../../lib/js/component.js");
var utils = require("../../../lib/js/utils.js");

function CommunitySeries() {

    this.initialize = function (element, options) {
        _this = this;
        
        this.carouselGuid = utils.generateGuid();
        this.$element.attr("id", "CommunitySeries-" + this.carouselGuid);
        this.$element.attr("data-guid", this.carouselGuid);
       
        var $carousel = this.$element.find(".CommunitySeries__carousel");
        var $wrapper = this.$element.find(".CommunitySeries__wrapper");
        var $head = this.$element.find(".CommunitySeries__head");
        var $heading = this.$element.find(".CommunitySeries__heading");
        var $content = this.$element.find(".CommunitySeries__content"); 
        var $title = this.$element.find(".CommunitySeries__title"); 
        var $image = this.$element.find(".CommunitySeries__image"); 
        var $cta = this.$element.find(".CommunitySeries__cta");
        var $slide = this.$element.find(".CommunitySeries__slide"); 
        var numOfCarouselSlides = $slide.length; 


        $title.css("color", $title.data("eventTitleColor"))
        $cta.css("color", $cta.data("ctaColor"))

        $heading.append(" (" + numOfCarouselSlides + ")")

        if(numOfCarouselSlides > 1 ){
            this.options = {
                    dots: false,
                    nav: true,
                    navSpeed: 300,
                    responsive:{
                        0:{
                            items:1, 
                            loop: true,
                            autoHeight: true
                        },
                        768:{
                            items: 2,
                            loop: (numOfCarouselSlides > 2),
                            autoHeight: false
                        },
                        1025:{
                            items: (numOfCarouselSlides > 2) ? 3 : 2,
                            loop: (numOfCarouselSlides > 2) ? 3 : 2,
                            autoHeight: false
                        },
                        1920:{
                            items: 2,
                            loop: (numOfCarouselSlides > 2),
                            autoHeight: false
                        }
                    }
                };
                this.$element.find(".owl-carousel").owlCarousel(this.options);
                this.$element.find(".owl-carousel").on('translate.owl.carousel', this.handleBeforeTranslate.bind(this))
                this.$element.find(".owl-carousel").on('translated.owl.carousel', this.handleAfterTranslate.bind(this))
                this.$element.find(".owl-carousel").on('refreshed.owl.carousel', this.handleCarouselADA.bind(this))
                this.$element.find(".owl-item").addClass("justify-content-center")
                $(window).on("resize", utils.debounce(this.handleWindowResize.bind(this), 250, false)); 
        }else{

            var $singleImage = this.$element.find(".u-responsiveMedia")
            $carousel.removeClass("owl-carousel owl-theme owl-drag")
            $carousel.addClass("d-flex justify-content-center")
            $singleImage.addClass("single-image");
            $content.addClass("single-item-width");
            

        }

        var prev = this.$element.find(".prev-nav-button");
        var next = this.$element.find(".next-nav-button");

        prev.addClass("owl-prev")
        prev.css("color", this.$element.find(".owl-carousel").data("carouselBtnColor"));
        prev.css("background-color", this.$element.find(".owl-carousel").data("carouselBtnBackgroundColor"));
        $(prev).on("click", function (event) {
            event.stopPropagation();
            $carousel.trigger('prev.owl.carousel');
        });

        next.addClass("owl-next")
        next.css("color", this.$element.find(".owl-carousel").data("carouselBtnColor"));
        next.css("background-color", this.$element.find(".owl-carousel").data("carouselBtnBackgroundColor"));
        $(next).on("click", function (event) {
            event.stopPropagation(); 
            $carousel.trigger('next.owl.carousel');
        });

        this.handleCarouselADA()
    
        // Handle toggling to Plans for "See Collection" CTA
        this.$element.find('.CommunitySeries__cta').on('click touch', function(e) {
            if (window.location.hash === e.currentTarget.hash) {
                //fire hashchange event to trigger deeplink scrolling
                $(window).trigger('hashchange');
            }

            if($(".qmi-grid").length > 0 || (".qmi-gallery").length > 0){
                $("label[for=r-Homes]").trigger("click");
            }
        });
    };

    this.handleBeforeTranslate = function(){
        var carousel = this.$element.find(".owl-carousel"); 
        var allCards = carousel.find(".owl-item");
        allCards.removeAttr("aria-hidden"); 
    }

    this.handleAfterTranslate = function(){
        this.handleCarouselNaviagationVisibility()
        this.hideNonVisibleCarouselCards()
        this.showVisibleCarouselCards()
    }

    this.handleWindowResize = function(){
       
        var owlStage = $(".owl-stage-outer");
        owlStage.css("height", "" );
        

        $(".owl-carousel").trigger("to.owl.carousel", [0,1]);
        $(".owl-carousel").trigger("refresh.owl.carousel");
       
    }

    this.handleCarouselADA = function(){
        this.handleBeforeTranslate()
        this.handleAfterTranslate()
    }


    this.handleCarouselNaviagationVisibility = function(){
        var topBuffer = $(window).outerWidth() < 1920 ? 0 : -115
        var prev = this.$element.find(".prev-nav-button");
        var next = this.$element.find(".next-nav-button");
        var owlNav = this.$element.find(".owl-nav");

        if(owlNav.not(".disabled").length > 0){
            prev.removeClass("hide");
            next.removeClass("hide");
        }
        else{
            prev.addClass("hide");
            next.addClass("hide");
        }
       
 
        prev.css("top", topBuffer + 340)
        next.css("top", topBuffer + 340)

    }

    this.hideNonVisibleCarouselCards = function(){
        var carousel = this.$element.find(".owl-carousel"); 
        var hiddenCards = carousel.find(".owl-item:not(.active)");
        hiddenCards.attr("aria-hidden", true);
        hiddenCards.find(".CommunitySeries__cta").attr("tabIndex", -1);
    }

    this.showVisibleCarouselCards = function(){
        var carousel = this.$element.find(".owl-carousel"); 
        var activeCards = carousel.find(".owl-item.active");
        activeCards.removeAttr("aria-hidden");
        activeCards.find(".CommunitySeries__cta").removeAttr("tabIndex");
    }

}

module.exports = component(CommunitySeries);