var popover = require('bootstrap-sass/assets/javascripts/bootstrap/popover');
var component = require('../../../lib/js/component.js');

function PopoverBtn() {

    this.initialize = function (element, options) {
        var _this = this;
        // Reference to original popover 'leave' method
        var originalLeave = $.fn.popover.Constructor.prototype.leave,
            trigger = element.dataset.overrideTrigger === 'true' ? element.trigger : 'click hover';
        // Override method to deal with hover intent so it won't close/leave when user interacts
        // with the accordion
        $.fn.popover.Constructor.prototype.leave = function (obj) {
            var self = obj instanceof this.constructor ?
                obj : $(obj.currentTarget)[this.type](this.getDelegateOptions()).data('bs.' + this.type)
            var container, timeout;

            originalLeave.call(this, obj);

            if (obj.currentTarget) {
                container = $(obj.currentTarget).siblings('.popover')
                timeout = self.timeout;
                container.one('mouseenter', function () {
                    //We entered the actual popover – call off the dogs
                    clearTimeout(timeout);
                    //Let's monitor popover content instead
                    container.one('mouseleave', function () {
                        $.fn.popover.Constructor.prototype.leave.call(self, self);
                    });
                })
            }
        };

        // Because the entire popover's markup is generated the close button
        // dosen't exist until the popover is shown.
        this.$element.on('shown.bs.popover', function () {
            // Remove unused close icon wrapper
            _this.$element.siblings().find('.PopoverBtn-header').unwrap();

            // Find the close btn in the dynamically generated popover
            _this.$element.siblings().find('.popover-close-btn').on('click', function (e) {
                e.preventDefault();
                _this.$element.popover('hide');
            });
        });

        this.$element.popover({
            trigger: trigger,
            delay: { show: 50, hide: 400 },
            template: '<div class="popover" role="tooltip"><div class="arrow"></div><div class="popover-scroll-wrap"><h3 class="popover-title"></h3><div class="popover-content"></div></div></div>'
        });
    };
}
module.exports = component(PopoverBtn);
