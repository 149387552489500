/**
 * Analytics Object Rewrite
 */


var analyticsFormObj = {
  /*
   * ReWriteObject: function thats rewrite the Analytics.user.form object 
   * @param {data} data 
   * @param {event} event
  */
  RewriteObjectForm: function ( data, event ) {
    if(Analytics && Analytics.user && Analytics.user.form) {
      var AnalyticsForm = Analytics.user.form,
          ErrorMessages = [],
          ErrorFields = [],
          FormError = {},
          FormName;
      //get the target form name
      if(data.onFail){
        FormName = data.name;
      }else {
        if(event.target) {
          FormName = event.target.name;
        }
      }

      if(!data.success){
        for (var field in data.fieldErrors ) {
          ErrorFields.push(field);
          ErrorMessages.push( data.fieldErrors[field] );
        }

        FormError = {
          data: {
            fields: ErrorFields.join('|'),
            errors: ErrorMessages.join('|')
          }
        }

        if (data.formError) {
          var formInputName = $(event.target).find('input[type=text]');
          FormError = {
            data: {
              fields: formInputName.attr('name'),
              errors: data.error
            }
          }
        }

      }else {

        FormError = {
          data: null
        }

      }
      
      //rewrite the Analytics.user.form object
      AnalyticsForm.name = FormName;
      AnalyticsForm.success = data.success;
      AnalyticsForm.data = FormError.data;
      AnalyticsForm.error = data.error;
      AnalyticsForm.status = data.status;
    }
  }

};

module.exports = analyticsFormObj;
