var component = require('../../../lib/js/component.js');


function CommunityHero() {

    this.initialize = function (element) {
        var _this = this,
            $element = $(element),
            modalVideoId = '#CommunityHero-videoPlayer',
            $initialPlayButton = $element.find('.community-video-link'),
            touch = Modernizr.touchevents;

        _this.$overlayVideoTarget = $(modalVideoId);
        _this.idVideo = $initialPlayButton.attr('data-idVideo');
        _this.$playButton = _this.$overlayVideoTarget.find('.start-video');
        _this.$videoWrapper = _this.$overlayVideoTarget.find('.CommunityHero-videoPlayer-wrapper');
        _this.$videoPlayerImage = _this.$videoWrapper.find('img');
        _this.player = {};
        _this.videoIsReady = false,

        startYoutubeAPI();

        onPlayerReady = function (event) {
            _this.videoIsReady = true;
        }

        onPlayerStateChange = function (event) {
            if (event.data == YT.PlayerState.ENDED) {
                _this.$playButton.fadeIn('normal');
            }
        }

        $initialPlayButton.on('click', function(e) {
            e.preventDefault();
            _this.renderModal(_this.idVideo);
        });

        _this.$videoWrapper.on('click', function(e) {
            _this.playVideo();
        }.bind(_this));

        _this.$overlayVideoTarget.on('hide.bs.modal', function (e) {
            if (_this.videoIsReady) {
                _this.player.pauseVideo();
            }

            $('body').removeClass('video-player-from-modal');
        });

        _this.playVideo = function () {
            if (_this.videoIsReady) {
                var heightVideo = _this.$videoPlayerImage.height();
                _this.$videoPlayer = _this.$overlayVideoTarget.find('.CommunityHero-videoPlayer-wrapper iframe');

                _this.$videoPlayerImage.hide();

                _this.$videoPlayer.height(heightVideo);
                _this.player.loadVideoById(_this.idVideo);
                _this.$videoPlayer.show();

                _this.$playButton.fadeOut('normal');
                _this.player.playVideo();
            } else {
                setTimeout(function() {
                    _this.playVideo()
                }, 1000);
            }
        };
        
        youtubeVideosStack.push({
            context: _this,
            player: 'player',
            id: 'playerCommunityHero',
            options: {
                height: '100%',
                width: '100%',
                videoId: '',
                playerVars: {
                    'autoplay': 0,
                    'rel': 0,
                    'showinfo': 0,
                    'enablejsapi': 1
                },
                events: {
                    'onReady': onPlayerReady,
                    'onStateChange': onPlayerStateChange
                }
        }
        });
    };

    /**
     * Method to render modal information
     * @param  id of YouTube video
     */
    this.renderModal = function () {
        var _this = this,
            sourceImage = '//i.ytimg.com/vi/' + _this.idVideo + '/hqdefault.jpg';
            sourceImageHd = '//i.ytimg.com/vi/' + _this.idVideo + '/maxresdefault.jpg';

        $('body').addClass('video-player-from-modal');
        _this.$overlayVideoTarget.modal('hide');

        _this.$videoPlayerImage.attr("src", sourceImageHd);

        setTimeout(function(){
            var img = _this.$videoPlayerImage[0];
            //120 default empty image
            if (typeof img.naturalWidth != "undefined" && (img.naturalWidth == 0 || img.naturalWidth == 120)) {
                _this.$videoPlayerImage.attr("src", sourceImage);
            }

            _this.$videoPlayerImage.show();
            _this.$playButton.show();
            _this.$videoPlayer = _this.$overlayVideoTarget.find('.CommunityHero-videoPlayer-wrapper iframe');
            _this.$videoPlayer.hide();
        }, 350);
    };
}

module.exports = component(CommunityHero);
