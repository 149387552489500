var component = require('../../../lib/js/component.js');

function TilesModuleTabs() {

    /**
     * Init for the Tiles Module Tabs
     * @param  {Object} element HTML element
     */
    this.initialize = function (element) {
        var $tileItemActive = $(element).find('.Tile-item'),
            $tileLink = $('.tileLink'),
            $imgs = this.$element.find('img');
            $.cloudinary.responsive();

        //events
        $tileLink.on('click', function (e) {
            e.preventDefault();
            $.cloudinary.responsive();

            setTimeout(function () {
                $imgs.cloudinary_update();
            }, 400);
            $tileItemActive.removeClass("active");
            $(this).closest('.Tile-item').addClass("active");
        });
    };
}

module.exports = component(TilesModuleTabs);
