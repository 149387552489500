var component = require('../../../lib/js/component.js');
var validator = require('./validator.js');
var constants = require('./constants.js');

var FieldGroupPasswords = function () {


    this.initialize = function (el, options) {
        this.options = options || {};
        this.data = this.$element.data();

        var fieldSelectors = ['.Field-text'];
        this.$element.on(constants.FIELD_INIT, fieldSelectors.join(), this.handleFieldInit.bind(this));
        this.$element.on(constants.FIELD_CHECKED, fieldSelectors.join(), this.handleFieldCheck.bind(this));
        this.$element.on(constants.BLUR, fieldSelectors.join(), this.handleFieldCheck.bind(this));

        this.trigger(constants.FIELD_INIT, this);
    };

    this.handleFieldInit = function (event, field) {
        if (field.$element.hasClass('Field-password')) {
            this.$password = field;
        } else if (field.$element.hasClass('Field-password-verify')) {
            this.$passwordVerify = field;
        }
        event.stopPropagation();
    };

    this.handleFieldCheck = function (event) {
        event.stopPropagation();
        var _this = this;
        // force error display on password-verify if the passwords don't match
        if (!this.isValid() &&
            ((this.$passwordVerify.getValue() !== '' && event.type !== constants.FIELD_CHECKED) ||
                (event.target.querySelector('input') === this.$passwordVerify.find('input')[0] && event.type !== constants.FIELD_CHECKED) ||
                (event.target === this.$passwordVerify.find('input')[0] && event.type === constants.FOCUS_OUT))) {

            //Time enough to overcome default validation but not message displaying        
            setTimeout(function () {
                _this.$passwordVerify.setErrorClass(false);
            }, 250);
        }
        else if (this.isValid()) {
            this.$passwordVerify.setErrorClass(true);
        }

        this.trigger(constants.FIELD_CHECKED, this);
    };

    //Used in form.js
    this.isValid = function (event) {
        var valid = false;
        if (this.$password.isValid() && this.$passwordVerify.isValid()) {
            if (this.$password.getValue() === this.$passwordVerify.getValue()) {
                valid = true;
            }
        }
        return valid;
    };

    this.appendData = function (data) {
        data[this.$password.getName()] = this.$password.getValue();
        data[this.$passwordVerify.getName()] = this.$passwordVerify.getValue();
        return data;
    };
};

module.exports = component(FieldGroupPasswords);
