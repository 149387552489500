// Cloudinary jQuery Plugin
var cloudinary = require('cloudinary-jquery-file-upload');
var component = require('../../../lib/js/component');
var utils = require('../../../lib/js/utils');
var ResponsiveMedia = require('./responsive-media');


var ResponsiveMediator = function () {

    var evt, $window;

    evt = {
        RESPONSIVE_RESIZE: 'RESPONSIVE_RESIZE',
        DYNAMIC_CONTENT_ADDED: 'DYNAMIC_CONTENT_ADDED'
    };

    $window = $(window);

    this.initialize = function (element, options) {
      this.options = options;

      // require responsive-image component
      this.find('.u-responsiveMedia').attach(ResponsiveMedia);

      $window.on('resize', utils.debounce(this.onResize.bind(this), 250));

      // configure cloudinary
      this.configure();
    };

    this.configure = function () {
        // cloud_name, api_key read from meta data
        var breakpoints = [];

        if(typeof utils.breakpoints !== 'object') {
            throw new Error('utils.breakpoints must be an object.');
            // return;
        }

        for(key in utils.breakpoints) {
            // if(utils.breakpoints[key] < 321) return;
            breakpoints.push(utils.breakpoints[key]);
        }

 
        $.cloudinary.config('stoppoints', breakpoints.join(','));
    };

    this.onResize = function () {
        this.trigger(evt.RESPONSIVE_RESIZE);
    };

};

module.exports = component(ResponsiveMediator);
