var component = require('../../../lib/js/component.js');
var requestInfo = require('../../../lib/js/request-info.js');
var utils = require("../../../lib/js/utils.js");
var moreToggle = require('../../../templates/shared/more-links.js');

function QmiOnlyModule() {
    // this code runs in relation to the HomeCollections module, rather than the QMIOnly module, I am not sure why so I am leaving this comment

    var MOBILE = "MOBILE"
    var TABLET = "TABLET"
    var LAPTOP = "LAPTOP"
    var DESKTOP = "DESKTOP"

    var qmisToDisplayMap = {
        MOBILE : 3,
        TABLET : 3,
        LAPTOP : 3, 
        DESKTOP : 3
    }

    var currentView = ''; 

    this.initialize = function (element) {
        var _this = this;

        _this = this;

        // ...More toggle for qmi plans
        var options = moreToggle.getOptions(this.$element, 4);
        options.lines.tablet = 3;
        moreToggle.initialize(options);

        var labels = this.$element.find(".header-wrapper").data()
        qmisToDisplayMap[MOBILE] = labels["mobileQmiDisplay"] ? parseInt(labels["mobileQmiDisplay"]) : qmisToDisplayMap[MOBILE]
        qmisToDisplayMap[TABLET] = labels["tabletQmiDisplay"] ? parseInt(labels["tabletQmiDisplay"]) : qmisToDisplayMap[TABLET]
        qmisToDisplayMap[LAPTOP] = labels["laptopQmiDisplay"] ? parseInt(labels["laptopQmiDisplay"]) : qmisToDisplayMap[LAPTOP]
        qmisToDisplayMap[DESKTOP] = labels["desktopQmiDisplay"] ? parseInt(labels["desktopQmiDisplay"]) : qmisToDisplayMap[DESKTOP]

        this.handleWindowResize();
        $(window).on("resize", utils.debounce(this.handleWindowResize.bind(this), 250, false)); 

        var expandCTA = this.$element.find(".btn-see-all")
        expandCTA.on("click", utils.debounce(this.handleExpand.bind(this), 250, false));

        this.setPlanQMIsAvailableNumber();
    };

    function getView(){
        var view = MOBILE
        if($(window).outerWidth() >= 1920){
            view = DESKTOP
        }
        else if(($(window).outerWidth() >= 1025)){
            view = LAPTOP
        }
        else if(($(window).outerWidth() >= 768)){
            view = TABLET
        }
        return view 
    }

    this.handleWindowResize = function(){
        var previousView = currentView; 
        currentView = getView(); 
        if(previousView != currentView){
            this.initQMILoadout(currentView, false)
        }
    }

    this.setPlanQMIsAvailableNumber = function() {
        var $planSections = $('.content-wrapper');
        $planSections.each(function() {
            var $this = $(this);
            var qmisInPlan = $this.find('.qmi-item').length;
            $this.find('.qmis-available-value').text(qmisInPlan);
        })
    }

    this.initQMILoadout = function(view, expand){
        
        var limit = qmisToDisplayMap[view];
        var $sections = this.$element.find(".content-wrapper");

        $sections.each(function(){
            var $this = $(this);
            var $qmiGridWrapper = $this.find(".qmi-grid-wrapper");
            var $gridQmis = $qmiGridWrapper.find(".qmi-grid-item");
            var $gridHRs = $qmiGridWrapper.find(".qmi-grid-hr");
            $gridQmis.removeClass("d-none");
            $gridHRs.removeClass("d-none");

            var $qmiGalleryWrapper = $this.find(".qmi-wrapper");
            var $galleryQmis = $qmiGalleryWrapper.find(".qmi-item");
            var $galleryHRs = $qmiGalleryWrapper.find(".qmi-hr");
            $galleryQmis.removeClass("d-none")
            $galleryQmis.addClass("d-flex")
            $galleryHRs.removeClass("d-none")
            $this.find(".footer").removeClass("d-none")

            if(expand){
                $galleryQmis.removeClass("expanded");
                $gridQmis.removeClass("expanded");
                $galleryHRs.removeClass("expanded");
                $gridHRs.removeClass("expanded");
                $this.find(".footer").removeClass("expanded");
                var $cta = $this.find(".btn-see-all");

                var $buttonTextSpan = $cta.find('span[data-name="text"]');
                var $buttonSROnlySpan = $cta.find('span[data-name="sr"]');
                var $buttonIcon = $cta.find('i');

                $buttonTextSpan.html($cta.data("seeAllText"));
                $buttonSROnlySpan.html('Expand to show all homes'); // setting html values inline to satisfy aria-live functionality for screen readers
                $buttonIcon.removeClass('icon-arrow-up').addClass('icon-arrow-down');

                $cta.removeClass("expanded");
                $cta.attr("aria-expanded", false);
                $cta.css({
                    "color" : $cta.data("seeAllTextColor"),
                    "background-color" : $cta.data("seeAllBackground"),
                    "border" : "1px solid "+ $cta.data("seeAllBorder")
                })

            }           
            
            setExpandedQmis($galleryQmis, $galleryHRs, limit, $this);
            setExpandedQmis($gridQmis, $gridHRs, limit, $this);
        });

    }

    setExpandedQmis = function($qmis, $hrs, limit, $qmiSection) {
        if($qmis.length > 0 && $qmis.length > limit){
            $qmis.each(function(index, elem){
                if(index+1 > limit && !$(elem).hasClass("expanded")){
                    $(elem).addClass("d-none")
                    $(elem).removeClass("d-flex")
                }
            })
            $hrs.each(function(index, elem){
                if(index+1 > limit && !$(elem).hasClass("expanded")){
                    $(elem).addClass("d-none")
                }
            })
        }
        else{
            $qmiSection.find(".footer").addClass("d-none")
            $($hrs[$hrs.length-1]).addClass("d-none")
        }
    }

    this.handleExpand = function(e){

        e.preventDefault();

        var $clickedCTA = $(e.currentTarget)
        var previouslyExpanded = $clickedCTA.hasClass("expanded")
        this.initQMILoadout(getView(), true)

        if(!previouslyExpanded){
            var $targetWrapper = $clickedCTA.parent().parent();
            var $targetQMIs = $targetWrapper.find(".qmi-item");
            var $targetGridQMIs = $targetWrapper.find(".qmi-grid-item");
            var $targetHRs = $targetWrapper.find(".qmi-hr");
            var $targetGridHRs = $targetWrapper.find(".qmi-grid-hr");

            $targetQMIs.removeClass("d-none");
            $targetGridQMIs.removeClass("d-none");
            $targetQMIs.addClass("d-flex");
            $targetHRs.removeClass("d-none");
            $targetGridHRs.removeClass("d-none");

            $targetQMIs.addClass("expanded");
            $targetGridQMIs.addClass("expanded");
            $targetHRs.addClass("expanded");
            $targetGridHRs.addClass("expanded");
            $clickedCTA.addClass("expanded");
            $clickedCTA.attr("aria-expanded", true);

            var $buttonTextSpan = $clickedCTA.find('span[data-name="text"]');
            var $buttonSROnlySpan = $clickedCTA.find('span[data-name="sr"]');
            var $buttonIcon = $clickedCTA.find('i');

            $buttonTextSpan.html($clickedCTA.data("expandedText"));
            $buttonSROnlySpan.html('Collapse list of homes'); // setting html values inline to satisfy aria-live functionality for screen readers
            $buttonIcon.removeClass('icon-arrow-down').addClass('icon-arrow-up');

            $clickedCTA.css({
                "color" : $clickedCTA.data("expandedTextColor"),
                "background-color" : $clickedCTA.data("expandedBackground"),
                "border" : "1px solid "+ $clickedCTA.data("expandedBorder")
            })
        }

    }

}

module.exports = component(QmiOnlyModule);