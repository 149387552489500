var component = require('../../../lib/js/component.js');
var requestInfo = require('../../../lib/js/request-info.js');
var tooltip = require('../tooltip/tooltip.js');

var LeadFormSlide = function() {

    var viewModel = function() {
        var defaultFirstName = "";
        var defaultLastName = "";
        var defaultEmail = "";
        var defaultZipcode = "";
        var defaultOutsideUS = false;
        var defaultPhone = "";

        // set default values if user info is populated
        if (Pulte && Pulte.user) {
            defaultFirstName = Pulte.user.FirstName;
            defaultLastName = Pulte.user.LastName;
            defaultEmail = Pulte.user.Email;
            defaultZipcode = Pulte.user.ZipCode;
            defaultOutsideUS = Pulte.user.OutsideUS
            defaultPhone = Pulte.user.Phone;
        }

        this.firstName = ko.observable(defaultFirstName);
        this.lastName = ko.observable(defaultLastName);
        this.email = ko.observable(defaultEmail);
        this.zipcode = ko.observable(defaultZipcode);
        this.outsideus = ko.observable(defaultOutsideUS);
        this.phone = ko.observable(defaultPhone);
    };

    this.initialize = function (element, options) {
        requestInfo.initRequestInfoModals();

        // prevent owl carousel click and drag event when user is interacting with form inputs
        this.$element.find('.lead-form-slide__input').on('touchstart mousedown', function(e) {
            e.stopPropagation();
        });
        
        var koViewModel = new viewModel();

        ko.applyBindings(koViewModel, element);

        // when lead form slide clone is loaded, attach the same knockout bindings so that the 2 slides remain identical from the user perspective
        this.$element.on('lead-form-slide-clone-loaded', function(e, leadFormSlideClone) {
            $leadFormSlideClone = $(leadFormSlideClone)

            $leadFormSlideClone.find('.TooltipBtn').attach(tooltip);

            ko.applyBindings(koViewModel, leadFormSlideClone);

            // prevent owl carousel click and drag event when user is interacting with cloned form inputs
            $leadFormSlideClone.find('.lead-form-slide__input').on('touchstart mousedown', function(e) {
                e.stopPropagation();
            });
        });
    };
};

module.exports = component(LeadFormSlide);
