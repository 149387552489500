startYoutubeAPI = function () {
    var youtubescript = $('#youtube-script');

    if (!youtubescript.length) {
        $('head').append('<script id="youtube-script" src="https://www.youtube.com/iframe_api"></script>');

        window.youtubeVideosStack = [];
        window.onYouTubeIframeAPIReady = function () {
            youtubeVideosStack.forEach(function (item) {
                item.context[item.player] = new YT.Player(item.id, item.options);
            });
        };
    }
}