var component = require('../../../lib/js/component.js');
var analyticsForm = require('../forms/analyticsForm');
var AdobeLaunch = require('../../../lib/js/adobelaunch.js');
var utils = require('../../../lib/js/utils.js');
var constants = require('../forms/constants.js');

function GlobalInlineLeadForm() {
    this.initialize = function (element, options) {
        utils.handleVisible(this.$element, function () {
            AdobeLaunch.pushFormViewedEvent(AdobeLaunch.FORM_GLOBAL_ID, AdobeLaunch.FORM_INLINE_TYPE, AdobeLaunch.FORM_REQUEST_INFO_NAME);
        });

        this.$form = this.$element.find('.GlobalLeadForm');
        this.$isRealtorLabel = this.$element.find('.GlobalLeadForm__is-realtor-label');
        this.$isRealtorCheckbox = this.$element.find('input[name=IsRealtor]');
        this.$isRealtorCheckbox.prop("disabled", true);

        this.$realtorInfoContainer = this.$element.find('.GlobalLeadForm__realtor-info-container');
        this.$realtorNameInput = this.$realtorInfoContainer.find('input[name=BrokerOffice]');
        this.$realtorNumberInput = this.$realtorInfoContainer.find('input[name=BrokerId]');

        this.$statesDropdown = this.$element.find('select[name=state]');
        this.$statesDropdown.change(this.onStateDropdownChange.bind(this));
        
        this.$regionsDropdown = this.$element.find('select[name=region]');
        this.$regionsDropdown.change(this.onRegionDropdownChange.bind(this));

        this.$communitiesDropdown = this.$element.find('select[name=CommunityIds]');
        this.$communitiesDropdown.change(this.onCommunitiesDropdownChange.bind(this));

        this.$state = this.$element.find('.Field-location-state').val();
        this.$region = this.$element.find('.Field-location-region').val();
        this.$edhRegionIdInput = this.$element.find('.Field-edhregionid');       

        if(this.$state){
            this.preselectState();
        }
    }

    // Functions
    this.preselectState = function(){
        this.$statesDropdown.val(this.$state);

        var state = this.$statesDropdown.val();
        var brand = window.Pulte.Brand ? window.Pulte.Brand.replace("-"," ") : 'Pulte';

        $.ajax({
            url: '/API/Region/Search',
            context: this,
            data: {
              State: state,
              Brand: brand
            },
            dataType: "json",
            success: this.onStatePreselected,
            error: this.onError,
            complete: this.resetCommunitiesDropdown
        });
    }

    this.preselectRegion = function () {
        this.$regionsDropdown.val(this.$region);
        this.onRegionDropdownChange();        
        this.$form.trigger(constants.REGION_SELECTED);
    }

    this.resetCommunitiesDropdown = function() {
        var children = this.$communitiesDropdown.children();
        if (children && children.length) {
            var $defaultOption = $(children).first().clone();
    
            this.$communitiesDropdown.empty();
            $defaultOption.appendTo(this.$communitiesDropdown);   
        }
    }

    // Event handlers
    this.onCommunitiesDropdownChange = function () {
        var selectedCommunities = this.$communitiesDropdown.val();
        var communityIsSelected = selectedCommunities && selectedCommunities.length;

        this.toggleRealtorControls(communityIsSelected);
    }

    this.onError = function(data, textStatus, event) {
        analyticsForm.RewriteObjectForm(data, event);
        AdobeLaunch.pushFormSubmitFailedEvent(AdobeLaunch.FORM_GLOBAL_ID, this.formType, AdobeLaunch.FORM_REQUEST_INFO_NAME);
    }

    this.onGetRegionInfo = function(result, status, xhr){
        if (result && result.EDHRegionId) {
            this.$edhRegionIdInput.val(result.EDHRegionId);
        }
    }

    this.onRegionDropdownChange = function(event) {
        var state = this.$statesDropdown.val();
        var region = this.$regionsDropdown.val();

        $.ajax({
            url: '/API/Region/GetRegionInfo',
            context: this,
            data: {
                state: state,
                region: region
            },
            success: this.onGetRegionInfo
        });

        this.toggleRealtorControls(false);
    }

    this.onStateDropdownChange = function(event) {
        this.toggleRealtorControls(false);
    }

    this.onStatePreselected = function(data){
        var _this = this;

        var children = this.$regionsDropdown.children();
        if (children && children.length) {
            var $defaultOption = $(children).first().clone();

            this.$regionsDropdown.empty();
            $defaultOption.appendTo(this.$regionsDropdown);
        }

        if (data && data.length) {
          data.forEach(function(region) {
            var regionOption = document.createElement('option');

            regionOption.value = region.Name;
            regionOption.text = region.Name;

            _this.$regionsDropdown.append(regionOption);
          });

          this.$regionsDropdown.prop('disabled', false);

          if (this.$region) {
            this.preselectRegion();    
          }

          return;
        }

        return onError(null, 'Unknown Error');
    }

    this.toggleRealtorControls = function(enabled){
        if (enabled) {
            this.$isRealtorCheckbox.prop("disabled", false);
            this.$isRealtorCheckbox.addClass("enabled");
            if(Pulte.user && Pulte.user['IsRealtor'] && Pulte.user['IsRealtor'].toLowerCase() === 'true') {
                this.$isRealtorCheckbox.prop("checked", true);
                this.$isRealtorCheckbox.trigger('change');
            }
        }
        else {
            this.$isRealtorCheckbox.prop("checked", false);
            this.$isRealtorCheckbox.prop("disabled", true);
            this.$isRealtorCheckbox.removeClass("enabled");

            this.$realtorNameInput.prop('disabled', true);
            this.$realtorNumberInput.prop('disabled', true);

            this.$isRealtorLabel.removeClass('d-none');
            this.$realtorInfoContainer.addClass('d-none');
        }
    }
    
}

module.exports = component(GlobalInlineLeadForm);
