/**
 * Export events used for communication in slideshow components.
 * @type {Object}
 */
module.exports = {
  OPEN: 'SLIDESHOW_MODAL_OPEN',
  OPENED: 'SLIDESHOW_MODAL_OPENED',
  CLOSE: 'SLIDESHOW_MODAL_CLOSE',
  CLOSED: 'SLIDESHOW_MODAL_CLOSED',
  UPDATE_TITLE: 'SLIDESHOW_UPDATE_TITLE'
};
