var component = require('../../../lib/js/component.js');

function HeroPFSOverlay() {
    this.initialize = function (element, options) {
        this.$mobileCTA = this.$element.find('.HeroPFSOverlay__MobileCTA');

        // attach the click handler
        this.$mobileCTA.on('click touch', this.handleClick.bind(this));
    }

    this.handleClick = function (e) {
        // try to get the video iframe
        var iframe = document.getElementById("HeroPFS_MobileVideo");

        if (!iframe) { // get the iframe from the wrapper div. during whitebox testing, the iframe id was different from what was in the code and iframe was null
            var wrapper = document.getElementById("HeroPFSMobileVideo__VideoWrapper");
            iframe = wrapper.getElementsByTagName('iframe')[0];
        }
        if (iframe) {
            iframe.contentWindow.postMessage(JSON.stringify({
                "event": "command",
                "func": "playVideo",
                "args": [],
                "id": iframe.id
                }), "*"
            );
        }
    }
}

module.exports = component(HeroPFSOverlay);