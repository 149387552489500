
module.exports = {
	populateDetailPage: function( list ){
        var resultMarkup = '';
        
        $.each( list, function( index, value ){
            if (value.Name){
                resultMarkup += '<div class="col-sm-4 DesignBoard-grid-item">' +
                    '<img class="u-responsiveMedia cld-responsive cld-hidpi" data-dam="//res.cloudinary.com/dv0jqjrc3/image/fetch/"'+
                    'data-name="http://res.cloudinary.com/dxyrbhrwp/image/upload/design-board1_y646by" data-size="{&quot;0&quot;:&quot;ar_1.5&quot;}" data-transformations="c_fill,dpr_auto,f_auto,w_auto"'+
                    'data-alt="Alt text" data-url="http://pulte.com" data-analytics="add-items-design-board|design-board-item|'+value.Name.toLowerCase().replace(' ', '-')+'" alt="Alt text"' +
                    'src="'+value.Url+'">'+
                    '<h4>'+value.Name+'</h4>'+
                    '</div>';
            }
            
        });

        return resultMarkup;
        
    },

    populateEditPage: function( list ){
        var resultMarkup = '';
        
        $.each( list, function( index, value ){
            if (value.Name){
                resultMarkup += '<div class="col-sm-4 col-md-3 DesignBoard-grid-item DesignBoard-grid-item-edit" data-id="'+value.Id+'" data-order="'+value.Order+'">'+
                    '<span class="DesignBoard-grid-item-icon"><i class="icon-checkmark"></i></span>'+
                    '<img class="u-responsiveMedia cld-responsive cld-hidpi" data-dam="//res.cloudinary.com/dv0jqjrc3/image/fetch/"'+
                    'data-name="http://res.cloudinary.com/dxyrbhrwp/image/upload/design-board1_y646by" data-size="{&quot;0&quot;:&quot;ar_1.5&quot;}" data-transformations="c_fill,dpr_auto,f_auto,w_auto"'+
                    'data-alt="Alt text" data-url="http://pulte.com" data-analytics="add-items-design-board|design-board-item|'+value.Name.toLowerCase().replace(' ', '-')+'" alt="Alt text"' +
                    'src="'+value.Url+'">'+
                    '<h4>'+value.Name+'</h4>'+
                    '</div>';
            }
        });

        return resultMarkup;
    }

}
