var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');

function AreaFeatures() {

    this.initialize = function(element, options) {
        // instance variables
        this.main = this.$element;
        this.foundVisible = false;

        // elements and their event bindings
        var $secondaryTab = this.main.find('.secondary-tab');
        var $mapFeaturesTab = this.main.find('.Map-featuresTab');
        $secondaryTab.on('click touch', this.filterContent.bind(this));
        $mapFeaturesTab.on('click touch', this.triggerMapUpdate.bind(this));

        this.emptyTabContent($(".AreaFeatures .main-row .content-container"), ".AreaFeatures"); //Non-DelWebb

        // first option is default, store its data-option value
        this.selectedOption = $secondaryTab.first().data().option;
        this.selectedSecondaryIndex = $secondaryTab.first().data().index;
        $.proxy(this.setFirstVisibleActive($secondaryTab),this);
        
        // Map elements and bindings
        this.poiMapInitialized = false;
        this.$poiMapTitle = this.find('.Map-AreaDescription h3');
        this.$poiMapCopy = this.find('.Map-AreaDescription .copy');
        this.$poiMapList = this.find('.Map-AreaDescription .list');
        this.$poiMap = this.find('.Map');
        
        // set visible content accordingly
        this.setActiveContent();

        // handle mobile scrollable options
        this.handleResize();
        $(window).resize(utils.debounce(this.handleResize.bind(this), 250, true));
        $('.scrollable').on('touchmove scroll', function() {
            $(this).removeClass('with-hint');
        });

        // bugfix for avoiding blurry imagery in the embedded Inspiration component
        $(window).trigger('resize');
    }

    this.emptyTabContent = function (elementsArray, parentClass) {
        elementsArray.each(function (i, val) {
            var target = $(val).attr('data-option');

            if ($(parentClass + ' .content-container[data-option="' + target + '"] .content-container-child').length > 0) {
                var child = $(parentClass + ' .content-container[data-option="' + target + '"] .content-container-child').children().length;

                if (child === 0) {
                    $(parentClass + ' .secondary-tab[data-option="' + target + '"]').addClass('hidden');
                }
            }

        })
    }

    this.setFirstVisibleActive = function (elementsArray) {
        elementsArray.each($.proxy(function (i, val) {

            if (!$(val).hasClass("hidden") && !this.foundVisible)
            {
                this.selectedOption = $(val).data().option;
                this.selectedSecondaryIndex = $(val).data().index;
                this.foundVisible = true;
                //break;
            }

        },this))
    }

    this.filterContent = function (e) {
        var $el = $(e.currentTarget);
        this.selectedOption = $el.data().option;
        this.selectedSecondaryIndex = $el.data().index;
        
        this.setActiveContent();
    }

    this.setActiveContent = function() {
        // toggle active secondary tab
        this.main.find('.secondary-tab.is-active').removeClass('is-active');
        this.main.find('.secondary-tab[data-option="' + this.selectedOption + '"]').addClass('is-active');
        // filter view
        this.main.find('.content-container.is-active').removeClass('is-active');
        this.main.find('.content-container[data-index="' + this.selectedSecondaryIndex + '"]').addClass('is-active');
    }

    this.triggerMapUpdate = function(e) {
        // get selected title and feature from secondary tab
        var $el = $(e.currentTarget);
        var $selectedFeature = $el.data().feature;
        var poiMapTitle = $el.data().title;
        var poiMapCopy = $el.data().copy;
        var poiMapList = $el.data().list;
        var poiPinColor = $el.data().color;

        // pass data into embedded map features component
        featureTabData = {
            curFeatureColor: poiPinColor,
            curSelectedFeature: $selectedFeature,
            target: e.target
        };

        // container of the map to update

        if (!this.poiMapInitialized) {
            $('body').trigger('InitTabbedMaps');
            this.poiMapInitialized = true;
        }
        this.$poiMap.trigger('featureTabSelected', featureTabData);
        this.$poiMapTitle.text(poiMapTitle);
        this.$poiMapCopy.text(poiMapCopy);

        if (this.$poiMapList != null && this.$poiMapList.length > 0) {
            this.$poiMapList.html(poiMapList);
        }
    }

    this.handleResize = function() {
        var windowWidth = $(window).outerWidth();
        if (windowWidth < 768) {
            $('.scrollable .scrollable-inner').each(function() {
                var spaceBetween = $(this).attr('data-space-between');
                var responsiveWidth = 0;
                var childCount = $(this).children('.scrollable-item').length;
                $(this).children('.scrollable-item').each(function(i) {
                    responsiveWidth += $(this).outerWidth() + parseInt(spaceBetween);
                    if (i === childCount - 1) {
                        responsiveWidth -= parseInt(spaceBetween);
                    }
                });
                responsiveWidth += 30; // account for the margin-left and margin-right
                $(this).css('width', responsiveWidth);

                if (responsiveWidth > windowWidth) {
                    $(this).closest('.scrollable').addClass('with-hint');
                } else {
                    $(this).closest('.scrollable').removeClass('with-hint');
                }
            });
        } else {
            $('.scrollable .scrollable-inner').each(function() {
                $(this).css('width', 'auto');
            });
        }
    }

}

module.exports = component(AreaFeatures);