var component = require('../../../lib/js/component.js');

function PartnerTileContainer() {

    /**
     * Init for the Partner Tile Container module
     * @param  {Object} element HTML element
     */
    this.initialize = function (element) {
        
        var $element = $(element);
        var lightboxes = $element.find('.PartnerTileContainer--lightboxModal');

        if (lightboxes != null && lightboxes.length > 0) {
            lightboxes.each(function() {
                var image = $(this).find('img');

                if (image != null && image.length > 0) {
                    $(this).on('show.bs.modal', function(e) {
                        // Call to load image lazily is delayed to allow modal to set height and width before image is loaded
                        setTimeout(function () {
                            if (!image.hasClass('is-loaded')) {
                                image.trigger("RESPONSIVE_LAZYLOAD");
                                image.cloudinary_update();
                                $.cloudinary.responsive();
                            }
                        }, 500);
                    });
                }
            });
        }
    }
}

module.exports = component(PartnerTileContainer);