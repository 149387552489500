var component = require('../../../lib/js/component.js');
var Form = require('../forms/form.js');
var constants = require('../forms/constants.js');

var Login = function () {

  var self = this;

  this.initialize = function (element, options) {
    self.$alert = this.find('.modal-alert');
    self.$passwordReset = this.find('.PasswordReset');
    self.$login = this.find('.Login');
    self.$passwordResetPrompt = this.find('.PasswordResetPrompt');
    self.$passwordResetConfirm = this.find('.PasswordResetConfirm');

    this.options = options || {};

    this.find('#LoginForm')
        .attach(Form)
        .on(constants.FORM_AJAX_SUBMIT, this.handleFormSubmissionLogin.bind(this));

    this.on('shown.bs.modal', this.resetLoginModal);

    this.find('#PasswordResetForm')
        .attach(Form)
        .on(constants.FORM_AJAX_SUBMIT, this.handleFormSubmissionForgot.bind(this));

    this.find('.PasswordReset-link').on("click", this.handleForgotPassword);
  };

  this.showError = function (msg) {
    self.$alert
      .text(msg)
      .slideDown(400);
  };

  this.hideError = function () {
    self.$alert.hide();
  };

  this.resetLoginModal = function () {
    self.hideError();
    self.$passwordReset.hide();
    self.$login.show();
  };

  this.handleFormSubmissionLogin = function (event, form) {
      if (event.target.id === 'LoginForm') {
        self.hideError();
        $.ajax({
            method: form.options.method,
            url: form.options.action,
            data: form.data,
            dataType: "json"
        }).done(function (data) {
            if (data) {
              if (data.Success && data.UserId) {
                AdobeLaunch.pushUserEvent(AdobeLaunch.USER_SIGNED_IN_EVENT_NAME, data.UserId);
              }
              
              location.href = data.RedirectUrl;
            }
        }).fail(function (jqXHR, textStatus) {
          if (jqXHR.responseJSON) {
            self.showError(jqXHR.responseJSON.error || textStatus);
          } else {
            self.showError(textStatus);
          }
        });
      }
  };

  this.handleFormSubmissionForgot = function (event, form) {
      if (event.target.id === 'PasswordResetForm') {
        self.hideError();
        $.ajax({
            method: form.options.method,
            url: form.options.action,
            data: form.data,
            dataType: "json"
        }).done(function (data) {
            self.$passwordResetPrompt.hide();
            self.$passwordResetConfirm.show();
        }).fail(function (jqXHR, textStatus) {
          if (jqXHR.responseJSON) {
            self.showError(jqXHR.responseJSON.error || textStatus);
          } else {
            self.showError(textStatus);
          }
        });
      }
  };

  this.handleForgotPassword = function() {
    self.$passwordResetConfirm.hide();
    self.$passwordResetPrompt.show();
    self.$passwordReset.show();
    self.$login.hide();
    self.hideError();
  }

};

module.exports = component(Login);
