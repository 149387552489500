var component = require('../../../lib/js/component.js');
var constants = require('../forms/constants.js');
var Form = require('../forms/form.js');
var analyticsForm = require('../forms/analyticsForm');
var AdobeLaunch = require('../../../lib/js/adobelaunch.js');
var FacebookPixel = require('../../../lib/js/facebookpixel.js');
var utils = require('../../../lib/js/utils.js');

var ServiceRequestWizard = function () {
    this.initialize = function (element, options) {
        this.visibleClass = 'ServiceRequestWizard--visible';
        this.formStep = '.ServiceRequestWizard__formStep';
        this.visibleSteps = '.ServiceRequestWizard__formStep.' + this.visibleClass,
        this.stepTitle = '.ServiceRequestWizard__titleTicket h3';
        this.summaryTicket = '.ServiceRequestWizard__summaryTicket';
        this.$formSteps = this.$element.find(this.formStep);
        this.ticketLabel = this.$element.find(this.stepTitle).first().data('ticket') + ' ';
        this.$visibleElements = this.$element.find(this.visibleSteps);
        this.visibleElementsCount = this.$visibleElements.length;

        this.$regionDropdown = this.$element.find('select[name="Region"]');
        this.$regionPhone = this.$element.find('.ServiceRequestWizard__localServicePhoneNumber');
        this.$addRepairRequest = this.$element.find('.ServiceRequestWizard__addTicket');
        this.$addRepairRequestButton = this.$addRepairRequest.find('a');
        this.$closeRequest = this.$element.find('.ServiceRequestWizard__closeTicket');
        this.$errorMessage = this.$element.find('.MsgModal--error').first();
        this.options = options || {};
        this.step1Data = {};
        this.step2Data = {};
        this.step3Data = {};

        this.$summary = this.$element.find('.ServiceRequestWizard__summary');
        this.$summaryTicket = this.$element.find(this.summaryTicket);
        this.$summaryTicketCopy = this.$summaryTicket.clone();
        this.summaryImageHtml = '<img class="ServiceRequestWizard__summaryImage"></img>';

        var $titleRequest = this.$visibleElements.find(this.stepTitle),
            $inputCloudinary = this.$element.find('.cloudinary-fileupload'),
            $ticketSelects = this.$formSteps.find('select'),
            $stateDropdown = this.$element.find('select[name="State"]'),
            $removeImage = this.$element.find('.ServiceRequestWizard__removeImage');

        this.$step1Form = this.$element.find("#ServiceRequestWizardStep1Form");
        $step2Form = this.$element.find("#ServiceRequestWizardStep2Form");
        $step3Form = this.$element.find("#ServiceRequestWizardStep3Form");
        $step2PrevButton = $step2Form.find(".step-2-prev-btn");
        $step3PrevButton = $step3Form.find(".step-3-prev-btn");

        this.emailData = $step3Form.data();

        $titleRequest.text(this.ticketLabel + this.visibleElementsCount);
        this.$visibleElements.css('order', this.visibleElementsCount);

        this.$addRepairRequestButton.on('click', this.onAddRepair.bind(this));
        this.$closeRequest.on('click', this.onRemoveRepair.bind(this));
        $removeImage.on('click', this.onRemoveImage.bind(this));
        $ticketSelects.on('change', this.onSelectTicketsChange.bind(this));
        $stateDropdown.on('change', this.populateRegions.bind(this));
        this.$regionDropdown.on('change', this.updateServiceRepInfo.bind(this));
        $step2PrevButton.on('click', this.step2Prev.bind(this));
        $step3PrevButton.on('click', this.step3Prev.bind(this));

        this.$step1Form
            .attach(Form)
            .on(constants.FORM_AJAX_SUBMIT, this.handleFormSubmission.bind(this))
            .on(constants.FORM_CHANGED, this.handleFormChanged.bind(this));
        $step2Form
            .attach(Form)
            .on(constants.FORM_AJAX_SUBMIT, this.handleFormSubmission.bind(this));
        $step3Form
            .attach(Form)
            .on(constants.FORM_AJAX_SUBMIT, this.handleFormSubmission.bind(this));

        $inputCloudinary.on('cloudinarydone', this.onAddImage.bind(this)).cloudinary_fileupload({
            start: function (e) {
                $('body').css('cursor', 'wait');
            }
        });

        AdobeLaunch.pushFormViewedEvent(AdobeLaunch.FORM_GLOBAL_ID, AdobeLaunch.FORM_INLINE_TYPE, AdobeLaunch.FORM_SERVICE_REQUEST_NAME);
    };

    this.populateRegions = function (e) {
        var _this = this,
            onRegionsFetchSuccess,
            $statesList = $(e.currentTarget),
            app = window.Pulte || {};

        onRegionsFetchSuccess = function (data) {
            var defaultOption = _this.$regionDropdown.children().first()[0].outerHTML;
            _this.$regionDropdown.empty().append(defaultOption);
            if (Array.isArray(data)) {
                data.forEach(function (region) {
                    _this.$regionDropdown.append('<option value="' + region.Name + '" data-phone ="' + region.TelephoneNumber + '" data-email="' + region.ServiceEmail + '">' + region.Name + '</option>');
                });
                _this.$regionDropdown.prop('disabled', false);
                _this.$regionDropdown.change();
                return;
            }
            return onError(null, 'Unknown Error');
        };
        this.$regionDropdown.prop('disabled', true);

        if ($statesList.val() !== '') {
            $.ajax({
                url: '/API/Region/GetServiceRegions',
                data: {
                    State: $statesList.val(),
                    Brand: app.Brand.replace("-"," ")
                },
                dataType: 'json'
            })
                .fail(onError)
                .done(onRegionsFetchSuccess);
        }
    };

    this.updateServiceRepInfo = function (e) {
        var $currentTarget = $(e.currentTarget);
        var regionPhoneNumber = $currentTarget.find('option:selected').data('phone');
        this.$regionPhone.text(regionPhoneNumber);

        var currentServiceEmail = $currentTarget.find('option:selected').data('email');
        if (currentServiceEmail) {
            this.emailData.to = currentServiceEmail;
        }
    };

    this.onSelectTicketsChange = function (e) {
        var addRequestButtonDisable = true;
        this.$visibleElements.find('select').each(function () {
            var $currentSelect = $(this);
            if ($currentSelect.val().length) {
                addRequestButtonDisable = false;
            } else {
                addRequestButtonDisable = true;
                return false;
            }
        });

        if (addRequestButtonDisable) {
            this.$addRepairRequestButton.addClass('disabled');
        } else {
            this.$addRepairRequestButton.removeClass('disabled');
        }
    };

    this.onAddImage = function (e, data) {
        var addImageIcon = e.currentTarget;
        var $imageElement = $(addImageIcon.parentElement);
        var $imageIcon = $imageElement.find('.ServiceRequestWizard__customFileUpload');
        var originalURL = data.result.url.split('upload');
        var imageURL = originalURL[0] + 'upload/w_140,c_fill' + originalURL[1];

        $imageElement.attr('data-url', data.result.url);
        $imageElement.css('background-image', 'url(' + imageURL + ')');
        $imageElement.css('background-size', 'cover');
        $imageElement.css('background-position', 'center');
        $imageIcon.addClass('hasImage');
        $('body').css('cursor', '');
    };

    this.onRemoveImage = function (e) {
        var $imageElement = $(e.currentTarget.parentElement);
        var $imageIcon = $imageElement.find('.ServiceRequestWizard__customFileUpload');

        $imageElement.removeAttr('style');
        $imageIcon.removeClass('hasImage');
    };

    this.handleFormSubmission = function (event, form) {
        this.$errorMessage.hide();

        if (form.options.action == "step1Next") {
            this.step1Data = form.data;
            this.changeToPage(2);
        } else if (form.options.action == "step2Next") {
            this.step2Data = this.getTicketData(form.data);
            this.changeToPage(3);
        } else {
            this.step3Data = form.data;

            // consolidate data
            var data = $.extend(this.emailData, this.step1Data, this.step2Data, this.step3Data);

            $.ajax({
                method: form.options.method,
                url: form.options.action,
                data: data,
                dataType: 'json',
                context: this,
                success: function (response) {
                    var errorMessage = [];
                    if (response.success) {
                        this.addSummaryTickets(data);
                        this.changeToPage(4);

                        //rewrite error function
                        AdobeLaunch.pushFormSubmitSucceededEvent(AdobeLaunch.FORM_GLOBAL_ID, AdobeLaunch.FORM_INLINE_TYPE, AdobeLaunch.FORM_SERVICE_REQUEST_NAME);
                        FacebookPixel.pushFormSubmissionEvent(FacebookPixel.FORM_INLINE_TYPE, FacebookPixel.FORM_SERVICE_REQUEST_NAME);
                        analyticsForm.RewriteObjectForm(response, event);
                    } else {
                        for (var field in response.fieldErrors) {
                            errorMessage.push(response.fieldErrors[field]);
                        }
                        this.onError(response, errorMessage.join('. '), event);
                    }
                }
            })
                .fail(this.onError.bind(this));
        }

        event.preventDefault();

        return false;
    };

    this.getTicketData = function (data) {
        var tickets = [],
            images;

        this.$element.find('.ServiceRequestWizard__ticket').children(this.visibleSteps).each(function () {
            var $currentTicket = $(this);
            images = [];

            $currentTicket.find('.ServiceRequestWizard__image').each(function () {
                var urlCurrentImage = $(this).data('url');

                if (urlCurrentImage) {
                    images.push(urlCurrentImage);
                }
            });

            var ticket = {
                'NeedOfRepair': $currentTicket.find('select.needToRepair').val(),
                'RepairLocation': $currentTicket.find('select.located').val(),
                'ProblemDescription': $currentTicket.find('textarea').val(),
                'Images': images
            }

            tickets.push(ticket);
        });

        data.tickets = tickets;

        return data;
    };

    this.addSummaryTickets = function (data) {
        var _this = this;

        data.tickets.forEach(function(ticket, index) {
            // add ticket if non-zero index
            if (index !== 0) {
                var $newSummaryTicket = _this.$summaryTicketCopy.clone();
                _this.$summary.append($newSummaryTicket);
            }

            // populate the ticket
            _this.populateTicketInfo(ticket, _this.step3Data, index);
        });
    };

    this.populateTicketInfo = function (ticketData, contactData, ticketIndex) {
        var _this = this;
        var ticketId = ticketIndex + 1;
        var $currentTicket = this.$summary.find(this.summaryTicket + ':last');
        var $currentImageList = $currentTicket.find('.ServiceRequestWizard__summaryImageList');

        //left column
        $currentTicket.find('.ServiceRequestWizard__ticketId').text(this.ticketLabel + ticketId);
        $currentTicket.find('.ServiceRequestWizard__summaryOption').text(ticketData.NeedOfRepair);
        $currentTicket.find('.ServiceRequestWizard__summaryLocation').text(ticketData.RepairLocation);
        $currentTicket.find('.ServiceRequestWizard__summaryDescriptionText').text(ticketData.ProblemDescription);

        //images
        ticketData.Images.forEach(function (image, index) {
            $currentImageList.append(_this.summaryImageHtml);

            var $currentImage = $currentImageList.find('.ServiceRequestWizard__summaryImage:last');
            var originalUrl = image.split('upload');
            var imageUrl = originalUrl[0] + 'upload/w_130,ar_1.5,c_fill' + originalUrl[1];
            $currentImage.attr('src', imageUrl);
        });

        //contact info
        $currentTicket.find('.ServiceRequestWizard__summaryName').text(contactData.FirstName + ' ' + contactData.LastName);
        $currentTicket.find('.ServiceRequestWizard__summaryEmail').text(contactData.Email);
        $currentTicket.find('.ServiceRequestWizard__summaryPhone').text(utils.formatPhoneNumber(contactData.Phone));
        $currentTicket.find('.ServiceRequestWizard__summaryComm').text(contactData.CommunityName);
        $currentTicket.find('.ServiceRequestWizard__summaryAddress1').text(contactData.Address1);
        $currentTicket.find('.ServiceRequestWizard__summaryAddress2').text(contactData.Address2);
        $currentTicket.find('.ServiceRequestWizard__summaryZip').text(contactData.ZipCode);
    };

    this.onError = function (data, textStatus, event) {
        this.$errorMessage.html(data && data.responseJSON ? data.responseJSON.error : textStatus);
        this.$errorMessage.show();

        $('html, body').animate({
            scrollTop: this.$errorMessage.offset().top
        }, 1000);

        AdobeLaunch.pushFormSubmitFailedEvent(AdobeLaunch.FORM_GLOBAL_ID, AdobeLaunch.FORM_INLINE_TYPE, AdobeLaunch.FORM_SERVICE_REQUEST_NAME);

        //rewrite error function
        analyticsForm.RewriteObjectForm(data, event);
    };

    this.onAddRepair = function () {
        var $nextStep = this.$formSteps.not('.' + this.visibleClass).first();

        $nextStep.addClass(this.visibleClass);
        $nextStep.find('select').prop('disabled', false);
        $nextStep.find('select.needToRepair').change();

        this.changeItemOrder($nextStep);
        this.goToTheCurrentTicket($nextStep);
        this.onSelectTicketsChange();
    };

    this.onRemoveRepair = function (e) {
        var _this = this;
        var ticketIndex,
            $currentStep = $(e.target).closest(this.formStep),
            $previousStep = $currentStep.prev();

        this.clearFields($currentStep);

        $currentStep.removeClass(this.visibleClass);

        this.changeItemOrder($currentStep);

        this.$visibleElements.each(function (index) {
            var $currentElement = $(this);
            ticketIndex = index + 1;
            $currentElement.css('order', ticketIndex);
            $titleRequest = $currentElement.find(_this.stepTitle);
            $titleRequest.text(_this.ticketLabel + ticketIndex);
        });

        if ($previousStep.length == 0) {
            $previousStep = this.$element.find(this.visibleSteps).first();
        }

        $previousStep.find('select.needToRepair').change();

        this.goToTheCurrentTicket($previousStep);
        this.onSelectTicketsChange();
    };

    this.changeItemOrder = function ($item) {
        var $titleRequest = $item.find(this.stepTitle);

        this.$visibleElements = this.$element.find(this.visibleSteps);
        this.visibleElementsCount = this.$visibleElements.length;

        $item.css('order', this.visibleElementsCount);
        $titleRequest.text(this.ticketLabel + this.visibleElementsCount);

        // Hide closeRequest button if only one ticket showing
        if (this.visibleElementsCount === 1) {
            this.$closeRequest.removeClass(this.visibleClass);
        } else {
            this.$closeRequest.addClass(this.visibleClass);
        }
    };

    this.goToTheCurrentTicket = function ($componentScrollTo) {
        this.$visibleElements = this.$element.find(this.visibleSteps);
        this.visibleElementsCount = this.$visibleElements.length;

        $('html, body').animate({
            scrollTop: $componentScrollTo.offset().top
        }, 1000);

        if (this.visibleElementsCount === this.$formSteps.length) {
            this.$addRepairRequest.hide();
        } else {
            this.$addRepairRequest.show();
        }
    };

    this.clearFields = function ($ticket) {
        $ticket.find('select').val('').prop('disabled', true);
        $ticket.find('textarea').val('');
        $ticket.find('.ServiceRequestWizard__image').removeAttr('style');
        $ticket.find('.ServiceRequestWizard__customFileUpload').removeClass('hasImage');
    };

    this.handleFormChanged = function () {
        this.$step1Form.off(constants.FORM_CHANGED);
        AdobeLaunch.pushFormStartedEvent(AdobeLaunch.FORM_GLOBAL_ID, AdobeLaunch.FORM_INLINE_TYPE, AdobeLaunch.FORM_SERVICE_REQUEST_NAME);
    }

    this.validateStep = function (stepNum) {
        var $currentStep = this.$element.find(".ServiceRequestWizard__Step" + stepNum);
        var $nextBtn = $currentStep.find(".step-" + stepNum + "-next-btn");
        var $requiredFields = $currentStep.find("[required]:not([disabled])");
        var isValid = true;

        $requiredFields.each(function(idx, elem) {
            if(!elem.checkValidity()) {
                isValid = false;
            }
        });

        $nextBtn.prop('disabled', !isValid);
    };

    // Step Navigation
    this.changeToPage = function (destination) {
        // 1) Hide any active steps
        this.$element.find(".ServiceRequestWizard__Page").hide();

        // 2) Activate destination step
        this.$element.find(".ServiceRequestWizard__Step" + destination).show();

        this.validateStep(destination);
    };

    this.step2Prev = function () {
        this.changeToPage(1);
    };

    this.step3Prev = function () {
        this.changeToPage(2);
    };
};

module.exports = component(ServiceRequestWizard);
