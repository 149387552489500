var component = require('../../../lib/js/component.js');

function Lightbox() {

    /**
     * Init for the Card module
     * @param  {Object} element HTML element
     */
    this.initialize = function (element) {

        var _this = this,
        $element = $(element),
        $previousElement = $element.prev(),
        $youtubeVideo = $element.find('.Lightbox-video'),
        $videoTour = $element.find('.Lightbox-tour');

        // If cinemagraph, add lightbox functionality to the video tag
        if ($previousElement.hasClass('u-responsiveVideo')) {
            $cinemagraph = $($previousElement[0].previousSibling);
            $cinemagraph.attr('data-toggle', 'modal');
            $cinemagraph.attr('data-target', $previousElement.data('target'));
            $cinemagraph.addClass('has-lightbox');
            
            if ($previousElement.hasClass('has-lightbox-tablet')) {
                $cinemagraph.addClass('has-lightbox-tablet');
            }
        }
        
        var $image = $element.find('img');

        
        $element.on('show.bs.modal', function(e) {
            if ($image !== null && $image.length > 0) {
                // Call to load image lazily is delayed to allow modal to set height and width before image is loaded
                setTimeout(function () {
                    if (!$image.hasClass('is-loaded')) {
                        $image.trigger("RESPONSIVE_LAZYLOAD");
                        $image.cloudinary_update();
                        $.cloudinary.responsive();
                    }
                }, 500);
            }

            if ($youtubeVideo.length > 0) {
                $youtubeVideo[0].src = "https://www.youtube-nocookie.com/embed/" + $youtubeVideo.data('youtube-id');
            } else if ($videoTour.length > 0) {
                var videoTourSrc = $videoTour.attr('src');

                if (videoTourSrc != null && videoTourSrc.length == 0) {
                    setTimeout(function () {
                        $videoTour.attr('src', $videoTour.data('tour'));    
                    }, 500);
                }
            }
        });

        $element.on('hide.bs.modal', function(e) {
            if ($youtubeVideo.length > 0) {
                $youtubeVideo[0].src = '';
            }
        });
    };
}

module.exports = component(Lightbox);