var component = require('../../../lib/js/component.js');
var MapCore = require('./map-core.js');
var mapsHelper = require('./maps-helper.js');
var mapPinHelper = require('./map-pin-helper.js');

// templates
var templates = require('./templates/info-window-templates');

var LocalAreaMapController = function () {
    this.initialize = function (el, options) {
        var _this = this;
        this.options = {};
        this.$mapElement = this.$element.find('.Map-googleMap');
        this.$filterCountText = $('.LocalAreaMap__filter-count');
        this.mobileLocationInfoWindow = $('.LocalAreaMap__mobile-location-info-window');
        this.mobileRouteInfoWindow = $('.LocalAreaMap__mobile-route-info-window');
        this.addressSearch = $('.LocalAreaMap__address-search');
        this.addressTextbox = $('.LocalAreaMap__address-search-input');
        this.addressSearchIcon = $('.LocalAreaMap__address-search-icon');
        this.mapMyCommuteCta = $('.LocalAreaMap__map-my-commute-cta');
        this.mapMyCommuteCtaContainer = $('.LocalAreaMap__map-my-commute-cta-container');
        this.mapMyCommuteCtaIcon = $('.LocalAreaMap__map-my-commute-cta--active-icon')
        this.localAreaMapModule = $('.LocalAreaMap');
        this.noReviewsLabel = this.localAreaMapModule.data('noReviewsLabel');
        this.googleMapsLinkText = this.localAreaMapModule.data('googleMapsLinkText');
        this.destinationPin = this.localAreaMapModule.data('destinationPin');
        this.mobileDropdownButton = $('.LocalAreaMap__dropdown-button');
        this.mobileDropdownButtonContainer = $('.LocalAreaMap__dropdown-button-container');
        this.mobileDropdownMenu = $('.LocalAreaMap__dropdown-menu-container');
        this.mobileFilterButtons = $('.LocalAreaMap__mobile-filter-buttons');
        this.resetButton = $('.LocalAreaMap__reset-button');
        this.applyButton = $('.LocalAreaMap__apply-button');
        $.extend(this.options, options, this.$mapElement.data());
        this.COMMUNITY_PIN_PNG_URL = this.options.map.defaults.COMMUNITY_PIN_PNG_URL;
        this.DEFAULT_PIN_ICON_SVG = this.options.map.defaults.MARKER_PIN_SVG_PATH;
        this.CommunityPosition;
        this.isDirectionsMode = false;
        this.currentRouteLeg = null;

        this.goldStarHtml = '<i class="LocalAreaInfoWindow__star font-icon-star-filled info-window-gold-star"></i>';
        this.grayStarHtml = '<i class="LocalAreaInfoWindow__star font-icon-star-filled info-window-gray-star"></i>';
        
        // Placeholder for reference to map instance
        this.map = {};

        // Placeholder for location markers
        this.locationMarkers = [];
        this.locationMarkers.push = function() { 
            Array.prototype.push.apply(this, arguments); 
            handleADA(this);
        };

        this.persistCommuteInfoMobile = false; 

        // Placeholder for selected map marker
        this.selectedMapMarker;

        // Set Map Type
        this.options.map.pulteMapType = 'localArea';

        // Enable zoom
        this.options.map.showZoomControls = true;
        this.options.map.enableDoubleClickZoom = true;
        this.options.map.enableScrollWheelZoom = false;

        this.searchRadiusInMeters = 40233;
        this.searchRadiusInMiles = 25;

        if (GlobalMapsObj.localAreaPoiMarkers) {
            // Store markers from global object
            this.markers = GlobalMapsObj.localAreaPoiMarkers;
        } else {
            console.error('LocalAreaMapController :: Marker data failed to load')
        }

        if (GlobalMapsObj.localAreaPoiCategories) {
            // Store categories from global object
            this.poiCategories = GlobalMapsObj.localAreaPoiCategories;

            // add isSelected property and set to true
            this.selectAllCategories();

            this.poiCategories.forEach(function (category) {
                $(_this.localAreaMapModule).on('click', '#poi-checkbox-' + category.Category, function () {
                    _this.togglePoiCategory(category.CommunityCategory.PointOfInterestID);
                });
            });
        } else {
            console.error('LocalAreaMapController :: Category data failed to load')
        }

        if (window.Pulte.isMapScriptLoaded) {
            // The google maps script has already been loaded.
            _this.setupMap();
        } else {
            // Add listener for when the async Google Maps script loads.
            $('body').on('MapScriptLoaded', function () {
                _this.setupMap();
            });
        }

        this.mapMyCommuteCta.on('click', function () {
            _this.toggleDirectionsMode();
        });

        this.mobileDropdownButton.on('click touch', function () {
            _this.toggleMobileDropdown();
        });

        this.resetButton.on('click', function () {
            _this.selectAllCategories();
        });

        this.applyButton.on('click', function () {
            _this.toggleMobileDropdown();
        });
    };

    function handleADA(markers){
        var filteredMarkers = markers.filter(function (marker) {
            return marker.location && marker.location.Location && marker.location.Location.POIName && marker.location.Location.POIAddress    
        });
        if(GlobalMapsObj.localAreaPoiCategories){
            var poiCategories = GlobalMapsObj.localAreaPoiCategories
            poiCategories.forEach(function(category){
                var categoryMarkers = filteredMarkers.filter(function(marker) {
                    return category.CommunityCategory.PointOfInterestID === marker.location.Location.PointOfInterestID
                })
                if(categoryMarkers){
                    var locations = categoryMarkers.map(function(marker){ return marker.location.Location.POIName + " " + marker.location.Location.POIAddress});
                    var adaText = "Locations: " + locations.join(", ")
                    $("#sr-only-poi-checkbox-" + category.Category).text(adaText)
                    $("#sr-only-dropdown-poi-checkbox-" + category.Category).text(adaText)
                }
            })
        }
        
    }

    /**
     * setupMap: attach mapCore and listen for added map instance
     */
    this.setupMap = function (external) {
        var _this = this;

        // Set up map specific listener for when map is ready
        var listener = this.options.map.pulteMapType + 'MapInstanceAdded';

        this.$mapElement.on(listener, function (e, map) {
            // Store reference to map
            _this.map = map;

            // close infowindows when they are clicked out of
            google.maps.event.addListener(_this.map, "click", function(e) {
                _this.closeInfoWindow();
            });

            // Setup markers
            _this.setupCommunityMarker(external);
            _this.setupLocationMarkers();

            // Remove this listener
            $(this).off(listener);
        });

        // Get instance of map
        mapsHelper.attachMapCore.call(this, MapCore);

        _this.map.setOptions({
            draggable: true,
            gestureHandling: 'cooperative',
            clickableIcons: false
        });

        // Initialize search input and add places auto complete
        var addressSearchInput = document.getElementById("address-search-input");
        _this.autoComplete = new google.maps.places.Autocomplete(addressSearchInput);

        // Bias the autoComplete results towards current map
        _this.map.addListener("bounds_changed", function () {
          _this.autoComplete.setBounds(_this.map.getBounds());
        });

        _this.addressSearchIcon.on('click', function () {
            _this.displayRouteToEnteredAddress();
        });

        // initialize directions services
        _this.directionsService = new google.maps.DirectionsService();
        _this.directionsRenderer = new google.maps.DirectionsRenderer();

        _this.locationInfoWindow = new google.maps.InfoWindow();
        _this.routeInfoWindow = new google.maps.InfoWindow();
        _this.setFilterCount();
        this.resetMap();

    };

    this.setupCommunityMarker = function (external) {
        var _this = this;

        // The first marker is the community
        var communityMarker = this.markers[0];

        // Get Google formated marker position
        var position = mapsHelper.getItemPosition(communityMarker.Location.POILatitude, communityMarker.Location.POILongitude);
        this.CommunityPosition = position;

        // external coords are passed in from the data
        if (external) {
            // pull them from the map's data attributes
            var lat = this.$element.find('.Map-googleMap').data().lat;
            var lng = this.$element.find('.Map-googleMap').data().lng;

            // set the coords of the center marker
            this.markers[0].Location.POILatitude = lat;
            this.markers[0].Location.POILongitude = lng;

            // set position accordingly
            position = mapsHelper.getItemPosition(lat, lng);
        }

        // Setup the marker icon
        var iconSvg = {
            url: communityMarker.MapPin ? mapPinHelper.convertSvgToUrl(communityMarker.MapPin) : this.COMMUNITY_PIN_PNG_URL
        };

        // Instantiate Google Maps Marker
        var mapMarker = new google.maps.Marker({
            position: position,
            map: this.map,
            icon: iconSvg,
            name: communityMarker.Name,
            isOrigin: true,
            zIndex: 99999
        });

        // Add click listener to marker
        google.maps.event.addListener(mapMarker, 'click', function () {
            _this.resetMap();
        });

        this.locationMarkers.push(mapMarker);
    };

    this.setupLocationMarkers = function () {
        if (this.markers) {
            // start at 1 to skip community marker
            for (var i = 1; i < this.markers.length; i++) {
                this.createPOILocationMarker(this.markers[i]);
            }

            this.setupGoogleResultsMarkers();
            this.showAllMarkers();

            this.fitManyToBounds(this.locationMarkers);
        }
    }

    this.setupGoogleResultsMarkers = function () {
        var _this = this;

        if (this.poiCategories) {
            var placesService = new google.maps.places.PlacesService(_this.map);
            var community = _this.CommunityPosition;

            this.poiCategories.forEach(function(category) {
                if (category.CommunityCategory.SourceType === 'G') {

                    var request = {
                        location: community,
                        radius: this.searchRadiusInMeters,
                        query: category.Category
                    };

                    placesService.textSearch(request, function (results, status) {
                        var placesResult = {
                            results: results,
                            status: status
                        }

                        _this.addGooglePlacesResultMarkers(placesResult, category, community);
                    });
                }
            });
        }
    }

    this.addGooglePlacesResultMarkers = function (placesResult, category, community) {
        if (placesResult.status == google.maps.places.PlacesServiceStatus.OK && placesResult.results.length > 0) {
            // Loop through results and make new markers
            for (var i = 0; i < placesResult.results.length; i++) {
                var destinationLatitude = placesResult.results[i].geometry.location.lat();
                var destinationLongitude = placesResult.results[i].geometry.location.lng();
                var destination = new google.maps.LatLng(destinationLatitude, destinationLongitude);
                var distanceFromCommunityInMiles = Math.round(mapsHelper.getDistanceInMiles(community, destination) * 10) / 10;
                var locationPin = {
                    Location : {
                        POILatitude: destinationLatitude,
                        POILongitude: destinationLongitude,
                        PointOfInterestID: category.CommunityCategory.PointOfInterestID,
                        POIName: placesResult.results[i].name,
                        GooglePlaceID: placesResult.results[i].place_id,
                        POIAddress: placesResult.results[i].formatted_address
                      },
                    MapPin: category.Map_Pin_Icon,
                    Rating: placesResult.results[i].rating,
                    TotalRatings: placesResult.results[i].user_ratings_total,
                    DistanceFromCommunity: distanceFromCommunityInMiles
                }
                this.createPOILocationMarker(locationPin);
            }
        } else {
            console.error("Error requesting data from Google Places. Status = ", placeObj.status);
        }
    }



    this.createPOILocationMarker = function (locationData) {
        var _this = this;

        var position = mapsHelper.getItemPosition(locationData.Location.POILatitude, locationData.Location.POILongitude);

        // Setup marker icon
        var iconSvg = {
            url: locationData.MapPin ? mapPinHelper.convertSvgToUrl(locationData.MapPin) : mapPinHelper.getGoogleMapPinObject(this.DEFAULT_PIN_ICON_SVG)
        };

        // Instantiate marker
        var mapMarker = new google.maps.Marker({
            map: _this.map,
            position: position,
            icon: iconSvg,
            name: locationData.Location.POIName,
            location: locationData,
            noReviewsLabel: _this.noReviewsLabel
        });

        // Add click listener to marker
        google.maps.event.addListener(mapMarker, 'click', function () {
            if (_this.selectedMapMarker) {
                _this.selectedMapMarker.setZIndex(0);
            }
            _this.selectedMapMarker = mapMarker;

            if (_this.isDirectionsMode) {
                _this.clearEnteredAddress();
                _this.displayRouteFromCommunity(mapMarker);
            } else {
                _this.displayPlaceDetailsInfoWindow(mapMarker);
            }
        });

        // Store this marker in the featureMarkers Array.
        if ( mapMarker.latitude !== 0 || mapMarker.longitude !== 0 ) {
            this.locationMarkers.push(mapMarker);
        }
    };

    /**
     * fitToBounds: Update bounds of markers and recenter map
     */
    this.fitManyToBounds = function (markers) {
        var bounds = mapsHelper.getBounds(markers);
        // Place markers on map and recenter.
        this.map.fitBounds(bounds);
        this.map.setCenter(this.CommunityPosition);
    };

    this.setFilterCount = function () {
        var filterCount = 0;

        this.poiCategories.forEach(function (category) {
            if (category.isSelected) {
                filterCount++;
            }
        });

        this.$filterCountText.html(filterCount);
    }

    this.setLocationInfoWindow = function (mapMarker) {
        var isMobile = window.matchMedia("(max-width: 767px)").matches;

        // Populate template markup with data from mapMkr
        mapMarker.goldStars = [];
        mapMarker.grayStars = [];
        for (var i = 1; i <= 5; i++) {
            if (i <= mapMarker.location.Rating) {
                mapMarker.goldStars.push(i);
            } else {
                mapMarker.grayStars.push(i);
            }
        }

        if (!isMobile) {
            // Get the proper infoWindow template
            var templateFunction = templates[this.options.type];


            var infoWindowContent = templateFunction(mapMarker);

            // Set the infoWindow content
            this.locationInfoWindow.setContent(infoWindowContent);

            // Open this markers infoWindow
            this.locationInfoWindow.open(this.map, mapMarker);

            $('.gm-style-iw').addClass('openIW');
        } else {
            this.setMobileLocationInfoWindow(mapMarker);
        }
    };

    this.setMobileLocationInfoWindow = function (mapMarker) {
        var _this = this;
        var locationName = $('.LocalAreaInfoWindow__location-name');
        var ratingData = $('.LocalAreaInfoWindow__rating-data')
        var noReviews = $('.LocalAreaInfoWindow__no-reviews')
        var ratingStars = $('.LocalAreaInfoWindow__rating-stars');
        var rating = $('.LocalAreaInfoWindow__rating');
        var totalRatings = $('.LocalAreaInfoWindow__total-ratings');
        var distance = $('.LocalAreaInfoWindow__distance');
        var starsHtml = '';

        locationName.html(mapMarker.name);

        if (mapMarker.location.Rating && mapMarker.location.TotalRatings) {
            ratingData.removeClass('d-none');
            noReviews.addClass('d-none');
            mapMarker.goldStars.forEach(function () {
                starsHtml += _this.goldStarHtml;
            });
            mapMarker.grayStars.forEach(function () {
                starsHtml += _this.grayStarHtml;
            });
            ratingStars.html(starsHtml);
            rating.html(mapMarker.location.Rating);
            totalRatings.html('(' + mapMarker.location.TotalRatings + ')');
        } else {
            ratingData.addClass('d-none');
            noReviews.removeClass('d-none');
            noReviews.html(mapMarker.noReviewsLabel);
        }

        distance.html(mapMarker.location.DistanceFromCommunity + ' Miles');

        this.mobileLocationInfoWindow.removeClass('d-none');
    };

    this.getAdditionalPlacesDetails = function (mapMarker) {
        var _this = this;

        // get additional details from google places
        var placesService = new google.maps.places.PlacesService(_this.map);
        var placesRequest = {
            placeId: mapMarker.location.Location.GooglePlaceID
        };

        placesService.getDetails(placesRequest, function (locationDetails, status) {
            if (status == google.maps.places.PlacesServiceStatus.OK) {
                var destinationLatitude = locationDetails.geometry.location.lat();
                var destinationLongitude = locationDetails.geometry.location.lng();
                var destination = new google.maps.LatLng(destinationLatitude, destinationLongitude);
                var distanceFromCommunityInMiles = Math.round(mapsHelper.getDistanceInMiles(_this.CommunityPosition, destination) * 10) / 10;
                mapMarker.location.Rating = locationDetails.rating;
                mapMarker.location.TotalRatings = locationDetails.user_ratings_total;
                mapMarker.location.DistanceFromCommunity = distanceFromCommunityInMiles;
                _this.setLocationInfoWindow.call(_this, mapMarker);
            }
        });
    }

    this.closeInfoWindow = function () {
        if (this.locationInfoWindow) {
            this.locationInfoWindow.close();
        }
        if (this.mobileLocationInfoWindow) {
            this.mobileLocationInfoWindow.addClass('d-none');
        }
        if (this.mobileRouteInfoWindow) {
            this.mobileRouteInfoWindow.addClass('d-none');
        }
    }

    this.resetMap = function () {
        this.closeInfoWindow();
        this.fitManyToBounds(this.locationMarkers); 
    }

 

    this.toggleDirectionsMode = function () {
        this.isDirectionsMode = !this.isDirectionsMode;

        if (this.isDirectionsMode) {
            this.addressSearch.removeClass('d-none');
            this.mapMyCommuteCta.addClass('LocalAreaMap__map-my-commute-cta--active');
            this.mapMyCommuteCtaIcon.removeClass('d-none');
            this.closeInfoWindow();

            this.adjustMapHeightWhenSearching();
        } else {
            this.addressSearch.addClass('d-none');
            this.mapMyCommuteCta.removeClass('LocalAreaMap__map-my-commute-cta--active');
            this.mapMyCommuteCtaIcon.addClass('d-none');
            this.routeInfoWindow.close();
            this.closeInfoWindow();
            this.clearDirectionsData();
            this.clearEnteredAddress();

            this.restoreMapHeightAfterSearching();
        }

        this.resetMap();
    }

    this.displayPlaceDetailsInfoWindow = function (mapMarker) {
        var _this = this;

        _this.closeInfoWindow();

        // get additional details from google places
        if (!mapMarker.location.Rating || !mapMarker.location.TotalRatings || !mapMarker.location.DistanceFromCommunity) {
            _this.getAdditionalPlacesDetails(mapMarker);
        } else {
            _this.setLocationInfoWindow.call(_this, mapMarker);
        }
    }

    this.displayRouteFromCommunity = function (mapMarker) {
        // pass in destination details and render route on the map
        var _this = this;
        var community = _this.CommunityPosition;
        var directionsRequest = {
            origin: community,
            destination: mapMarker.position,
            travelMode: 'DRIVING'
        }

        _this.clearDirectionsData();

        _this.directionsService.route(directionsRequest, function(result, status) {
            if (status == 'OK') {
                _this.directionsRenderer.setMap(_this.map);
                _this.directionsRenderer.setOptions({suppressMarkers: true});
                _this.directionsRenderer.setDirections(result);

                // don't need the whole route object, just one leg (community to destination)
                _this.currentRouteLeg = result.routes[0].legs[0];

                // use overview path to get the halfway point on the route for info window placement
                var midwayPointIndex = result.routes[0].overview_path.length / 2;
                var midwayPoint = result.routes[0].overview_path[midwayPointIndex | 0];

                // center map on the route
                var bounds = new google.maps.LatLngBounds();
                bounds.extend(community);
                bounds.extend(mapMarker.position);
                _this.map.setCenter(bounds.getCenter());
                _this.map.panToBounds(bounds, 200);

                // Places marker visibily above neighboring markers
                mapMarker.setZIndex(99999);

                // Show route info window
                _this.setRouteInfoWindow(mapMarker.name, midwayPoint);
              }
        })
    }

    this.clearDirectionsData = function () {
        this.directionsRenderer.set('directions', null);
    }

    this.displayRouteToEnteredAddress = function () {
        // Listen for the event fired when the user selects a prediction and retrieve
        // more details for that place.
        var _this = this;
        var place = _this.autoComplete.getPlace();

        this.clearEnteredAddress()

        if (!place) {
          return;
        }

        // For each place, get the icon, name and location.
        var bounds = new google.maps.LatLngBounds();

        if (!place.geometry || !place.geometry.location) {
            console.log("Returned place contains no geometry");
            return;
        }

        var iconSvg = {
            url: _this.destinationPin ? mapPinHelper.convertSvgToUrl(_this.destinationPin) : mapPinHelper.getGoogleMapPinObject(_this.DEFAULT_PIN_ICON_SVG)
        };

        var mapMarker = new google.maps.Marker({
          map: _this.map,
          position: place.geometry.location,
          icon: iconSvg,
          name: place.name,
          isEnteredAddress: true
        });

        // Create a marker for each place.
        _this.locationMarkers.push(mapMarker);
        bounds.extend(place.geometry.location);
        _this.displayRouteFromCommunity(mapMarker);
    }

    this.clearEnteredAddress = function () {
        var _this = this;
        _this.locationMarkers.forEach(function(marker) {

            if (marker.isEnteredAddress) {
                marker.setMap(null);
                _this.locationMarkers.pop();
                _this.autoComplete.set('place', null);

                // google places autocomplete looks for blur
                // call blur and then change value
                // otherwise previous value will persist
                _this.addressTextbox.blur();
                setTimeout(function() {
                    _this.addressTextbox.val('');}
                    ,10);
            }
        });
    }

    this.toggleMobileDropdown = function () {
        if (this.mobileDropdownMenu) {
            this.hideOrShowElement(this.mobileDropdownMenu);
            this.hideOrShowElement(this.mobileDropdownButtonContainer);
            this.hideOrShowElement(this.mapMyCommuteCtaContainer);
            this.hideOrShowElement(this.mobileFilterButtons);
            if(this.isDirectionsMode){
                this.hideOrShowElement(this.addressSearch);
                
                if(!this.mobileDropdownMenu.hasClass('d-none') && !this.mobileRouteInfoWindow.hasClass('d-none')){
                    this.mobileRouteInfoWindow.addClass('d-none')
                    this.persistCommuteInfoMobile = true; 
                } else if(this.persistCommuteInfoMobile && this.mobileDropdownMenu.hasClass('d-none')){
                    this.mobileRouteInfoWindow.removeClass('d-none')
                    this.persistCommuteInfoMobile = false; 
                }
            }
        }
    }

    this.hideOrShowElement = function (element) {
        if (element) {
            if (element.hasClass('d-none')) {
                element.removeClass('d-none');
            } else {
                element.addClass('d-none');
            }
        }
    }

    this.togglePoiCategory = function (categoryId) {
        var _this = this;
        _this.poiCategories.forEach(function(category) {
            if (category.CommunityCategory.PointOfInterestID === categoryId) {
                category.isSelected = !category.isSelected;
            }
            

            _this.locationMarkers.forEach(function (marker) {  
                if (marker.location &&
                    marker.location.Location &&
                    category.CommunityCategory.PointOfInterestID === marker.location.Location.PointOfInterestID) {
                     
                    if (category.isSelected) {
                        marker.setMap(_this.map);
                    } else {
                        marker.setMap(null);
                    }
                }
            });
        });

        _this.setFilterCount();
    }

    this.selectAllCategories = function () {
        this.poiCategories.forEach( function(category) {
            category.isSelected = true;
            $('#poi-checkbox-' + category.Category).prop('checked', true);
        });

        this.setFilterCount();
        this.showAllMarkers();
    }

    this.showAllMarkers = function () {
        var _this = this;
        this.locationMarkers.forEach(function (marker) {
            marker.setMap(_this.map);
        });
    }

    

    this.setRouteInfoWindow = function (locationName, midwayPoint) {
        var _this = this;
        var isMobile = window.matchMedia("(max-width: 767px)").matches;

        if (_this.currentRouteLeg) {
            var routeData = {
                name: locationName,
                travelTime: _this.currentRouteLeg.duration.text,
                googleMapsLinkText: _this.googleMapsLinkText,
                originLatitude: _this.currentRouteLeg.start_location.lat(),
                originLongitude: _this.currentRouteLeg.start_location.lng(),
                destinationLatitude: _this.currentRouteLeg.end_location.lat(),
                destinationLongitude: _this.currentRouteLeg.end_location.lng()
            };

            if (!isMobile) {
                // Get the proper infoWindow template
                var templateFunction = templates['route'];

                var infoWindowContent = templateFunction(routeData);

                // Set the infoWindow content
                this.routeInfoWindow.setContent(infoWindowContent);
                this.routeInfoWindow.setPosition(midwayPoint);

                // Open this route's infoWindow
                this.routeInfoWindow.open(this.map);

                $('.gm-style-iw').addClass('openIW');
            } else {
                this.setMobileRouteInfoWindow(routeData);
            }
        }
    };

    this.setMobileRouteInfoWindow = function (routeData) {
        var destinationName = $('.LocalAreaInfoWindow__destination-name');
        var travelTime = $('.LocalAreaInfoWindow__travel-time');
        var googleMapsLink = $('.LocalAreaInfoWindow__google-maps-link');

        var googleMapsLinkUrl =
            'https://maps.google.com/maps/dir/?api=1&origin=' +
             routeData.originLatitude + ',' +
             routeData.originLongitude +
             '&destination=' +
             routeData.destinationLatitude + ',' +
             routeData.destinationLongitude;

        destinationName.html(routeData.name);

        travelTime.html(routeData.travelTime);

        googleMapsLink.html(this.googleMapsLinkText);
        googleMapsLink.attr('href', googleMapsLinkUrl);

        this.mobileRouteInfoWindow.removeClass('d-none');
    };

    this.adjustMapHeightWhenSearching = function(){
        var divChild = this.$mapElement.children().first();
        if(divChild) {
            var addressSearchHeight = $(this.addressSearch).outerHeight() + "px";

            $(divChild).css({
                height: "calc(100% - " + addressSearchHeight + ")",
                bottom: 0,
                top: "unset"
            });
        }
    }

    this.restoreMapHeightAfterSearching = function(){
        var divChild = this.$mapElement.children().first();
        if(divChild) {
            $(divChild).css({
                height: "100%",
                top: 0,
                bottom: "unset"
            });
        }
    }
};
module.exports = component(LocalAreaMapController);
