var component = require('../../../lib/js/component.js');
var Form = require('../forms/form.js');
var constants = require('../forms/constants.js');

var ForgotPassword = function () {

    this.initialize = function (el, options) {
        this.options = options || {};
        this.$element.find('.ForgotPassword__form').attach(Form).on(constants.FORM_AJAX_SUBMIT, this.handleForgotPasswordFormSubmision.bind(this));

        this.$errorBanner = this.$element.find('.ForgotPassword__error-banner');
        this.$formContainer = this.$element.find('.ForgotPassword__form-container');
        this.$confirmationContainer = this.$element.find('.ForgotPassword__confirmation-container');

    }

    this.handleForgotPasswordFormSubmision = function(event, form) {
        $.ajax({
            method: form.options.method,
            url: form.options.action,
            data: form.data,
            dataType: "json",
            context: this
        }).done(function (data) {
            if (data && data.Success) {
                // hide the form container and show the confirmation container
                this.$formContainer.addClass('d-none');
                this.$confirmationContainer.removeClass('d-none');
            } else {
                // show the error banner and add margin to the form container
                this.$errorBanner.removeClass('d-none');

                // this added margin makes room for the error banner
                this.$formContainer.addClass('mt-10');
            }
        });
    }
}

module.exports = component(ForgotPassword);
