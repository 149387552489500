var component = require('../../../lib/js/component.js');

function Card() {

    /**
     * Init for the Card module
     * @param  {Object} element HTML element
     */
    this.initialize = function (element) {

        $element = $(element);

        if ($element.find('.Card-cta a').length && !$element.hasClass('Card--tab') && !$element.hasClass('Card--article')) {

            $element.addClass('Card--isLink');

            $element.on('click touch', function (e) {
                if (elementIsLightbox(e.target)) { return; }

                var $cta = $(this).find('.Card-cta a');
                var cta = $cta.attr('href');
                var ctaTarget = $cta.attr('target');
                if (cta) {
                    e.preventDefault();
                    var newWindow = window.open(cta, ctaTarget ? ctaTarget : '_self');
                    newWindow.opener = null;
                } else {
                    // The if below is checking to make sure the click was not on the CTA itself. The logic inside the if 
                    // prevents the CTA from working, but is necessary if the click was elsewhere on the card.
                    var $eventTarget = $(e.target);
                    if ($eventTarget[0] !== $cta[0]) {
                        var ctaDataTarget = $cta.data('target');
                        if (ctaDataTarget) {
                            e.preventDefault();
                            var $dataTarget = $(document).find(ctaDataTarget);
                            if ($dataTarget.hasClass('modal')) {
                                // if the click was off the cta we need to handle swapping lead form image here and not on the modal show 
                                // event since its not called unless the cta is clicked
                                var leadFormOverrideImageURL = $cta.data("overrideimageurl");
                                var overrideAltText = $cta.data("overridealttext");
                                var $leadFormImage = $('#global-modal-lead-form').find('img');
                                var $overrideImage = $("img.overrideimage[src='" + leadFormOverrideImageURL + "']");

                                if ($overrideImage.length > 0 && $overrideImage.prop('src') == leadFormOverrideImageURL) {
                                    $leadFormImage.hide();
                                    $overrideImage.show();
                                } else if (leadFormOverrideImageURL) {
                                    $leadFormImage.hide();
                                    var dataSize = $leadFormImage.data('size');
                                    var dataTransformations = $leadFormImage.data('transformations');
                                    var dataDam = $leadFormImage.data('dam');
                                    var csrc = dataDam + dataSize[0] + ',' + dataTransformations + '/' + leadFormOverrideImageURL;

                                    var imgTag = document.createElement('img');
                                    imgTag.src = leadFormOverrideImageURL;
                                    $(imgTag).attr('data-csrc', csrc);
                                    $(imgTag).attr('csrc', csrc);
                                    $(imgTag).attr('data-size', dataSize);
                                    $(imgTag).attr('data-transformations', dataTransformations);
                                    $(imgTag).attr('data-dam', dataDam);
                                    $(imgTag).attr('data-alt', overrideAltText);
                                    $(imgTag).attr('alt', overrideAltText);

                                    $(imgTag).addClass('u-responsiveMedia cld-responsive is-loaded is-initialized overrideimage');
                                    document.querySelector('div.GlobalLeadForm__leftPanel > div.row').appendChild(imgTag);
                                }
                                $dataTarget.modal('show');
                            }
                        }
                    }
                }
            });
        }

        var elementIsLightbox = function (target) {
            var isLightbox = false;

            if ($(target).hasClass('has-lightbox') || $(target).hasClass('Lightbox') ||
                (($(target.offsetParent) !== null) && ($(target.offsetParent).hasClass('Lightbox') || $(target.offsetParent).hasClass('has-lightbox')))) {
                isLightbox = true;
            }

            return isLightbox;
        }
    };
}

module.exports = component(Card);
