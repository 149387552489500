var templater = require("handlebars/runtime")["default"].template;module.exports = templater({"1":function(depth0,helpers,partials,data) {
    var helper;

  return "Map-markerContent-"
    + this.escapeExpression(((helper = (helper = helpers.kind || (depth0 != null ? depth0.kind : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"kind","hash":{},"data":data}) : helper)));
},"3":function(depth0,helpers,partials,data) {
    var stack1;

  return "    <ul class=\"clearfix\">\r\n\r\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.startingFromPrice : depth0),{"name":"if","hash":{},"fn":this.program(4, data, 0),"inverse":this.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.minBedrooms : depth0),{"name":"if","hash":{},"fn":this.program(8, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.minBathrooms : depth0),{"name":"if","hash":{},"fn":this.program(11, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.minGarage : depth0),{"name":"if","hash":{},"fn":this.program(14, data, 0),"inverse":this.program(17, data, 0),"data":data})) != null ? stack1 : "")
    + "        \r\n    </ul>\r\n";
},"4":function(depth0,helpers,partials,data) {
    var helper;

  return "        <li>\r\n            <span class=\"markerDetail-data\">$"
    + this.escapeExpression(((helper = (helper = helpers.startingFromPrice || (depth0 != null ? depth0.startingFromPrice : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"startingFromPrice","hash":{},"data":data}) : helper)))
    + "</span>\r\n            <span class=\"markerDetail-label\">Starting At</span>\r\n        </li>\r\n";
},"6":function(depth0,helpers,partials,data) {
    var helper;

  return "        <li class=\"markerDetail--status\">\r\n            <span class=\"markerDetail-data\">"
    + this.escapeExpression(((helper = (helper = helpers.priceStatus || (depth0 != null ? depth0.priceStatus : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"priceStatus","hash":{},"data":data}) : helper)))
    + "</span>\r\n            <span class=\"markerDetail-label\"></span>\r\n        </li>\r\n";
},"8":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "        <li>\r\n            <span class=\"markerDetail-data\">"
    + this.escapeExpression(((helper = (helper = helpers.minBedrooms || (depth0 != null ? depth0.minBedrooms : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"minBedrooms","hash":{},"data":data}) : helper)))
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.maxBedrooms : depth0),{"name":"if","hash":{},"fn":this.program(9, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</span>\r\n            <span class=\"markerDetail-label\">Bedrooms</span>\r\n        </li>\r\n";
},"9":function(depth0,helpers,partials,data) {
    var helper;

  return "-"
    + this.escapeExpression(((helper = (helper = helpers.maxBedrooms || (depth0 != null ? depth0.maxBedrooms : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"maxBedrooms","hash":{},"data":data}) : helper)));
},"11":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "        <li>\r\n            <span class=\"markerDetail-data\">"
    + this.escapeExpression(((helper = (helper = helpers.minBathrooms || (depth0 != null ? depth0.minBathrooms : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"minBathrooms","hash":{},"data":data}) : helper)))
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.maxBathrooms : depth0),{"name":"if","hash":{},"fn":this.program(12, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</span>\r\n            <span class=\"markerDetail-label\">Bathrooms</span>\r\n        </li>\r\n";
},"12":function(depth0,helpers,partials,data) {
    var helper;

  return "-"
    + this.escapeExpression(((helper = (helper = helpers.maxBathrooms || (depth0 != null ? depth0.maxBathrooms : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"maxBathrooms","hash":{},"data":data}) : helper)));
},"14":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "        <li class=\"hidden-xs\">\r\n            <span class=\"markerDetail-data\">"
    + this.escapeExpression(((helper = (helper = helpers.minGarage || (depth0 != null ? depth0.minGarage : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"minGarage","hash":{},"data":data}) : helper)))
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.maxGarage : depth0),{"name":"if","hash":{},"fn":this.program(15, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</span>\r\n            <span class=\"markerDetail-label\">Garage</span>\r\n        </li>\r\n";
},"15":function(depth0,helpers,partials,data) {
    var helper;

  return "-"
    + this.escapeExpression(((helper = (helper = helpers.maxGarage || (depth0 != null ? depth0.maxGarage : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"maxGarage","hash":{},"data":data}) : helper)));
},"17":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.maxGarage : depth0),{"name":"if","hash":{},"fn":this.program(18, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"18":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "        <li class=\"hidden-xs\">\r\n            <span class=\"markerDetail-data\">"
    + this.escapeExpression(((helper = (helper = helpers.minGarage || (depth0 != null ? depth0.minGarage : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"minGarage","hash":{},"data":data}) : helper)))
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.maxGarage : depth0),{"name":"if","hash":{},"fn":this.program(15, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</span>\r\n            <span class=\"markerDetail-label\">Garage</span>\r\n        </li>\r\n        ";
},"20":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "            <a href=\""
    + alias3(((helper = (helper = helpers.CommunityLink || (depth0 != null ? depth0.CommunityLink : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"CommunityLink","hash":{},"data":data}) : helper)))
    + "\" "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.minBedrooms : depth0),{"name":"if","hash":{},"fn":this.program(21, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "class=\"btn btn-primary marker-community-link\" data-analytics=\"map|window-info|view-community\">"
    + alias3(((helper = (helper = helpers.ctaText || (depth0 != null ? depth0.ctaText : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ctaText","hash":{},"data":data}) : helper)))
    + "</a>\r\n";
},"21":function(depth0,helpers,partials,data) {
    return "target=\"_blank\" rel=\"noopener\"";
},"23":function(depth0,helpers,partials,data) {
    var helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "            <a href=\""
    + alias3(((helper = (helper = helpers.CommunityLink || (depth0 != null ? depth0.CommunityLink : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"CommunityLink","hash":{},"data":data}) : helper)))
    + "#qmi\" target=\"_blank\" rel=\"noopener\" class=\"btn btn-default marker-qmi-link\" data-analytics=\"map|window-info|view-community-qmi\">"
    + alias3(((helper = (helper = helpers.ctaQmiText || (depth0 != null ? depth0.ctaQmiText : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"ctaQmiText","hash":{},"data":data}) : helper)))
    + "</a>\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "<div id=\"info-window-nearby-template\" class=\"Map-markerContent clearfix "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.kind : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\">\r\n    <h3 class=\"marker-name\">"
    + this.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"name","hash":{},"data":data}) : helper)))
    + "</h3>\r\n    <hr>\r\n"
    + ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.kind : depth0),{"name":"unless","hash":{},"fn":this.program(3, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\r\n    <div class=\"marker-links\">\r\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.CommunityLink : depth0),{"name":"if","hash":{},"fn":this.program(20, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.inventoryCount : depth0),{"name":"if","hash":{},"fn":this.program(23, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\r\n  \r\n</div>\r\n";
},"useData":true});