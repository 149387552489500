var component = require('../../../lib/js/component.js');

function FacebookForm() {

    this.initialize = function (element) {
        this.$form = this.find('form');
        // get the <input type="image"> that Sitecore writes into the page
        // with its Facebook login helper.
        this.$badSubmit = this.find('[type="image"]');
        // get the real submit button we wrote into the page
        this.$goodSubmit = this.find('.FacebookForm-submit');
        // goodbye silly Sitecore button
        this.$badSubmit.remove();
        // teleport the real submit button into the <form>
        this.$goodSubmit.detach();
        this.$form.append(this.$goodSubmit);
    };
}

module.exports = component(FacebookForm);
