var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');
var AdobeLaunch = require('../../../lib/js/adobelaunch.js');

function ExperienceMessage() {

    this.initialize = function (element, options) {

        var _this = this;

        var modalData = this.$element.data();
        var brandIsActiveAdult = false;
        var targetBrandIsActiveAdult = false;
        var isSameBrand = false;
        var $headline = this.$element.find(".ExperienceMessage__headline");
        var $description = this.$element.find(".ExperienceMessage__description");
        var $descriptionMobile = this.$element.find(".ExperienceMessage__description-mobile");
        var $about = this.$element.find(".ExperienceMessage__about");
        var $aboutMobile = this.$element.find(".ExperienceMessage__about-mobile");
        var $cta = this.$element.find(".ExperienceMessage__button-submit");
        var $ctaGoBack = this.$element.find(".ExperienceMessage__button-back");
        var $doNotShowCheckBox = this.$element.find(".ExperienceMessage__label input");

        this.brand = $('body').data('brand');
        this.relatedTargetCTA = null;
        this.cookieName;
        this.cookiePrefixes = { sameBrandToAgeRestrictedCommunity : "sameBrandToAgeRestrictedCommunity",
                                differentBrandToAgeRestrictedCommunity : "differentBrandToAgeRestrictedCommunity",
                                differentBrandToNonAgeRestrictedBrand : "differentBrandToNonAgeRestrictedBrand",
                                ageRestrictedBrandToNonAgeRestrictedCommunity : "ageRestrictedBrandToNonAgeRestrictedCommunity" }

        $ctaGoBack.text(modalData.ctaGoBack);

        $('.ExperienceMessage').on('show.bs.modal', function (e) {
            var buttonData = $(e.relatedTarget).data();
            var bypassModal = false;
            isSameBrand = false;
            brandIsActiveAdult = false;
            targetBrandIsActiveAdult = false;
            _this.cookieName = null;
            $headline.focus();

            if(_this.brand === 'Del Webb') {
                brandIsActiveAdult = true;
            }

            if(buttonData.targetBrand === 'Del Webb') {
                targetBrandIsActiveAdult = true;
            }

            if(buttonData.targetBrand === _this.brand) {
                isSameBrand = true;
            }

            switch(buttonData.targetBrand) {
                case 'Pulte':
                    if(buttonData.isActiveAdult) {
                        $about.html(modalData.aboutPulteActiveAdult);
                        $aboutMobile.html(modalData.aboutPulteActiveAdultMobile);
                    }
                    else {
                        $about.html(modalData.aboutPulte);
                        $aboutMobile.html(modalData.aboutPulteMobile);
                    }
                    break;
                case 'Del Webb':
                    $about.html(modalData.aboutDelwebb);
                    $aboutMobile.html(modalData.aboutDelwebbMobile);
                    break;
                case 'Centex':
                    $about.html(modalData.aboutCentex);
                    $aboutMobile.html(modalData.aboutCentexMobile);
                    break;
                case 'DiVosta':
                    $about.html(modalData.aboutDivosta);
                    $aboutMobile.html(modalData.aboutDivostaMobile);
                    break;
                case 'JW':
                    $about.html(modalData.aboutJw);
                    $aboutMobile.html(modalData.aboutJwMobile);
                    break;
                case 'AmericanWest':
                    $about.html(modalData.aboutAmericanwest);
                    $aboutMobile.html(modalData.aboutAmericanwestMobile);
                    break;
            }

            if(!brandIsActiveAdult && !targetBrandIsActiveAdult) {
                // UC1 - Site visitor is navigating to an age restricted community within the same brand site
                if(!_this.getDoNotShowMessageCookie(_this.cookiePrefixes.sameBrandToAgeRestrictedCommunity, _this.brand, buttonData.targetBrand) && buttonData.isActiveAdult && isSameBrand) {
                    $headline.html(_this.replaceMergeFields(modalData.sameBrandToAgeRestrictedCommunityHeadline, buttonData.targetBrand));
                    $description.html(_this.replaceMergeFields(modalData.sameBrandToAgeRestrictedCommunityDescription, buttonData.targetBrand));
                    $descriptionMobile.html(_this.replaceMergeFields(modalData.sameBrandToAgeRestrictedCommunityDescriptionMobile, buttonData.targetBrand));
                    $cta.html(_this.replaceMergeFields(modalData.sameBrandToAgeRestrictedCommunityCta, buttonData.targetBrand));
                }
                // UC2 - Site Visitor is leaving the current brand site to an age-restricted community on another brand site that is not entirely age restricted
                else if(!_this.getDoNotShowMessageCookie(_this.cookiePrefixes.differentBrandToAgeRestrictedCommunity, _this.brand, buttonData.targetBrand) && buttonData.isActiveAdult && !isSameBrand) {
                    $headline.html(_this.replaceMergeFields(modalData.differentBrandToAgeRestrictedCommunityHeadline, buttonData.targetBrand));
                    $description.html(_this.replaceMergeFields(modalData.differentBrandToAgeRestrictedCommunityDescription, buttonData.targetBrand));
                    $descriptionMobile.html(_this.replaceMergeFields(modalData.differentBrandToAgeRestrictedCommunityDescriptionMobile, buttonData.targetBrand));
                    $cta.html(_this.replaceMergeFields(modalData.differentBrandToAgeRestrictedCommunityCta, buttonData.targetBrand));
                }
                // UC4 - User is leaving the current brand site to another non age restricted brand
                else if(!_this.getDoNotShowMessageCookie(_this.cookiePrefixes.differentBrandToNonAgeRestrictedBrand, _this.brand, buttonData.targetBrand) && !buttonData.isActiveAdult && !isSameBrand) {
                    $headline.html(_this.replaceMergeFields(modalData.differentBrandToNonAgeRestrictedBrandHeadline, buttonData.targetBrand));
                    $description.html(_this.replaceMergeFields(modalData.differentBrandToNonAgeRestrictedBrandDescription, buttonData.targetBrand));
                    $descriptionMobile.html(_this.replaceMergeFields(modalData.differentBrandToNonAgeRestrictedBrandDescriptionMobile, buttonData.targetBrand));
                    $cta.html(_this.replaceMergeFields(modalData.differentBrandToNonAgeRestrictedBrandCta, buttonData.targetBrand));
                }
                else {
                    bypassModal = true;
                }
            }
            else {
                //UC3 - Site visitor is leaving the current brand site to an age-restricted community in an age restricted brand
                if(!_this.getDoNotShowMessageCookie(_this.cookiePrefixes.differentBrandToAgeRestrictedCommunity, _this.brand, buttonData.targetBrand) && buttonData.isActiveAdult && !isSameBrand && targetBrandIsActiveAdult) {
                    $headline.html(_this.replaceMergeFields(modalData.differentBrandToAgeRestrictedBrandHeadline, buttonData.targetBrand));
                    $description.html(_this.replaceMergeFields(modalData.differentBrandToAgeRestrictedBrandDescription, buttonData.targetBrand));
                    $descriptionMobile.html(_this.replaceMergeFields(modalData.differentBrandToAgeRestrictedBrandDescriptionMobile, buttonData.targetBrand));
                    $cta.html(_this.replaceMergeFields(modalData.differentBrandToAgeRestrictedCommunityCta, buttonData.targetBrand));
                }
                // UC2 - Site Visitor is leaving the current brand site to an age-restricted community on another brand site that is not entirely age restricted
                else if(!_this.getDoNotShowMessageCookie(_this.cookiePrefixes.differentBrandToAgeRestrictedCommunity, _this.brand, buttonData.targetBrand) && buttonData.isActiveAdult && !isSameBrand) {
                    $headline.html(_this.replaceMergeFields(modalData.differentBrandToAgeRestrictedCommunityHeadline, buttonData.targetBrand));
                    $description.html(_this.replaceMergeFields(modalData.differentBrandToAgeRestrictedCommunityDescription, buttonData.targetBrand));
                    $descriptionMobile.html(_this.replaceMergeFields(modalData.differentBrandToAgeRestrictedCommunityDescriptionMobile, buttonData.targetBrand));
                    $cta.html(_this.replaceMergeFields(modalData.differentBrandToAgeRestrictedCommunityCta, buttonData.targetBrand));
                }
                // UC6 - User is leaving age restricted brand to a non age restricted community
                else if (!_this.getDoNotShowMessageCookie(_this.cookiePrefixes.ageRestrictedBrandToNonAgeRestrictedCommunity, _this.brand, buttonData.targetBrand) && !buttonData.isActiveAdult && !isSameBrand) {
                    $headline.html(_this.replaceMergeFields(modalData.ageRestrictedBrandToNonAgeRestrictedCommunityHeadline, buttonData.targetBrand));
                    $description.html(_this.replaceMergeFields(modalData.ageRestrictedBrandToNonAgeRestrictedCommunityDescription, buttonData.targetBrand));
                    $descriptionMobile.html(_this.replaceMergeFields(modalData.ageRestrictedBrandToNonAgeRestrictedCommunityDescriptionMobile, buttonData.targetBrand));
                    $cta.html(_this.replaceMergeFields(modalData.ageRestrictedBrandToNonAgeRestrictedCommunityCta, buttonData.targetBrand));
                }
                // UC4 - User is leaving the current brand site to another non age restricted brand
                else if(!_this.getDoNotShowMessageCookie(_this.cookiePrefixes.differentBrandToNonAgeRestrictedBrand, _this.brand, buttonData.targetBrand) && !isSameBrand) {
                    $headline.html(_this.replaceMergeFields(modalData.differentBrandToNonAgeRestrictedBrandHeadline, buttonData.targetBrand));
                    $description.html(_this.replaceMergeFields(modalData.differentBrandToNonAgeRestrictedBrandDescription, buttonData.targetBrand));
                    $descriptionMobile.html(_this.replaceMergeFields(modalData.differentBrandToNonAgeRestrictedBrandDescriptionMobile, buttonData.targetBrand));
                    $cta.html(_this.replaceMergeFields(modalData.differentBrandToNonAgeRestrictedBrandCta, buttonData.targetBrand));
                }
                else{
                    bypassModal = true;
                }
            }
            //UC5 - No modal message would appear navigating between communities within an age restricted brand/same brand non age restricted to non restricted.
            if (bypassModal) {
                window.open(buttonData.href);
                e.preventDefault();

                if (e.relatedTarget)
                    AdobeLaunch.sendListingClickedEventFromCTA($(e.relatedTarget));
            }
            else {
                $cta.attr("href", buttonData.href);
                _this.relatedTargetCTA = $(e.relatedTarget);
            }
        });
        $cta.on('click', function(e) {
           e.preventDefault(); //Prevent the default action
            var url = $(this).attr('href'); //Get the URL from the href attribute

			if($doNotShowCheckBox.length && $doNotShowCheckBox[0].checked) {
                utils.setCookie(_this.cookieName, true, 30);
            }
            $doNotShowCheckBox.prop("checked", false);
            $('#modal-experience').modal('hide');

            if (_this.relatedTargetCTA)
                AdobeLaunch.sendListingClickedEventFromCTA(_this.relatedTargetCTA);

            if(url) {
                    window.open(url, '_blank'); //Open the URL in a new tab
            } 
		});
 
        //ADA: trapping focus inside the experience message pop-up for screenreaders.
        var dialog = document.querySelector('#modal-experience');
        if (!dialog) return;      
        var firstFocusableElement = dialog.querySelector('#closeButton');
        var lastFocusableElement = dialog.querySelector('#experienceCheckbox');     
        if (!firstFocusableElement || !lastFocusableElement) return;
    
        dialog.addEventListener('keydown', function(e) {
            // If the first focusable element is focused and shift+tab is pressed
            if (e.target === firstFocusableElement && e.key === 'Tab' && e.shiftKey) {
                e.preventDefault();
                lastFocusableElement.focus();
            }
            // If the last focusable element is focused and tab is pressed
            else if (e.target === lastFocusableElement && e.key === 'Tab' && !e.shiftKey) {
                e.preventDefault();
                firstFocusableElement.focus();
            }
        });

    }

    this.getDoNotShowMessageCookie = function(useCaseType, currentBrand, targetBrand) {
        this.cookieName = useCaseType + currentBrand + targetBrand;
        return utils.getCookie(this.cookieName);
    }

    this.replaceMergeFields = function (originalText, targetBrand) {
        var brandName = this.brand;

        if (this.brand === 'AmericanWest') {
            brandName = 'American West';
        }

        if (targetBrand === 'AmericanWest') {
            targetBrand = 'American West';
        }

        return originalText.replace("{brand}", brandName).replace("{targetbrand}", targetBrand);
    }

}

 module.exports = component(ExperienceMessage);








