var component = require('../../../lib/js/component.js');

function TilesModuleLoadMore() {

    /**
     * Init for the load more functionality of the tile module
     * @param  {Object} element HTML element
     */
    this.initialize = function (element) {
        this.element = element;
        this.processing = false;
        this.loadMoreBtn = $(element).find('.LoadMore')[0];

        $(this.loadMoreBtn).on('click', this.loadMore.bind(this));
    };

    this.loadMore = function(evt) {
        evt.preventDefault();

        if(!this.processing) {
            this.processing = true;
            this.request = $.ajax({
                type: "GET",
                url: $(this.loadMoreBtn).attr("href"),
                success: this.handleResultsSuccess.bind(this),
                error: this.handleResultsError,
                dataType: 'html'
            });
        }
    };

    this.handleResultsError = function () {
        this.processing = false;
    };

    this.handleResultsSuccess = function (data) {
        $(data).insertBefore(this.element);
        $(this.element).remove();
    };
}

module.exports = component(TilesModuleLoadMore);
