var component = require('../../../lib/js/component.js');
var savedItemsService = require('../../../lib/js/savedItemsService.js');

function SavedItemsPage() {
  this.initialize = function (element) {
    // configure the saved items service to ignore page urls on this page when querying and saving
    savedItemsService.activateIgnorePageUrlSetting();
  };
}

module.exports = component(SavedItemsPage);
